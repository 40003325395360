import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import {
  tabBaseLocation,
  // tabAmbulatory,
  // tabWheelchair,
  // tabStretcher,
  tabFareList,
  tabAddServices,
} from "../../../actions/tabActions";
import NotAllowed from "../../NotAllowed";
import AdminSettingPaymentsBaseLocation from "./AdminSettingPaymentsBaseLocation";
import AdminSettingPaymentsFare from "./AdminSettingPaymentsFare";
import AdminSettingPaymentsFareTable from "./AdminSettingPaymentsFareTable";

const AdminSettingPaymentsComponent = () => {
  const myState = useSelector((state) => state.changeTheTab);
  const service = useSelector((state) => state.service);
  const dispatch = useDispatch();
  const auth = useSelector((x) => x.auth);

  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (auth?.user?.permissionId?.accounting?.isAllow) {
      dispatch(tabFareList());
    }
  }, []);
  return (
    <div className="">
      <div
        className="mobile-close-overlay w-100 h-100"
        id="close-settings-overlay"
      />
      {/* <div className="settings-sidebar border py-3" id="mob-settings-sidebar"> */}
      <Link to="" className="d-block d-lg-none close-it" id="close-settings">
        <svg
          className="svg-inline--fa fa-times fa-w-11"
          aria-hidden="true"
          focusable="false"
          data-prefix="fa"
          data-icon="times"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 352 512"
          data-fa-i2svg=""
        >
          <path
            fill="currentColor"
            d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
          ></path>
        </svg>
      </Link>
      {/* <ul className="settings-menu" id="settingsMenu"> */}
      {/* <li
            className={myState.viewPayment === "baselocation" ? "active" : ""}
          > */}
      {myState.viewPayment === "baselocation" ? (
        <>
          {windowSize.width >= 992 ? (
            <>
              <div className="row align-items-center">
                <div className="col">
                  <div className="col-7 mitbsnav">
                    <ul className="nav nav-pills nav-justified">
                      {!auth?.user?.permissionId?.accounting?.isAllow && (
                        <>
                          <li className="nav-item active">
                            <NavLink
                              to="/payers"
                              onClick={() => dispatch(tabBaseLocation())}
                              className=""
                            >
                              Base Location
                            </NavLink>
                          </li>
                          <li className="nav-item">
                            <NavLink
                              to="/payers"
                              onClick={() => dispatch(tabAddServices())}
                              className=""
                            >
                              Clients Pricing Parameters
                            </NavLink>
                          </li>
                        </>
                      )}
                      <li className="nav-item">
                        <NavLink
                          to="/payers"
                          onClick={() => dispatch(tabFareList())}
                          className=""
                        >
                          Payers
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      ) : myState.viewPayment === "AddServices" ? (
        <>
          {windowSize.width >= 992 ? (
            <>
              <div className="row align-items-center">
                <div className="col">
                  <div className="col-7 mitbsnav">
                    <ul className="nav nav-pills nav-justified">
                      {!auth?.user?.permissionId?.accounting?.isAllow && (
                        <>
                          <li className="nav-item ">
                            <NavLink
                              to="/payers"
                              onClick={() => dispatch(tabBaseLocation())}
                              className=""
                            >
                              Base Location
                            </NavLink>
                          </li>
                          <li className="nav-item active">
                            <NavLink
                              to="/payers"
                              onClick={() => dispatch(tabAddServices())}
                              className=""
                            >
                              Clients Pricing Parameters
                            </NavLink>
                          </li>
                        </>
                      )}
                      <li className="nav-item">
                        <NavLink
                          to="/payers"
                          onClick={() => dispatch(tabFareList())}
                          className=""
                        >
                          Payers
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      ) : myState.viewPayment === "fareList" ? (
        <>
          {windowSize.width >= 992 ? (
            <>
              <div className="row align-items-center">
                <div className="col">
                  <div className="col-7 mitbsnav">
                    <ul className="nav nav-pills nav-justified">
                      {!auth?.user?.permissionId?.accounting?.isAllow && (
                        <>
                          <li className="nav-item ">
                            <NavLink
                              to="/payers"
                              onClick={() => dispatch(tabBaseLocation())}
                              className=""
                            >
                              Base Location
                            </NavLink>
                          </li>
                          <li className="nav-item ">
                            <NavLink
                              to="/payers"
                              onClick={() => dispatch(tabAddServices())}
                              className=""
                            >
                              Clients Pricing Parameters
                            </NavLink>
                          </li>
                        </>
                      )}
                      <li className="nav-item active">
                        <NavLink
                          to="/payers"
                          onClick={() => dispatch(tabFareList())}
                          className=""
                        >
                          Payers
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}

      <br />

      {/* <NavLink
            className="d-block f-15 text-dark-grey text-capitalize border-bottom-grey"
              to="/payers"
              onClick={() => dispatch(tabBaseLocation())}
            >
              Base Location
            </NavLink>
          </li>
          <li className={myState.viewPayment === "AddServices" ? "active" : ""}>
            <NavLink
              className="d-block f-15 text-dark-grey text-capitalize border-bottom-grey"
              to="/payers"
              onClick={() => dispatch(tabAddServices())}
            >
              Clients Pricing Parameters
            </NavLink>
          </li>
          <li className={myState.viewPayment === "fareList" ? "active" : ""}>
            <NavLink
              className="d-block f-15 text-dark-grey text-capitalize border-bottom-grey"
              to="/payers"
              onClick={() => dispatch(tabFareList())}
            >
              Fare List
            </NavLink>
          </li> */}
      {/* </ul> */}

      {/* <ul className="settings-menu" id="settingsMenu">
          {myState.viewPayment === "baselocation" ? (
            <>
              <li className="active">
                <NavLink
                  className="d-block f-15 text-dark-grey text-capitalize border-bottom-grey"
                  to="/payers"
                  onClick={() => dispatch(tabBaseLocation())}
                >
                  Base Location
                </NavLink>
              </li>

              <li>
                <NavLink
                  className="d-block f-15 text-dark-grey text-capitalize border-bottom-grey"
                  to="/payers"
                  onClick={() => dispatch(tabFareList())}
                >
                  Fare List
                </NavLink>
              </li>
            </>
          ) : myState.viewPayment === "Ambulatory" ? (
            <>
              <li className="">
                <NavLink
                  className="d-block f-15 text-dark-grey text-capitalize border-bottom-grey"
                  to="/payers"
                  onClick={() => dispatch(tabBaseLocation())}
                >
                  Base Location
                </NavLink>
              </li>
              {service.service &&
                service.service.length > 0 &&
                service.service.map((current, i) => {
                  return current.name === "Ambulatory" ? (
                    <li className="active">
                      <NavLink
                        className="d-block f-15 text-dark-grey text-capitalize border-bottom-grey"
                        to="/payers"
                        onClick={() => dispatch(tabAmbulatory())}
                      >
                        {current.name} Service
                      </NavLink>
                    </li>
                  ) : current.name === "Wheelchair" ? (
                    <li>
                      <NavLink
                        className="d-block f-15 text-dark-grey text-capitalize border-bottom-grey"
                        to="/payers"
                        onClick={() => dispatch(tabWheelchair())}
                      >
                        {current.name} Service
                      </NavLink>
                    </li>
                  ) : current.name === "Stretcher" ? (
                    <li>
                      <NavLink
                        className="d-block f-15 text-dark-grey text-capitalize border-bottom-grey"
                        to="/payers"
                        onClick={() => dispatch(tabStretcher())}
                      >
                        {current.name} Service
                      </NavLink>
                    </li>
                  ) : (
                    <></>
                  );
                })}
              <li>
                <NavLink
                  className="d-block f-15 text-dark-grey text-capitalize border-bottom-grey"
                  to="/payers"
                  onClick={() => dispatch(tabFareList())}
                >
                  Fare List
                </NavLink>
              </li>
            </>
          ) : myState.viewPayment === "Wheelchair" ? (
            <>
              <li className="">
                <NavLink
                  className="d-block f-15 text-dark-grey text-capitalize border-bottom-grey"
                  to="/payers"
                  onClick={() => dispatch(tabBaseLocation())}
                >
                  Base Location
                </NavLink>
              </li>
              {service.service &&
                service.service.length > 0 &&
                service.service.map((current, i) => {
                  return current.name === "Ambulatory" ? (
                    <li>
                      <NavLink
                        className="d-block f-15 text-dark-grey text-capitalize border-bottom-grey"
                        to="/payers"
                        onClick={() => dispatch(tabAmbulatory())}
                      >
                        {current.name} Service
                      </NavLink>
                    </li>
                  ) : current.name === "Wheelchair" ? (
                    <li className="active">
                      <NavLink
                        className="d-block f-15 text-dark-grey text-capitalize border-bottom-grey"
                        to="/payers"
                        onClick={() => dispatch(tabWheelchair())}
                      >
                        {current.name} Service
                      </NavLink>
                    </li>
                  ) : current.name === "Stretcher" ? (
                    <li>
                      <NavLink
                        className="d-block f-15 text-dark-grey text-capitalize border-bottom-grey"
                        to="/payers"
                        onClick={() => dispatch(tabStretcher())}
                      >
                        {current.name} Service
                      </NavLink>
                    </li>
                  ) : (
                    <></>
                  );
                })}
              <li>
                <NavLink
                  className="d-block f-15 text-dark-grey text-capitalize border-bottom-grey"
                  to="/payers"
                  onClick={() => dispatch(tabFareList())}
                >
                  Fare List
                </NavLink>
              </li>
            </>
          ) : myState.viewPayment === "Stretcher" ? (
            <>
              <li className="">
                <NavLink
                  className="d-block f-15 text-dark-grey text-capitalize border-bottom-grey"
                  to="/payers"
                  onClick={() => dispatch(tabBaseLocation())}
                >
                  Base Location
                </NavLink>
              </li>
              {service.service &&
                service.service.length > 0 &&
                service.service.map((current, i) => {
                  return current.name === "Ambulatory" ? (
                    <li>
                      <NavLink
                        className="d-block f-15 text-dark-grey text-capitalize border-bottom-grey"
                        to="/payers"
                        onClick={() => dispatch(tabAmbulatory())}
                      >
                        {current.name} Service
                      </NavLink>
                    </li>
                  ) : current.name === "Wheelchair" ? (
                    <li>
                      <NavLink
                        className="d-block f-15 text-dark-grey text-capitalize border-bottom-grey"
                        to="/payers"
                        onClick={() => dispatch(tabWheelchair())}
                      >
                        {current.name} Service
                      </NavLink>
                    </li>
                  ) : current.name === "Stretcher" ? (
                    <li className="active">
                      <NavLink
                        className="d-block f-15 text-dark-grey text-capitalize border-bottom-grey"
                        to="/payers"
                        onClick={() => dispatch(tabStretcher())}
                      >
                        {current.name} Service
                      </NavLink>
                    </li>
                  ) : (
                    <></>
                  );
                })}
              <li>
                <NavLink
                  className="d-block f-15 text-dark-grey text-capitalize border-bottom-grey"
                  to="/payers"
                  onClick={() => dispatch(tabFareList())}
                >
                  Fare List
                </NavLink>
              </li>
            </>
          ) : myState.viewPayment === "fareList" ? (
            <>
              <li className="">
                <NavLink
                  className="d-block f-15 text-dark-grey text-capitalize border-bottom-grey"
                  to="/payers"
                  onClick={() => dispatch(tabBaseLocation())}
                >
                  Base Location
                </NavLink>
              </li>
              {service.service &&
                service.service.length > 0 &&
                service.service.map((current, i) => {
                  return current.name === "Ambulatory" ? (
                    <li>
                      <NavLink
                        className="d-block f-15 text-dark-grey text-capitalize border-bottom-grey"
                        to="/payers"
                        onClick={() => dispatch(tabAmbulatory())}
                      >
                        {current.name} Service
                      </NavLink>
                    </li>
                  ) : current.name === "Wheelchair" ? (
                    <li>
                      <NavLink
                        className="d-block f-15 text-dark-grey text-capitalize border-bottom-grey"
                        to="/payers"
                        onClick={() => dispatch(tabWheelchair())}
                      >
                        {current.name} Service
                      </NavLink>
                    </li>
                  ) : current.name === "Stretcher" ? (
                    <li>
                      <NavLink
                        className="d-block f-15 text-dark-grey text-capitalize border-bottom-grey"
                        to="/payers"
                        onClick={() => dispatch(tabStretcher())}
                      >
                        {current.name} Service
                      </NavLink>
                    </li>
                  ) : (
                    <></>
                  );
                })}
              <li className="active">
                <NavLink
                  className="d-block f-15 text-dark-grey text-capitalize border-bottom-grey"
                  to="/payers"
                  onClick={() => dispatch(tabFareList())}
                >
                  Fare List
                </NavLink>
              </li>
            </>
          ) : (
            <></>
          )}

        </ul> */}
      {/* </div> */}

      <div className="d-lg-none">
        <div className="settings-box rounded">
          <select
            className="custom-select custom-select mb-3"
            onChange={(e) => dispatch({ type: e.target.value })}
          >
            {!auth?.user?.permissionId?.accounting?.isAllow && (
              <>
                <option selected value="TAB_BASELOCATION">
                  Base Location
                </option>
                <option value="TAB_ADDSERVICES">
                  Clients Pricing Parameters
                </option>
              </>
            )}
            <option value="TAB_FARELIST">Payers</option>
          </select>
        </div>
      </div>

      <div className="settings-box  rounded">
        <form
          method="POST"
          id="editSettings"
          className="ajax-form"
          autoComplete="off"
        >
          {/* <input type="search" className="autocomplete-password" style={{ opacity: "0", position: "absolute" }} autoComplete="off" />
                        <input type="password" className="autocomplete-password" style={{ opacity: "0", position: "absolute" }} autoComplete="off" />
                        <input type="email" name="f_email" className="autocomplete-password" readonly="" style={{ opacity: 0, position: "absolute" }} autoComplete="off" />
                        <input type="text" name="f_slack_username" className="autocomplete-password" readonly="" style={{ opacity: 0, position: "absolute" }} autoComplete="off" />
                        <input type="hidden" id="redirect_url" name="redirect_url" value="" autoComplete="off" />
                        <input type="hidden" name="_token" value="5mlTxRodlHNMW53tKvFGyucGNql3SECn5q3xqDEP" autoComplete="off" />
                        <input type="hidden" name="_method" value="PUT" autoComplete="off" />
                          <a className="mb-0 d-block d-lg-none text-dark-grey s-b-mob-sidebar" onClick="openSettingsSidebar()">
                            <svg className="svg-inline--fa fa-ellipsis-v fa-w-6" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="ellipsis-v" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" data-fa-i2svg="">
                              <path fill="currentColor" d="M96 184c39.8 0 72 32.2 72 72s-32.2 72-72 72-72-32.2-72-72 32.2-72 72-72zM24 80c0 39.8 32.2 72 72 72s72-32.2 72-72S135.8 8 96 8 24 40.2 24 80zm0 352c0 39.8 32.2 72 72 72s72-32.2 72-72-32.2-72-72-72-72 32.2-72 72z"></path>
                            </svg>
                          </a> */}
          <div className="s-b-inner s-b-notifications bg-white b-shadow-4 rounded">
            <div className="s-b-n-header d-lg-flex justify-content-between card-header border-bottom-grey">
              <div className="s-b-n-header" id="tabs">
                <h2 className="mb-0 p-20 f-21 font-weight-normal text-capitalize">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="21"
                    fill="currentColor"
                    className="bi bi-credit-card-2-back"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1z" />
                    <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm13 2v5H1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm-1 9H2a1 1 0 0 1-1-1v-1h14v1a1 1 0 0 1-1 1z" />
                  </svg>
                  <span>PRICING PARAMETERS</span>
                </h2>
              </div>
            </div>
            {myState.viewPayment === "baselocation" ? (
              auth?.user?.permissionId?.settings?.read === true ? (
                <AdminSettingPaymentsBaseLocation />
              ) : (
                <NotAllowed />
              )
            ) : myState.viewPayment === "AddServices" ? (
              auth?.user?.permissionId?.settings?.create === true ||
              auth?.user?.permissionId?.accounting?.isAllow ? (
                <AdminSettingPaymentsFare />
              ) : (
                <NotAllowed />
              )
            ) : myState.viewPayment === "fareList" ? (
              auth?.user?.permissionId?.settings?.read === true ||
              auth?.user?.permissionId?.accounting?.isAllow ? (
                <AdminSettingPaymentsFareTable />
              ) : (
                <NotAllowed />
              )
            ) : (
              <></>
            )}

            {/* <div className="s-b-n-content">
                                <div className="tab-content" id="nav-tabContent">
                                    <div className="tab-pane fade show active" id="nav-email" role="tabpanel" aria-labelledby="nav-email-tab">
                                        <div className="d-flex flex-wrap justify-content-between">
                                            <div className="col-lg-12 col-md-12 ntfcn-tab-content-left w-100 p-4 ">
                                                <input type="hidden" name="_method" value="PUT" autoComplete="off" />
                                                <div className="row">
                                                    <div className="col-lg-12 mb-3 border-bottom-grey">
                                                        <div className="form-group ">
                                                            <label className="f-14 text-dark-grey mb-12" data-label="" htmlFor="after_days">Base Location</label>
                                                            <sup className="text-danger f-12">*</sup>
                                                            <input type="text" className="form-control height-35 f-14" placeholder="Enter Your Base Location" value="" name="after_days" id="after_days" min="0" autoComplete="off" />
                                                        </div>
                                                    </div>
                                                    <table className="table table-nowrap custom-table mb-0">
                                                        <tbody>
                                                            <AdminSettingPaymentsAccordion />
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
          </div>
        </form>
      </div>
    </div>
  );
};
export default AdminSettingPaymentsComponent;
