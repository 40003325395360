/** @format */

import { useSelector } from "react-redux";
import { ENVIRONMENT } from "../constants";

export function navigateUserFunc(user, navigate) {
  let navigateUrl = "";

  // console.log(user, "new user 1212");
  if (user?.isExternalCompany === true || user?.isClient === true) {
    // navigate("/clients-list");
    navigate("/dashboard");
  } else if (
    ENVIRONMENT !== "local" &&
    (user?.disable === true || user?.isDriver === true)
  ) {
    navigate("/access-denied");
  } else {
    navigateUrl = Object.values(user?.permissionId).find((innerObj) => {
      return Object.entries(innerObj).some(([key, value]) => {
        return key === "isAllow" && value === true;
      });
    });

    // console.log(navigateUrl, "aaaaaaaaaaaaaaaaaaaaaaa")
    // if (user?.permissionId?.dashboard?.isAllow === true) {
    //     navigate('/dashboard-admin')
    // }
    // else if (user?.permissionId?.dashboardDriver?.isAllow === true) {
    //     navigate('/dashboard-driver')
    // }
    // else if (user?.permissionId?.dashboardDispatcher?.isAllow === true) {
    //     navigate('/dashboard-dispatcher')
    // }
    navigate(navigateUrl.urlRoute);
  }
}

// export function paginationDataToShow (limit, pageNumber, data) {
//     const entriesToShow = limit;
//     const totalPages = Math.ceil(data.length / entriesToShow);
//     const currentPage= pageNumber;
//     const newData = data.slice(
//         currentPage * entriesToShow - entriesToShow,
//         currentPage * entriesToShow
//     )
//     let paginationArr= []
//     for(let i =1 ; i<= totalPages; i++){
//         paginationArr[i-1]=i
//     }
//     const obj = {
//         displayingResults: newData.length,
//         limit: entriesToShow,
//         page: currentPage,
//         paginationArr,
//         results: newData,
//         totalPages,
//         totalResults: data.length,
//         completeData: data,
//     }
//     return obj
//   };

export function paginationDataToShow(
  limit,
  pageNumber,
  data,
  sortField,
  sortOrder = "asc",
  searchData = null
) {
  const entriesToShow = limit;
  const vehicle = localStorage.getItem("vehicle")
    ? JSON.parse(localStorage.getItem("vehicle"))
    : null;
  // console.log(data, searchData, searchData.length, "sortField");
  // Search
  let searchedData = [];
  searchedData = data;
  // if (searchData !== null && searchData !== '') {
  //     const searchValue = searchData.toLowerCase();
  //     searchedData = searchedData.filter(item =>
  //         Object.values(item).some(value => value.toString().toLowerCase().includes(searchValue))
  //     );
  // }

  if (searchData !== null && searchData !== "") {
    const searchValue = searchData?.toString().toLowerCase().trim();
    let searchFields = [
      //booking
      "orderId",
      "loadNumber",
      "specialNumber",
      "driverId",
      "assetId",
      "pickUpTime",
      "dropOffTime",
      "customerId",
      "pickUpAddress",
      "pickUpDate",
      "dropOffAddress",
      "type",
      "serviceName",
      "status",
      //passenger
      "uniqueId",
      "firstName",
      "lastName",
      "email",
      "phone",
      "notes",
      //petracking tracking table
      //route table peh kaam
      //vehicle
      "assetNo",
      "vehicleOwnership",
      "serviceType",
      "make",
      "model",
      "year",
      "vin",
      "numberPlate",
      "mileage",
      //invoicing peh kaam
      "reservationId",
      //driver pay peh kaam
      "reservationId",
      // user
      "permissionId",
      "vehicleId",
      "state",
      "username",
      // settlement
      "batchNumber",
      "orderId",
      "netAmount",
      "reservations",
      "reservations[0]",
      // "reservations[0].assetId.assetNo",
      "datePaid",
      // "status",
      //accounting overview home
      "driverId.vehicleId",
      "totalPay",
      // "reservation.orderId",
    ];

    if (searchValue === "not started") {
      // Special case: Search for "requested" or "scheduled" in the status field
      // const requestedResults = searchedData?.filter(
      //   (item) => item.status?.toLowerCase() === "requested" || []
      // );

      // const scheduledResults = searchedData?.filter(
      //   (item) => item.status?.toLowerCase() === "scheduled"
      // );
      const requestedResults = (searchedData || []).filter(
        (item) => item?.status?.toLowerCase() === "requested"
      );

      const scheduledResults = (searchedData || []).filter(
        (item) => item?.status?.toLowerCase() === "scheduled"
      );

      searchedData = [...requestedResults, ...scheduledResults];
    } else if (searchValue === "not accepted") {
      const requestedResults = (searchedData || []).filter(
        (item) => item?.status?.toLowerCase() === "requested" && item?.driverId
      );
      searchedData = [...requestedResults];
    } else if (searchValue === "unassigned") {
      const requestedResults = (searchedData || []).filter(
        (item) => !item?.driverId
      );
      searchedData = [...requestedResults];
    } else if (
      searchValue == "0.0" ||
      searchValue == "0." ||
      searchValue == "0.00" ||
      searchValue == "$0.0" ||
      searchValue == "$0" ||
      searchValue == "$0." ||
      searchValue == "$0.00"
    ) {
      const requestedResults = (searchedData || []).filter(
        (item) => item?.fareAndDistance?.driverAmount == 0
      );
      searchedData = [...requestedResults];
    } else if (searchValue == "not billable") {
      const requestedResults = (searchedData || []).filter(
        (item) => item?.notBillable === true
      );
      searchedData = [...requestedResults];
    } else if (searchValue == "online drivers") {
      const requestedResults = (searchedData || []).filter(
        (item) =>
          item?.isDriver === true &&
          item?.isOnline === true &&
          item?.disable === false &&
          item?.delete === false
      );
      searchedData = [...requestedResults];
    } else if (searchValue == "offline drivers") {
      const requestedResults = (searchedData || []).filter(
        (item) =>
          item?.isDriver === true &&
          item?.isOnline === false &&
          item?.disable === false &&
          item?.delete === false
      );
      searchedData = [...requestedResults];
    } else if (searchValue == "all drivers") {
      const requestedResults = (searchedData || []).filter(
        (item) => item?.isDriver === true
      );
      searchedData = [...requestedResults];
    } else if (searchValue == "active") {
      const requestedResults = (searchedData || []).filter(
        (item) => item?.disable === false
      );
      searchedData = [...requestedResults];
    } else if (searchValue == "inactive") {
      const requestedResults = (searchedData || []).filter(
        (item) => item?.disable === true
      );
      searchedData = [...requestedResults];
    } else if (
      searchValue == "n/a" ||
      searchValue == "n/" ||
      searchValue == "n"
    ) {
      const requestedResults = (searchedData || []).filter(
        (item) => !item?.datePaid
      );

      searchedData = [...requestedResults];
    }
    // else if (/^\d{2}\/\d{2}\/\d{4}$/.test(searchValue)) {
    //   // If searchValue matches the date format "MM/DD/YYYY"
    //   // Convert searchValue to the format "YYYY-MM-DD" for comparison
    //   let searchDate = searchValue.split("/");
    //   searchDate = searchDate[2] - searchDate[0] - searchDate[1];
    //   const requestedResults = (searchedData || []).filter(
    //     (item) => item.datePaid === searchDate
    //   );
    //   searchedData = [...requestedResults];
    // }
    else {
      searchedData = searchedData?.filter((item) => {
        // return Object.keys(item).some((key) => {
        //   const value = item[key];
        return searchFields.some((field) => {
          const value = item[field];
          if (Array.isArray(value)) {
            // Handle array of objects
            return value.some((arrayItem) =>
              Object.values(arrayItem).some((nestedValue) => {
                // Handle each property within the object
                // if (Array.isArray(nestedValue)) {
                //   // Handle the 'weekdays' array
                //   return nestedValue.some((dayItem) => {
                //     if (
                //       dayItem.dayName &&
                //       dayItem.dayName.toLowerCase().includes(searchValue)
                //     ) {
                //       // Check if 'active' property is true
                //       return dayItem.active === true;
                //     }
                //     return false;
                //   });
                // } else
                if (typeof nestedValue === "object" && nestedValue !== null) {
                  // Convert object to a string representation that makes sense for your search
                  const objectString = Object.values(nestedValue).join(" ");
                  return objectString.toLowerCase().includes(searchValue);
                } else {
                  // console.log(
                  //   nestedValue,
                  //   /^[0-9a-fA-F]{24}$/.test(nestedValue),
                  //   "nestedValue"
                  // );
                  if (!/^[0-9a-fA-F]{24}$/.test(nestedValue)) {
                    return nestedValue
                      ?.toString()
                      .toLowerCase()
                      .includes(searchValue);
                  }
                }
              })
            );
          } else if (
            // (key === "driverId" || key === "customerId") &&
            field === "datePaid" &&
            value
          ) {
            // If searching in the "user" or "customer" field, concatenate firstName and lastName
            let subStr = value?.substring(0, 10);
            let parts = subStr?.split("-");
            let displayDate = `${parts[1]}/${parts[2]}/${parts[0]}`;
            return (
              displayDate.toLowerCase().includes(searchValue) ||
              displayDate.toLowerCase().indexOf(searchValue) === 0
            );
          } else if (
            // (key === "driverId" || key === "customerId") &&
            (field === "driverId" || field === "customerId") &&
            typeof value === "object" &&
            value !== null
          ) {
            // If searching in the "user" or "customer" field, concatenate firstName and lastName
            const userFullName = `${value.firstName} ${value.lastName}`.trim();
            let cond =
              userFullName.toLowerCase().includes(searchValue) ||
              userFullName.toLowerCase().indexOf(searchValue) === 0;
            if (cond) {
              return cond;
            } else {
              const vehicleFound = vehicle?.find(
                (x) => x.id === value?.vehicleId
              );
              let check = `${vehicleFound?.assetNo}`.trim();
              cond =
                check.toLowerCase().includes(searchValue) ||
                check.toLowerCase().indexOf(searchValue) === 0;
              if (!cond) {
                check = `${vehicleFound?.serviceType?.name}`.trim();
                cond =
                  check.toLowerCase().includes(searchValue) ||
                  check.toLowerCase().indexOf(searchValue) === 0;
              }
              return cond;
            }
          } else if (
            // key === "reservationId" &&
            field === "reservationId" &&
            typeof value === "object" &&
            value !== null
          ) {
            const userFullName =
              `${value?.customerId?.firstName} ${value?.customerId?.lastName}`.trim();
            let cond =
              userFullName.toLowerCase().includes(searchValue) ||
              userFullName.toLowerCase().indexOf(searchValue) === 0;
            if (!cond) {
              const driverFullName =
                `${value?.driverId?.firstName} ${value?.driverId?.lastName}`.trim();
              cond =
                driverFullName.toLowerCase().includes(searchValue) ||
                driverFullName.toLowerCase().indexOf(searchValue) === 0;
            }
            if (!cond) {
              const check = `${value?.orderId}`.trim();
              cond =
                check.toLowerCase().includes(searchValue) ||
                check.toLowerCase().indexOf(searchValue) === 0;
            }
            if (!cond) {
              const check = `${value?.loadNumber}`.trim();
              cond =
                check.toLowerCase().includes(searchValue) ||
                check.toLowerCase().indexOf(searchValue) === 0;
            }
            if (!cond) {
              const check = `${value?.specialNumber}`.trim();
              cond =
                check.toLowerCase().includes(searchValue) ||
                check.toLowerCase().indexOf(searchValue) === 0;
            }
            if (!cond) {
              const check = `${value?.pickUpAddress?.address}`.trim();
              cond =
                check.toLowerCase().includes(searchValue) ||
                check.toLowerCase().indexOf(searchValue) === 0;
            }
            if (!cond) {
              const check = `${value?.dropOffAddress?.address}`.trim();
              cond =
                check.toLowerCase().includes(searchValue) ||
                check.toLowerCase().indexOf(searchValue) === 0;
            }
            if (!cond) {
              const check = `${value?.status}`.trim();
              cond =
                check.toLowerCase().includes(searchValue) ||
                check.toLowerCase().indexOf(searchValue) === 0;
            }
            if (!cond) {
              const check = `${value?.assetId?.assetNo}`.trim();
              cond =
                check.toLowerCase().includes(searchValue) ||
                check.toLowerCase().indexOf(searchValue) === 0;
            }
            return cond;
          } else if (typeof value === "object" && value !== null) {
            // Handle nested properties
            return Object.values(value).some((nestedValue) =>
              nestedValue?.toString().toLowerCase().includes(searchValue)
            );
          } else {
            // Handle non-nested properties
            // if (key === "firstName") {
            if (field === "firstName") {
              const userFullName = `${item.firstName} ${item.lastName}`.trim();
              return (
                userFullName.toLowerCase().includes(searchValue) ||
                userFullName.toLowerCase().indexOf(searchValue) === 0
              );
            } else {
              // if (field !== "id" || field !== "_id") {
              return value?.toString().toLowerCase().includes(searchValue);
              // }
            }
          }
        });
      });
    }
  }
  // Sort
  // const sortedData = searchedData.sort((a, b) => {
  //     const aValue = a[sortField];
  //     const bValue = b[sortField];
  //     console.log(a.assetId?.assetNo,bValue,"Value")
  //     return sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
  // });
  const sortedData =
    searchedData?.length > 0 &&
    searchedData?.sort((a, b) => {
      const comparableAValue = getComparableValue(a, sortField);
      const comparableBValue = getComparableValue(b, sortField);

      // Handle cases where the nested property is not present
      if (comparableAValue === null) {
        return sortOrder === "asc" ? -1 : 1;
      }
      if (comparableBValue === null) {
        return sortOrder === "asc" ? 1 : -1;
      }

      // Compare the values
      if (
        typeof comparableAValue === "number" &&
        typeof comparableBValue === "number"
      ) {
        return sortOrder === "asc"
          ? comparableAValue - comparableBValue
          : comparableBValue - comparableAValue;
      } else if (
        typeof comparableAValue === "string" &&
        typeof comparableBValue === "string"
      ) {
        return sortOrder === "asc"
          ? comparableAValue.localeCompare(comparableBValue)
          : comparableBValue.localeCompare(comparableAValue);
      } else {
        return sortOrder === "asc"
          ? comparableAValue - comparableBValue
          : comparableBValue - comparableAValue;
      }
    });

  const totalPages = Math.ceil(sortedData?.length / entriesToShow);
  const currentPage = pageNumber ? pageNumber : 1;
  const newData =
    sortedData.length > 0
      ? sortedData?.slice(
          currentPage * entriesToShow - entriesToShow,
          currentPage * entriesToShow
        )
      : [];

  // Calculate startPage and endPage
  const maxPagesToShow = 5;
  let startPage = 1;
  let endPage = totalPages;

  if (totalPages > maxPagesToShow) {
    const middle = Math.floor(maxPagesToShow / 2);
    if (currentPage <= middle) {
      startPage = 1;
    } else if (currentPage >= totalPages - middle) {
      startPage = totalPages - maxPagesToShow + 1;
    } else {
      startPage = currentPage - middle;
    }
    endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);
  }
  // const paginationArr = Array.from({ length: totalPages }, (_, i) => i + 1);
  const paginationArr = Array.from(
    { length: endPage - startPage + 1 },
    (_, i) => i + startPage
  );
  const obj = {
    displayingResults: newData?.length,
    limit: entriesToShow,
    currentPageNumber: currentPage,
    paginationArr,
    results: newData,
    totalPages,
    totalResults: sortedData?.length,
    completeData: sortedData,
    startPage,
    endPage,
  };

  return obj;
}

// Function to get comparable nested property value
const getComparableValue = (obj, field) => {
  const dotIndex = field.indexOf(".");

  if (dotIndex !== -1) {
    const beforeDot = field.slice(0, dotIndex);
    const afterDot = field.slice(dotIndex + 1);

    if (obj[beforeDot] && typeof obj[beforeDot] === "object") {
      return getComparableValue(obj[beforeDot], afterDot);
    } else {
      // Handle the case where the nested property is not present
      return null;
    }
  } else {
    if (field === "assetNo" || field === "routeNumber" || field === "vin") {
      return parseInt(obj[field]);
    } else {
      return obj[field];
    }
  }
};

export function paginationDataToShowRoute(
  limit,
  pageNumber,
  data,
  sortField,
  sortOrder = "asc",
  searchData = null
) {
  const entriesToShow = limit;

  // console.log(data, searchData, searchData.length, "sortField");
  // Search
  let searchedData = [];
  searchedData = data;
  // if (searchData !== null && searchData !== '') {
  //     const searchValue = searchData.toLowerCase();
  //     searchedData = searchedData.filter(item =>
  //         Object.values(item).some(value => value.toString().toLowerCase().includes(searchValue))
  //     );
  // }

  if (searchData !== null && searchData !== "") {
    const searchValue = searchData?.toString().toLowerCase().trim();

    if (searchValue === "not started") {
      // Special case: Search for "requested" or "scheduled" in the status field
      // const requestedResults = searchedData?.filter(
      //   (item) => item.status?.toLowerCase() === "requested" || []
      // );

      // const scheduledResults = searchedData?.filter(
      //   (item) => item.status?.toLowerCase() === "scheduled"
      // );
      const requestedResults = (searchedData || []).filter(
        (item) => item?.status?.toLowerCase() === "requested"
      );

      const scheduledResults = (searchedData || []).filter(
        (item) => item?.status?.toLowerCase() === "scheduled"
      );

      searchedData = [...requestedResults, ...scheduledResults];
    } else if (searchValue === "not accepted") {
      const requestedResults = (searchedData || []).filter(
        (item) => item?.status?.toLowerCase() === "requested" && item?.driverId
      );
      searchedData = [...requestedResults];
    } else if (searchValue == "online drivers") {
      const requestedResults = (searchedData || []).filter(
        (item) =>
          item?.isDriver === true &&
          item?.isOnline === true &&
          item?.disable === false &&
          item?.delete === false
      );
      searchedData = [...requestedResults];
    } else if (searchValue == "offline drivers") {
      const requestedResults = (searchedData || []).filter(
        (item) =>
          item?.isDriver === true &&
          item?.isOnline === false &&
          item?.disable === false &&
          item?.delete === false
      );
      searchedData = [...requestedResults];
    } else if (searchValue == "all drivers") {
      const requestedResults = (searchedData || []).filter(
        (item) => item?.isDriver === true
      );
      searchedData = [...requestedResults];
    } else {
      searchedData = searchedData?.filter((item) => {
        return Object.keys(item).some((key) => {
          const value = item[key];
          if (Array.isArray(value)) {
            // Handle array of objects
            return value.some((arrayItem) =>
              Object.values(arrayItem).some((nestedValue) => {
                // Handle each property within the object
                // if (Array.isArray(nestedValue)) {
                //   // Handle the 'weekdays' array
                //   return nestedValue.some((dayItem) => {
                //     if (
                //       dayItem.dayName &&
                //       dayItem.dayName.toLowerCase().includes(searchValue)
                //     ) {
                //       // Check if 'active' property is true
                //       return dayItem.active === true;
                //     }
                //     return false;
                //   });
                // } else
                if (typeof nestedValue === "object" && nestedValue !== null) {
                  // Convert object to a string representation that makes sense for your search
                  const objectString = Object.values(nestedValue).join(" ");
                  return objectString.toLowerCase().includes(searchValue);
                } else {
                  return nestedValue
                    ?.toString()
                    .toLowerCase()
                    .includes(searchValue);
                }
              })
            );
          } else if (
            (key === "driverId" || key === "customerId") &&
            typeof value === "object" &&
            value !== null
          ) {
            // If searching in the "user" or "customer" field, concatenate firstName and lastName
            const userFullName = `${value.firstName} ${value.lastName}`.trim();
            return (
              userFullName.toLowerCase().includes(searchValue) ||
              userFullName.toLowerCase().indexOf(searchValue) === 0
            );
          } else if (
            key === "reservationId" &&
            typeof value === "object" &&
            value !== null
          ) {
            const userFullName =
              `${value?.customerId?.firstName} ${value?.customerId?.lastName}`.trim();
            return (
              userFullName.toLowerCase().includes(searchValue) ||
              userFullName.toLowerCase().indexOf(searchValue) === 0
            );
          } else if (typeof value === "object" && value !== null) {
            // Handle nested properties
            return Object.values(value).some((nestedValue) =>
              nestedValue?.toString().toLowerCase().includes(searchValue)
            );
          } else {
            // Handle non-nested properties
            if (key === "firstName") {
              const userFullName = `${item.firstName} ${item.lastName}`.trim();
              return (
                userFullName.toLowerCase().includes(searchValue) ||
                userFullName.toLowerCase().indexOf(searchValue) === 0
              );
            } else {
              return value?.toString().toLowerCase().includes(searchValue);
            }
          }
        });
      });
    }
  }
  // Sort
  // const sortedData = searchedData.sort((a, b) => {
  //     const aValue = a[sortField];
  //     const bValue = b[sortField];
  //     console.log(a.assetId?.assetNo,bValue,"Value")
  //     return sortOrder === 'asc' ? aValue - bValue : bValue - aValue;
  // });
  const sortedData =
    searchedData?.length > 0 &&
    searchedData?.sort((a, b) => {
      const comparableAValue = getComparableValue(a, sortField);
      const comparableBValue = getComparableValue(b, sortField);

      // Handle cases where the nested property is not present
      if (comparableAValue === null) {
        return sortOrder === "asc" ? -1 : 1;
      }
      if (comparableBValue === null) {
        return sortOrder === "asc" ? 1 : -1;
      }

      // Compare the values
      if (
        typeof comparableAValue === "number" &&
        typeof comparableBValue === "number"
      ) {
        return sortOrder === "asc"
          ? comparableAValue - comparableBValue
          : comparableBValue - comparableAValue;
      } else if (
        typeof comparableAValue === "string" &&
        typeof comparableBValue === "string"
      ) {
        return sortOrder === "asc"
          ? comparableAValue.localeCompare(comparableBValue)
          : comparableBValue.localeCompare(comparableAValue);
      } else {
        return sortOrder === "asc"
          ? comparableAValue - comparableBValue
          : comparableBValue - comparableAValue;
      }
    });

  const totalPages = Math.ceil(sortedData?.length / entriesToShow);
  const currentPage = pageNumber ? pageNumber : 1;
  const newData =
    sortedData.length > 0
      ? sortedData?.slice(
          currentPage * entriesToShow - entriesToShow,
          currentPage * entriesToShow
        )
      : [];

  // Calculate startPage and endPage
  const maxPagesToShow = 5;
  let startPage = 1;
  let endPage = totalPages;

  if (totalPages > maxPagesToShow) {
    const middle = Math.floor(maxPagesToShow / 2);
    if (currentPage <= middle) {
      startPage = 1;
    } else if (currentPage >= totalPages - middle) {
      startPage = totalPages - maxPagesToShow + 1;
    } else {
      startPage = currentPage - middle;
    }
    endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);
  }
  // const paginationArr = Array.from({ length: totalPages }, (_, i) => i + 1);
  const paginationArr = Array.from(
    { length: endPage - startPage + 1 },
    (_, i) => i + startPage
  );
  const obj = {
    displayingResults: newData?.length,
    limit: entriesToShow,
    currentPageNumber: currentPage,
    paginationArr,
    results: newData,
    totalPages,
    totalResults: sortedData?.length,
    completeData: sortedData,
    startPage,
    endPage,
  };

  return obj;
}

export function searchChat(data, searchTerm) {
  searchTerm = searchTerm.toLowerCase(); // Convert the search term to lowercase for case-insensitive matching

  return data.filter((chat) => {
    // Check if any user's first name, last name, or the latest message contains the search term
    const userMatches = chat.users.some(
      (user) =>
        user.firstName.toLowerCase().includes(searchTerm) ||
        user.lastName.toLowerCase().includes(searchTerm)
    );

    // Check if the latest message contains the search term
    const messageMatches = chat.latestMessage.message
      .toLowerCase()
      .includes(searchTerm);

    // Return true if any match is found in users or latest message
    return userMatches || messageMatches;
  });
}

export function formArray(totalPages, currentPage) {
  // Calculate startPage and endPage
  const maxPagesToShow = 5;
  let startPage = 1;
  let endPage = totalPages;

  if (totalPages > maxPagesToShow) {
    const middle = Math.floor(maxPagesToShow / 2);
    if (currentPage <= middle) {
      startPage = 1;
    } else if (currentPage >= totalPages - middle) {
      startPage = totalPages - maxPagesToShow + 1;
    } else {
      startPage = currentPage - middle;
    }
    endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);
  }
  // const paginationArr = Array.from({ length: totalPages }, (_, i) => i + 1);
  const paginationArr = Array.from(
    { length: endPage - startPage + 1 },
    (_, i) => i + startPage
  );
  const obj = {
    currentPageNumber: currentPage,
    paginationArr,
    startPage,
    endPage,
  };

  return obj;
}
