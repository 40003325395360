/** @format */

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getChatsAction,
  getMessagesAction,
  startCall,
  createChatAction,
  createGroupAction,
} from "../../actions/chatActions";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  CHAT_SELECT_SUCCESS,
  MESSAGE_RECEIVE_SUCCESS,
  SERVERADDRESS,
} from "../../constants";
import CreateChatModal from "./createChatModal";
import { useState } from "react";
import CreateGroupModal from "./createGroupModal";
import ChatItem from "./chatItem";
import Loader from "../Loader";

import { sendMessageAction } from "../../actions/chatActions";
import MessageItem from "./messageItem";
import { useRef } from "react";
import defaultImg from "../../assets/images/profile.jpg";
import groupIcon from "../../assets/images/undraw_People_re_8spw.png";
import noChat from "../../assets/images/PngItem_1489584.png";
import moment from "moment";
import {
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
} from "agora-rtc-sdk-ng/esm";

import {
  VERSION,
  createClient,
  createCameraVideoTrack,
  createMicrophoneAudioTrack,
  onCameraChanged,
  onMicrophoneChanged,
} from "agora-rtc-sdk-ng/esm";
import Select from "react-select";
import { getReservationById } from "../../actions/reservationAction";
import axios from "axios";
// import Multiselect from "multiselect-react-dropdown";
// import defaultimg from '../../../assets/images/profile.jpg'

// console.log("Current SDK VERSION: ", VERSION);

onCameraChanged((device) => {
  // console.log("onCameraChanged: ", device);
});
onMicrophoneChanged((device) => {
  // console.log("onMicrophoneChanged: ", device);
});

const client = createClient({
  mode: "rtc",
  codec: "vp8",
});
let audioTrack;
let videoTrack;

function UsersChatNew() {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const users = useSelector((state) => state.users);
  const chats = useSelector((state) => state.chats);
  const reservation = useSelector((state) => state.reservation);
  const [selectedImage, setSelectedImage] = useState(defaultImg);

  const sockets = useSelector((state) => state.sockets);
  //   const sortedChats = [...chats?.list].sort((a, b) => {
  //     const dateA = a?.lastestMessage?.dateTime;
  //     const dateB = b?.lastestMessage?.dateTime;
  //     return dateB - dateA;
  //   });
  const { socketConnected, socketInstance } = sockets;

  const [chatModal, setChatModal] = useState(false);
  const [groupModal, setGroupModal] = useState(false);
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    if (auth.user)
      dispatch(
        getChatsAction({
          userId: auth.user.id,
          companyId: auth.user.companyId.id,
        })
      );
  }, [auth.user]);

  const selectChat = (chat) => {
    dispatch({
      type: CHAT_SELECT_SUCCESS,
      payload: chat,
    });
    if (chat.reservationId) {
      tripDataGet(chat.reservationId);
    }
    dispatch(getMessagesAction(chat.id, auth.user.id));
  };

  // console.log("chats mili", chats);
  // useEffect(() => {
  //   if (chats.list.length > 0 && !chats.selectedChat) {
  //     selectChat(chats.list[0]);
  //   }
  // }, [chats.list.length]);
  useEffect(() => {
    if (socketInstance) {
      socketInstance.on("typing", (user) => {
        // console.log(user, "typing");
        setIsTyping(user);
      });
      socketInstance.on("stop typing", (user) => setIsTyping(false));
    }
  }, [socketInstance]);
  useEffect(() => {
    // if (socketInstance) {
    //   socketInstance.on("typing", (user) => {
    //     console.log(user, "typing");
    //     setIsTyping(user);
    //   });
    //   socketInstance.on("stop typing", (user) => setIsTyping(false));
    // }
    if (socketInstance && chats.selectedChat) {
      if (
        chats?.messages?.list?.length > 0 &&
        chats?.messages?.list[0]?.chatId?.id !== chats.selectedChat.id
      ) {
        dispatch(getMessagesAction(chats.selectedChat.id, auth.user.id));
        socketInstance.emit("join room", chats.selectedChat.id);
        // console.log("join room", chats.selectedChat.id);
        // console.log("join room", chats.selectedChat.id);
      }
    }
  }, [chats.selectedChat]);
  useEffect(() => {
    if (socketInstance && chats.selectedChat) {
      dispatch(getMessagesAction(chats.selectedChat.id, auth.user.id));
      if (chats?.messages?.list[0]?.chatId?.id === chats.selectedChat.id) {
        socketInstance.emit("join room", chats.selectedChat.id);
        // console.log("join room2", chats.selectedChat.id);
      }
    }
  }, []);

  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPicker, setShowPicker] = useState(false);

  //   for message area

  const scrollRef = useRef();
  const [typing, setTyping] = useState(false);

  const messageHandler = (e) => {
    setMessage(e.target.value);

    if (!socketConnected) return;
    if (!typing) {
      setTyping(true);
      // console.log(
      //   {
      //     room: chats.selectedChat.id,
      //     user: {
      //       id: auth.user.id,
      //       name: auth.user.firstName + " " + auth.user.lastName,
      //       picUrl: auth.user.profilePic.fileUrl,
      //     },
      //     users: chats.selectedChat.users,
      //   },
      //   "abcdedf"
      // );
      socketInstance.emit("typing", {
        room: chats.selectedChat.id,
        user: {
          id: auth.user.id,
          name: auth.user.firstName + " " + auth.user.lastName,
          ...(auth?.user?.profilePic?.fileUrl && {
            picUrl: auth.user.profilePic.fileUrl,
          }),
        },
        users: chats.selectedChat.users,
      });
    }
    let lastTime = new Date().getTime();
    var time = 3000;
    setTimeout(() => {
      var timeNow = new Date().getTime();
      var timeDiff = timeNow - lastTime;
      if (timeDiff >= time) {
        socketInstance.emit("stop typing", {
          room: chats.selectedChat.id,
          user: {
            id: auth.user.id,
            name: auth.user.firstName + " " + auth.user.lastName,
          },
          users: chats.selectedChat.users,
        });
        setTyping(false);
      }
    }, time);
  };

  const sendMessage = (e) => {
    e.preventDefault();
    const dateTime = new Date();
    // console.log(message, "messagemessage");
    if (message !== "") {
      dispatch(
        sendMessageAction(
          {
            sender: auth.user,
            chatId: chats.selectedChat,
            message: message,
            dateTime,
          },
          () => {
            // console.log("nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn");
            // let arr = [];
            // chats.selectedChat.users.forEach((e) => {
            //   arr.push({ id: e.id });
            // });
            // let obj = {
            //   id: chats.selectedChat.id,
            //   users: arr,
            // };
            // console.log({
            //   sender: {
            //     firstName: auth.user.firstName,
            //     lastName: auth.user.lastName,
            //     id: auth.user.id,
            //     // profilePic: {
            //     //   fileUrl: auth.user.profilePic.fileUrl,
            //     // },
            //     ...(auth?.user?.profilePic?.fileUrl && {
            //       profilePic: { fileUrl: auth.user.profilePic.fileUrl },
            //     }),
            //   },
            //   // chatId: obj,
            //   chatId: chats.selectedChat,
            //   message: message,
            //   dateTime,
            // });
            socketInstance.emit("new message", {
              sender: {
                firstName: auth.user.firstName,
                lastName: auth.user.lastName,
                id: auth.user.id,
                // profilePic: {
                //   fileUrl: auth.user.profilePic.fileUrl,
                // },
                ...(auth?.user?.profilePic?.fileUrl && {
                  profilePic: { fileUrl: auth.user.profilePic.fileUrl },
                }),
              },
              // chatId: obj,
              chatId: chats.selectedChat,
              message: message,
              dateTime,
            });
            // console.log("nnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn");
          }
        )
      );
      setMessage("");
    }
  };

  useEffect(() => {
    const lastChildElement = scrollRef.current?.lastElementChild;
    lastChildElement?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      bottom: 0,
    });
  }, [chats.messages]);

  const receiver = chats?.selectedChat?.users?.find(
    (x) => x.id !== auth.user.id
  );
  // console.log(
  //   !chats?.selectedChat?.isGroup
  //     ? receiver?.isOnline
  //       ? "avatar-online"
  //       : !receiver?.isOnline
  //       ? "avatar-offline"
  //       : ""
  //     : "",
  //   chats?.selectedChat?.isGroup,
  //   "chats?.selectedChat?.isGroup"
  // );
  let lastDate = null;
  let currentSender = null;
  const [windowSize, setWindowSize] = useState({
    // width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        // width: window.innerWidth,
        height: window.innerHeight * 0.66 + "px",
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  // console.log(isTyping, "isTyping");
  // console.log(window.innerHeight, windowSize, "windowSize");
  const [isAudioOn, setIsAudioOn] = useState(false);
  const [isVideoOn, setIsVideoOn] = useState(false);
  const [isAudioPubed, setIsAudioPubed] = useState(false);
  const [isVideoPubed, setIsVideoPubed] = useState(false);
  const [isVideoSubed, setIsVideoSubed] = useState(false);
  const [isJoined, setIsJoined] = useState(false);
  const channel = useRef("");
  const appid = useRef("");
  const token = useRef("");
  const turnOnCamera = async (flag) => {
    flag = flag === undefined ? !isVideoOn : flag;
    setIsVideoOn(flag);

    if (videoTrack) {
      return videoTrack.setEnabled(flag);
    }
    videoTrack = await createCameraVideoTrack();
    videoTrack.play("camera-video");
  };
  const turnOnMicrophone = async (flag) => {
    flag = flag === undefined ? !isAudioOn : flag;
    setIsAudioOn(flag);

    if (audioTrack) {
      return audioTrack.setEnabled(flag);
    }

    audioTrack = await createMicrophoneAudioTrack();
    // audioTrack.play();
  };
  const startCalling = async (e) => {
    e.preventDefault();
    const dateTime = new Date();
    dispatch(
      startCall(
        {
          sender: auth.user,
          chatId: chats.selectedChat,
          message: "Calling",
          dateTime,
          isCall: true,
        },
        socketInstance,
        joinAndPublishAudio
      )
    );
    setMessage("");
  };
  const joinAndPublishAudio = async (token, channelName) => {
    if (!channel.current) {
      channel.current = "react-room";
    }

    if (isJoined) {
      await leaveChannel();
    }

    client.on("user-published", onUserPublish);

    // await client.join(
    //   appid.current,
    //   channel.current,
    //   token.current || null,
    //   null
    // );
    // console.log(token, "token", channelName);
    await client.join(
      "747e7634e8124c7da9bc8dde4a68b178",
      channelName,
      token,
      null
    );
    setIsJoined(true);
    // console.log("user on call hurray");
    // Publish audio
    await turnOnMicrophone(true);

    await client.publish(audioTrack);
    setIsAudioPubed(true);
  };

  const leaveChannel = async () => {
    setIsJoined(false);
    setIsAudioPubed(false);
    setIsVideoPubed(false);

    await client.leave();
  };

  const onUserPublish = async (user, mediaType) => {
    if (mediaType === "video") {
      const remoteTrack = await client.subscribe(user, mediaType);
      remoteTrack.play("remote-video");
      setIsVideoSubed(true);
    }
    if (mediaType === "audio") {
      const remoteTrack = await client.subscribe(user, mediaType);
      remoteTrack.play();
    }
  };

  const publishVideo = async () => {
    await turnOnCamera(true);

    if (!isJoined) {
      await joinAndPublishAudio();
    }
    await client.publish(videoTrack);
    setIsVideoPubed(true);
  };

  const publishAudio = async () => {
    await turnOnMicrophone(true);

    if (!isJoined) {
      await joinAndPublishAudio();
    }

    await client.publish(audioTrack);
    setIsAudioPubed(true);
  };

  // const theChatModal = () => {
  //   setChatModal(true);
  //   // Any additional logic you want to perform when opening the chat modal
  // };
  const [create, setCreate] = useState(false);
  const handleChat = () => {
    if (chatModal === true) {
      setCreate(false);
    }
    if (chatModal === false) {
      setCreate(true);
    }
    setChatModal(!chatModal);
  };

  // const [options] = useState([
  //   { name: 'Option 1️⃣', id: 1 },
  //   { name: 'Option 2️⃣', id: 2 }
  // ]);

  // Creating options array based on users.allEmployees
  const options =
    users.allEmployees && users.allEmployees.length > 0
      ? users.allEmployees.map((current, i) => ({
          value: JSON.stringify({
            id: current.id,
            name: current.firstName + " " + current.lastName,
            // email: current.email,
          }),
          label: (
            <div>
              <img
                className="inline-block"
                src={
                  current.profilePic
                    ? current.profilePic.fileUrl
                    : selectedImage
                } // Replace with the actual image URL from your data
                alt={current.firstName + " " + current.lastName}
                // alt="Profile Pic"
                style={{
                  width: "30px",
                  height: "30px",
                  marginRight: "10px",
                  borderRadius: "50%",
                }}
              />
              {current.firstName + " " + current.lastName}
            </div>
          ),
          // label: current.firstName + ' ' + current.lastName,
          key: i + 1,
        }))
      : [];
  // console.log(options, "options");

  const [selectedValue, setSelectedValue] = useState([]);
  // console.log(selectedValue, "selectedValue");

  const onSelect = (selectedList, selectedItem) => {
    // Set the state with the newly selected value
    setSelectedValue(selectedList);
  };

  const onRemove = (selectedList, removedItem) => {
    // Your logic when an item is removed
    // ...
    setSelectedValue(selectedList);
  };

  const [values, setValues] = useState({
    receiverId: "",

    user: auth.user,
    company: auth.user.companyId.id,
  });
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };

  const handleCreateChatMessage = async (event) => {
    event.preventDefault();
    if (selectedValue.length === 1) {
      dispatch(createChatAction());
    } else if (selectedValue.length > 1) {
      dispatch(createGroupAction());
    }
  };
  // const handleCreateChatMessage = async (event) => {
  //   event.preventDefault();
  //   // dispatch(createChatAction(groupData, successHandler));
  //   dispatch(createChatAction());
  // };
  // const handleCreateGroupMessage = async (event) => {
  //   event.preventDefault();
  //   dispatch(createGroupAction());
  // };
  // console.log(values, " values");
  // const dropdownRef = useRef();
  // const [isDropdownOpen, setDropdownOpen] = useState(false);
  // const [isApiCallPending, setApiCallPending] = useState(false);

  // // Function to handle clicks outside the dropdown
  // const handleClickOutside = (event) => {
  //   if (
  //     dropdownRef.current &&
  //     !dropdownRef.current.contains(event.target) &&
  //     !isDropdownOpen
  //   ) {
  //     // Click occurred outside the closed dropdown and not within the dropdown menu
  //     // Wait for a short delay to see if the dropdown is handling its internal events
  //     setTimeout(() => {
  //       if (!isApiCallPending) {
  //         // Trigger API call only if it hasn't been triggered by the dropdown's internal events
  //         // Make your API call here
  //         // Example: fetch('your-api-endpoint').then(response => response.json()).then(data => console.log(data));
  //         console.log("API call triggered");
  //       }
  //     }, 100);
  //   }
  // };

  // const handleDropdownToggle = (state) => {
  //   // Update the dropdown state when it's opened or closed
  //   setDropdownOpen(state);

  //   // Set a flag to indicate that the dropdown's internal events are being processed
  //   setApiCallPending(state);
  // };

  // // Attach the click event listener to the document
  // useEffect(() => {
  //   document.addEventListener("click", handleClickOutside);

  //   // Clean up the event listener on component unmount
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside);
  //   };
  // }, [isDropdownOpen]);
  // const handleChatFooterClick = () => {
  //   // Trigger your API call here
  //   // Example: fetch('your-api-endpoint').then(response => response.json()).then(data => console.log(data));
  //   console.log("API call triggered on chat footer click");
  // };
  const handleInputClick = () => {
    // Trigger your API call here
    // Example: fetch('your-api-endpoint').then(response => response.json()).then(data => console.log(data));
    // console.log("API call triggered on input click");
    if (create) {
      if (selectedValue.length === 1) {
        const idArray = selectedValue.map((item) => {
          try {
            // Parse the JSON string into a JavaScript object
            const jsonObject = JSON.parse(item.value);

            // Return the 'id' property
            return jsonObject.id;
          } catch (error) {
            console.error("Error parsing JSON:", error);
            return null; // Handle the error, e.g., return null for failed parsing
          }
        });
        // console.log(idArray[0]);
        dispatch(
          createChatAction(
            {
              receiverId: idArray[0],
              user: auth.user,
              company: auth.user.companyId.id,
            },
            successHandler
          )
        );
      } else if (selectedValue.length > 1) {
        const idArray = selectedValue.map((item) => {
          try {
            // Parse the JSON string into a JavaScript object
            const jsonObject = JSON.parse(item.value);

            // Return the 'id' property
            return jsonObject.id;
          } catch (error) {
            console.error("Error parsing JSON:", error);
            return null; // Handle the error, e.g., return null for failed parsing
          }
        });
        // console.log(idArray);
        dispatch(
          createGroupAction(
            {
              userId: auth.user.id,
              company: auth.user.companyId.id,
              chatName: "Group",
              isGroup: true,
              isCompanyGroup: false,
              latestMessage: null,
              groupAdmin: auth.user.id,
              groupType: "Selected Users",
              users: idArray,
              members: idArray,
            },
            successHandler
          )
        );
      }
    }
  };

  const successHandler = () => {
    setCreate(false);
    setChatModal(false);
    setSelectedValue([]);
  };
  const [values2, setValues2] = useState({
    _id: "",
  });

  const noShowData = {
    _id: reservation?.reservationDetails?.trip?.id,
    tripCancel: "No Show",
    pickUpTime: moment().format().toString().substring(0, 19) + ".000Z",
    pickUpLat: reservation?.reservationDetails?.pickUpAddress?.latitude,
    pickUpLng: reservation?.reservationDetails?.pickUpAddress?.longitude,
    dropOffTime: moment().format().toString().substring(0, 19) + ".000Z",
    dropOffLat: reservation?.reservationDetails?.dropOffAddress?.latitude,
    dropOffLng: reservation?.reservationDetails?.dropOffAddress?.longitude,
    isCompleted: false,
  };

  // useEffect(() => {
  //   if (chats.selectedChat && chats.selectedChat.reservationId) {
  //     tripDataGet();
  //   }
  // }, [chats]);

  const tripDataGet = async (reservationId) => {
    // const { data } = await axios.post(
    //   `${SERVERADDRESS}/v1/reservation/getReservationById`,
    // );
    dispatch(getReservationById({ _id: reservationId }));
    // getReservationById()
    // setValues2({
    //   ...values2,
    //   _id: data?.trip?.id,
    // });
  };

  return (
    <>
      <div className="card-body">
        <div className="">
          <div className="chat-window b-shadow-4" style={{ height: "80vh" }}>
            <div className="chat-cont-left">
              <div className="chat-header">
                <span>Chats</span>
                <span className="d-flex my-2 gap-2">
                  {/*    <button
                        className="btn btn-primary"
                        onClick={() => setChatModal(true)}
                        >
                        New Chat
                        </button>
                        <button
                        className="btn btn-primary"
                        onClick={() => setGroupModal(true)}
                        >
                        New Group
                        </button> */}
                  <a
                    className="chat-compose"
                    title="New Chat"
                    onClick={handleChat}
                    checked={chatModal}
                  >
                    <i
                      title="New Chat"
                      className="material-icons fa fa-plus-circle"
                    ></i>
                  </a>
                  {/* <a
                    className="chat-compose"
                    title="New Group"
                    onClick={() => setGroupModal(true)}
                  >
                    <i
                      title="New Group"
                      className="material-icons fa fa-users"
                    ></i>
                  </a> */}
                </span>
              </div>
              <form className="chat-search">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <i className="fas fa-search"></i>
                  </div>
                  <input
                    type="text"
                    className="form-control height-35"
                    placeholder="Search"
                  />
                </div>
              </form>
              <div className="chat-users-list">
                <div className="chat-scroll" style={{}}>
                  {/* offline circle */}
                  {/* <div className="avatar avatar-offline"> */}
                  {/* away */}
                  {/* <div className="avatar avatar-away"> */}
                  {/* online */}
                  {/* <div className="avatar avatar-online"> */}

                  <ul
                    // className="list-unstyled mb-0 mt-1 h-100 w-100 d-flex flex-column"
                    style={{ overflowY: "auto" }}
                  >
                    {chats.loading && <Loader />}
                    {/* {sortedChats?.map((chat) => { */}
                    {chats?.list?.map((chat) => {
                      const receiver = chat?.users?.find(
                        (x) => x.id !== auth?.user?.id
                      );
                      return (
                        <ChatItem
                          chat={chat}
                          receiver={receiver}
                          selectChat={selectChat}
                          isTyping={isTyping}
                        />
                      );
                    })}
                  </ul>
                  {/* {chatModal && (
                    <CreateChatModal
                      setOpenModal={setChatModal}
                      openModal={chatModal}
                    />
                  )} */}
                  {groupModal && (
                    <CreateGroupModal
                      setOpenModal={setGroupModal}
                      openModal={groupModal}
                    />
                  )}

                  {/* left chat side ka code */}
                </div>
              </div>
            </div>

            <div className="chat-cont-right">
              {chatModal && (
                <>
                  <div className="chat-header">
                    <a id="back_user_list" href="" className="back-user-list">
                      <i className="material-icons">chevron_left</i>
                    </a>
                    <div
                      className="row"
                      style={{ margin: "auto", width: "100%" }}
                      // ref={dropdownRef}
                    >
                      To:
                      <div className="" style={{ width: "100%" }}>
                        <Select
                          options={options} // Options to display in the dropdown
                          value={selectedValue} // Preselected value to persist in dropdown
                          onChange={onSelect} // Function will trigger on select event
                          onRemove={onRemove} // Function will trigger on remove event
                          displayValue="name" // Property name to display in the dropdown options
                          isMulti
                          // onMenuOpen={() => handleDropdownToggle(true)}
                          // onMenuClose={() => handleDropdownToggle(false)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="chat-body">
                    <div className="chat-scroll">
                      <ul
                        // className="list-unstyled relative"
                        className="list-unstyled"
                        ref={scrollRef}
                        style={{
                          overflowY: "auto",
                          minHeight: windowSize.height,
                        }}
                      >
                        {" "}
                        {chats?.messages?.loading ? (
                          <Loader />
                        ) : (
                          chats?.messages?.list.length === 0 && (
                            <p className="text-center bg-white p-3">
                              No messages in the chat, start with sending a
                              message!
                            </p>
                          )
                        )}
                        {chats?.messages?.list.map((chatMessage) => {
                          const date = moment(
                            chatMessage?.dateTime
                            // chatMessage.dateTime.toString().substring(0, 10)
                          );
                          const currentDate = moment(date);
                          const formattedDate =
                            currentDate.format("MMM DD, YYYY"); // Customize the format as needed

                          const isToday = currentDate.isSame(moment(), "day");
                          let displayDate = isToday
                            ? "Today"
                            : moment(formattedDate).format("ll");

                          // Only render the date if it has changed since the last message
                          const renderDate = lastDate !== displayDate;
                          lastDate = displayDate;

                          let isSameSender =
                            currentSender === chatMessage?.sender?.id;
                          if (renderDate) {
                            isSameSender = false;
                          }
                          // If the sender changes, update the currentSender variable
                          if (!isSameSender) {
                            currentSender = chatMessage?.sender?.id;
                          }
                          return (
                            <MessageItem
                              chatMessage={chatMessage}
                              renderDate={renderDate}
                              displayDate={displayDate}
                              isSameSender={isSameSender}
                              noShowData={noShowData}
                            />
                          );
                        })}
                        {isTyping &&
                          chats?.selectedChat?.id == isTyping.room && (
                            <li className="media d-flex received">
                              <div
                                className="avatar"
                                style={{ backgroundColor: "#f5f5f6" }}
                              >
                                <img
                                  src={
                                    isTyping?.user?.picUrl
                                      ? isTyping?.user?.picUrl !== "null"
                                        ? isTyping?.user?.picUrl
                                        : defaultImg
                                      : defaultImg
                                  }
                                  alt="User Image"
                                  className="chat-avatar-img rounded-circle centralized-avatar"
                                />
                              </div>
                              <div className="media-body">
                                <div className="msg-box">
                                  <div>
                                    <div className="msg-typing">
                                      <span></span>
                                      <span></span>
                                      <span></span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </li>
                          )}
                      </ul>
                    </div>
                  </div>
                  <div className="chat-footer">
                    <form
                      className="mb-3 mx-2"
                      onSubmit={sendMessage}
                      // onSubmit={handleCreateChatMessage}
                    >
                      <div className="input-group">
                        <div className="input-group-prepend">
                          {/* <div className="btn-file btn">
                            <i className="fas fa-paperclip"></i>
                            <input type="file" />
                          </div> */}
                        </div>
                        <input
                          type="text"
                          className="input-msg-send form-control height-35"
                          placeholder="Aa"
                          onChange={messageHandler}
                          onClick={handleInputClick}
                          // onChange={message}
                          value={message}
                          required
                        />
                        <div
                          className="input-group-append"
                          style={{ zIndex: 0 }}
                        >
                          <button
                            type="submit"
                            className="btn msg-send-btn height-35"
                          >
                            <i className="fas fa-paper-plane"></i>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </>
              )}

              {!chats.selectedChat
                ? !chatModal && (
                    <div
                      className="dash-stats-list"
                      style={{ textAlign: "center" }}
                    >
                      <br />
                      <br />
                      <img
                        alt=""
                        src={noChat}
                        style={{
                          width: "145px",
                          opacity: "0.5",
                          marginTop: "100px",
                        }}
                      />
                      <br />
                      <br />
                      <p style={{ opacity: "0.5", fontSize: "20px" }}>
                        No chats selected
                      </p>
                    </div>
                  )
                : !chatModal && (
                    <>
                      <div className="chat-header d-flex">
                        <a
                          id="back_user_list"
                          href=""
                          className="back-user-list"
                        >
                          <i className="material-icons">...</i>
                        </a>
                        <div className="media d-flex">
                          <div className="media-img-wrap">
                            <div
                              className={`avatar ${
                                !chats?.selectedChat?.isGroup
                                  ? receiver?.isOnline
                                    ? "avatar-online"
                                    : !receiver?.isOnline
                                    ? "avatar-offline"
                                    : ""
                                  : ""
                              }`}
                            >
                              {/* <img src="images/profiles/avatar-02.jpg" alt="User Image" className="chat-avatar-img rounded-circle"/> */}
                              <img
                                src={
                                  chats?.selectedChat?.isGroup
                                    ? groupIcon
                                    : receiver?.profilePic
                                    ? receiver?.profilePic.fileUrl
                                    : defaultImg
                                }
                                alt="avatar"
                                className="rounded-circle d-flex align-self-center me-3-wala-new shadow-1-strong object-fit-cover inline-block"
                                width="40"
                                height="40"
                              />
                            </div>
                          </div>
                          <div className="media-body">
                            <div className="user-name">
                              {chats?.selectedChat?.isGroup
                                ? chats?.selectedChat?.chatName
                                : receiver
                                ? `${receiver.firstName} ${receiver.lastName}`
                                : ""}
                            </div>
                            <div className="user-status">{`${
                              !chats?.selectedChat?.isGroup
                                ? receiver?.isOnline
                                  ? "online"
                                  : !receiver?.isOnline
                                  ? "offline"
                                  : ""
                                : chats?.selectedChat?.users?.length +
                                  " members"
                            }`}</div>
                          </div>
                        </div>
                        <div className="chat-options">
                          {/* <a href="">
                        <i className="material-icons fa fa-phone"></i>
                      </a> */}
                          <button
                            onClick={startCalling}
                            className={isJoined ? "button-on" : ""}
                          >
                            <i className="material-icons fa fa-phone"></i>
                          </button>
                          {/* <a href="">
                            <i className="material-icons icon-options-vertical"></i>
                          </a> */}
                        </div>
                      </div>
                      <div className="chat-body">
                        <div className="chat-scroll">
                          <ul
                            // className="list-unstyled relative"
                            className="list-unstyled"
                            ref={scrollRef}
                            style={{
                              overflowY: "auto",
                              minHeight: windowSize.height,
                            }}
                          >
                            {" "}
                            {chats?.messages.loading ? (
                              <Loader />
                            ) : (
                              chats?.messages?.list.length === 0 && (
                                <p className="text-center bg-white p-3">
                                  No messages in the chat, start with sending a
                                  message!
                                </p>
                              )
                            )}
                            {chats?.messages?.list.map((chatMessage) => {
                              const date = moment(
                                chatMessage?.dateTime
                                // chatMessage.dateTime.toString().substring(0, 10)
                              );

                              const currentDate = moment(date);
                              const formattedDate =
                                currentDate.format("MMM DD, YYYY"); // Customize the format as needed

                              const isToday = currentDate.isSame(
                                moment(),
                                "day"
                              );
                              let displayDate = isToday
                                ? "Today"
                                : moment(formattedDate).format("ll");

                              // Only render the date if it has changed since the last message
                              const renderDate = lastDate !== displayDate;
                              lastDate = displayDate;

                              let isSameSender =
                                currentSender === chatMessage?.sender?.id;
                              if (renderDate) {
                                isSameSender = false;
                              }
                              // If the sender changes, update the currentSender variable
                              if (!isSameSender) {
                                currentSender = chatMessage?.sender?.id;
                              }
                              return (
                                <MessageItem
                                  chatMessage={chatMessage}
                                  renderDate={renderDate}
                                  displayDate={displayDate}
                                  isSameSender={isSameSender}
                                  noShowData={noShowData}
                                />
                              );
                            })}
                            {isTyping &&
                              chats?.selectedChat?.id == isTyping.room &&
                              auth?.user?.id != isTyping?.user?.id && (
                                <li className="media d-flex received">
                                  <div
                                    className="avatar"
                                    style={{ backgroundColor: "#f5f5f6" }}
                                  >
                                    <img
                                      src={
                                        isTyping?.user?.picUrl
                                          ? isTyping.user.picUrl
                                          : defaultImg
                                      }
                                      alt="User Image"
                                      className="chat-avatar-img rounded-circle centralized-avatar"
                                    />
                                  </div>
                                  <div className="media-body">
                                    {isTyping &&
                                      chats?.selectedChat?.id ==
                                        isTyping.room && (
                                        <div style={{ fontSize: "12px" }}>
                                          {isTyping?.user?.name}
                                        </div>
                                      )}
                                    <div className="msg-box">
                                      <div>
                                        <div className="msg-typing">
                                          <span></span>
                                          <span></span>
                                          <span></span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              )}
                            {/* <li className="media sent">
                              <div className="media-body">
                                  <div className="msg-box">
                                      <div>
                                          <p>Hello. What can I do for you?</p>
                                          <ul className="chat-msg-info">
                                              <li>
                                                  <div className="chat-time">
                                                      <span>8:30 AM</span>
                                                  </div>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </li> */}
                            {/* <li className="media sent">
                              <div className="media-body">
                                  <div className="msg-box">
                                      <div>
                                          <p>Where?</p>
                                          <ul className="chat-msg-info">
                                              <li>
                                                  <div className="chat-time">
                                                      <span>8:42 AM</span>
                                                  </div>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                                  <div className="msg-box">
                                      <div>
                                          <p>OK, my name is Limingqiang. I like singing, playing basketballand so on.</p>
                                          <ul className="chat-msg-info">
                                              <li>
                                                  <div className="chat-time">
                                                      <span>8:42 AM</span>
                                                  </div>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                                  <div className="msg-box">
                                      <div>
                                          <div className="chat-msg-attachments">
                                              <div className="chat-attachment">
                                                  <img src="images/img-01.jpg" alt=""/>
                                                  <div className="chat-attach-caption">placeholder.jpg</div>
                                                  <a href="" className="chat-attach-download">
                                                      <i className="fas fa-download"></i>
                                                  </a>
                                              </div>
                                          </div>
                                          <ul className="chat-msg-info">
                                              <li>
                                                  <div className="chat-time">
                                                      <span>8:50 AM</span>
                                                  </div>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </li>
                          <li className="media d-flex received">
                              <div className="avatar">
                                  <img src="images/profiles/avatar-02.jpg" alt="User Image" className="chat-avatar-img rounded-circle"/>
                              </div>
                              <div className="media-body">
                                  <div className="msg-box">
                                      <div>
                                          <p>You wait for notice.</p>
                                          <p>Consectetuorem ipsum dolor sit?</p>
                                          <p>Ok?</p>
                                          <ul className="chat-msg-info">
                                              <li>
                                                  <div className="chat-time">
                                                      <span>8:55 PM</span>
                                                  </div>
                                              </li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </li>
                          <li className="chat-date">Today</li>
                          <li className="media d-flex received">
                              <div className="avatar">
                                  <img src="images/profiles/avatar-02.jpg" alt="User Image" className="chat-avatar-img rounded-circle"/>
                              </div>
                              <div className="media-body">
                                  <div className="msg-box">
                                      <div>
                                          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit,</p>
                                          <ul className="chat-msg-info">
                                              <li>
                                                  <div className="chat-time">
                                                      <span>10:17 AM</span>
                                                  </div>
                                              </li>
                                              <li><a href="#">Edit</a></li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </li>
                          <li className="media sent">
                              <div className="media-body">
                                  <div className="msg-box">
                                      <div>
                                          <p>Lorem ipsum dollar sit</p>
                                          <div className="chat-msg-actions dropdown">
                                              <a href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                  <i className="fe fe-elipsis-v"></i>
                                              </a>
                                              <div className="dropdown-menu dropdown-menu-right">
                                                  <a className="dropdown-item" href="#">Delete</a>
                                              </div>
                                          </div>
                                          <ul className="chat-msg-info">
                                              <li>
                                                  <div className="chat-time">
                                                      <span>10:19 AM</span>
                                                  </div>
                                              </li>
                                              <li><a href="#">Edit</a></li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                          </li>
                          <li className="media d-flex received">
                              <div className="avatar">
                                  <img src="images/profiles/avatar-02.jpg" alt="User Image" className="chat-avatar-img rounded-circle"/>
                              </div>
                              <div className="media-body">
                                  <div className="msg-box">
                                      <div>
                                          <div className="msg-typing">
                                              <span></span>
                                              <span></span>
                                              <span></span>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </li> */}
                          </ul>
                        </div>
                      </div>
                      <div className="chat-footer">
                        <form className="mb-3 mx-2" onSubmit={sendMessage}>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              {/* <div className="btn-file btn">
                                <i className="fas fa-paperclip"></i>
                                <input type="file" />
                              </div> */}
                            </div>
                            <input
                              type="text"
                              className="input-msg-send form-control height-35"
                              placeholder="Aa"
                              onChange={messageHandler}
                              value={message}
                              required
                              // disabled={
                              //   !chats?.selectedChat?.acceptedUser ||
                              //   chats?.selectedChat?.acceptedUser ===
                              //     auth.user.id
                              //     ? false
                              //     : true
                              // }
                            />
                            <div className="input-group-append">
                              <button
                                type="submit"
                                // disabled={
                                //   !chats?.selectedChat?.acceptedUser ||
                                //   chats?.selectedChat?.acceptedUser ===
                                //     auth.user.id
                                //     ? false
                                //     : true
                                // }
                                className="btn msg-send-btn height-35"
                              >
                                <i className="fas fa-paper-plane"></i>
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </>
                  )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default UsersChatNew;
