/** @format */

import axios from "axios";
import { notifyFailure, notifySuccess } from "../components/toast";
import {
  USER_VIEW_REQUEST,
  USER_VIEW_SUCCESS,
  USER_VIEW_FAIL,
  USER_VIEW_REQUEST_V2,
  USER_VIEW_SUCCESS_V2,
  USER_VIEW_FAIL_V2,
  INACTIVE_USER_VIEW_REQUEST,
  INACTIVE_USER_VIEW_SUCCESS,
  INACTIVE_USER_VIEW_FAIL,
  ALL_ACTIVE_USER_VIEW_REQUEST,
  ALL_ACTIVE_USER_VIEW_SUCCESS,
  ALL_ACTIVE_USER_VIEW_FAIL,
  USER_CREATE_REQUEST,
  USER_CREATE_SUCCESS,
  USER_CREATE_FAIL,
  DRIVER_VIEW_REQUEST,
  DRIVER_VIEW_SUCCESS,
  DRIVER_VIEW_FAIL,
  DRIVER_CREATE_REQUEST,
  DRIVER_CREATE_SUCCESS,
  DRIVER_CREATE_FAIL,
  ALL_DRIVER_VIEW_REQUEST,
  ALL_DRIVER_VIEW_SUCCESS,
  ALL_DRIVER_VIEW_FAIL,
  ALL_DRIVER_AVAIL_FOR_ROUTES_VIEW_REQUEST,
  ALL_DRIVER_AVAIL_FOR_ROUTES_VIEW_SUCCESS,
  ALL_DRIVER_AVAIL_FOR_ROUTES_VIEW_FAIL,
  CLIENT_VIEW_REQUEST,
  CLIENT_VIEW_SUCCESS,
  CLIENT_VIEW_FAIL,
  CLIENT_CREATE_REQUEST,
  CLIENT_CREATE_SUCCESS,
  CLIENT_CREATE_FAIL,
  USER_UPDATE_REQUEST,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  CLIENT_UPDATE_REQUEST,
  CLIENT_UPDATE_SUCCESS,
  CLIENT_UPDATE_FAIL,
  CLIENT_OR_USER_DELETE_REQUEST,
  CLIENT_OR_USER_DELETE_SUCCESS,
  CLIENT_OR_USER_DELETE_FAIL,
  GET_CLIENTS_REQUEST,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAIL,
  STUDENT_CREATE_REQUEST,
  STUDENT_CREATE_SUCCESS,
  STUDENT_CREATE_FAIL,
  ROUTE_PASSENGER_CREATE_REQUEST,
  ROUTE_PASSENGER_CREATE_SUCCESS,
  ROUTE_PASSENGER_CREATE_FAIL,
  ALL_EMPLOYEES_DATA_REQUEST,
  ALL_EMPLOYEES_DATA_SUCCESS,
  ALL_EMPLOYEES_DATA_FAIL,
  ONLINE_USERS_DATA_REQUEST,
  ONLINE_USERS_DATA_SUCCESS,
  ONLINE_USERS_DATA_FAIL,
  GET_STUDENTS_REQUEST,
  GET_STUDENTS_SUCCESS,
  GET_STUDENTS_FAIL,
  GET_ROUTE_PASSENGERS_REQUEST,
  GET_ROUTE_PASSENGERS_SUCCESS,
  GET_ROUTE_PASSENGERS_FAIL,
  EXTERNAL_COMPANY_USER_VIEW_REQUEST,
  EXTERNAL_COMPANY_USER_VIEW_SUCCESS,
  EXTERNAL_COMPANY_USER_VIEW_FAIL,
  EXTERNAL_COMPANY_USER_UPDATE_REQUEST,
  EXTERNAL_COMPANY_USER_UPDATE_SUCCESS,
  EXTERNAL_COMPANY_USER_UPDATE_FAIL,
  EXTERNAL_COMPANY_USER_DELETE_REQUEST,
  EXTERNAL_COMPANY_USER_DELETE_SUCCESS,
  EXTERNAL_COMPANY_USER_DELETE_FAIL,
  PASSENGER_VIEW_REQUEST,
  PASSENGER_VIEW_SUCCESS,
  PASSENGER_VIEW_FAIL,
  PASSENGER_CREATE_REQUEST,
  PASSENGER_CREATE_SUCCESS,
  PASSENGER_CREATE_FAIL,
  PASSENGER_UPDATE_REQUEST,
  PASSENGER_UPDATE_SUCCESS,
  PASSENGER_UPDATE_FAIL,
  PASSENGER_DELETE_REQUEST,
  PASSENGER_DELETE_SUCCESS,
  PASSENGER_DELETE_FAIL,
  GET_PASSENGER_REQUEST,
  GET_PASSENGER_SUCCESS,
  GET_PASSENGER_FAIL,
  GET_VIEW_TRACK_REQUEST,
  GET_VIEW_TRACK_SUCCESS,
  GET_VIEW_TRACK_FAIL,
  USER_INDIVIDUAL_CREATE_REQUEST,
  USER_INDIVIDUAL_CREATE_SUCCESS,
  USER_INDIVIDUAL_CREATE_FAIL,
  SERVERADDRESS,
} from "../constants";

// ALL USER DISPLAY KA VIEW
export const getAllUser = (obj, limitDef, pageNum) => async (dispatch) => {
  dispatch({
    type: USER_VIEW_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/users/allUsers/?sortBy=firstName%3Aasc&limit=${limitDef}&page=${pageNum}`,
      obj
    );
    localStorage.setItem("users", JSON.stringify(data));
    dispatch({
      type: USER_VIEW_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    dispatch({
      type: USER_VIEW_FAIL,
      payload: err,
    });
  }
};

// // ALL USER DISPLAY KA VIEW WITHOUT PAGINATION
export const allCompanyUsers = (obj) => async (dispatch) => {
  dispatch({
    type: USER_VIEW_REQUEST_V2,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v2/users/allCompanyUsers`,
      obj
    );
    localStorage.setItem("users", JSON.stringify(data));
    dispatch({
      type: USER_VIEW_SUCCESS_V2,
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    dispatch({
      type: USER_VIEW_FAIL_V2,
      payload: err,
    });
  }
};

// ADD NEW USER
export const addUser = (obj, successHandler) => async (dispatch) => {
  dispatch({
    type: USER_CREATE_REQUEST,
  });
  try {
    const { data } = await axios.post(`${SERVERADDRESS}/v1/users/`, obj);
    dispatch({
      type: USER_CREATE_SUCCESS,
      payload: data,
    });
    notifySuccess("Client added Successfully !");
    successHandler();
  } catch (err) {
    // console.log(err);
    notifyFailure(err.response.data.message);
    dispatch({
      type: USER_CREATE_FAIL,
      payload: err,
    });
  }
};

// ALL DRIVER DISPLAY KA VIEW
export const getAllDriver = (obj) => async (dispatch) => {
  dispatch({
    type: DRIVER_VIEW_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/users/allDrivers/?sortBy=firstName%3Aasc&limit=10&page=1`,
      obj
    );
    localStorage.setItem("driver", JSON.stringify(data));
    dispatch({
      type: DRIVER_VIEW_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    dispatch({
      type: DRIVER_VIEW_FAIL,
      payload: err,
    });
  }
};

// ALL DRIVER DISPLAY KA VIEW FOR DROPDOWN
export const getAllDriverWithoutPagination = (obj) => async (dispatch) => {
  dispatch({
    type: ALL_DRIVER_VIEW_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/users/allDriversData`,
      obj
    );
    localStorage.setItem("allDriversWithoutPagination", JSON.stringify(data));
    dispatch({
      type: ALL_DRIVER_VIEW_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    dispatch({
      type: ALL_DRIVER_VIEW_FAIL,
      payload: err,
    });
  }
};

// ALL AVAILABLEDRIVERFORROUTES DISPLAY KA VIEW FOR DROPDOWN
export const allDriversAvailableForRoutes = (obj) => async (dispatch) => {
  dispatch({
    type: ALL_DRIVER_AVAIL_FOR_ROUTES_VIEW_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/users/allDriversAvailableForRoutes`,
      obj
    );
    localStorage.setItem("allDriversAvailableForRoutes", JSON.stringify(data));
    dispatch({
      type: ALL_DRIVER_AVAIL_FOR_ROUTES_VIEW_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    dispatch({
      type: ALL_DRIVER_AVAIL_FOR_ROUTES_VIEW_FAIL,
      payload: err,
    });
  }
};

// ADD NEW DRIVER
export const addDriver = (obj) => async (dispatch) => {
  dispatch({
    type: DRIVER_CREATE_REQUEST,
  });
  try {
    const { data } = await axios.post(`${SERVERADDRESS}/v1/users/`, obj);
    dispatch({
      type: DRIVER_CREATE_SUCCESS,
      payload: data,
    });
    // navigateUser()
  } catch (err) {
    // console.log(err);
    dispatch({
      type: DRIVER_CREATE_FAIL,
      payload: err,
    });
  }
};

// ALL CLIENT DISPLAY KA VIEW
export const getAllUsers = (obj, limitDef, pageNum) => async (dispatch) => {
  dispatch({
    type: CLIENT_VIEW_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/users/allUsers/?sortBy=firstName%3Aasc&limit=${limitDef}&page=${pageNum}`,
      obj
    );
    localStorage.setItem("client", JSON.stringify(data));
    dispatch({
      type: CLIENT_VIEW_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    dispatch({
      type: CLIENT_VIEW_FAIL,
      payload: err,
    });
  }
};

// ALL CLIENT DISPLAY KA VIEW
export const getAllClients = (obj) => async (dispatch) => {
  // console.log("chala==>", obj);
  dispatch({
    type: GET_CLIENTS_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/users/allClients`,
      obj
    );
    localStorage.setItem("allClients", JSON.stringify(data));
    dispatch({
      type: GET_CLIENTS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    dispatch({
      type: GET_CLIENTS_FAIL,
      payload: err,
    });
  }
};

// ADD NEW CLIENT
export const addClient =
  (obj, values, setValues, successHandler) => async (dispatch) => {
    dispatch({
      type: CLIENT_CREATE_REQUEST,
    });
    try {
      // console.log(values, "values in actions")
      const { data } = await axios.post(`${SERVERADDRESS}/v1/users/`, obj);
      notifySuccess("Client added Successfully !");
      successHandler();
      setValues({
        ...values,
        name: data.firstName + " " + data.lastName,
        customerId: data.id,
      });
      dispatch({
        type: CLIENT_CREATE_SUCCESS,
        payload: data,
      });
    } catch (err) {
      // console.log(err);
      notifyFailure(err.response.data.message);
      dispatch({
        type: CLIENT_CREATE_FAIL,
        payload: err,
      });
    }
  };

// ADD NEW STUDENT
export const addStudentAction = (obj, successHandler) => async (dispatch) => {
  dispatch({
    type: STUDENT_CREATE_REQUEST,
  });
  try {
    // console.log(values, "values in actions")
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/passenger/createPassenger`,
      obj
    );
    notifySuccess("Passenger added Successfully !");
    successHandler(data.id);
    dispatch({
      type: STUDENT_CREATE_SUCCESS,
      payload: data,
    });
    // navigate("/passengers");
  } catch (err) {
    // console.log(err);
    // notifyFailure(err.response.data.message);
    dispatch({
      type: STUDENT_CREATE_FAIL,
      payload: err,
    });
  }
};

// ADD NEW ROUTE PASSENGER
export const addRoutePassengerAction =
  (obj, successHandler) => async (dispatch) => {
    dispatch({
      type: ROUTE_PASSENGER_CREATE_REQUEST,
    });
    try {
      // console.log(values, "values in actions")
      const { data } = await axios.post(
        `${SERVERADDRESS}/v1/passenger/createPassenger`,
        obj
      );
      notifySuccess("Passenger added Successfully !");
      successHandler(data.id);
      dispatch({
        type: ROUTE_PASSENGER_CREATE_SUCCESS,
        payload: data,
      });
      // navigate("/passengers");
    } catch (err) {
      // console.log(err);
      // notifyFailure(err.response.data.message);
      dispatch({
        type: ROUTE_PASSENGER_CREATE_FAIL,
        payload: err,
      });
    }
  };

// ADD NEW PASSENGER FROM PASSENGER
export const addPassengerFormAction = (obj, navigate) => async (dispatch) => {
  dispatch({
    // type: PASSENGER_CREATE_REQUEST,
    type: STUDENT_CREATE_REQUEST,
  });
  try {
    // console.log(values, "values in actions")
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/passenger/createPassenger`,
      obj
    );
    notifySuccess("Passenger added Successfully !");
    dispatch({
      // type: PASSENGER_CREATE_SUCCESS,
      type: STUDENT_CREATE_SUCCESS,
      payload: data,
    });
    navigate("/passengers");
  } catch (err) {
    // console.log(err);
    notifyFailure(err.response.data.message);
    dispatch({
      // type: PASSENGER_CREATE_FAIL,
      type: STUDENT_CREATE_FAIL,
      payload: err,
    });
  }
};

// ADD NEW STUDENT
export const addStudentActionFromBooking =
  (obj, values, setValues, openDropDown) => async (dispatch) => {
    dispatch({
      type: STUDENT_CREATE_REQUEST,
    });
    try {
      // console.log(values, "values in actions")
      const { data } = await axios.post(
        `${SERVERADDRESS}/v1/passenger/createPassenger`,
        obj
      );
      notifySuccess("Passenger added Successfully !");
      setValues({
        ...values,
        name: data.firstName + " " + data.lastName,
        customerId: data.id,
      });
      openDropDown();
      dispatch({
        type: STUDENT_CREATE_SUCCESS,
        payload: data,
      });
    } catch (err) {
      // console.log(err);
      notifyFailure(err.response.data.message);
      dispatch({
        type: STUDENT_CREATE_FAIL,
        payload: err,
      });
    }
  };

// ALL CLIENT DISPLAY KA VIEW
export const getAllStudents = (obj) => async (dispatch) => {
  // console.log("chala==>", obj);
  dispatch({
    type: GET_STUDENTS_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/passenger/getPassengersByCompanyId`,
      obj
    );
    localStorage.setItem("allStudentList", JSON.stringify(data));
    // localStorage.setItem("list", JSON.stringify(data));
    dispatch({
      type: GET_STUDENTS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    dispatch({
      type: GET_STUDENTS_FAIL,
      payload: err,
    });
  }
};

// ALL CLIENT DISPLAY KA VIEW WITH EXTERNAL COMPANY ID
export const getPassengersByExternalCompanyId = (obj) => async (dispatch) => {
  // console.log("chala==>", obj);
  dispatch({
    type: GET_STUDENTS_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/passenger/getPassengersByExternalCompanyId`,
      obj
    );
    localStorage.setItem("allStudentList", JSON.stringify(data));
    // localStorage.setItem("list", JSON.stringify(data));
    dispatch({
      type: GET_STUDENTS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    dispatch({
      type: GET_STUDENTS_FAIL,
      payload: err,
    });
  }
};

// ALL ROUTE PASSENGER DISPLAY KA VIEW
export const getRoutePassengersByCompanyId = (obj) => async (dispatch) => {
  dispatch({
    type: GET_ROUTE_PASSENGERS_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/passenger/getRoutePassengersByCompanyId`,
      obj
    );
    localStorage.setItem("routePassengerList", JSON.stringify(data));
    dispatch({
      type: GET_ROUTE_PASSENGERS_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    dispatch({
      type: GET_ROUTE_PASSENGERS_FAIL,
      payload: err,
    });
  }
};

// yeh wala shyd ab tak driver part mein use horha
// Edit USER
export const editUserAction = (obj, handleSuccess) => async (dispatch) => {
  dispatch({
    type: USER_UPDATE_REQUEST,
  });
  try {
    // console.log(values, "values in actions")
    const { data } = await axios.patch(
      `${SERVERADDRESS}/v1/users/userById`,
      obj
    );
    // setValues({
    //   ...values,
    //   name: data.firstName + " " + data.lastName,
    //   customerId: data.id,
    // });
    notifySuccess("Profile updated successfully !");
    if (handleSuccess) {
      handleSuccess();
    }
    localStorage.setItem("user", JSON.stringify(data));
    dispatch({
      type: USER_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    notifyFailure(err?.response?.data?.message);

    dispatch({
      type: USER_UPDATE_FAIL,
      payload: err,
    });
  }
};

// Edit CLIENT
export const editClientAction = (obj, handleSuccess) => async (dispatch) => {
  dispatch({
    type: CLIENT_UPDATE_REQUEST,
  });
  try {
    // console.log(values, "values in actions")
    const { data } = await axios.patch(
      `${SERVERADDRESS}/v1/users/userById`,
      obj
    );
    // setValues({
    //   ...values,
    //   name: data.firstName + " " + data.lastName,
    //   customerId: data.id,
    // });
    if (handleSuccess) {
      handleSuccess();
      notifySuccess("Updated successfully !");
    }
    dispatch({
      type: CLIENT_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    notifyFailure(err?.response?.data?.message);
    dispatch({
      type: CLIENT_UPDATE_FAIL,
      payload: err,
    });
  }
};

// Edit CLIENT
export const documentStatus = (obj) => async (dispatch) => {
  dispatch({
    type: CLIENT_UPDATE_REQUEST,
  });
  try {
    const { data } = await axios.put(
      `${SERVERADDRESS}/v1/users/documentStatus`,
      obj
    );
    // if (handleSuccess) {
    //   handleSuccess();
    notifySuccess("Updated successfully !");
    // }
    dispatch({
      type: CLIENT_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    console.log(err);
    notifyFailure(err?.response?.data?.message);
    dispatch({
      type: CLIENT_UPDATE_FAIL,
      payload: err,
    });
  }
};

// DELETE CLIENT
export const deleteClientorUser = (obj, handleSuccess) => async (dispatch) => {
  dispatch({
    type: CLIENT_OR_USER_DELETE_REQUEST,
  });
  try {
    const { data } = await axios.patch(
      `${SERVERADDRESS}/v1/users/userById`,
      obj
    );
    if (handleSuccess) {
      handleSuccess();
      notifySuccess("Deleted successfully !");
    }
    dispatch({
      type: CLIENT_OR_USER_DELETE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    notifyFailure(err?.response?.data?.message);
    dispatch({
      type: CLIENT_OR_USER_DELETE_FAIL,
      payload: err,
    });
  }
};

// ADMIN ALL USER WITHOUT PAGINATION
export const allEmployees = (obj) => async (dispatch) => {
  dispatch({
    type: ALL_EMPLOYEES_DATA_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/users/allEmployees`,
      obj
    );
    localStorage.setItem("allEmployees", JSON.stringify(data));
    dispatch({
      type: ALL_EMPLOYEES_DATA_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err)
    dispatch({
      type: ALL_EMPLOYEES_DATA_FAIL,
      payload: err,
    });
  }
};

// ADMIN ALL USER ONLINE
export const onlineUsers = (obj) => async (dispatch) => {
  dispatch({
    type: ONLINE_USERS_DATA_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/users/onlineUsers`,
      obj
    );
    localStorage.setItem("onlineUsers", JSON.stringify(data));
    dispatch({
      type: ONLINE_USERS_DATA_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err)
    dispatch({
      type: ONLINE_USERS_DATA_FAIL,
      payload: err,
    });
  }
};

// DRIVER ALL USERS WITHOUT OTHER DRIVERS
export const allUsersForDriver = (obj) => async (dispatch) => {
  dispatch({
    type: ALL_EMPLOYEES_DATA_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/users/allUsersForDriver`,
      obj
    );
    localStorage.setItem("allEmployees", JSON.stringify(data));
    dispatch({
      type: ALL_EMPLOYEES_DATA_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err)
    dispatch({
      type: ALL_EMPLOYEES_DATA_FAIL,
      payload: err,
    });
  }
};

// EXTERNAL COMPANY ACTION
export const getPassengerByExternalCompanyId = (obj) => async (dispatch) => {
  dispatch({
    type: EXTERNAL_COMPANY_USER_VIEW_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/passenger/getPassengerByExternalCompanyId`,
      obj
    );
    localStorage.setItem("externalCompanyUsers", JSON.stringify(data));
    dispatch({
      type: EXTERNAL_COMPANY_USER_VIEW_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    dispatch({
      type: EXTERNAL_COMPANY_USER_VIEW_FAIL,
      payload: err,
    });
  }
};

// Edit EXTERNAL COMPANY CLIENT
export const updateExternalCompanyUser =
  (obj, handleSuccess) => async (dispatch) => {
    dispatch({
      type: EXTERNAL_COMPANY_USER_UPDATE_REQUEST,
    });
    try {
      const { data } = await axios.put(
        `${SERVERADDRESS}/v1/passenger/updatePassenger`,
        obj
      );
      if (handleSuccess) {
        handleSuccess();
        notifySuccess("Updated successfully !");
      }
      dispatch({
        type: EXTERNAL_COMPANY_USER_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (err) {
      // console.log(err);
      notifyFailure(err?.response?.data?.message);
      dispatch({
        type: EXTERNAL_COMPANY_USER_UPDATE_FAIL,
        payload: err,
      });
    }
  };

// DELETE EXTERNAL COMPANY CLIENT
export const deleteExternalCompanyUser =
  (obj, handleSuccess) => async (dispatch) => {
    dispatch({
      type: EXTERNAL_COMPANY_USER_DELETE_REQUEST,
    });
    try {
      const { data } = await axios.put(
        `${SERVERADDRESS}/v1/passenger/updatePassenger`,
        obj
      );
      if (handleSuccess) {
        handleSuccess();
        notifySuccess("Deleted successfully !");
      }
      dispatch({
        type: EXTERNAL_COMPANY_USER_DELETE_SUCCESS,
        payload: data,
      });
    } catch (err) {
      // console.log(err);
      notifyFailure(err?.response?.data?.message);
      dispatch({
        type: EXTERNAL_COMPANY_USER_DELETE_FAIL,
        payload: err,
      });
    }
  };

// ALL ACTIVE USER DISPLAY KA VIEW
export const getAllActiveUser =
  (obj, limitDef, pageNum) => async (dispatch) => {
    dispatch({
      type: ALL_ACTIVE_USER_VIEW_REQUEST,
    });
    try {
      const { data } = await axios.post(
        `${SERVERADDRESS}/v1/users/activeUsers/?sortBy=firstName%3Aasc&limit=${limitDef}&page=${pageNum}`,
        obj
      );
      localStorage.setItem("activeUsers", JSON.stringify(data));
      dispatch({
        type: ALL_ACTIVE_USER_VIEW_SUCCESS,
        payload: data,
      });
    } catch (err) {
      // console.log(err);
      dispatch({
        type: ALL_ACTIVE_USER_VIEW_FAIL,
        payload: err,
      });
    }
  };
// ALL INACTIVE USER DISPLAY KA VIEW
export const getAllInactiveUser =
  (obj, limitDef, pageNum) => async (dispatch) => {
    dispatch({
      type: INACTIVE_USER_VIEW_REQUEST,
    });
    try {
      const { data } = await axios.post(
        `${SERVERADDRESS}/v1/users/inactiveUsers/?sortBy=firstName%3Aasc&limit=${limitDef}&page=${pageNum}`,
        obj
      );
      localStorage.setItem("inactiveUsers", JSON.stringify(data));
      dispatch({
        type: INACTIVE_USER_VIEW_SUCCESS,
        payload: data,
      });
    } catch (err) {
      // console.log(err);
      dispatch({
        type: INACTIVE_USER_VIEW_FAIL,
        payload: err,
      });
    }
  };

// ALL PASSENGER DISPLAY KA VIEW
export const getAllPassengers =
  (obj, limitDef, pageNum) => async (dispatch) => {
    dispatch({
      type: PASSENGER_VIEW_REQUEST,
    });
    try {
      const { data } = await axios.post(
        `${SERVERADDRESS}/v1/passenger/getPassengerByExternalCompanyId`,
        obj
      );
      localStorage.setItem("passenger", JSON.stringify(data));
      dispatch({
        type: PASSENGER_VIEW_SUCCESS,
        payload: data,
      });
    } catch (err) {
      // console.log(err);
      dispatch({
        type: PASSENGER_VIEW_FAIL,
        payload: err,
      });
    }
  };

// ALL PASSENGER DISPLAY KA VIEW
export const getPassengersByCompanyIdWithPagination =
  (obj, limitDef, pageNum) => async (dispatch) => {
    dispatch({
      type: PASSENGER_VIEW_REQUEST,
    });
    try {
      const { data } = await axios.post(
        `${SERVERADDRESS}/v1/passenger/getPassengersByCompanyIdWithPagination`,
        obj
      );
      localStorage.setItem("passenger", JSON.stringify(data));
      dispatch({
        type: PASSENGER_VIEW_SUCCESS,
        payload: data,
      });
    } catch (err) {
      // console.log(err);
      dispatch({
        type: PASSENGER_VIEW_FAIL,
        payload: err,
      });
    }
  };

// Edit PASSENGER
export const editPassenger = (obj, handleSuccess) => async (dispatch) => {
  dispatch({
    type: PASSENGER_UPDATE_REQUEST,
  });
  try {
    const { data } = await axios.put(
      `${SERVERADDRESS}/v1/passenger/updatePassenger`,
      obj
    );
    if (handleSuccess) {
      handleSuccess();
      notifySuccess("Updated successfully !");
    }
    dispatch({
      type: PASSENGER_UPDATE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    notifyFailure(err?.response?.data?.message);
    dispatch({
      type: PASSENGER_UPDATE_FAIL,
      payload: err,
    });
  }
};

// DELETE PASSENGER
export const deletePassenger = (obj, handleSuccess) => async (dispatch) => {
  dispatch({
    type: PASSENGER_DELETE_REQUEST,
  });
  try {
    const { data } = await axios.put(
      `${SERVERADDRESS}/v1/passenger/updatePassenger`,
      obj
    );
    if (handleSuccess) {
      handleSuccess();
      notifySuccess("Deleted successfully !");
    }
    dispatch({
      type: PASSENGER_DELETE_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    notifyFailure(err?.response?.data?.message);
    dispatch({
      type: PASSENGER_DELETE_FAIL,
      payload: err,
    });
  }
};
// ALL TRACK DISPLAY KA VIEW
export const getTracksByCompanyId = (obj) => async (dispatch) => {
  // console.log("chala==>", obj);
  dispatch({
    type: GET_VIEW_TRACK_REQUEST,
  });
  try {
    const { data } = await axios.post(
      `${SERVERADDRESS}/v1/track/getTracksByCompanyId`,
      obj
    );
    localStorage.setItem("getTracks", JSON.stringify(data));
    dispatch({
      type: GET_VIEW_TRACK_SUCCESS,
      payload: data,
    });
  } catch (err) {
    // console.log(err);
    dispatch({
      type: GET_VIEW_TRACK_FAIL,
      payload: err,
    });
  }
};

export const createIndividualClient =
  (obj, successHandler) => async (dispatch) => {
    dispatch({
      type: USER_INDIVIDUAL_CREATE_REQUEST,
    });
    try {
      const { data } = await axios.post(
        `${SERVERADDRESS}/v1/users/createIndividualClient`,
        obj
      );
      notifySuccess("Permission Granted Successfully!");

      dispatch({
        type: USER_INDIVIDUAL_CREATE_SUCCESS,
        payload: data,
      });
      successHandler(data);
    } catch (err) {
      // console.log(err);
      notifyFailure(err.response.data.message);

      dispatch({
        type: USER_INDIVIDUAL_CREATE_FAIL,
        payload: err,
      });
    }
  };
