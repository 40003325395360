import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import shift1 from "../../../assets/images/load.png";
import {
  tabBaseLocation,
  // tabAmbulatory,
  // tabWheelchair,
  // tabStretcher,
  tabFareList,
  tabAddServices,
} from "../../../actions/tabActions";
import NotAllowed from "../../NotAllowed";
import AdminSettingPaymentsBaseLocation from "./AdminSettingPaymentsBaseLocation";
import AdminSettingPaymentsFare from "./AdminSettingPaymentsFare";
import AdminSettingPaymentsFareTable from "./AdminSettingPaymentsFareTable";
import { paginationDataToShow } from "../../../actions/generalActions";
import AdminSettingClientFareTableRow from "./AdminSettingClientFareTableRow";
import { getClientFaresByCompanyId } from "../../../actions/fareActions";

const AdminSettingClientFareComponent = () => {
  const myState = useSelector((state) => state.changeTheTab);
  const service = useSelector((state) => state.service);
  const dispatch = useDispatch();
  const auth = useSelector((x) => x.auth);
  const students = useSelector((state) => state.students);
  const fare = useSelector((state) => state.fare);

  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc"); //asc, desc
  const [searchTerm, setSearchTerm] = useState("");
  const [limit, setLimit] = useState(10); // Set your default limit here
  useEffect(() => {
    dispatch(getClientFaresByCompanyId({ companyId: auth.user.companyId.id }));
  }, []);

  // Function to handle pagination, sorting, and searching
  const handlePagination = (page, field, order, search, newLimit) => {
    let newPage;
    if (page < 1) {
      newPage = 1;
    } else if (page > paginatedData.totalPages) {
      newPage = paginatedData.totalPages;
    } else {
      newPage = page;
    }
    setCurrentPage(newPage);
    setSortField(field);
    setSortOrder(order);
    setSearchTerm(search);
    setLimit(newLimit);
  };

  const fullData = fare.clientFare;
  // Use the pagination function to get the data for the current page
  const paginatedData = paginationDataToShow(
    limit,
    currentPage,
    fullData,
    sortField,
    sortOrder,
    searchTerm
  );

  // Function to handle sorting icon click
  const handleSortClick = (field, order) => {
    // Call handlePagination with sorting parameters
    handlePagination(currentPage, field, order, searchTerm, limit);
  };
  // Function to determine the color based on the current sorting order
  const getArrowColor = (field, direction) => {
    return sortField === field && sortOrder === direction
      ? "#000000"
      : "#A0A0A0";
  };

  return (
    <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
      <div className="d-flex border">
        <div className="flex-grow-1">
          <div className="dataTables_length d-flex" id="leads-table_length">
            <div className="p-2">
              <label>Show entries</label>
            </div>
            <label>
              <select
                name="leads-table_length"
                aria-controls="leads-table"
                className="custom-select custom-select-sm form-control form-control-sm"
                autoComplete="false"
                value={limit} // Make sure to set the value attribute to the current limit
                onChange={(e) =>
                  handlePagination(
                    1,
                    sortField,
                    sortOrder,
                    searchTerm,
                    parseInt(e.target.value)
                  )
                }
              >
                {[10, 25, 50, 100].map((current, i) => {
                  return (
                    <option value={current} key={i}>
                      {current}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
        </div>
        <div className="task-search task-search-new d-flex  py-1 px-lg-3 px-0 border-right-grey align-items-center">
          <form
            className="w-100 mr-1 mr-lg-0 mr-md-1 ml-md-1 ml-0 ml-lg-0"
            onSubmit={(e) => e.preventDefault()}
          >
            <div className="input-group bg-grey rounded">
              <div className="input-group-prepend">
                <span className="input-group-text border-0 bg-additional-grey">
                  <i className="fa fa-search f-13 text-dark-grey"></i>
                </span>
              </div>
              <input
                type="text"
                className="form-control f-14 p-1 border-additional-grey"
                id="search-text-field"
                placeholder="Start typing to search"
                value={searchTerm}
                onChange={(e) =>
                  handlePagination(
                    1,
                    sortField,
                    sortOrder,
                    e.target.value,
                    limit
                  )
                }
              />
            </div>
          </form>
        </div>
      </div>
      <div
        id="leads-table_wrapper"
        className="dataTables_wrapper dt-bootstrap4 no-footer"
      >
        <div className="row">
          <div className="col-sm-12">
            <table
              className="table border w-100 dataTable no-footer"
              id="leads-table"
              role="grid"
              aria-describedby="leads-table_info"
              style={{ overflowX: "auto" }}
            >
              <thead className="card-header">
                <tr role="row">
                  <th
                    title="Service"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Mobile: activate to sort column ascending"
                  >
                    Service{" "}
                    {/* <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{ color: getArrowColor("uniqueId", "asc") }}
                          onClick={() => handleSortClick("uniqueId", "asc")}
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{ color: getArrowColor("uniqueId", "desc") }}
                          onClick={() => handleSortClick("uniqueId", "desc")}
                        ></i>
                      </span> */}
                  </th>
                  <th
                    title="Curb-to-Curb"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Mobile: activate to sort column ascending"
                  >
                    Curb-to-Curb{" "}
                    {/* <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{
                            color: getArrowColor("firstName", "asc"),
                          }}
                          onClick={() => handleSortClick("firstName", "asc")}
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{
                            color: getArrowColor("firstName", "desc"),
                          }}
                          onClick={() => handleSortClick("firstName", "desc")}
                        ></i>
                      </span> */}
                  </th>
                  <th
                    title="Door-to-Door"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    Door-to-Door{" "}
                    {/* <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{ color: getArrowColor("email", "asc") }}
                          onClick={() => handleSortClick("email", "asc")}
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{ color: getArrowColor("email", "desc") }}
                          onClick={() => handleSortClick("email", "desc")}
                        ></i>
                      </span> */}
                  </th>
                  <th
                    title="Door-through-Door"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    Door-through-Door{" "}
                    {/* <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{ color: getArrowColor("phone", "asc") }}
                          onClick={() => handleSortClick("phone", "asc")}
                        ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{ color: getArrowColor("phone", "desc") }}
                          onClick={() => handleSortClick("phone", "desc")}
                        ></i>
                      </span> */}
                  </th>
                  <th
                    title="Responsible Party"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    Responsible Party
                  </th>
                  <th
                    title="Infectious Disease"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    Infectious Disease
                  </th>
                  <th
                    title="Mileage Rate (Loaded)"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    Mileage Rate (Loaded)
                  </th>
                  <th
                    title="Long Distance Mileage Rate"
                    className="text-right pr-20 sorting_disabled border-right"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Action"
                  >
                    Long Distance Mileage Rate
                  </th>
                  <th
                    title="Deadhead Mileage Rate"
                    className="text-right pr-20 sorting_disabled border-right"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Action"
                  >
                    Deadhead Mileage Rate
                  </th>
                </tr>
              </thead>
              <tbody>
                {fare &&
                  fare?.clientFare &&
                  fare?.clientFare?.length > 0 &&
                  paginatedData?.results.map((current, i) => {
                    // if (current.delete === false) {
                    return (
                      <AdminSettingClientFareTableRow
                        current={current}
                        i={i}
                        key={i}
                        // handleModal={handleModal}
                      />
                    );
                    // }
                  })}
              </tbody>
            </table>
            {students && students?.list && students?.list?.length === 0 && (
              <div className="dash-stats-list" style={{ textAlign: "center" }}>
                <br />
                <br />
                <img
                  alt=""
                  src={shift1}
                  style={{ width: "145px", opacity: "0.5" }}
                />
                <br />
                <p style={{ opacity: "0.5" }}>No Data Found</p>
              </div>
            )}
            <div
              id="leads-table_processing"
              className="dataTables_processing card"
              style={{ display: "none" }}
            >
              Processing...
            </div>
          </div>
        </div>
        {students &&
          students?.list &&
          students?.list?.length > 0 &&
          paginatedData?.results?.length > 0 && (
            <div className="d-flex border">
              {students.loading === false ? (
                <div className="flex-grow-1">
                  <div
                    className="dataTables_info"
                    id="leads-table_info"
                    role="status"
                    aria-live="polite"
                  >
                    Showing{" "}
                    {1 +
                      paginatedData.limit *
                        (paginatedData?.currentPageNumber - 1)}{" "}
                    to{" "}
                    {paginatedData.displayingResults +
                      paginatedData.limit *
                        (paginatedData?.currentPageNumber - 1)}{" "}
                    of {paginatedData.totalResults} entries
                  </div>
                </div>
              ) : (
                <div className="flex-grow-1">
                  <div
                    className="dataTables_info"
                    id="leads-table_info"
                    role="status"
                    aria-live="polite"
                  >
                    Loading entries
                  </div>
                </div>
              )}

              <div>
                <div
                  className="dataTables_paginate paging_simple_numbers"
                  id="leads-table_paginate"
                >
                  <ul className="pagination">
                    <li className="page-item">
                      <NavLink
                        className="page-link"
                        to="#"
                        aria-label="Previous"
                        // onClick={onClickPrev}
                        onClick={(e) =>
                          handlePagination(
                            currentPage - 1,
                            sortField,
                            sortOrder,
                            searchTerm,
                            limit
                          )
                        }
                      >
                        <span aria-hidden="true">Previous</span>
                        <span className="sr-only">Previous</span>
                      </NavLink>
                    </li>
                    {students &&
                      students?.list &&
                      paginatedData?.paginationArr &&
                      paginatedData?.paginationArr?.length > 0 &&
                      Array.from(
                        {
                          length:
                            paginatedData.endPage - paginatedData.startPage + 1,
                        },
                        (_, i) => {
                          const current = paginatedData.startPage + i;
                          return paginatedData?.currentPageNumber ===
                            current ? (
                            <li className="page-item active" key={i}>
                              <Link to="" className="page-link" value={current}>
                                {current}
                              </Link>
                            </li>
                          ) : (
                            <li className="page-item" key={i}>
                              <Link
                                className="page-link"
                                value={current}
                                onClick={(e) =>
                                  handlePagination(
                                    parseInt(current),
                                    sortField,
                                    sortOrder,
                                    searchTerm,
                                    limit
                                  )
                                }
                                to=""
                              >
                                {current}
                              </Link>
                            </li>
                          );
                        }
                      )}
                    <li className="page-item">
                      <NavLink
                        className="page-link"
                        to="#"
                        aria-label="Next"
                        // onClick={onClickNext}
                        onClick={(e) =>
                          handlePagination(
                            currentPage + 1,
                            sortField,
                            sortOrder,
                            searchTerm,
                            limit
                          )
                        }
                      >
                        <span aria-hidden="true">Next</span>
                        <span className="sr-only">Next</span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};
export default AdminSettingClientFareComponent;
