import React, { useEffect, useState } from "react";
import AdminNotesAddModal from "./AdminNotesAddModal";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getNotesByCompanyId } from "../../../actions/noteActions";
import AdminNotesTableRow from "./AdminNotesTableRow";
import { paginationDataToShow } from "../../../actions/generalActions";
import shift1 from "../../../assets/images/load.png";

const AdminNotesTable = () => {
  const auth = useSelector((state) => state.auth);
  const note = useSelector((state) => state.note);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showDropDown, setshowDropDown] = useState(false);
  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState("uniqueId");
  const [sortOrder, setSortOrder] = useState("desc"); //asc, desc
  const [searchTerm, setSearchTerm] = useState("");
  const [limit, setLimit] = useState(10); // Set your default limit here

  // Function to handle pagination, sorting, and searching
  const handlePagination = (page, field, order, search, newLimit) => {
    let newPage;
    if (page < 1) {
      newPage = 1;
    } else if (page > paginatedData.totalPages) {
      newPage = paginatedData.totalPages;
    } else {
      newPage = page;
    }
    setCurrentPage(newPage);
    setSortField(field);
    setSortOrder(order);
    setSearchTerm(search);
    setLimit(newLimit);
  };

  const fullData = note.note;
  // Use the pagination function to get the data for the current page
  const paginatedData = paginationDataToShow(
    limit,
    currentPage,
    fullData,
    sortField,
    sortOrder,
    searchTerm
  );

  // Function to handle sorting icon click
  const handleSortClick = (field, order) => {
    // Call handlePagination with sorting parameters
    handlePagination(currentPage, field, order, searchTerm, limit);
  };
  // Function to determine the color based on the current sorting order
  const getArrowColor = (field, direction) => {
    return sortField === field && sortOrder === direction
      ? "#000000"
      : "#A0A0A0";
  };

  const noteData = async (e) => {
    dispatch(
      getNotesByCompanyId({
        companyId: auth.user.companyId.id,
      })
    );
  };
  useEffect(() => {
    noteData();
  }, []);
  return (
    <>
      <div className="d-block d-lg-flex d-md-flex justify-content-between action-bar mb-20 mt-3">
        <div id="table-actions" className="flex-grow-1 align-items-center">
          <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">
            {" "}
            Notes{" "}
          </h4>
        </div>
        <div
          className="btn-group mt-2 mt-lg-0 mt-md-0 ml-0 ml-lg-3 ml-md-3"
          role="group"
        >
          <button
            //   to="/create-passenger"
            onClick={openDropDown}
            type="button"
            className="btn-secondary rounded f-14 p-2 mr-3 float-left mb-2 mb-lg-0 mb-md-0 "
            id="add-lead"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-plus-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
            </svg>{" "}
            Add Notes
          </button>
        </div>
      </div>
      <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
        <div className="d-flex border">
          <div className="flex-grow-1">
            <div className="dataTables_length d-flex" id="leads-table_length">
              <div className="p-2">
                <label>Show entries</label>
              </div>
              <label>
                <select
                  name="leads-table_length"
                  aria-controls="leads-table"
                  className="custom-select custom-select-sm form-control form-control-sm"
                  autoComplete="false"
                  value={limit} // Make sure to set the value attribute to the current limit
                  onChange={(e) =>
                    handlePagination(
                      1,
                      sortField,
                      sortOrder,
                      searchTerm,
                      parseInt(e.target.value)
                    )
                  }
                >
                  {[10, 25, 50, 100].map((current, i) => {
                    return (
                      <option value={current} key={i}>
                        {current}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>
          </div>
          {/* <div className="task-search task-search-new d-flex  py-1 px-lg-3 px-0 border-right-grey align-items-center">
                  <form className="w-100 mr-1 mr-lg-0 mr-md-1 ml-md-1 ml-0 ml-lg-0" onSubmit={(e) => e.preventDefault()}>
                    <div className="input-group bg-grey rounded">
                      <div className="input-group-prepend">
                        <span className="input-group-text border-0 bg-additional-grey">
                          <i className="fa fa-search f-13 text-dark-grey"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        className="form-control f-14 p-1 border-additional-grey"
                        id="search-text-field"
                        placeholder="Start typing to search"
                        // value={searchTerm}
                        // onChange={(e) =>
                        //   handlePagination(
                        //     1,
                        //     sortField,
                        //     sortOrder,
                        //     e.target.value,
                        //     limit
                        //   )
                        // }
                      />
                    </div>
                  </form>
                </div> */}
        </div>
        <div
          id="leads-table_wrapper"
          className="dataTables_wrapper dt-bootstrap4 no-footer"
        >
          <div className="row">
            <div className="col-sm-12">
              <table
                className="table border w-100 dataTable no-footer"
                id="leads-table"
                role="grid"
                aria-describedby="leads-table_info"
                style={{ overflowX: "auto" }}
              >
                <thead className="card-header">
                  <tr
                    role="row"
                    // style={{ display: "flex", width: "100%" }}
                  >
                    <th
                      title="Title"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Title: activate to sort column ascending"
                      style={{ flex: 1 }}
                    >
                      Title
                    </th>
                    <th
                      title="Message Note"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Message Note: activate to sort column ascending"
                      style={{ flex: 2 }}
                    >
                      Message Note
                    </th>
                    <th
                      title="Date"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Date: activate to sort column ascending"
                      style={{ flex: 1 }}
                    >
                      Date
                    </th>
                    <th
                      title="Create By"
                      className="sorting border-right"
                      tabIndex="0"
                      aria-controls="leads-table"
                      rowSpan="1"
                      colSpan="1"
                      aria-label="Create By: activate to sort column ascending"
                      style={{ flex: 1 }}
                    >
                      Create By
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {note &&
                    note?.note &&
                    note?.note?.length > 0 &&
                    paginatedData?.results.map((current, i) => {
                      // if (current.delete === false) {
                      return (
                        <AdminNotesTableRow
                          current={current}
                          i={i}
                          key={i}
                          //   handleModal={handleModal}
                        />
                      );
                      // }
                    })}
                </tbody>
              </table>
              {note && note?.note && note?.note?.length === 0 && (
                <div
                  className="dash-stats-list"
                  style={{ textAlign: "center" }}
                >
                  <br />
                  <br />
                  <img
                    alt=""
                    src={shift1}
                    style={{ width: "145px", opacity: "0.5" }}
                  />
                  <br />
                  <p style={{ opacity: "0.5" }}>No Data Found</p>
                </div>
              )}
              <div
                id="leads-table_processing"
                className="dataTables_processing card"
                style={{ display: "none" }}
              >
                Processing...
              </div>
            </div>
          </div>
          {note &&
            note?.note &&
            note?.note?.length > 0 &&
            paginatedData?.results?.length > 0 && (
              <div className="d-flex border">
                {note.loading === false ? (
                  <div className="flex-grow-1">
                    <div
                      className="dataTables_info"
                      id="leads-table_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing{" "}
                      {1 +
                        paginatedData.limit *
                          (paginatedData?.currentPageNumber - 1)}{" "}
                      to{" "}
                      {paginatedData.displayingResults +
                        paginatedData.limit *
                          (paginatedData?.currentPageNumber - 1)}{" "}
                      of {paginatedData.totalResults} entries
                    </div>
                  </div>
                ) : (
                  <div className="flex-grow-1">
                    <div
                      className="dataTables_info"
                      id="leads-table_info"
                      role="status"
                      aria-live="polite"
                    >
                      Loading entries
                    </div>
                  </div>
                )}

                <div>
                  <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="leads-table_paginate"
                  >
                    <ul className="pagination">
                      <li className="page-item">
                        <NavLink
                          className="page-link"
                          to="#"
                          aria-label="Previous"
                          // onClick={onClickPrev}
                          onClick={(e) =>
                            handlePagination(
                              currentPage - 1,
                              sortField,
                              sortOrder,
                              searchTerm,
                              limit
                            )
                          }
                        >
                          <span aria-hidden="true">Previous</span>
                          <span className="sr-only">Previous</span>
                        </NavLink>
                      </li>
                      {note &&
                        note?.note &&
                        paginatedData?.paginationArr &&
                        paginatedData?.paginationArr?.length > 0 &&
                        Array.from(
                          {
                            length:
                              paginatedData.endPage -
                              paginatedData.startPage +
                              1,
                          },
                          (_, i) => {
                            const current = paginatedData.startPage + i;
                            return paginatedData?.currentPageNumber ===
                              current ? (
                              <li className="page-item active" key={i}>
                                <Link
                                  to=""
                                  className="page-link"
                                  value={current}
                                >
                                  {current}
                                </Link>
                              </li>
                            ) : (
                              <li className="page-item" key={i}>
                                <Link
                                  className="page-link"
                                  value={current}
                                  onClick={(e) =>
                                    handlePagination(
                                      parseInt(current),
                                      sortField,
                                      sortOrder,
                                      searchTerm,
                                      limit
                                    )
                                  }
                                  to=""
                                >
                                  {current}
                                </Link>
                              </li>
                            );
                          }
                        )}
                      <li className="page-item">
                        <NavLink
                          className="page-link"
                          to="#"
                          aria-label="Next"
                          // onClick={onClickNext}
                          onClick={(e) =>
                            handlePagination(
                              currentPage + 1,
                              sortField,
                              sortOrder,
                              searchTerm,
                              limit
                            )
                          }
                        >
                          <span aria-hidden="true">Next</span>
                          <span className="sr-only">Next</span>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
      <AdminNotesAddModal
        // current={current}
        showDropDown={showDropDown}
        openDropDown={openDropDown}
      />
    </>
  );
};
export default AdminNotesTable;
