/** @format */

import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BUCKET_BASE_URL, MODIFY_SELECT_SUCCESS } from "../../constants";
import defaultImg from "../../assets/images/profile.jpg";
import AdminNoshowAcceptDeclineModal from "../adminComponents/adminDispatchingComponents/AdminNoshowAcceptDeclineModal";
import {
  getChatsAction,
  getMessagesAction,
  noShowAction,
  sendMessageAction,
} from "../../actions/chatActions";

function MessageItem({
  chatMessage,
  renderDate,
  displayDate,
  isSameSender,
  noShowData,
}) {
  const auth = useSelector((state) => state.auth);
  const chats = useSelector((state) => state.chats);
  const { selectedChat } = chats;
  // console.log(noShowData, "noShowData");
  // console.log(chatMessage, "chatMessage");
  // console.log(displayDate, "displayDate");
  // console.log(renderDate, "renderDate");
  // console.log(isSameSender, "isSameSender");

  const timeNow = new Date();
  const messageTime = new Date(chatMessage.dateTime);
  const secondsDiff = Math.round((timeNow - messageTime) / 1000);
  const hoursDiff = Math.round(secondsDiff / 60 / 60);
  const dispatch = useDispatch();
  const sockets = useSelector((state) => state.sockets);
  const { socketInstance } = sockets;
  let time = "";
  if (secondsDiff < 60) {
    time = "Just Now";
  } else {
    if (secondsDiff < 600) {
      time = Math.round(secondsDiff / 60) + " mins ago";
    } else {
      if (hoursDiff > 24) {
        time = moment(messageTime).format("ddd, hh:mm a");
      } else {
        // console.log("messageTIme==>", messageTime);
        time = moment(messageTime).format("hh:mm a");
      }
    }
  }
  // console.log("first==>", displayDate, renderDate, isSameSender);
  const [showDeclineDropDown, setshowDeclineDropDown] = useState(false);
  const openDeclineDropDown = (resWork) => {
    if (chats?.selectedChat?.noShowMessageAction === false) {
      setshowDeclineDropDown((showDeclineDropDown) => !showDeclineDropDown);
      setStatus(resWork);
    }
  };
  const [reason, setReason] = useState("");
  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };
  const [status, setStatus] = useState();
  const sendMessage = (e) => {
    e.preventDefault();
    const dateTime = new Date();
    // console.log(message, "messagemessage");
    if (reason !== "") {
      if (status === "Accepted") {
        dispatch(
          noShowAction(
            {
              sender: auth.user,
              chatId: chats.selectedChat,
              message: "Accepted: No Show",
              senderId: auth.user.id,
              dateTime,
              requestDetails: {
                type: "No Show",
                action: "Accepted",
                reason: reason,
              },
              isAction: true,
              tripId: noShowData._id,
              tripCancel: noShowData.tripCancel,
              pickUpTime: noShowData.pickUpTime,
              pickUpLat: noShowData.pickUpLat,
              pickUpLng: noShowData.pickUpLng,
              dropOffTime: noShowData.dropOffTime,
              dropOffLat: noShowData.dropOffLat,
              dropOffLng: noShowData.dropOffLng,
              isCompleted: noShowData.isCompleted,
            },
            () => {
              socketInstance?.emit("send-reservation-req", {
                userId: auth.user.id,
                companyId: auth.user.companyId.id,
                reservationId: chats?.selectedChat?.reservationId?.id
                  ? chats?.selectedChat?.reservationId?.id
                  : chats?.selectedChat?.reservationId,
                role: "admin",
              });
              socketInstance.emit("new message", {
                sender: {
                  firstName: auth.user.firstName,
                  lastName: auth.user.lastName,
                  id: auth.user.id,
                  ...(auth?.user?.profilePic?.fileUrl && {
                    profilePic: { fileUrl: auth.user.profilePic.fileUrl },
                  }),
                },
                // chatId: chats.selectedChat,
                chatId: {
                  ...chats.selectedChat,
                  reservationId: chats?.selectedChat?.reservationId?.id
                    ? chats.selectedChat.reservationId.id
                    : chats.selectedChat.reservationId,
                },
                message: "Accepted: No Show",
                senderId: auth.user.id,
                dateTime,
                requestDetails: {
                  type: "No Show",
                  action: "Accepted",
                  reason: reason,
                },
                isAction: true,
              });
              dispatch({
                type: MODIFY_SELECT_SUCCESS,
                payload: true,
              });
            }
          )
        );
        // socketInstance.emit("new message", {
        //   sender: {
        //     firstName: auth.user.firstName,
        //     lastName: auth.user.lastName,
        //     id: auth.user.id,
        //     profilePic: {
        //       fileUrl: auth.user.profilePic.fileUrl,
        //     },
        //   },
        //   chatId: chats.selectedChat,
        //   message: message,
        //   dateTime,
        // });
      } else {
        dispatch(
          noShowAction(
            {
              sender: auth.user,
              chatId: chats.selectedChat,
              message: "Declined: No Show",
              senderId: auth.user.id,
              dateTime,
              requestDetails: {
                type: "No Show",
                action: "Declined",
                reason: reason,
              },
              isAction: true,
            },
            () => {
              socketInstance?.emit("send-reservation-req", {
                userId: auth.user.id,
                companyId: auth.user.companyId.id,
                reservationId: chats?.selectedChat?.reservationId?.id
                  ? chats?.selectedChat?.reservationId?.id
                  : chats?.selectedChat?.reservationId,
                role: "admin",
              });
              socketInstance.emit("new message", {
                sender: {
                  firstName: auth.user.firstName,
                  lastName: auth.user.lastName,
                  id: auth.user.id,
                  ...(auth?.user?.profilePic?.fileUrl && {
                    profilePic: { fileUrl: auth.user.profilePic.fileUrl },
                  }),
                },
                // chatId: chats.selectedChat,
                chatId: {
                  ...chats.selectedChat,
                  reservationId: chats?.selectedChat?.reservationId?.id
                    ? chats.selectedChat.reservationId.id
                    : chats.selectedChat.reservationId,
                },
                message: "Declined: No Show",
                senderId: auth.user.id,
                dateTime,
                requestDetails: {
                  type: "No Show",
                  action: "Declined",
                  reason: reason,
                },
                isAction: true,
              });
              dispatch({
                type: MODIFY_SELECT_SUCCESS,
                payload: true,
              });
            }
          )
        );
        // socketInstance.emit("new message", {
        //   sender: {
        //     firstName: auth.user.firstName,
        //     lastName: auth.user.lastName,
        //     id: auth.user.id,
        //     profilePic: {
        //       fileUrl: auth.user.profilePic.fileUrl,
        //     },
        //   },
        //   chatId: chats.selectedChat,
        //   message: message,
        //   dateTime,
        // });
      }
    }
    setshowDeclineDropDown((showDeclineDropDown) => !showDeclineDropDown);
  };

  // console.log(chatMessage, "cm");
  return (
    <>
      <div key={chatMessage.id}>
        {renderDate && <li className="chat-date">{displayDate}</li>}
        {/* <li
        className={`d-flex mb-1 gap-2 ${
          chatMessage?.sender?.id === auth.user.id ? "flex-row-reverse" : ""
        }`}
      >
        <img
          src={
            chatMessage.sender?.profilePic
              ? chatMessage.sender?.profilePic?.fileUrl
              : defaultImg
          }
          alt="avatar"
          className="rounded-circle d-flex align-self-start shadow-1-strong object-fit-cover"
          height="40"
          width="40"
        />
        <div className="card chat-message-cont">
          <div className="card-header d-flex align-items-center justify-content-between p-2">
            <p className="fw-bold mb-0">
              {chatMessage?.sender?.id === auth.user.id
                ? "You"
                : `${chatMessage.sender?.firstName} ${chatMessage.sender?.lastName}`}
            </p>
            <p className="text-muted small mb-0">
              <i className="far fa-clock"></i> {time}
            </p>
          </div>
          <div className="card-body p-2">
            <p className="mb-0">{chatMessage.message}</p>
            <p className="text-muted small mb-0">{time}</p>
          </div>
        </div>
      </li>
       */}
        {chatMessage?.isRequest ? (
          <li
            className={`media ${
              chatMessage?.sender?.id === auth.user.id
                ? "sent show"
                : "d-flex received"
            }`}
          >
            {chatMessage?.sender?.id !== auth.user.id && (
              <div className="avatar" style={{ backgroundColor: "#f5f5f6" }}>
                {!isSameSender && (
                  <img
                    src={
                      chatMessage?.sender?.profilePic
                        ? chatMessage?.sender?.profilePic?.fileUrl !== "null"
                          ? chatMessage?.sender?.profilePic?.fileUrl
                          : defaultImg
                        : defaultImg
                    }
                    alt="User Image"
                    className="chat-avatar-img rounded-circle centralized-avatar inline-block"
                  />
                )}
              </div>
            )}
            <div className="media-body">
              {chatMessage?.sender?.id !== auth?.user?.id && !isSameSender && (
                <div style={{ fontSize: "12px" }}>
                  {chatMessage?.sender?.firstName +
                    " " +
                    chatMessage?.sender?.lastName}
                </div>
              )}
              <div className="msg-box msg-box2">
                <div>
                  {chatMessage.isRequest === true &&
                  chatMessage.message == "Customer Ready" ? (
                    <>
                      <p>{chatMessage?.message}</p>
                      <ul className="chat-msg-info">
                        <li>
                          <div className="chat-time">
                            <span>{time}</span>
                          </div>
                        </li>
                      </ul>
                    </>
                  ) : (
                    <>
                      <p>{chatMessage?.message}</p>Reason:{" "}
                      {chatMessage?.requestDetails?.reason}{" "}
                      <ul className="chat-msg-info">
                        <li>
                          <div className="chat-time">
                            <span>{time}</span>
                          </div>
                        </li>
                        <li
                          style={{
                            textDecoration: "underline",
                          }}
                        >
                          {/* Reason: {chatMessage?.requestDetails?.reason}{" "} */}
                        </li>

                        <li>
                          <button
                            className="btn btn-accept"
                            onClick={() => openDeclineDropDown("Accepted")}
                            // disabled={
                            //   !chats?.selectedChat?.acceptedUser ||
                            //   (chats?.selectedChat?.acceptedUser ===
                            //     auth.user.id &&
                            //     chats?.selectedChat?.noShowMessageAction ===
                            //       false)
                            //     ? false
                            //     : true
                            // }
                          >
                            Accept <span className="fa fa-check"></span>
                          </button>
                        </li>
                        <li>
                          <button
                            className="btn btn-deny"
                            onClick={() => openDeclineDropDown("Declined")}
                            disabled={
                              !chats?.selectedChat?.acceptedUser ||
                              (chats?.selectedChat?.acceptedUser ===
                                auth.user.id &&
                                chats?.selectedChat?.noShowMessageAction ===
                                  false)
                                ? false
                                : true
                            }
                          >
                            Decline <span className="fa fa-close"></span>
                          </button>
                        </li>
                      </ul>
                    </>
                  )}
                </div>
              </div>
            </div>
          </li>
        ) : chatMessage?.isAction ? (
          <li
            className={`media ${
              chatMessage?.sender?.id === auth?.user?.id
                ? "sent show"
                : "d-flex received"
            }`}
          >
            {chatMessage?.sender?.id !== auth?.user?.id && (
              <div className="avatar" style={{ backgroundColor: "#f5f5f6" }}>
                {!isSameSender && (
                  <img
                    src={
                      chatMessage?.sender?.profilePic
                        ? chatMessage?.sender?.profilePic?.fileUrl !== "null"
                          ? chatMessage?.sender?.profilePic?.fileUrl
                          : defaultImg
                        : defaultImg
                    }
                    alt="User Image"
                    className="chat-avatar-img rounded-circle centralized-avatar inline-block"
                  />
                )}
              </div>
            )}

            <div className="media-body">
              {chatMessage?.sender?.id !== auth?.user?.id && !isSameSender && (
                <div style={{ fontSize: "12px" }}>
                  {chatMessage?.sender?.firstName +
                    " " +
                    chatMessage?.sender?.lastName}
                </div>
              )}
              <div className="msg-box msg-box2">
                <div>
                  <p>
                    <button
                      className="btn"
                      style={{
                        color: `${
                          chatMessage?.requestDetails?.action === "Accepted"
                            ? "#008000"
                            : "#DC143C"
                        }`,
                        textDecoration: "underline",
                      }}
                    >
                      {chatMessage?.requestDetails?.action}
                      <span className="fa fa-check">:</span>
                    </button>{" "}
                    {chatMessage?.requestDetails?.type}
                  </p>
                  <ul className="chat-msg-info">
                    <li>
                      <div className="chat-time">
                        <span>{time}</span>
                      </div>
                    </li>
                    <li
                      style={{
                        textDecoration: "underline",
                      }}
                    >
                      Reason: {chatMessage?.requestDetails?.reason}{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
        ) : (
          <li
            className={`media ${
              chatMessage?.sender?.id === auth?.user?.id
                ? "sent show"
                : "d-flex received"
            }`}
          >
            {chatMessage?.sender?.id !== auth?.user?.id && (
              <div className="avatar" style={{ backgroundColor: "#f5f5f6" }}>
                {!isSameSender && (
                  <img
                    src={
                      chatMessage?.sender?.profilePic
                        ? chatMessage?.sender?.profilePic?.fileUrl !== "null"
                          ? chatMessage?.sender?.profilePic?.fileUrl
                          : defaultImg
                        : defaultImg
                    }
                    alt="User Image"
                    className="chat-avatar-img rounded-circle centralized-avatar inline-block"
                  />
                )}
              </div>
            )}

            <div className="media-body">
              {chatMessage?.sender?.id !== auth?.user?.id && !isSameSender && (
                <div style={{ fontSize: "12px" }}>
                  {chatMessage?.sender?.firstName +
                    " " +
                    chatMessage?.sender?.lastName}
                </div>
              )}
              <div className="msg-box msg-box2">
                <div
                  className={`${
                    chatMessage?.sender?.id !== auth?.user?.id
                      ? "extra-space"
                      : "extra-space-remove"
                  }`}
                >
                  <p className="chat-message1">
                    {chatMessage?.isCall && (
                      <i className="material-icons fa fa-phone">&nbsp;</i>
                    )}
                    {chatMessage?.message}
                  </p>
                  <ul className="chat-msg-info">
                    <li>
                      <div className="chat-time">
                        <span>{time}</span>
                      </div>
                    </li>
                    {/* <li>
                  <a href="#">Edit</a>
                </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </li>
        )}
      </div>
      {showDeclineDropDown && (
        <AdminNoshowAcceptDeclineModal
          showDeclineDropDown={showDeclineDropDown}
          openDeclineDropDown={openDeclineDropDown}
          // current={selectedChat}
          sendMessage={sendMessage}
          handleReasonChange={handleReasonChange}
          reason={reason}
        />
      )}
    </>
  );
}

export default MessageItem;
