import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createFacility } from "../../../actions/kpiActions";

const AdminSettingAddFacilityModal = ({
  showDropDown,
  openDropDown,
  values,
  setValues,
}) => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [val, setVal] = useState({
    name: "",
    description: "",
  });
  const handleChange = (fieldName) => (event) => {
    setVal({
      ...val,
      [fieldName]: event.target.value,
    });
  };
  const handleSubmit = async (event) => {
    // event.preventDefault()
    if (val.name !== "") {
      if(val.description === ''){
        delete val.description
      }
        dispatch(
          createFacility({ companyId: auth.user.companyId.id, name: val.name, description: val.description },successHandler)
        );
      }
  };
  const successHandler = (facilityId) => {
    setValues({
      ...values,
      facility: facilityId,
    });
    setVal({  name: "",
      description: "", });
    openDropDown();
  };
  return (
    <>
      <div
        className={`modal fade ${showDropDown ? "show modal-y-axis" : "hide"}`}
        role="dialog"
        data-backdrop="static"
        data-keyboard="false" // To prevent closing with the keyboard
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openDropDown(); // Call your close modal function
          }
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {" "}
                New Facility
              </h5>
              <button
                type="button"
                id="openDropDown3"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openDropDown()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark-grey mb-12"
                      data-label=""
                      htmlFor="after_days"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      id="serviceNameAdd3"
                      className="form-control height-35 f-14"
                      placeholder="Enter Name"
                      autoComplete="false"
                      value={val.name}
                      onChange={handleChange("name")}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark-grey mb-12"
                      data-label=""
                      htmlFor="after_days"
                    >
                      Description
                    </label>
                    <input
                      type="text"
                      id="serviceNameAdd4"
                      className="form-control height-35 f-14"
                      placeholder="Enter Description"
                      autoComplete="false"
                      value={val.description}
                      onChange={handleChange("description")}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn-secondary rounded f-14 p-2 mr-3"
                data-dismiss="modal"
                id="openDropDown"
                onClick={() => openDropDown()}
              >
                Close
              </button>
              <button
                type="button"
                id="openDropDown2"
                className="btn-primary rounded f-14 p-2 mr-3"
                onClick={handleSubmit}
              >
                Add
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminSettingAddFacilityModal;
