import React from "react";
import ExcelImport from "../../../ExcelImport";
import AdminSidebar from "../../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar";
import AdminHeader from "../../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader";
import AdminRouteDistanceReport from "../../../components/adminComponents/adminReportComponents/AdminRouteDistanceReport";
import AdminSummaryReportTable from "../../../components/adminComponents/adminReportComponents/AdminSummaryReportTable";
import ExcelExport from "../../../ExcelExport";
import { useSelector } from "react-redux";
import AdminDailyReportTable from "../../../components/adminComponents/adminReportComponents/AdminDailyReportTable";

const AdminRouteDailyReportPage = () => {
  const kpi = useSelector((state) => state.kpi);

  const formatDate = (value) => {
    const firstSplit = value?.split("-route:")[0]
    const secondSplit = firstSplit?.split(":")[1]
        const chicagoDateOptions = {
      timeZone: "America/Chicago",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    const chicagoDate = new Date(secondSplit).toLocaleDateString(
      "en-US",
      chicagoDateOptions
    );
    return chicagoDate
  }

  const exportData = kpi?.routeDailyDrivenReport?.flatMap((item) => {
    if (item?.msg) {
      return [
        {
          "Route #": item.routeNumber,
          "Date": formatDate(item?.trip?.routeDateIdentifier),
          "Shift Time": item.shiftTime,
          From: "-",
          To: "-",
          "# of Stops": "-",
          Distance: "-",
        },
      ];
    } else if (item?.route?.length > 1) {
      return item.route
        .map((location, index) => {
          if (index < item?.route?.length - 1) {
            return {
              "Route #": item?.routeNumber,
              "Date": formatDate(item?.trip?.routeDateIdentifier),
              "Shift Time": item?.shiftTime,
              From: item?.route[index].location,
              To: item?.route[index + 1].location,
              "# of Stops": index + 1,
              Distance: parseFloat(item?.route[index].distance),
              // "Total Distance": parseFloat(item.distance),
            };
          }
          return null; // Exclude the last item since it has no "To" address
        })
        .filter((row) => row !== null);
    } else {
      // Handle the case where there is only one or no stops
      return [
        {
          "Route #": item.routeNumber,
          "Date": formatDate(item?.trip?.routeDateIdentifier),
          "Shift Time": item.shiftTime,
          From: item?.route?.length > 0 ? item?.route[0].location : "",
          To: "",
          "# of Stops": 1,
          Distance: parseFloat(item.distance),
          // "Total Distance": parseFloat(item.distance),
        },
      ];
    }
  });

  return (
    <>
      <AdminSidebar />
      <AdminHeader />
      <div className="body-wrapper clearfix">
        <section className="main-container " id="fullscreen">
          <div className="px-4 py-2 border-top-0 emp-dashboard">
            <div className="settings-box rounded">
              <div className="s-b-inner s-b-notifications bg-white b-shadow-4 rounded">
                <div className="s-b-n-header d-lg-flex justify-content-between card-header border-bottom-grey">
                  <div className="s-b-n-header" id="tabs">
                    <h2 className="mb-0 p-20 f-21 font-weight-normal text-capitalize">
                      <span>ROUTE DAILY REPORTS</span>
                    </h2>
                  </div>
                  <ExcelExport
                    excelData={exportData}
                    fileName="Route Daily Driven Report"
                    styling
                  />
                </div>
                <AdminDailyReportTable />
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* </div> */}
    </>
  );
};

export default AdminRouteDailyReportPage;
