/** @format */

import {
  GET_NOTIFICATIONS_FAIL,
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  NOTIFICATION_RECEIVE_SUCCESS,
  READ_NOTIFICATIONS_SUCCESS,
  READALL_CHAT_SUCCESS,
} from "../constants";

export const notificationReducer = (
  state = {
    list: [],
  },
  action
) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        list: action.payload,
      };
    case GET_NOTIFICATIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case NOTIFICATION_RECEIVE_SUCCESS:
      const notificationFound = state.list.find(
        (x) => x.id === action.payload.id
      );
      if (notificationFound) {
        return {
          ...state,
          // list: state.list.map((x) =>
          //   x.id === action.payload.id ? action.payload : x
          // ),
          list: [
            ...state.list.filter((x) => x.id === action.payload.id),
            ...state.list.filter((x) => x.id !== action.payload.id),
          ],
        };
      } else {
        return {
          ...state,
          // list: [...state.list, action.payload],
          list: [action.payload, ...state.list],
        };
      }

    case READ_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        list: state.list.map((x) =>
          x.id === action.payload.id ? action.payload : x
        ),
      };

    case READALL_CHAT_SUCCESS:
      return {
        ...state,
        list: state.list.map((x) => ({
          ...x,
          readBy: [
            ...x.readBy,
            { readerId: action.payload.userId, readAt: new Date() },
          ],
        })),
      };

    default:
      return state;
  }
};
