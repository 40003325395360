import React, { useEffect } from "react";
import AdminHeader from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader";
import AdminSidebar from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar";
import { NavLink } from "react-router-dom";
import ExternalCompanyUserTable from "../../components/externalCompanyComponents/ExternalCompanyUserTable";
import { useDispatch, useSelector } from "react-redux";
import {
  getPassengerByExternalCompanyId,
  getPassengersByExternalCompanyId,
} from "../../actions/userActions";
import ExternalCompanyBookingCreateComponent from "../../components/externalCompanyComponents/ExternalCompanyBookingCreateComponent";
import { getServicesByCompanyId } from "../../actions/serviceActions";
import {
  getPastReservationsByCustomerId,
  getPastReservationsByExternalCompanyId,
  getTodayReservationsByCustomerId,
  getTodayReservationsByExternalCompanyId,
} from "../../actions/reservationAction";

const ExternalCompanyDashboard = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const passengerData = async (e) => {
    const chicagoDateOptions = {
      timeZone: "America/Chicago",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    const chicagoDate = new Date().toLocaleDateString(
      "en-US",
      chicagoDateOptions
    );
    const parts = chicagoDate.split("/");
    const formattedChicagoDate = `${parts[2]}-${parts[0]}-${parts[1]}`;

    if (auth.user.externalCompanyId) {
      dispatch(
        getPassengersByExternalCompanyId({
          companyId: auth.user.companyId.id,
          externalCompanyId: auth.user.externalCompanyId,
        })
      );
      dispatch(
        getTodayReservationsByExternalCompanyId({
          companyId: auth.user.companyId.id,
          externalCompanyId: auth.user.externalCompanyId,
          today: formattedChicagoDate,
        })
      );
      dispatch(
        getPastReservationsByExternalCompanyId({
          companyId: auth.user.companyId.id,
          externalCompanyId: auth.user.externalCompanyId,
          today: formattedChicagoDate,
        })
      );
    }
    if (auth.user.isClient) {
      dispatch(
        getTodayReservationsByCustomerId({
          companyId: auth.user.companyId.id,
          customerId: auth.user.passengerId,
          today: formattedChicagoDate,
        })
      );
      dispatch(
        getPastReservationsByCustomerId({
          companyId: auth.user.companyId.id,
          customerId: auth.user.passengerId,
          today: formattedChicagoDate,
        })
      );
    }
    dispatch(getServicesByCompanyId({ companyId: auth.user.companyId.id }));
  };
  useEffect(() => {
    passengerData();
  }, []);
  return (
    <>
      <AdminHeader />
      <AdminSidebar />
      <div className="body-wrapper clearfix">
        <section className="main-container " id="fullscreen">
          <div className="px-4 py-2 border-top-0 emp-dashboard">
            <div className="d-block d-lg-flex d-md-flex justify-content-between action-bar mb-20 mt-3">
              <div
                id="table-actions"
                className="flex-grow-1 align-items-center"
              ></div>
              <div
                className="btn-group mt-2 mt-lg-0 mt-md-0 ml-0 ml-lg-3 ml-md-3 activee"
                role="group"
              ></div>
            </div>
            {/* {
                            auth?.user?.permissionId?.clients?.read === true ?
                            <AdminClientViewTable />
                            :
                            <NotAllowed />
                        } */}
          </div>
        </section>
      </div>
    </>
  );
};
export default ExternalCompanyDashboard;
