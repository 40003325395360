import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getExternalCompanies } from "../../../actions/companyAction";
import {
  createIndividualClient,
  editPassenger,
} from "../../../actions/userActions";
// import MyComponent from "../Map/Map";
// import Select from "react-select";

const AdminGrantPermissionModal = ({
  openGrantPermissionDropDown,
  showGrantPermissionDropDown,
  current,
}) => {
  const auth = useSelector((state) => state.auth);
  const students = useSelector((state) => state.students);
  const fare = useSelector((state) => state.fare);
  const kpi = useSelector((state) => state.kpi);
  const { facility } = kpi;
  const { loading } = students;

  const companyEmail =
    fare?.fare.find((x) => x?.business?.id === current?.externalCompanyId)
      ?.business.email || "";

  const [values2, setValues2] = useState({
    id: "",
    email: "",
  });
  const handleChange = (fieldName) => (event) => {
    setValues2({
      ...values2,
      [fieldName]: event.target.value,
    });
  };
  const currentPassenger = async () => {
    try {
      setValues2({
        id: "",
        email: "",
      });
      setValues2({
        id: current.id,
        email: current.email,
      });
    } catch (error) {
      // console.log(error);
    }
  };
  useEffect(() => {
    currentPassenger();
  }, [current]);

  const dispatch = useDispatch();
  const passengerData = {
    _id: values2.id,
    email: values2.email,
  };

  const handleSuccess = () => {
    openGrantPermissionDropDown();
  };

  useEffect(() => {
    dispatch(getExternalCompanies({ companyId: auth.user.companyId.id }));
  }, [auth]);

  //   const handleSubmit = async (event) => {
  //     event.preventDefault();
  //     dispatch(editPassenger(passengerData));
  //   };
  const data = {
    companyId: current.companyId,
    firstName: current.firstName,
    lastName: current.lastName,
    email: current.email ? current.email : values2.email,
    isClient: true,
    passengerId: current.id,
    verificationRequired: false,
  };

  // console.log(current, "current");
  // console.log(data, "dataaaaaaaaaaaa");
  const handleGrantPermission = async (event) => {
    event.preventDefault();
    dispatch(createIndividualClient(data));
    dispatch(editPassenger(passengerData));
    openGrantPermissionDropDown();
  };
  return (
    <>
      <div
        className={`modal fade ${
          showGrantPermissionDropDown === true ? "show modal-y-axis" : "hide"
        }`}
        role="dialog"
        data-backdrop="static"
        data-keyboard="false" // To prevent closing with the keyboard
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openGrantPermissionDropDown(); // Call your close modal function
          }
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content custom-modal-content">
            <form onSubmit={handleGrantPermission}>
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  {" "}
                  Grant Permission
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => openGrantPermissionDropDown()}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              {current.isCompany == true ? (
                <div className="modal-body" style={{ width: "50% !important" }}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group my-3">
                        <label
                          className="f-14 text-dark-grey mb-12"
                          data-label=""
                          htmlFor="after_days"
                        >
                          The following is email associated with the company.
                        </label>
                        <input
                          type="Email"
                          className="form-control height-35 f-14"
                          //   placeholder="Enter Your Email"
                          autoComplete="false"
                          disabled
                          value={companyEmail}
                          name="email"
                          //   onChange={handleChange("email")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="modal-body" style={{ width: "50% !important" }}>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group my-3">
                        <label
                          className="f-14 text-dark-grey mb-12"
                          data-label=""
                          htmlFor="after_days"
                        >
                          Email
                        </label>
                        <input
                          type="Email"
                          className="form-control height-35 f-14"
                          placeholder="Enter Your Email"
                          autoComplete="false"
                          required
                          value={values2.email}
                          name="email"
                          onChange={handleChange("email")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {current.isCompany == true ? (
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn-secondary rounded f-14 p-2 mr-3"
                    data-dismiss="modal"
                    onClick={() => openGrantPermissionDropDown()}
                    //   disabled={loading}
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn-secondary rounded f-14 p-2 mr-3"
                    data-dismiss="modal"
                    onClick={() => openGrantPermissionDropDown()}
                    //   disabled={loading}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn-primary rounded f-14 p-2 mr-3"
                    //   disabled={loading}
                    //   onClick={handleGrantPermission}
                  >
                    Grant Permission
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminGrantPermissionModal;
