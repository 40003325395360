import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";

const AdminNotesTableRow = ({ current, i }) => {
  const note = useSelector((state) => state.note);
  const users = useSelector((state) => state.users);

  const userFound = users?.users?.find((x) => x.id == current?.createdBy);
  // console.log(userFound, "ddddddd");
  return (
    <>
      <tr
        id="row-20"
        role="row"
        className="odd border"
        key={i}
        // style={{ display: "flex", width: "100%" }}
        // onMouseLeave={() => setshowMoreVertDropDown(false)}
      >
        <td className="border-right" style={{ flex: 1 }}>
          {current.title}
        </td>
        <td className="border-right" style={{ flex: 2 }}>
          {current.noteText}
        </td>
        <td className="border-right" style={{ flex: 1 }}>
          {moment(current.date).format("MM-DD-YYYY")}
        </td>
        <td className="border-right" style={{ flex: 1 }}>
          {`${userFound?.firstName} ${userFound?.lastName}`}
        </td>
      </tr>
    </>
  );
};
export default AdminNotesTableRow;
