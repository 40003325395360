/** @format */

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
// import Select from "react-select";
import moment from "moment";
import { addTrip } from "../../../actions/tripActions";
import {
  getReservationById,
  markedLocation,
  setReservationData,
  undoInprogressAction,
  undoStartAction,
  updateTrip,
} from "../../../actions/reservationAction";
import { Link, useNavigate, useParams } from "react-router-dom";
import { notifyFailure } from "../../../components/toast";
import UpdatingModal from "../../UpdatingModal";
// import AdminBookingPriceDetailModal from "../adminBookingComponents/AdminBookingPriceDetailModal";
import axios from "axios";
import {
  // createChatAction,
  createGroupAction,
  getMessagesAction,
  sendMessageAction,
} from "../../../actions/chatActions";
import defaultImg from "../../../assets/images/profile.jpg";
import Loader from "../../Loader";
import MessageItem from "../../chatComponent/messageItem";
import { SERVERADDRESS } from "../../../constants";
// import AdminNoshowAcceptDeclineModal from "./AdminNoshowAcceptDeclineModal";

const AdminBookingViewComponent = () => {
  const trip = useSelector((state) => state.trip);
  const auth = useSelector((state) => state.auth);
  const sockets = useSelector((state) => state.sockets);
  const { socketConnected, socketInstance } = sockets;
  const reservation = useSelector((state) => state.reservation);
  const params = useParams();
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };
  const users = useSelector((state) => state.users);
  const chats = useSelector((state) => state.chats);
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(defaultImg);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  let currentSender = null;
  let lastDate = null;
  const [showChat, setShowChat] = useState(false);

  const [values, setValues] = useState({
    companyId: "",
    reservationId: "",
    tripStartTime: "",
  });
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  // console.log(values)
  useEffect(() => {
    dispatch(
      getReservationById({
        _id: params?.id,
      })
    );
    setValues({
      ...values,
      reservationId: params?.id,
    });
  }, []);
  const [values2, setValues2] = useState({
    _id: "",
    // arrivedTime: '',
    // pickUpTime: '',
    // pickUpLat: '',
    // pickUpLng: '',
    // dropOffTime: '',
    // dropOffLat: '',
    // dropOffLng: '',
    // waitingTimePickUp: '',
    // waitingTimePickUpInDecimal: '',
    // tripDuration: '',
    // tripDurationInDecimal: '',
    // tripStatus: '',
    // tripCancel: '',
    // isCompleted: '',
  });
  useEffect(() => {
    if (reservation?.reservationDetails?.trip) {
      setValues2({
        ...values2,
        _id: reservation?.reservationDetails?.trip?.id,
        // arrivedTime: current.trip?.arrivedTime,
        // pickUpTime: current.trip?.pickUpTime,
        // pickUpLat: current.trip?.pickUpLat,
        // pickUpLng: current.trip?.pickUpLng,
        // dropOffTime: current.trip?.dropOffTime,
        // dropOffLat: current.trip?.dropOffLat,
        // dropOffLng: current.trip?.dropOffLng,
        // waitingTimePickUp: current.trip?.waitingTimePickUp,
        // waitingTimePickUpInDecimal: current.trip?.waitingTimePickUpInDecimal,
        // tripDuration: current.trip?.tripDuration,
        // tripDurationInDecimal: current.trip?.tripDurationInDecimal,
        // tripStatus: current.trip?.tripStatus,
        // tripCancel: current.trip?.tripCancel,
        // isCompleted: current.trip?.isCompleted,
      });
    }
  }, [reservation]);
  const dispatch = useDispatch();
  const onClickNavigation = () => {
    dispatch(markedLocation(reservation?.reservationDetails));
  };
  const tripData = {
    companyId: auth.user.companyId.id,
    driverId: reservation?.reservationDetails?.driverId?.id,
    reservationId: values.reservationId,
    // pickUpLat: 24.9261844,
    // pickUpLng: 67.1356306,
    tripStartTime: moment().format().toString().substring(0, 19) + ".000Z",
  };
  // console.log(values2, "sssssssssssssssssssssssssssssssssssssss")
  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log("first")
    // dispatch(addTrip(tripData));
    // setValues({
    //   assetNo: "",
    //   serviceType: "",
    //   vehicleOwnership: "",
    // });
  };

  const handleArrived = async (event) => {
    dispatch(
      updateTrip(
        {
          _id: values2._id,
          arrivedTime: moment().format().toString().substring(0, 19) + ".000Z",
          tripStatus: "Arrived",
          dispatcherId: auth.user.id,
        },
        () => {
          socketInstance?.emit("send-reservation-req", {
            userId: auth.user.id,
            companyId: auth.user.companyId.id,
            reservationId: values.reservationId,
            role: "admin",
          });
        }
      )
    );
  };

  const handlePickUp = async (event) => {
    dispatch(
      updateTrip(
        {
          _id: values2._id,
          pickUpTime: moment().format().toString().substring(0, 19) + ".000Z",
          tripStatus: "Ongoing",
          pickUpLat: reservation?.reservationDetails?.pickUpAddress?.latitude,
          pickUpLng: reservation?.reservationDetails?.pickUpAddress?.longitude,
          dispatcherId: auth.user.id,
        },
        () => {
          socketInstance?.emit("send-reservation-req", {
            userId: auth.user.id,
            companyId: auth.user.companyId.id,
            reservationId: values.reservationId,
            role: "admin",
          });
        }
      )
    );
  };

  const noShowData = {
    _id: values2._id,
    tripCancel: "No Show",
    pickUpTime: moment().format().toString().substring(0, 19) + ".000Z",
    pickUpLat: reservation?.reservationDetails?.pickUpAddress?.latitude,
    pickUpLng: reservation?.reservationDetails?.pickUpAddress?.longitude,
    dropOffTime: moment().format().toString().substring(0, 19) + ".000Z",
    dropOffLat: reservation?.reservationDetails?.dropOffAddress?.latitude,
    dropOffLng: reservation?.reservationDetails?.dropOffAddress?.longitude,
    isCompleted: false,
  };

  const handleNoShowRide = async (event) => {
    dispatch(
      updateTrip(
        {
          _id: values2._id,
          tripCancel: "No Show",
          // tripStatus: "No Show",
          // customerCancelReason: "No Show",
          pickUpTime: moment().format().toString().substring(0, 19) + ".000Z",
          pickUpLat: reservation?.reservationDetails?.pickUpAddress?.latitude,
          pickUpLng: reservation?.reservationDetails?.pickUpAddress?.longitude,
          dropOffTime: moment().format().toString().substring(0, 19) + ".000Z",
          dropOffLat: reservation?.reservationDetails?.dropOffAddress?.latitude,
          dropOffLng:
            reservation?.reservationDetails?.dropOffAddress?.longitude,
          isCompleted: false,
          dispatcherId: auth.user.id,
        },
        () => {
          socketInstance?.emit("send-reservation-req", {
            userId: auth.user.id,
            companyId: auth.user.companyId.id,
            reservationId: values.reservationId,
            role: "admin",
          });
        }
      )
    );
  };
  const handleEndRide = async (event) => {
    dispatch(
      updateTrip(
        {
          _id: values2._id,
          dropOffTime: moment().format().toString().substring(0, 19) + ".000Z",
          tripStatus: "Completed",
          dropOffLat: reservation?.reservationDetails?.dropOffAddress?.latitude,
          dropOffLng:
            reservation?.reservationDetails?.dropOffAddress?.longitude,
          isCompleted: true,
          dispatcherId: auth.user.id,
        },
        () => {
          socketInstance?.emit("send-reservation-req", {
            userId: auth.user.id,
            companyId: auth.user.companyId.id,
            reservationId: values.reservationId,
            role: "admin",
          });
        }
      )
    );
  };
  const [showDetailDropDown, setshowDetailDropDown] = useState(false);
  const openDetailDropDown = () => {
    setshowDetailDropDown((showDetailDropDown) => !showDetailDropDown);
    // if (useHardCodedFares === false && useDirectFare === false) {
    //   calculateFareFrontEnd();
    // }
  };

  // const [reqData, setReqData] = useState();
  // const dataLoaded = async () => {
  //   try{

  //     const { data } = await axios.post(
  //       `${SERVERADDRESS}/v1/reservation/reservationDetails`,
  //       {
  //         _id: reservation?.reservationDetails?._id,
  //       }
  //       );
  //       setReqData(data);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  // };
  // useEffect(() => {
  //   dataLoaded();
  // }, [reservation]);
  const closeModal = () => {
    setshowMoreVertDropDown(false);
  };
  const handleUndoAction = async (undoAction) => {
    try {
      const { data } = await axios.put(`${SERVERADDRESS}/v1/trip/undoTrip`, {
        _id: reservation?.reservationDetails?.trip?.id,
        undoActions: undoAction,
        dispatcherId: auth.user.id,
      });
      socketInstance?.emit("send-reservation-req", {
        userId: auth.user.id,
        companyId: auth.user.companyId.id,
        reservationId: values.reservationId,
        role: "admin",
      });
      if (undoAction === "start") {
        // setReqData({...reqData, trip: {}});
        dispatch(undoStartAction(data), closeModal());
      } else {
        // setReqData({...reqData, trip: data});
        dispatch(undoInprogressAction(data), closeModal());
      }
    } catch (error) {
      // Handle network or other errors
      console.error("Error performing undo action:", error);
    }
  };

  // ******************************************************************
  // const messageGroupData = {
  //   users: users.users
  //     .filter((user) => user.isDriver === false)
  //     .map((user) => ({ id: user.id })),
  //   driverId: reservation?.reservationDetails?.driverId?.id,
  // };
  const nonDriverIds = users?.users
    ?.filter((user) => user.isDriver === false)
    ?.map((user) => user.id);

  const driverId = reservation?.reservationDetails?.driverId?.id;

  // const messageGroupData = [
  //   ...nonDriverIds,
  //   ...(driverId !== undefined ? [driverId] : []),
  // ];
  const messageGroupData = Array.from(
    new Set([...nonDriverIds, ...(driverId !== undefined ? [driverId] : [])])
  );
  // const messageGroupData = [
  //   ...users.users
  //     .filter((user) => user.isDriver === false)
  //     .map((user) => user.id),
  //   reservation?.reservationDetails?.driverId?.id,
  // ];
  // console.log(messageGroupData, "messageGroupData");

  const [isTyping, setIsTyping] = useState(false);

  const [chatModal, setChatModal] = useState(false);
  const [create, setCreate] = useState(false);
  const handleChat = () => {
    if (chatModal === true) {
      setCreate(false);
    }
    if (chatModal === false) {
      setCreate(true);
    }
    setChatModal(!chatModal);
  };
  const [selectedValue, setSelectedValue] = useState([]);
  const onSelect = (selectedList, selectedItem) => {
    setSelectedValue(selectedList);
  };
  const onRemove = (selectedList, removedItem) => {
    setSelectedValue(selectedList);
  };
  useEffect(() => {
    if (socketInstance) {
      socketInstance.on("typing", (user) => setIsTyping(user));
      socketInstance.on("stop typing", (user) => setIsTyping(false));
    }
    if (socketInstance && chats.selectedChat) {
      // console.log(
      //   chats?.messages?.list?.length > 0 &&
      //     chats?.messages?.list[0]?.chatId?.id === chats?.selectedChat?.id,
      //   chats?.messages?.list[0]?.chatId?.id === chats?.selectedChat?.id,
      //   "chkkkkkkkkk"
      // );
      if (
        chats?.messages?.list?.length > 0 &&
        chats?.messages?.list[0]?.chatId?.id === chats?.selectedChat?.id
      ) {
        dispatch(getMessagesAction(chats.selectedChat.id, auth.user.id, true));
        // console.log(
        //   chats?.messages?.list?.length > 0 &&
        //     chats?.messages?.list[0]?.chatId?.id === chats?.selectedChat?.id,
        //   chats?.messages?.list[0]?.chatId?.id === chats?.selectedChat?.id,
        //   "chkkkkkkkkk"
        // );
      } else {
        dispatch(getMessagesAction(chats.selectedChat.id, auth.user.id));
      }

      socketInstance.emit("join room", chats.selectedChat.id);
    }
  }, [chats.selectedChat, socketInstance]);
  const [typing, setTyping] = useState(false);

  const messageHandler = (e) => {
    setMessage(e.target.value);

    if (!socketConnected) return;
    if (!typing) {
      setTyping(true);
      socketInstance.emit("typing", {
        room: chats.selectedChat.id,
        user: {
          id: auth.user.id,
          name: auth.user.firstName + " " + auth.user.lastName,
          ...(auth?.user?.profilePic?.fileUrl && {
            picUrl: auth.user.profilePic.fileUrl,
          }),
        },
        users: chats.selectedChat.users,
      });
    }
    let lastTime = new Date().getTime();
    var time = 3000;
    setTimeout(() => {
      var timeNow = new Date().getTime();
      var timeDiff = timeNow - lastTime;
      if (timeDiff >= time) {
        socketInstance.emit("stop typing", {
          room: chats.selectedChat.id,
          user: {
            id: auth.user.id,
            name: auth.user.firstName + " " + auth.user.lastName,
          },
          users: chats.selectedChat.users,
        });
        setTyping(false);
      }
    }, time);
  };

  const sendMessage = (e) => {
    e.preventDefault();
    const dateTime = new Date();
    // console.log(message, "messagemessage");
    if (message !== "") {
      dispatch(
        sendMessageAction(
          {
            sender: auth.user,
            chatId: chats.selectedChat,
            message: message,
            dateTime,
          },
          () => {
            // console.log({
            //   sender: {
            //     firstName: auth.user.firstName,
            //     lastName: auth.user.lastName,
            //     id: auth.user.id,
            //     ...(auth?.user?.profilePic?.fileUrl && {
            //       profilePic: { fileUrl: auth.user.profilePic.fileUrl },
            //     }),
            //   },
            //   chatId: chats.selectedChat,
            //   message: message,
            //   dateTime,
            // });
            socketInstance.emit("new message", {
              sender: {
                firstName: auth.user.firstName,
                lastName: auth.user.lastName,
                id: auth.user.id,
                ...(auth?.user?.profilePic?.fileUrl && {
                  profilePic: { fileUrl: auth.user.profilePic.fileUrl },
                }),
              },
              // chatId: chats.selectedChat,
              chatId: {
                ...chats.selectedChat,
                // ajeeb harkatein hain selectedChat ki jagah selected likh dia sirf
                reservationId: chats?.selectedChat?.reservationId?.id
                  ? chats.selectedChat.reservationId.id
                  : chats.selectedChat.reservationId,
              },
              message: message,
              dateTime,
            });
            if (!chats?.selectedChat?.acceptedUser) {
              dispatch(getMessagesAction(chats.selectedChat.id, auth.user.id));
            }
          }
        )
      );
      setMessage("");
    }
  };

  const handleInputClick = () => {
    dispatch(
      createGroupAction(
        {
          // userId: auth.user.id,
          // company: auth.user.companyId.id,
          // chatName: "Group",
          // isGroup: true,
          // isCompanyGroup: false,
          // // latestMessage: null,
          // groupAdmin: auth.user.id,
          // groupType: "Selected Users",
          // users: messageGroupData.users,
          // // users: idArray,
          // // members: idArray,

          // userId: auth.user.id,
          company: auth.user.companyId.id,
          chatName: reservation?.reservationDetails?.orderId,
          isGroup: true,
          isCompanyGroup: false,
          latestMessage: null,
          groupAdmin: auth.user.id,
          groupType: "Selected Users",
          users: messageGroupData,
          reservationId: reservation?.reservationDetails?._id
            ? reservation?.reservationDetails?._id
            : reservation?.reservationDetails?.id,
          orderId: reservation?.reservationDetails?.orderId,
        },
        successHandler
      )
    );
    setShowChat(!showChat);
    // }
  };
  const successHandler = () => {
    setCreate(false);
    setChatModal(false);
    setSelectedValue([]);
  };

  // const [showDeclineDropDown, setshowDeclineDropDown] = useState(false);
  // const openDeclineDropDown = () => {
  //   setshowDeclineDropDown((showDeclineDropDown) => !showDeclineDropDown);
  // };

  useEffect(() => {
    if (socketInstance) {
      socketInstance?.emit("send-reservation-req", {
        userId: auth.user.id,
        companyId: auth.user.companyId.id,
        reservationId: values.reservationId,
        role: "admin",
      });
      socketInstance?.on(
        "receive-reservation-detail",
        (newNotificationRecieved) => {
          // console.log("reservation received==>", newNotificationRecieved);
          const { reservation, trip } = newNotificationRecieved;
          // console.log(reservation, trip, "<==reservation");
          dispatch(
            setReservationData({
              ...reservation,
              _id: reservation.id,
              trip,
            })
          );
          // auth.tokens.refresh.token &&
          //   rideNotification(
          //     newNotificationRecieved.text,
          //     auth.tokens.refresh.token
          //   );
        }
      );
    }
    return () => {
      socketInstance?.off("receive-reservation-detail");
    };
  }, [socketInstance]);

  return (
    <>
      {reservation.loading && reservation?.reservationDetails && (
        <UpdatingModal />
      )}
      {reservation?.reservationDetails && (
        <>
          <div className="d-block d-lg-flex d-md-flex justify-content-between action-bar mb-20 mt-3">
            <div id="table-actions" className="flex-grow-1 align-items-center">
              {/* <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">  My Bookings </h4> */}
            </div>
            <div
              className="ride-bttn-new btn-group mt-2 mt-lg-0 mt-md-0 ml-0 ml-lg-3 ml-md-3"
              role="group"
            >
              {/* </div> */}
              {/* <div className="col-lg-2">
                    <div className="form-group my-2">
                        {
                            auth?.user?.permissionId?.myOrder?.create === true || auth?.user?.permissionId?.myOrder?.update === true) ?
                            <button type="button" className="btn btn-success" style={{cursor:"default", width: "100%"}} >Completed</button>
                            :
                            routes?.routeDetails?.trip !== null?
                            <button type="button" className="btn btn-info" style={{cursor:"default", width: "100%"}} >In Progress</button>
                            :
                            routes?.routeDetails?.trip === null ?
                            <button type="button" className="btn btn-primary" style={{cursor:"default", width: "100%"}} 
                            onClick={() => 
                                {
                                    routes?.routeDetails?.route?.driverBookingStatus !== 'Assigned' ?
                                    notifyFailure("Driver not Assigned")
                                    :
                                    dispatch(addRouteTrip({
                                        companyId: auth.user.companyId.id,
                                        driverId: routes?.routeDetails?.route?.driverId?.id,
                                        routeId: params.id,
                                        tripStartTime: moment().format().toString().substring(0,19)+".000Z",
                                    }))
                                    setTimeout(() => 
                                        dispatch(getRouteandTripByIdV2({
                                            _id:params.id,
                                            today: moment().format('YYYY-MM-DD')
                                        }))
                                    , 2000);
                                }
                            } 
                            >Start</button>
                            :
                            <></>
                        }
                    </div>
                </div> */}
              <button
                type="button"
                title="Message"
                className="btn-excel-new"
                data-toggle="tooltip"
                data-original-title="Filters"
                // onClick={() =>
                //   navigate(`/chat`)
                // }
                onClick={handleInputClick}
              >
                <i className="fas fa-comment-dots"></i>{" "}
              </button>{" "}
              {
                // (auth?.user?.permissionId?.myOrder?.create === true || auth?.user?.permissionId?.myOrder?.update === true) &&

                // data.driverBookingStatus !== 'Assigned' ?
                // notifyFailure("Driver not Assigned")
                // :
                reservation?.reservationDetails?.status === "Completed" ||
                reservation?.reservationDetails?.trip?.tripStatus ===
                  "Completed" ? (
                  <button
                    type="button"
                    className="btn btn-success"
                    style={{ cursor: "default" }}
                  >
                    Completed
                  </button>
                ) : reservation?.reservationDetails?.status === "Canceled" ? (
                  <button
                    type="button"
                    className="btn btn-info"
                    style={{ cursor: "default" }}
                  >
                    Canceled
                  </button>
                ) : reservation?.reservationDetails?.trip?.tripCancel ===
                  "No Show" ? (
                  <button
                    type="button"
                    className="btn btn-info"
                    style={{ cursor: "default" }}
                  >
                    No Show
                  </button>
                ) : reservation &&
                  reservation?.reservationDetails?.trip === null ? (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      reservation?.reservationDetails?.driverBookingStatus !==
                      "Assigned"
                        ? notifyFailure("Driver not Assigned")
                        : // handleSubmit()
                          dispatch(
                            addTrip(tripData, () => {
                              socketInstance?.emit("send-reservation-req", {
                                userId: auth.user.id,
                                companyId: auth.user.companyId.id,
                                reservationId: values.reservationId,
                                role: "admin",
                              });
                            })
                          );
                      // setValues({
                      //   assetNo: "",
                      //   serviceType: "",
                      //   vehicleOwnership: "",
                      // });
                    }}
                  >
                    Start Ride
                  </button>
                ) : reservation?.reservationDetails?.trip?.tripStatus ===
                  "Towards Customer" ? (
                  <button
                    type="button"
                    className="btn btn-dark"
                    onClick={handleArrived}
                  >
                    Arrived
                  </button>
                ) : reservation?.reservationDetails?.trip?.tripStatus ===
                  "Arrived" ? (
                  <>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={handleNoShowRide}
                    >
                      No Show
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={handlePickUp}
                    >
                      Pick Customer
                    </button>
                  </>
                ) : reservation?.reservationDetails?.trip?.tripStatus ===
                  "Ongoing" ? (
                  <button
                    type="button"
                    className="btn btn-warning"
                    onClick={handleEndRide}
                  >
                    Ride End
                  </button>
                ) : (
                  <></>
                )
              }
            </div>
          </div>
          <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
            <table
              className="table border dataTable no-footer"
              id="leads-table"
              role="grid"
              aria-describedby="leads-table_info"
            >
              <thead className="card-header">
                <tr role="row">
                  {/* <th
                                title="Order ID"
                                className="sorting border-right"
                                tabIndex="0"
                                aria-controls="leads-table"
                                rowSpan="1"
                                colSpan="1"
                                aria-label="Mobile: activate to sort column ascending"
                            >
                                Order ID
                            </th> */}
                  <th
                    title="Time"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    Time
                  </th>
                  <th
                    title="Passenger"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    Passenger
                  </th>
                  <th
                    title="Pickup - Drop Off"
                    className="sorting border-right w-30"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    Pickup - Drop Off
                  </th>
                  <th
                    title="Vehicle Type"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    Vehicle Type
                  </th>
                  <th
                    title="Price"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    Price
                  </th>
                  <th
                    title="Status"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    Status
                  </th>
                  <th
                    title="Driver Name"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    Driver Name
                  </th>
                  <th
                    title="Undo Actions"
                    className="sorting border-right"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Action"
                  >
                    Undo Actions
                  </th>
                  {/* <th
                    title="Paid Status"
                    className="sorting border-right"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Action"
                  >
                    Paid Status
                  </th> */}
                </tr>
              </thead>
              <tbody>
                <tr role="row" className="odd border">
                  {/* <td className="border-right">{reservation?.reservationDetails?.orderId}</td> */}
                  <td className="border-right">
                    <strong>Pickup:</strong>{" "}
                    {parseInt(
                      reservation?.reservationDetails?.pickUpTime?.substring(
                        0,
                        2
                      )
                    ) === 0
                      ? 12
                      : parseInt(
                          reservation?.reservationDetails?.pickUpTime?.substring(
                            0,
                            2
                          )
                        ) > 12
                      ? parseInt(
                          reservation?.reservationDetails?.pickUpTime?.substring(
                            0,
                            2
                          )
                        ) - 12
                      : parseInt(
                          reservation?.reservationDetails?.pickUpTime?.substring(
                            0,
                            2
                          )
                        )}
                    {":" +
                      reservation?.reservationDetails?.pickUpTime?.substring(
                        3,
                        5
                      )}
                    {parseInt(
                      reservation?.reservationDetails?.pickUpTime?.substring(
                        0,
                        2
                      )
                    ) > 11
                      ? "pm"
                      : "am"}
                    <br />
                    <strong>Dropoff:</strong>{" "}
                    {parseInt(
                      reservation?.reservationDetails?.dropOffTime?.substring(
                        0,
                        2
                      )
                    ) === 0
                      ? 12
                      : parseInt(
                          reservation?.reservationDetails?.dropOffTime?.substring(
                            0,
                            2
                          )
                        ) > 12
                      ? parseInt(
                          reservation?.reservationDetails?.pickUpTime?.substring(
                            0,
                            2
                          )
                        ) - 12
                      : parseInt(
                          reservation?.reservationDetails?.dropOffTime?.substring(
                            0,
                            2
                          )
                        )}
                    {":" +
                      reservation?.reservationDetails?.dropOffTime?.substring(
                        3,
                        5
                      )}
                    {parseInt(
                      reservation?.reservationDetails?.dropOffTime?.substring(
                        0,
                        2
                      )
                    ) > 11
                      ? "pm"
                      : "am"}
                    <br />
                    <strong>ETA:</strong>{" "}
                    {
                      reservation?.reservationDetails?.fareAndDistance
                        ?.estimatedTime
                    }
                  </td>
                  <td className="border-right">
                    <strong>
                      {reservation?.reservationDetails?.customerId?.firstName}{" "}
                      {reservation?.reservationDetails?.customerId?.lastName}
                    </strong>
                    <br />
                    {reservation?.reservationDetails?.customerId?.phone}
                    <br />
                  </td>
                  <td className="border-right w-30">
                    <div className="d-flex justify-content-between">
                      <div>
                        <div>
                          <p className="f-12 font-medium mb-0">
                            <strong>
                              {
                                reservation?.reservationDetails?.pickUpAddress
                                  ?.address
                              }
                            </strong>{" "}
                          </p>
                        </div>
                        <div>
                          <time>
                            {" "}
                            {moment(
                              reservation?.reservationDetails?.pickUpDate?.substring(
                                0,
                                10
                              )
                            ).format("MM/DD/YYYY")}{" "}
                            -{" "}
                            {parseInt(
                              reservation?.reservationDetails?.pickUpTime?.substring(
                                0,
                                2
                              )
                            ) === 0
                              ? 12
                              : parseInt(
                                  reservation?.reservationDetails?.pickUpTime?.substring(
                                    0,
                                    2
                                  )
                                ) > 12
                              ? parseInt(
                                  reservation?.reservationDetails?.pickUpTime?.substring(
                                    0,
                                    2
                                  )
                                ) - 12
                              : parseInt(
                                  reservation?.reservationDetails?.pickUpTime?.substring(
                                    0,
                                    2
                                  )
                                )}
                            {":" +
                              reservation?.reservationDetails?.pickUpTime?.substring(
                                3,
                                5
                              )}
                            {parseInt(
                              reservation?.reservationDetails?.pickUpTime?.substring(
                                0,
                                2
                              )
                            ) > 11
                              ? "pm"
                              : "am"}
                          </time>
                        </div>
                      </div>
                      <div className="mt-4 px-4">
                        <div className="hidden sm:block">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="50"
                            height="8"
                            viewBox="0 0 40 6"
                            fill="none"
                          >
                            <path
                              d="M40 3L35 0.113249V5.88675L40 3ZM0 3.5H35.5V2.5H0V3.5Z"
                              fill="black"
                            ></path>
                          </svg>
                        </div>
                        <p className="text-center font-medium">
                          <span>
                            {reservation?.reservationDetails?.distanceFromPickToDropOff?.toFixed(
                              2
                            )}{" "}
                            mi
                          </span>
                        </p>
                      </div>
                      <div>
                        <div>
                          <p className="f-12 font-medium mb-0">
                            <strong>
                              {
                                reservation?.reservationDetails?.dropOffAddress
                                  ?.address
                              }
                            </strong>
                          </p>
                        </div>
                        <div>
                          <time>
                            {" "}
                            {moment(
                              reservation?.reservationDetails?.dropOffDate?.substring(
                                0,
                                10
                              )
                            ).format("MM/DD/YYYY")}{" "}
                            -{" "}
                            {parseInt(
                              reservation?.reservationDetails?.dropOffTime?.substring(
                                0,
                                2
                              )
                            ) === 0
                              ? 12
                              : parseInt(
                                  reservation?.reservationDetails?.dropOffTime?.substring(
                                    0,
                                    2
                                  )
                                ) > 12
                              ? parseInt(
                                  reservation?.reservationDetails?.pickUpTime?.substring(
                                    0,
                                    2
                                  )
                                ) - 12
                              : parseInt(
                                  reservation?.reservationDetails?.dropOffTime?.substring(
                                    0,
                                    2
                                  )
                                )}
                            {":" +
                              reservation?.reservationDetails?.dropOffTime?.substring(
                                3,
                                5
                              )}
                            {parseInt(
                              reservation?.reservationDetails?.dropOffTime?.substring(
                                0,
                                2
                              )
                            ) > 11
                              ? "pm"
                              : "am"}
                          </time>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="border-right">
                    {reservation?.reservationDetails?.type?.name}
                  </td>
                  <td
                    className="border-right price-tag-detail"
                    title="details"
                    // onClick={openDetailDropDown}
                  >
                    {/* <strong className="price-tag-detail"> */}
                    <strong>
                      $
                      {
                        reservation?.reservationDetails?.fareAndDistance
                          ?.driverAmount
                        // .toFixed(
                        //   2
                        // )
                      }
                    </strong>
                    <i data-toggle="tooltip"></i>
                  </td>
                  {/* <td className="border-right">
                    <strong>
                      ${reservation?.reservationDetails?.totalAmount.toFixed(2)}
                    </strong>
                    <i data-toggle="tooltip"></i>
                  </td> */}
                  <td className="border-right">
                    {reservation?.reservationDetails?.status}
                  </td>
                  <td className="border-right">
                    {reservation?.reservationDetails?.isAssigned === true
                      ? reservation?.reservationDetails?.driverId?.firstName +
                        " " +
                        reservation?.reservationDetails?.driverId?.lastName
                      : "-"}
                  </td>
                  {/* <td className=""> */}
                  {/* {reservation?.reservationDetails?.isPaid === true
                      ? "Paid"
                      : "Unpaid"} */}
                  {/* nil */}
                  {/* </td> */}
                  <td className=" text-right pr-20">
                    <div className="task_view">
                      <div className="dropdown">
                        <Link
                          to=""
                          className="task_view_more d-flex align-items-center justify-content-center dropdown-toggle"
                          type="link"
                          data-toggle="dropdown"
                          onClick={openMoreVertDropDown}
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i className="">Undo Action</i>
                        </Link>
                        <div
                          className={`dropdown-menu dropdown-menu-right ${
                            showMoreVertDropDown
                              ? "dropdown-menu dropdown-menu-right show"
                              : "dropdown-menu dropdown-menu-right"
                          }`}
                          aria-labelledby="dropdownMenuLink-20"
                        >
                          <button
                            className="dropdown-item"
                            onClick={() => handleUndoAction("start")}
                          >
                            <i className=""> Undo Start</i>
                          </button>

                          <button
                            className="dropdown-item"
                            onClick={() => handleUndoAction("arrived")}
                          >
                            <i className=""> Undo Arrived</i>
                          </button>
                          {reservation?.reservationDetails?.trip?.tripCancel?.toLowerCase() ===
                          "no show" ? (
                            <button
                              className="dropdown-item delete-table-row"
                              onClick={() => handleUndoAction("noshow")}
                            >
                              <i className=""> Undo No Show</i>
                            </button>
                          ) : (
                            <>
                              <button
                                className="dropdown-item"
                                onClick={() => handleUndoAction("pick")}
                              >
                                <i className=""> Undo Pickup</i>
                              </button>
                              <button
                                className="dropdown-item delete-table-row"
                                onClick={() => handleUndoAction("dropoff")}
                              >
                                <i className=""> Undo Dropoff</i>
                              </button>
                            </>
                          )}

                          {/* <button className="dropdown-item" onClick={() => handleUndoAction('pick')}>
                            <i className=""> Undo Pickup</i>
                          </button>
                          <button className="dropdown-item delete-table-row" onClick={() => handleUndoAction('dropoff')}>
                            <i className=""> Undo Dropoff</i>
                          </button>
                          {reservation?.reservationDetails?.trip?.tripCancel?.toLowerCase() == "no show" && (
                            <button className="dropdown-item delete-table-row" onClick={() => handleUndoAction('noshow')}>
                              <i className=""> Undo No Show</i>
                            </button>
                          )} */}
                        </div>
                      </div>
                    </div>
                  </td>
                  {/* <td className="">
                    {reservation?.reservationDetails?.isPaid === true
                      ? "Paid"
                      : "Unpaid"}
                  </td> */}
                </tr>
              </tbody>
            </table>
          </div>

          <br />
          <br />
          <h4 style={{ overflowX: "auto", width: "95%", margin: "auto" }}>
            {" "}
            All Adresses & Stops{" "}
          </h4>
          <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
            {/* <div id="table-actions" className="flex-grow-1 align-items-center"> */}

            <table
              className="table border dataTable no-footer"
              id="leads-table"
              role="grid"
              aria-describedby="leads-table_info"
            >
              <thead className="card-header">
                <tr role="row">
                  <th
                    title="Address"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Mobile: activate to sort column ascending"
                  >
                    Address
                  </th>
                  <th
                    title="Date"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    Date
                  </th>
                  <th
                    title="Time"
                    className="sorting border-right"
                    tabIndex="0"
                    aria-controls="leads-table"
                    rowSpan="1"
                    colSpan="1"
                    aria-label="Lead Agent: activate to sort column ascending"
                  >
                    Time
                  </th>
                </tr>
              </thead>
              <tbody>
                {reservation &&
                  reservation?.reservationDetails?.rideHistory &&
                  reservation?.reservationDetails?.rideHistory.length > 0 &&
                  reservation?.reservationDetails?.rideHistory.map(
                    (current, i) => {
                      return (
                        <tr role="row" className="odd border">
                          <td className="border-right">
                            {current?.address?.address}
                          </td>
                          <td className="border-right">
                            {moment(current?.date?.substring(0, 10)).format(
                              "MM/DD/YYYY"
                            )}
                          </td>
                          <td className="border-right">
                            {parseInt(current?.time?.substring(0, 2)) === 0
                              ? 12
                              : parseInt(current?.time?.substring(0, 2)) > 12
                              ? parseInt(current?.time?.substring(0, 2)) - 12
                              : parseInt(current?.time?.substring(0, 2))}
                            {":" + current?.time?.substring(3, 5)}
                            {parseInt(current?.time?.substring(0, 2)) > 11
                              ? "pm"
                              : "am"}
                          </td>
                        </tr>
                      );
                    }
                  )}
              </tbody>
            </table>
          </div>

          {showChat && (
            <div className="card-body">
              <div className="">
                <div className="chat-window b-shadow-4">
                  {/* <div className="chat-window b-shadow-4" style={{ height: "80vh"}}> */}
                  <div
                    className="chat-cont-right"
                    style={{ flex: "0 0 100%", maxWidth: "100%" }}
                  >
                    {/* {chatModal && ( */}
                    <>
                      <div className="chat-header">
                        <a
                          id="back_user_list"
                          href=""
                          className="back-user-list"
                        >
                          <i className="material-icons">-</i>
                        </a>
                      </div>
                      <div className="chat-body">
                        <div
                          className="chat-scroll2"
                          // className="chat-scroll"
                        >
                          <ul
                            className="list-unstyled"
                            // ref={scrollRef}
                            style={{
                              overflowY: "auto",
                              height: "330px",
                              // minHeight: windowSize.height,
                            }}
                          >
                            {" "}
                            {chats.messages.loading ? (
                              <Loader />
                            ) : (
                              chats.messages.list.length === 0 && (
                                <p className="text-center bg-white p-3">
                                  No messages in the chat, start with sending a
                                  message!
                                </p>
                              )
                            )}
                            {chats.messages.list.map((chatMessage) => {
                              const date = moment(chatMessage.dateTime);
                              const currentDate = moment(date);
                              const formattedDate =
                                currentDate.format("MMM DD, YYYY"); // Customize the format as needed
                              const isToday = currentDate.isSame(
                                moment(),
                                "day"
                              );
                              let displayDate = isToday
                                ? "Today"
                                : moment(formattedDate).format("ll");
                              const renderDate = lastDate !== displayDate;
                              lastDate = displayDate;
                              let isSameSender =
                                currentSender === chatMessage.sender.id;
                              if (renderDate) {
                                isSameSender = false;
                              }
                              if (!isSameSender) {
                                currentSender = chatMessage.sender.id;
                              }
                              return (
                                <MessageItem
                                  chatMessage={chatMessage}
                                  renderDate={renderDate}
                                  displayDate={displayDate}
                                  isSameSender={isSameSender}
                                  noShowData={noShowData}
                                />
                              );
                            })}
                            {isTyping &&
                              chats?.selectedChat?.id == isTyping.room && (
                                <li className="media d-flex received">
                                  <div
                                    className="avatar"
                                    style={{ backgroundColor: "#f5f5f6" }}
                                  >
                                    <img
                                      src={
                                        isTyping?.user?.picUrl
                                          ? isTyping?.user?.picUrl !== "null"
                                            ? isTyping?.user?.picUrl
                                            : defaultImg
                                          : defaultImg
                                      }
                                      alt="User Image"
                                      className="chat-avatar-img rounded-circle centralized-avatar"
                                    />
                                  </div>
                                  <div className="media-body">
                                    {isTyping &&
                                      chats?.selectedChat?.id ==
                                        isTyping.room && (
                                        <div style={{ fontSize: "12px" }}>
                                          {isTyping?.user?.name}
                                        </div>
                                      )}
                                    <div className="msg-box">
                                      <div>
                                        <div className="msg-typing">
                                          <span></span>
                                          <span></span>
                                          <span></span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              )}
                          </ul>
                        </div>
                      </div>
                      <div className="chat-footer">
                        <form className="mb-3 mx-2" onSubmit={sendMessage}>
                          <div className="input-group">
                            <div className="input-group-prepend"></div>
                            <input
                              type="text"
                              className="input-msg-send form-control height-35"
                              placeholder="Aa"
                              onChange={messageHandler}
                              // onClick={handleInputClick}
                              // onChange={(e) => setMessage(e.target.value)}
                              value={message}
                              required
                              disabled={
                                !chats?.selectedChat?.acceptedUser ||
                                chats?.selectedChat?.acceptedUser ===
                                  auth.user.id
                                  ? false
                                  : true
                              }
                            />
                            <div
                              className="input-group-append"
                              style={{ zIndex: 0 }}
                            >
                              <button
                                type="submit"
                                disabled={
                                  !chats?.selectedChat?.acceptedUser ||
                                  chats?.selectedChat?.acceptedUser ===
                                    auth.user.id
                                    ? false
                                    : true
                                }
                                className="btn msg-send-btn height-35"
                              >
                                <i className="fas fa-paper-plane"></i>
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {/* <AdminNoshowAcceptDeclineModal
        showDeclineDropDown={showDeclineDropDown}
        openDeclineDropDown={openDeclineDropDown}
      /> */}
      {/* <AdminNoshowAcceptDeclineModal 
      showDeclineDropDown={showDeclineDropDown}
      openDeclineDropDown={openDeclineDropDown}
      /> */}
      {/* <AdminBookingPriceDetailModal
        showDetailDropDown={showDetailDropDown}
        openDetailDropDown={openDetailDropDown}
      /> */}
    </>
  );
};
export default AdminBookingViewComponent;

// {!chats.selectedChat
//   ? !chatModal && (
//       <div
//         className="dash-stats-list"
//         style={{ textAlign: "center" }}
//       >
//         <br />
//         <br />
//         <img
//           alt=""
//           // src={noChat}
//           style={{
//             width: "145px",
//             opacity: "0.5",
//             marginTop: "100px",
//           }}
//         />
//         <br />
//         <br />
//         <p style={{ opacity: "0.5", fontSize: "20px" }}>
//           No chats
//         </p>
//       </div>
//      )
//   : !chatModal && (
//       <>
//         <div className="chat-header">
//           <a
//             id="back_user_list"
//             href=""
//             className="back-user-list"
//           >
//             <i className="material-icons">chevron_left</i>
//           </a>
//           <div className="media d-flex">
//             <div className="media-img-wrap">
//               <div
//                 // className={`avatar ${
//                 //   !chats?.selectedChat?.isGroup
//                 //     ? receiver?.isOnline
//                 //       ? "avatar-online"
//                 //       : !receiver?.isOnline
//                 //       ? "avatar-offline"
//                 //       : ""
//                 //     : ""
//                 // }`}
//               >
//                 <img
//                   // src={
//                   //   chats?.selectedChat?.isGroup
//                   //     ? groupIcon
//                   //     : receiver?.profilePic
//                   //     ? receiver?.profilePic.fileUrl
//                   //     : defaultImg
//                   // }
//                   alt="avatar"
//                   className="rounded-circle d-flex align-self-center me-3-wala-new shadow-1-strong object-fit-cover inline-block"
//                   width="40"
//                   height="40"
//                 />
//               </div>
//             </div>
//             <div className="media-body">
//               <div className="user-name">
//                 {chats?.selectedChat?.isGroup
//                   ? chats?.selectedChat?.chatName
//                   : receiver
//                   ? `${receiver.firstName} ${receiver.lastName}`
//                   : ""}
//               </div>
//               <div className="user-status">{`${
//                 !chats?.selectedChat?.isGroup
//                   ? receiver?.isOnline
//                     ? "online"
//                     : !receiver?.isOnline
//                     ? "offline"
//                     : ""
//                   : chats?.selectedChat?.users?.length +
//                     " members"
//               }`}
//               </div>
//             </div>
//           </div>
//           <div className="chat-options">

//             <button
//               // onClick={startCalling}
//               // className={isJoined ? "button-on" : ""}
//             >
//               <i className="material-icons fa fa-phone"></i>
//             </button>

//           </div>
//         </div>
//         <div className="chat-body">
//           <div className="chat-scroll">
//             <ul
//               className="list-unstyled"
//               // ref={scrollRef}
//               style={{
//                 overflowY: "auto",
//                 // minHeight: windowSize.height,
//               }}
//             >
//               {" "}
//               {chats.messages.loading ? (
//                 <Loader />
//               ) : (
//                 chats.messages.list.length === 0 && (
//                   <p className="text-center bg-white p-3">
//                     No messages in the chat, start with sending a
//                     message!
//                   </p>
//                 )
//               )}
//               {chats.messages.list.map((chatMessage) => {
//                 const date = moment(
//                   chatMessage.dateTime
//                 );
//                 const currentDate = moment(date);
//                 const formattedDate =
//                   currentDate.format("MMM DD, YYYY"); // Customize the format as needed

//                 const isToday = currentDate.isSame(
//                   moment(),
//                   "day"
//                 );
//                 let displayDate = isToday
//                   ? "Today"
//                   : moment(formattedDate).format('ll');

//                 const renderDate = lastDate !== displayDate;
//                 lastDate = displayDate;

//                 let isSameSender =
//                   currentSender === chatMessage.sender.id;
//                 if (renderDate) {
//                   isSameSender = false;
//                 }
//                 if (!isSameSender) {
//                   currentSender = chatMessage.sender.id;
//                 }
//                 return (
//                   <MessageItem
//                     chatMessage={chatMessage}
//                     renderDate={renderDate}
//                     displayDate={displayDate}
//                     isSameSender={isSameSender}
//                   />
//                 );
//               })}
//               {isTyping &&
//                 chats?.selectedChat?.id == isTyping.room &&
//                 auth?.user?.id != isTyping?.user?.id && (
//                   <li className="media d-flex received">
//                     <div
//                       className="avatar"
//                       style={{ backgroundColor: "#f5f5f6" }}
//                     >
//                       <img
//                         src={
//                           isTyping?.user?.picUrl
//                             ? isTyping.user.picUrl
//                             : defaultImg
//                         }
//                         alt="User Image"
//                         className="chat-avatar-img rounded-circle centralized-avatar"
//                       />
//                     </div>
//                     <div className="media-body">
//                       <div className="msg-box">
//                         <div>
//                           <div className="msg-typing">
//                             <span></span>
//                             <span></span>
//                             <span></span>
//                           </div>
//                         </div>
//                       </div>
//                     </div>
//                   </li>
//                 )}
//             </ul>
//           </div>
//         </div>
//         <div className="chat-footer">
//           <form className="mb-3 mx-2"
//           // onSubmit={sendMessage}
//           >
//             <div className="input-group">
//               <div className="input-group-prepend">
//               </div>
//               <input
//                 type="text"
//                 className="input-msg-send form-control height-35"
//                 placeholder="Aa"
//                 // onChange={messageHandler}
//                 // value={message}
//                 required
//               />
//               <div className="input-group-append">
//                 <button
//                   type="submit"
//                   className="btn msg-send-btn height-35"
//                 >
//                   <i className="fas fa-paper-plane"></i>
//                 </button>
//               </div>
//             </div>
//           </form>
//         </div>
//       </>
//     )}
