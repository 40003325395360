/** @format */

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import AdminOrderSearchTableRow from "./AdminOrderSearchTableRow";
import { getReservationsByDate } from "../../../actions/reservationAction";
import shift1 from "../../../assets/images/load.png";
import { Link, NavLink } from "react-router-dom";
import { paginationDataToShow } from "../../../actions/generalActions";
import NotAllowed from "../../NotAllowed";
import LoadingComponentRideModal from "../../LoadingComponentRideModal";

const AdminOrderSearchTable = ({ setExportData }) => {
  const auth = useSelector((state) => state.auth);
  const reservation = useSelector((state) => state.reservation);
  const { loading } = reservation;
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };
  const dispatch = useDispatch();

  // State to manage pagination, sorting, and limit
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState("pickUpDate");
  const [sortOrder, setSortOrder] = useState("asc"); //asc, desc
  const [searchTerm, setSearchTerm] = useState("");
  const [limit, setLimit] = useState(50); // Set your default limit here

  const [paginatedData, setPaginatedData] = useState({});
  // console.log(paginatedData, "paginatedData");

  // Function to handle pagination, sorting, and searching
  const handlePagination = (page, field, order, search, newLimit) => {
    let newPage;
    if (page < 1) {
      newPage = 1;
    } else if (page > paginatedData.totalPages) {
      newPage = paginatedData.totalPages;
    } else {
      newPage = page;
    }
    setCurrentPage(newPage);
    setSortField(field);
    setSortOrder(order);
    setSearchTerm(search);
    setLimit(newLimit);
  };

  const [fullData, setFullData] = useState(
    reservation?.getReservationsByDateV2
  );
  useEffect(() => {
    setFullData(reservation?.getReservationsByDateV2);
  });
  // const { today } = reservation?.reservation;
  useEffect(() => {
    const fullData = reservation?.getReservationsByDateV2;
    // Use the pagination function to get the data for the current page
    setPaginatedData(
      paginationDataToShow(
        limit,
        currentPage,
        fullData,
        sortField,
        sortOrder,
        searchTerm
      )
    );
  }, [
    limit,
    currentPage,
    sortField,
    sortOrder,
    searchTerm,
    reservation?.getReservationsByDateV2,
  ]);

  useEffect(() => {
    if (paginatedData?.results) {
      setExportData(paginatedData?.results);
    }
    // Use the pagination function to get the data for the current page
  }, [paginatedData]);

  // Function to handle sorting icon click
  const handleSortClick = (field, order) => {
    // Call handlePagination with sorting parameters
    handlePagination(currentPage, field, order, searchTerm, limit);
  };
  // Function to determine the color based on the current sorting order
  const getArrowColor = (field, direction) => {
    return sortField === field && sortOrder === direction
      ? "#000000"
      : "#A0A0A0";
  };
  return auth?.user?.permissionId?.booking?.read === true ? (
    <>
      {loading && !reservation?.getReservationsByDateV2 && (
        <LoadingComponentRideModal />
      )}
      {reservation?.getReservationsByDateV2 && (
        <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
          <div className="d-flex border">
            <div className="flex-grow-1">
              <div className="dataTables_length d-flex" id="leads-table_length">
                <div className="p-2">
                  <label>Show entries</label>
                </div>
                <label>
                  <select
                    name="leads-table_length"
                    aria-controls="leads-table"
                    className="custom-select custom-select-sm form-control form-control-sm"
                    autoComplete="false"
                    value={limit} // Make sure to set the value attribute to the current limit
                    onChange={(e) =>
                      handlePagination(
                        1,
                        sortField,
                        sortOrder,
                        searchTerm,
                        parseInt(e.target.value)
                      )
                    }
                  >
                    {[10, 25, 50, 100].map((current, i) => {
                      return (
                        <option value={current} key={i}>
                          {current}
                        </option>
                      );
                    })}
                  </select>
                </label>
              </div>
            </div>
            <div className="task-search task-search-new d-flex  py-1 px-lg-3 px-0 border-right-grey align-items-center">
              <form
                className="w-100 mr-1 mr-lg-0 mr-md-1 ml-md-1 ml-0 ml-lg-0"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="input-group bg-grey rounded">
                  <div className="input-group-prepend">
                    <span className="input-group-text border-0 bg-additional-grey">
                      <i className="fa fa-search f-13 text-dark-grey"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control f-14 p-1 border-additional-grey"
                    id="search-text-field"
                    placeholder="Start typing to search"
                    value={searchTerm}
                    onChange={(e) =>
                      handlePagination(
                        1,
                        sortField,
                        sortOrder,
                        e.target.value,
                        limit
                      )
                    }
                  />
                </div>
              </form>
            </div>
          </div>
          <div
            id="leads-table_wrapper"
            className="dataTables_wrapper dt-bootstrap4 no-footer"
          >
            <div className="row">
              <div className="col-sm-12">
                <table
                  className="table border w-100 dataTable no-footer"
                  id="leads-table"
                  role="grid"
                  aria-describedby="leads-table_info"
                  style={{ overflowX: "auto" }}
                >
                  <thead className="card-header">
                    <tr role="row">
                      <th
                        title="Order ID"
                        className="sorting border-right align-table-header"
                        tabIndex="0"
                        aria-controls="leads-table"
                        rowSpan="1"
                        colSpan="1"
                        aria-label="Mobile: activate to sort column ascending"
                      >
                        Order ID{" "}
                        <span className="right-sorting-new">
                          <i
                            className="fas fa-long-arrow-alt-up"
                            style={{ color: getArrowColor("orderId", "asc") }}
                            onClick={() => handleSortClick("orderId", "asc")}
                          ></i>
                          <i
                            className="fas fa-long-arrow-alt-down"
                            style={{ color: getArrowColor("orderId", "desc") }}
                            onClick={() => handleSortClick("orderId", "desc")}
                          ></i>
                        </span>
                      </th>
                      <th
                        title="Load#"
                        className="sorting border-right align-table-header"
                        tabIndex="0"
                        aria-controls="leads-table"
                        rowSpan="1"
                        colSpan="1"
                        aria-label="Mobile: activate to sort column ascending"
                      >
                        Load#{" "}
                        <span className="right-sorting-new">
                          <i
                            className="fas fa-long-arrow-alt-up"
                            style={{
                              color: getArrowColor("loadNumber", "asc"),
                            }}
                            onClick={() => handleSortClick("loadNumber", "asc")}
                          ></i>
                          <i
                            className="fas fa-long-arrow-alt-down"
                            style={{
                              color: getArrowColor("loadNumber", "desc"),
                            }}
                            onClick={() =>
                              handleSortClick("loadNumber", "desc")
                            }
                          ></i>
                        </span>
                      </th>
                      <th
                        title="Driver Name"
                        className="sorting border-right align-table-header"
                        tabIndex="0"
                        aria-controls="leads-table"
                        rowSpan="1"
                        colSpan="1"
                        aria-label="Mobile: activate to sort column ascending"
                      >
                        Driver Name{" "}
                        <span className="right-sorting-new">
                          <i
                            className="fas fa-long-arrow-alt-up"
                            style={{
                              color: getArrowColor("driverId.firstName", "asc"),
                            }}
                            onClick={() =>
                              handleSortClick("driverId.firstName", "asc")
                            }
                          ></i>
                          <i
                            className="fas fa-long-arrow-alt-down"
                            style={{
                              color: getArrowColor(
                                "driverId.firstName",
                                "desc"
                              ),
                            }}
                            onClick={() =>
                              handleSortClick("driverId.firstName", "desc")
                            }
                          ></i>
                        </span>
                      </th>

                      <th
                        title="Vehicle#"
                        className="sorting_disabled border-right align-table-header"
                        rowSpan="1"
                        colSpan="1"
                        aria-label="Next Follow Up"
                      >
                        Vehicle#{" "}
                        <span className="right-sorting-new">
                          <i
                            className="fas fa-long-arrow-alt-up"
                            style={{
                              color: getArrowColor("assetId.assetNo", "asc"),
                            }}
                            onClick={() =>
                              handleSortClick("assetId.assetNo", "asc")
                            }
                          ></i>
                          <i
                            className="fas fa-long-arrow-alt-down"
                            style={{
                              color: getArrowColor("assetId.assetNo", "desc"),
                            }}
                            onClick={() =>
                              handleSortClick("assetId.assetNo", "desc")
                            }
                          ></i>
                        </span>
                      </th>
                      <th
                        title="Time"
                        className="sorting border-right align-table-header"
                        tabIndex="0"
                        aria-controls="leads-table"
                        rowSpan="1"
                        colSpan="1"
                        aria-label="Lead Agent: activate to sort column ascending"
                      >
                        Time{" "}
                        <span className="right-sorting-new">
                          <i
                            className="fas fa-long-arrow-alt-up"
                            style={{
                              color: getArrowColor("pickUpDate", "asc"),
                            }}
                            onClick={() => handleSortClick("pickUpDate", "asc")}
                          ></i>
                          <i
                            className="fas fa-long-arrow-alt-down"
                            style={{
                              color: getArrowColor("pickUpDate", "desc"),
                            }}
                            onClick={() =>
                              handleSortClick("pickUpDate", "desc")
                            }
                          ></i>
                        </span>
                      </th>
                      <th
                        title="Passenger"
                        className="sorting border-right align-table-header"
                        tabIndex="0"
                        aria-controls="leads-table"
                        rowSpan="1"
                        colSpan="1"
                        aria-label="Lead Agent: activate to sort column ascending"
                      >
                        Passenger{" "}
                        {/* <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{
                            color: getArrowColor("customerId.firstName", "asc"),
                          }}
                          onClick={() =>
                            handleSortClick("customerId.firstName", "asc")
                          }
                          ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{
                            color: getArrowColor("customerId.firstName", "desc"),
                          }}
                          onClick={() =>
                            handleSortClick("customerId.firstName", "desc")
                          }
                        ></i>
                      </span> */}
                      </th>
                      <th
                        title="Pickup - Drop Off"
                        className="sorting border-right w-30 align-table-header"
                        tabIndex="0"
                        aria-controls="leads-table"
                        rowSpan="1"
                        colSpan="1"
                        aria-label="Lead Agent: activate to sort column ascending"
                      >
                        Pickup - Drop Off{" "}
                        {/* <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{
                            color: getArrowColor("pickUpDate", "asc"),
                          }}
                          onClick={() => handleSortClick("pickUpDate", "asc")}
                          ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{
                            color: getArrowColor("pickUpDate", "desc"),
                          }}
                          onClick={() => handleSortClick("pickUpDate", "desc")}
                          ></i>
                        </span> */}
                      </th>
                      <th
                        title="Vehicle Type"
                        className="sorting border-right align-table-header"
                        tabIndex="0"
                        aria-controls="leads-table"
                        rowSpan="1"
                        colSpan="1"
                        aria-label="Lead Agent: activate to sort column ascending"
                      >
                        Vehicle Type{" "}
                        <span className="right-sorting-new">
                          <i
                            className="fas fa-long-arrow-alt-up"
                            style={{
                              color: getArrowColor("type.name", "asc"),
                            }}
                            onClick={() => handleSortClick("type.name", "asc")}
                          ></i>
                          <i
                            className="fas fa-long-arrow-alt-down"
                            style={{
                              color: getArrowColor("type.name", "desc"),
                            }}
                            onClick={() => handleSortClick("type.name", "desc")}
                          ></i>
                        </span>
                      </th>
                      <th
                        title="Driver Amount"
                        className="sorting border-right align-table-header"
                        tabIndex="0"
                        aria-controls="leads-table"
                        rowSpan="1"
                        colSpan="1"
                        aria-label="Lead Agent: activate to sort column ascending"
                      >
                        Driver Amount{" "}
                        {/* <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{ color: getArrowColor("totalAmount", "asc") }}
                          onClick={() => handleSortClick("totalAmount", "asc")}
                          ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{ color: getArrowColor("totalAmount", "desc") }}
                          onClick={() => handleSortClick("totalAmount", "desc")}
                        ></i>
                      </span> */}
                      </th>
                      <th
                        title="Status"
                        className="sorting border-right align-table-header"
                        tabIndex="0"
                        aria-controls="leads-table"
                        rowSpan="1"
                        colSpan="1"
                        aria-label="Lead Agent: activate to sort column ascending"
                      >
                        Status{" "}
                        <span className="right-sorting-new">
                          <i
                            className="fas fa-long-arrow-alt-up"
                            style={{ color: getArrowColor("status", "asc") }}
                            onClick={() => handleSortClick("status", "asc")}
                          ></i>
                          <i
                            className="fas fa-long-arrow-alt-down"
                            style={{ color: getArrowColor("status", "desc") }}
                            onClick={() => handleSortClick("status", "desc")}
                          ></i>
                        </span>
                      </th>
                      <th
                        title="Actions"
                        className="text-right pr-20 sorting_disabled border-right"
                        rowSpan="1"
                        colSpan="1"
                        aria-label="Action"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData?.results &&
                      paginatedData?.results.length > 0 &&
                      paginatedData?.results.map((current, i) => {
                        return (
                          <AdminOrderSearchTableRow
                            current={current}
                            i={i}
                            key={i}
                            fullData={fullData}
                            setFullData={setFullData}
                          />
                        );
                      })}
                  </tbody>
                </table>
                {reservation &&
                  reservation?.getReservationsByDateV2 &&
                  reservation?.getReservationsByDateV2?.length === 0 && (
                    // paginatedData?.results?.length === 0 && (
                    <div
                      className="dash-stats-list"
                      style={{ textAlign: "center" }}
                    >
                      <br />
                      <br />
                      <img
                        alt=""
                        src={shift1}
                        style={{ width: "145px", opacity: "0.5" }}
                      />
                      <br />
                      <p style={{ opacity: "0.5" }}>No Booking Found</p>
                    </div>
                  )}
                <div
                  id="leads-table_processing"
                  className="dataTables_processing card"
                  style={{ display: "none" }}
                >
                  Processing...
                </div>
              </div>
            </div>
            {reservation &&
              reservation?.reservation &&
              // reservation?.reservation?.today &&
              // reservation?.reservation?.today.length > 0 &&
              paginatedData?.results?.length > 0 && (
                <div className="d-flex border">
                  {reservation.loading === false ? (
                    <div className="flex-grow-1">
                      <div
                        className="dataTables_info"
                        id="leads-table_info"
                        role="status"
                        aria-live="polite"
                      >
                        Showing{" "}
                        {1 +
                          paginatedData.limit *
                            (paginatedData?.currentPageNumber - 1)}{" "}
                        to{" "}
                        {paginatedData.displayingResults +
                          paginatedData.limit *
                            (paginatedData?.currentPageNumber - 1)}{" "}
                        of {paginatedData.totalResults} entries
                      </div>
                    </div>
                  ) : (
                    <div className="flex-grow-1">
                      <div
                        className="dataTables_info"
                        id="leads-table_info"
                        role="status"
                        aria-live="polite"
                      >
                        Loading entries
                      </div>
                    </div>
                  )}
                  <div>
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="leads-table_paginate"
                    >
                      <ul className="pagination">
                        <li className="page-item">
                          <NavLink
                            className="page-link"
                            to="#"
                            aria-label="Previous"
                            // onClick={onClickPrev}
                            onClick={(e) =>
                              handlePagination(
                                currentPage - 1,
                                sortField,
                                sortOrder,
                                searchTerm,
                                limit
                              )
                            }
                          >
                            <span aria-hidden="true">Previous</span>
                            <span className="sr-only">Previous</span>
                          </NavLink>
                        </li>
                        {reservation &&
                          reservation.reservation &&
                          // reservation.reservation.today &&
                          paginatedData.paginationArr &&
                          paginatedData.paginationArr.length > 0 &&
                          // reservation.reservation.previous.paginationArr.map((current, i) => {
                          // reservation.reservation.previous.paginationArr.slice(startIndex, endIndex).map((current, i) => {
                          Array.from(
                            {
                              length:
                                paginatedData.endPage -
                                paginatedData.startPage +
                                1,
                            },
                            (_, i) => {
                              const current = paginatedData.startPage + i;
                              return paginatedData?.currentPageNumber ===
                                current ? (
                                <li className="page-item active" key={i}>
                                  <Link
                                    to=""
                                    className="page-link"
                                    value={current}
                                  >
                                    {current}
                                  </Link>
                                </li>
                              ) : (
                                <li className="page-item" key={i}>
                                  <Link
                                    className="page-link"
                                    value={current}
                                    onClick={(e) =>
                                      handlePagination(
                                        parseInt(current),
                                        sortField,
                                        sortOrder,
                                        searchTerm,
                                        limit
                                      )
                                    }
                                    to=""
                                  >
                                    {current}
                                  </Link>
                                </li>
                              );
                            }
                          )}
                        <li className="page-item">
                          <NavLink
                            className="page-link"
                            to="#"
                            aria-label="Next"
                            // onClick={onClickNext}
                            onClick={(e) =>
                              handlePagination(
                                currentPage + 1,
                                sortField,
                                sortOrder,
                                searchTerm,
                                limit
                              )
                            }
                          >
                            <span aria-hidden="true">Next</span>
                            <span className="sr-only">Next</span>
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
      )}
    </>
  ) : (
    <>
      <NotAllowed />
    </>
  );
};
export default AdminOrderSearchTable;
