import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { getPassengersByCompanyIdWithPagination } from "../../../actions/userActions";
import defaultimg from "../../../assets/images/profile.jpg";
import { useLocation } from "react-router-dom";
// import AdminClientEditNewModal from "./AdminPassengerEditNewModal";
import AdminPassengerEditNewModal from "./AdminPassengerEditNewModal";

const AdminPassengerFullDetailComponent = () => {
  const { state } = useLocation();
  const users = useSelector((state) => state.users);
  const auth = useSelector((state) => state.auth);
  const [selectedImage, setSelectedImage] = useState(defaultimg);

  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [limit, setLimit] = useState(10);

  const dispatch = useDispatch();
  const userData = async (e) => {
    dispatch(
      getPassengersByCompanyIdWithPagination({
        companyId: auth.user.companyId.id,
        limit: limit,
        page: currentPageNumber,
      })
    );
  };
  useEffect(() => {
    if (auth.user !== null) {
    }
  }, [auth.user]);

  useEffect(() => {
    userData();
  }, []);

  const [showDropDown, setshowDropDown] = useState(false);
  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
  };
  const kpi = useSelector((state) => state.kpi);
  const { facility } = kpi;
  const facilityName = (id) => {
    const found = facility.find((x) => x.id == id || x._id == id);
    return found.name;
  };
  return (
    <>
      <div className="row emp-dash-detail">
        <div className="col-xl-12 col-md-6 mb-4 mb-lg-0">
          <div className="card border-0 b-shadow-4">
            <div className="card-horizontal align-items-center">
              <div className="modal-body"></div>
              <div className="card-body border-0 pl-0">
                <div className="row">{/* row kam ki? */}</div>
                {auth?.user?.permissionId?.user?.update === true && (
                  <a className="edit-icon" onClick={openDropDown}>
                    <i className="fa fa-pencil"></i>
                  </a>
                )}
                <div className="row p-3">
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 text-capitalize">
                        <strong>Unique Id: </strong>
                        {state?.uniqueId}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 text-capitalize">
                        <strong>Full Name: </strong>
                        {state?.firstName} {state?.lastName}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0 f-14 text-capitalize">
                        <strong>Phone: </strong>
                        {state?.phone}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 text-capitalize">
                        <strong>Email: </strong>
                        {state?.email}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 w-30 text-capitalize">
                        <strong>User Type: </strong>
                        {state?.role}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 text-capitalize">
                        <strong>Address: </strong>
                        {state?.address}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 text-capitalize">
                        <strong>Username: </strong>
                        {state?.username}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 text-capitalize">
                        <strong>City: </strong>
                        {state?.city}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 text-capitalize">
                        <strong>State: </strong>
                        {state?.state}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 text-capitalize">
                        <strong>Facility: </strong>
                        {facilityName(state?.facility)}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="col-12 px-0 pb-3 d-lg-flex d-md-flex d-block">
                      <p className="mb-0  f-14 text-capitalize">
                        <strong>Notes: </strong>
                        {state?.notes}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AdminPassengerEditNewModal
        current={state}
        showDropDown={showDropDown}
        openDropDown={openDropDown}
      />
    </>
  );
};
export default AdminPassengerFullDetailComponent;
