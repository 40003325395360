import React, { useState } from "react";
import AdminHeader from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader";
import AdminSidebar from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import ExternalCompanyBookingCreateComponent from "../../components/externalCompanyComponents/ExternalCompanyBookingCreateComponent";
import axios from "axios";
import { SERVERADDRESS } from "../../constants";
import { notifyFailure, notifySuccess } from "../../components/toast";

const ExternalCompanyBookingCreatePage = () => {
  const auth = useSelector((state) => state.auth);
  const [file, setFile] = useState(null);

  const handleClick = async () => {
    try {
      // Make a request to get the document details using axios
      const { data } = await axios.get(
        `${SERVERADDRESS}/v1/document/getTermsDocument`
        // {
        // headers: {
        //   "Content-Type": "application/json",
        // },
        // }
      );

      // Check if the response contains a valid fileUrl
      if (data && data.fileUploadId && data.fileUploadId.fileUrl) {
        const fileUrl = data.fileUploadId.fileUrl;

        const fileExtension = fileUrl.split(".").pop().toLowerCase();

        const filename = `TermsDocument.${fileExtension}`;

        const link = document.createElement("a");
        link.href = fileUrl;
        link.setAttribute("download", filename); // Set dynamic file name based on extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up the link element
      } else {
        throw new Error("No file URL found in response.");
      }
    } catch (error) {
      console.error("Error downloading file:", error);
      alert("There was an error downloading the document.");
    }
  };

  const handleSelectFile = (e) => {
    const acceptedFiles = e.target.files;
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];

      // Define the allowed MIME types for PDF, Word, Excel files
      const allowedMimeTypes = [
        "application/pdf", // MIME type for .pdf files
        "application/msword", // MIME type for .doc files
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // MIME type for .docx files
        "application/vnd.ms-excel", // MIME type for .xls files
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // MIME type for .xlsx files
      ];

      if (allowedMimeTypes.includes(file.type)) {
        // If the file type is allowed, set the file
        setFile(file);
      } else {
        // Handle invalid file type
        notifyFailure(
          "Invalid file type. Please upload a PDF, Word, or Excel file."
        );
      }
    }
  };

  const handleFileUpload = async () => {
    // Ensure the file is selected
    if (!file) {
      notifyFailure("Please select a file to upload.");
      return;
    }

    // Prepare FormData
    const formData = new FormData();
    formData.append("file", file); // Add the file
    formData.append("companyId", auth.user.companyId.id); // Add the companyId
    formData.append("userId", auth.user.id); // Add the userId

    try {
      // Send POST request to upload the file
      const response = await axios.put(
        `${SERVERADDRESS}/v1/fileupload/uploadDocument`,
        formData
        // {
        //   headers: {
        //     "Content-Type": "multipart/form-data",
        //   },
        // }
      );

      // Handle successful upload response
      if (response.data) {
        notifySuccess("File uploaded successfully.");
      } else {
        notifyFailure("File upload failed. Please try again.");
      }
    } catch (error) {
      // Handle error during upload
      console.error("File upload error", error);
      notifyFailure("An error occurred while uploading the file.");
    }
  };
  return (
    <>
      <AdminHeader />
      <AdminSidebar />
      <div className="body-wrapper clearfix">
        <section className="main-container " id="fullscreen">
          <div className="px-4 py-2 border-top-0 emp-dashboard">
            {auth.user.verificationRequired === false && (
              <>
                <div class="d-block d-lg-flex d-md-flex justify-content-between action-bar mb-20 mt-3">
                  <div
                    id="table-actions"
                    class="flex-grow-1 align-items-center"
                  >
                    <h4 class=" mb-0 f-21 text-capitalize font-weight-bold">
                      New Reservation
                    </h4>
                  </div>
                  <div
                    class="btn-group mt-2 mt-lg-0 mt-md-0 ml-0 ml-lg-3 ml-md-3"
                    role="group"
                  >
                    <button
                      type="button"
                      class="btn-secondary rounded f-14 p-2 mr-3 float-left mb-2 mb-lg-0 mb-md-0"
                      id="add-lead"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-arrow-clockwise"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                        ></path>
                        <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"></path>
                      </svg>{" "}
                      Refresh
                    </button>

                    <NavLink
                      to="/booking-view"
                      type="button"
                      // class="btn-secondary rounded f-14 p-2 mr-3 float-left mb-2 mb-lg-0 mb-md-0"
                      className="btn-secondary rounded f-14 p-2 mr-3 float-left mb-2 mb-lg-0 mb-md-0"
                      id="add-lead"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-eye"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"></path>
                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"></path>
                      </svg>{" "}
                      View
                    </NavLink>
                  </div>
                </div>
                <ExternalCompanyBookingCreateComponent />
              </>
            )}

            {auth.user.verificationRequired === true &&
              (auth.user.isDocumentVerified === "Accept" ? (
                <>
                  <div class="d-block d-lg-flex d-md-flex justify-content-between action-bar mb-20 mt-3">
                    <div
                      id="table-actions"
                      class="flex-grow-1 align-items-center"
                    >
                      <h4 class=" mb-0 f-21 text-capitalize font-weight-bold">
                        New Reservation
                      </h4>
                    </div>
                    <div
                      class="btn-group mt-2 mt-lg-0 mt-md-0 ml-0 ml-lg-3 ml-md-3"
                      role="group"
                    >
                      <button
                        type="button"
                        class="btn-secondary rounded f-14 p-2 mr-3 float-left mb-2 mb-lg-0 mb-md-0"
                        id="add-lead"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-arrow-clockwise"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                          ></path>
                          <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"></path>
                        </svg>{" "}
                        Refresh
                      </button>

                      <NavLink
                        to="/booking-view"
                        type="button"
                        className="btn-secondary rounded f-14 p-2 mr-3 float-left mb-2 mb-lg-0 mb-md-0"
                        id="add-lead"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-eye"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"></path>
                          <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"></path>
                        </svg>{" "}
                        View
                      </NavLink>
                    </div>
                  </div>
                  <ExternalCompanyBookingCreateComponent />
                </>
              ) : auth.user.isDocumentVerified === "Pending" &&
                auth.user.document ? (
                <div>
                  <p>Your request is pending.</p>
                </div>
              ) : auth.user.isDocumentVerified === "Decline" &&
                auth.user.document ? (
                <div>
                  <p>
                    Your request has been declined. Please contact the admin or
                    re-submit the file.
                  </p>
                  <div className="row">
                    <div className="col-sm-3 my-1">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleClick}
                      >
                        Download Policy File
                      </button>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group my-2">
                        <input
                          type="file"
                          className="input-group-text  f-14"
                          name="file"
                          id="file"
                          style={{ width: "100%" }}
                          // autocomplete="off"
                          onChange={handleSelectFile}
                        />
                      </div>
                    </div>
                    {/* Submit button */}
                    <div className="col-sm-3 my-2">
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={handleFileUpload}
                      >
                        Submit File
                      </button>
                    </div>
                  </div>
                </div>
              ) : !auth.user.document ? (
                <div>
                  <p>
                    Download the policy file, sign it, and upload your document.
                    Once your document has been verified, you will be notified,
                    and you can start booking.
                  </p>
                  <div className="row">
                    <div className="col-sm-3 my-1">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={handleClick}
                      >
                        Download Policy File
                      </button>
                    </div>

                    {/* Upload file input */}
                    <div className="col-sm-6">
                      <div className="form-group my-2">
                        <input
                          type="file"
                          className="input-group-text  f-14"
                          name="file"
                          id="file"
                          style={{ width: "100%" }}
                          onChange={handleSelectFile}
                          // value={file}
                        />
                      </div>
                    </div>
                    {/* Submit button */}
                    <div className="col-sm-3 my-2">
                      <button
                        type="button"
                        className="btn btn-success"
                        onClick={handleFileUpload}
                      >
                        Submit File
                      </button>
                    </div>
                  </div>
                </div>
              ) : null)}
            {/* <ExternalCompanyUserTable /> */}
          </div>
        </section>
      </div>
    </>
  );
};
export default ExternalCompanyBookingCreatePage;

// v1/fileUpload/uploadDocument
