import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getExternalCompanies } from "../../../actions/companyAction";
import { editPassenger } from "../../../actions/userActions";
import Select from "react-select";

const AdminEditPassengerModal = ({
  // current,
  values,
  showEditUserDropDown,
  openEditUserDropDown,
}) => {
  const navigate = useNavigate();
  const externalCompanies = useSelector((state) => state.externalCompanies);
  const auth = useSelector((state) => state.auth);
  const students = useSelector((state) => state.students);
  const kpi = useSelector((state) => state.kpi);
  const { facility } = kpi;
  const { loading } = students;

  const [values2, setValues2] = useState({
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    notes: "",
    facility: "",
    daysOfWeek: [
      { day: 1, dayName: "Monday", active: true },
      { day: 2, dayName: "Tuesday", active: true },
      { day: 3, dayName: "Wednesday", active: true },
      { day: 4, dayName: "Thursday", active: true },
      { day: 5, dayName: "Friday", active: true },
      { day: 6, dayName: "Saturday", active: false },
      { day: 7, dayName: "Sunday", active: false },
    ],
    standingOrder: false,
  });

  // console.log(values2, "val2");

  const handleChange = (fieldName) => (event) => {
    setValues2({
      ...values2,
      [fieldName]: event.target.value,
    });
  };
  const currentPassenger = async () => {
    try {
      setValues2({
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        notes: "",
        daysOfWeek: [
          { day: 1, dayName: "Monday", active: true },
          { day: 2, dayName: "Tuesday", active: true },
          { day: 3, dayName: "Wednesday", active: true },
          { day: 4, dayName: "Thursday", active: true },
          { day: 5, dayName: "Friday", active: true },
          { day: 6, dayName: "Saturday", active: false },
          { day: 7, dayName: "Sunday", active: false },
        ],
        standingOrder: false,
      });
      console.log(values, "values");

      const selectedPassenger = values?.customerId?.id
        ? values?.customerId?.id
        : values?.customerId;
      const matchedStudent = students?.list?.find(
        (student) => student.id === selectedPassenger
      );
      if (matchedStudent) {
        setValues2({
          id: matchedStudent.id,
          firstName: matchedStudent.firstName,
          lastName: matchedStudent.lastName,
          email: matchedStudent.email,
          phone: matchedStudent.phone,
          notes: matchedStudent.notes,
          facility: matchedStudent.facility,
          standingOrder: matchedStudent.standingOrder,
          daysOfWeek:
            matchedStudent.daysOfWeek && matchedStudent.daysOfWeek.length > 0
              ? matchedStudent.daysOfWeek
              : [
                  { day: 1, dayName: "Monday", active: true },
                  { day: 2, dayName: "Tuesday", active: true },
                  { day: 3, dayName: "Wednesday", active: true },
                  { day: 4, dayName: "Thursday", active: true },
                  { day: 5, dayName: "Friday", active: true },
                  { day: 6, dayName: "Saturday", active: false },
                  { day: 7, dayName: "Sunday", active: false },
                ],
        });
      }
    } catch (error) {
      // console.log(error);
    }
  };
  useEffect(() => {
    currentPassenger();
  }, [students.list]);

  const dispatch = useDispatch();
  const passengerData = {
    _id: values2.id,
    firstName: values2.firstName,
    lastName: values2.lastName,
    email: values2.email,
    phone: values2.phone,
    notes: values2.notes,
    facility: values2.facility,
    standingOrder: values2.standingOrder,
    daysOfWeek: values2.daysOfWeek,
  };

  const handleSuccess = () => {
    openEditUserDropDown();
  };

  useEffect(() => {
    dispatch(getExternalCompanies({ companyId: auth.user.companyId.id }));
  }, [auth]);

  let [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [data, setData] = useState({});

  const handlePhone = (fieldName) => (event) => {
    const numericValue = event.target.value.replace(/\D/g, "");
    let formattedValue = "";
    if (numericValue.length > 0) {
      formattedValue = `(${numericValue.slice(0, 3)}`;
    }
    if (numericValue.length > 3) {
      formattedValue += `)${numericValue.slice(3, 6)}`;
    }
    if (numericValue.length > 6) {
      formattedValue += `-${numericValue.slice(6, 10)}`;
    }
    setValues2({
      ...values2,
      [fieldName]: formattedValue,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!passengerData.standingOrder) {
      delete passengerData.daysOfWeek;
    }
    dispatch(editPassenger(passengerData, handleSuccess));
  };

  const handleDayToggle = (index) => {
    setValues2({
      ...values2,
      daysOfWeek: values2.daysOfWeek.map((day, dayIndex) =>
        dayIndex === index ? { ...day, active: !day.active } : day
      ),
    });
  };
  return (
    <>
      <div
        className={`modal fade ${
          showEditUserDropDown ? "show modal-y-axis" : "hide"
        }`}
        role="dialog"
        data-backdrop="static"
        data-keyboard="false"
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            openEditUserDropDown();
          }
        }}
      >
        <div
          className="modal-dialog modal-dialog-centered"
          role="document"
          style={{ top: "8%" }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                {" "}
                Edit Passenger
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => openEditUserDropDown()}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark-grey mb-12"
                      data-label=""
                      htmlFor="after_days"
                    >
                      First Name
                    </label>
                    <sup className="text-danger f-12">*</sup>
                    <input
                      type="text"
                      className="form-control height-35 f-14"
                      placeholder="Enter Your First Name"
                      autoComplete="false"
                      value={values2.firstName}
                      name="firstName"
                      onChange={handleChange("firstName")}
                      required
                      maxLength={35}
                    />
                  </div>
                </div>
                {students.list?.isRouteUser === true ? (
                  <div className="col-lg-6">
                    <div className="form-group my-3">
                      <label
                        className="f-14 text-dark-grey mb-12"
                        data-label=""
                        htmlFor="after_days"
                      >
                        Last Name
                      </label>
                      <input
                        type="text"
                        className="form-control height-35 f-14"
                        placeholder="Enter Your Last Name"
                        // autoComplete="off"
                        value={values2.lastName}
                        name="lastName"
                        onChange={handleChange("lastName")}
                        // required
                        maxLength={35}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="col-lg-6">
                    <div className="form-group my-3">
                      <label
                        className="f-14 text-dark-grey mb-12"
                        data-label=""
                        htmlFor="after_days"
                      >
                        Last Name
                      </label>
                      <sup className="text-danger f-12">*</sup>
                      <input
                        type="text"
                        className="form-control height-35 f-14"
                        placeholder="Enter Your Last Name"
                        // autoComplete="off"
                        value={values2.lastName}
                        name="lastName"
                        onChange={handleChange("lastName")}
                        required
                      />
                    </div>
                  </div>
                )}
                <div className="col-lg-4">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark-grey mb-12"
                      data-label=""
                      htmlFor="after_days"
                    >
                      Email
                    </label>
                    <input
                      type="Email"
                      className="form-control height-35 f-14"
                      placeholder="Enter Your Email"
                      autoComplete="false"
                      value={values2.email}
                      name="email"
                      onChange={handleChange("email")}
                      // required
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark-grey mb-12"
                      data-label=""
                      htmlFor="after_days"
                    >
                      Phone
                    </label>
                    <input
                      type="tel"
                      className="form-control height-35 f-14"
                      name="phone"
                      value={values2.phone}
                      onChange={handlePhone("phone")}
                      placeholder="(123)456-7890"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark-grey mb-12"
                      data-label=""
                      htmlFor="after_days"
                    >
                      Facility
                    </label>
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      defaultValue={""}
                      value={facility
                        ?.map((x) => ({
                          label: `${x.name}`,
                          value: x.id,
                        }))
                        .find((e) => e.value === values2?.facility)}
                      isDisabled={false}
                      isClearable={false}
                      isRtl={false}
                      isSearchable={true}
                      required={true}
                      name="facility"
                      id="facility"
                      onChange={(e) => {
                        setValues2({
                          ...values2,
                          facility: e.value,
                        });
                      }}
                      options={facility?.map((x) => ({
                        label: `${x.name}`,
                        value: x.id,
                      }))}
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group my-3">
                    <label
                      className="f-14 text-dark-grey mb-12"
                      data-label=""
                      htmlFor="after_days"
                    >
                      Standing Order
                    </label>
                    <div className="btn-choice">
                      <label className="switch">
                        <input
                          id="standingorder"
                          type="checkbox"
                          className="switch-input"
                          checked={values2.standingOrder}
                          onChange={() => {
                            setValues2({
                              ...values2,
                              standingOrder: !values2.standingOrder,
                            });
                          }}
                        />
                        <span
                          className="switch-label"
                          data-on="YES"
                          data-off="NO"
                        ></span>
                        <span className="switch-handle"></span>
                      </label>
                    </div>
                  </div>
                </div>
                {values2.standingOrder === true && (
                  <div className="container">
                    <div className="row">
                      {values2.daysOfWeek.map((day, dayIndex) => (
                        <div className="col-md col-md-smlweekcol">
                          <div className="form-group my-3 mx-2">
                            <div>
                              <label
                                className="f-14 text-dark"
                                data-label=""
                                htmlFor="clnumber"
                              >
                                {day.dayName}
                              </label>
                              <div className="btn-choice">
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    className="switch-input"
                                    id="toggle"
                                    checked={day.active}
                                    autoComplete="off"
                                    onChange={() => handleDayToggle(dayIndex)}
                                  />
                                  <span
                                    className="switch-label"
                                    data-on="Yes"
                                    data-off="No"
                                  ></span>
                                  <span className="switch-handle"></span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                <div className="col-lg-12">
                  <label htmlFor="allowed_file_types"> Notes </label>
                  <textarea
                    type="text"
                    placeholder="Note"
                    className="form-control f-14"
                    autoComplete="false"
                    value={values2.notes}
                    name="notes"
                    onChange={handleChange("notes")}
                  ></textarea>
                </div>
                <div className="col-lg-12"></div>
                <br />
                <div className="col-lg-12">
                  <div
                    className="form-group "
                    style={{ marginTop: "1.5em !important" }}
                  >
                    <button
                      type="button"
                      className="btn-primary rounded f-14 p-2 mr-3"
                      disabled={loading}
                      onClick={handleSubmit}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminEditPassengerModal;
