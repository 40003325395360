/** @format */

import React, { useEffect, useState } from "react";
import AdminBookingPastBooking from "../../components/adminComponents/adminBookingComponents/AdminBookingPastBooking";
import AdminHeader from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader";
import AdminSidebar from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { navigateUserFunc } from "../../actions/generalActions";
import AdminOrderSearchTable from "../../components/adminComponents/adminDispatchingComponents/AdminOrderSearchTable";
import AdminSearchByFilterComponentV2 from "../../components/adminComponents/adminDispatchingComponents/AdminSearchByFilterComponentV2";
import * as XLSX from "xlsx";
import { tabUnassignedDispatching } from "../../actions/tabActions";
import { getAllStudents } from "../../actions/userActions";
import ExcelExport from "../../ExcelExport";
import moment from "moment";
import {
  getAllReservationV2,
  getPastReservationsByCompanyId,
  getPastReservationsByCompanyIdV3,
} from "../../actions/reservationAction";
import { notifySuccess } from "../../components/toast";

const AdminBookingPastPage = () => {
  let navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  const reservation = useSelector((state) => state?.reservation);
  const myState = useSelector((state) => state.changeTheTab);
  const dispatch = useDispatch();

  const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    if (
      auth?.user?.permissionId?.booking?.isAllow === false ||
      auth?.user?.disable === true
    ) {
      navigateUserFunc(auth?.user, navigate);
    }
    dispatch(tabUnassignedDispatching());
  }, []);

  const refresh = () => {
    const chicagoDateOptions = {
      timeZone: "America/Chicago",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    const chicagoDate = new Date().toLocaleDateString(
      "en-US",
      chicagoDateOptions
    );
    const parts = chicagoDate.split("/");
    const formattedChicagoDate = `${parts[2]}-${parts[0]}-${parts[1]}`;

    // dispatch(
    //   getAllReservationV2({
    //     companyId: auth.user.companyId.id,
    //     today: formattedChicagoDate,
    //     isToday: false,
    //   })
    // );
    // dispatch(
    //   getPastReservationsByCompanyId({
    //     companyId: auth.user.companyId.id,
    //     today: formattedChicagoDate,
    //   })
    // );
    dispatch(
      getPastReservationsByCompanyIdV3(
        {
          limit: 50,
          page: 1,
          companyId: auth.user.companyId.id,
          today: formattedChicagoDate,
        },
        cb
      )
    );
  };
  const cb = (data) => {
    // setIsRefreshing(false);
    notifySuccess("Data Updated");
  };
  // const [showUploadModal, setShowUploadModal] = useState(false);
  // const openFileUploadModal = () => {
  //   setShowUploadModal(true);
  // };

  // const handleExportToExcel = () => {
  //   // Convert the array of objects to a worksheet
  //   const ws = XLSX.utils.json_to_sheet(exportData);

  //   // Create a workbook
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, "sheetName" || "Sheet 1");

  //   // Save the workbook to an Excel file
  //   XLSX.writeFile(wb, "exported-data.xlsx" || "data.xlsx");
  // };
  // const data = reservation?.reservation?.previous?.map(item=>({
  const data = reservation?.reservation?.previous?.map((item) => {
    const dateString = item?.dateForReservation;
    const [datePart, timePart] = dateString.split("T");
    const [year, month, day] = datePart.split("-");
    const formattedDate = `${month}/${day}/${year}`;
    return {
      "Order Id": item?.orderId,
      "Load Number": item?.loadNumber,
      "Reservation Date": formattedDate,
      "Passenger First Name": item?.customerId?.firstName,
      "Passenger Last Name": item?.customerId?.lastName,
      "Pick Up Address": item?.pickUpAddress?.address,
      "Drop Off Address": item?.dropOffAddress?.address,
      // "Pick Up Time": item?.pickUpTime,
      "Pick Up Time":
        (parseInt(item?.pickUpTime?.substring(0, 2)) === 0
          ? 12
          : parseInt(item?.pickUpTime?.substring(0, 2)) > 12
          ? parseInt(item?.pickUpTime?.substring(0, 2)) - 12
          : parseInt(item?.pickUpTime?.substring(0, 2))) +
        ":" +
        item?.pickUpTime?.substring(3, 5) +
        (parseInt(item?.pickUpTime?.substring(0, 2)) > 11 ? "pm" : "am"),
      // "Drop Off Time": item?.dropOffTime,
      "Drop Off Time":
        (parseInt(item?.dropOffTime?.substring(0, 2)) === 0
          ? 12
          : parseInt(item?.dropOffTime?.substring(0, 2)) > 12
          ? parseInt(item?.dropOffTime?.substring(0, 2)) - 12
          : parseInt(item?.dropOffTime?.substring(0, 2))) +
        ":" +
        item?.dropOffTime?.substring(3, 5) +
        (parseInt(item?.dropOffTime?.substring(0, 2)) > 11 ? "pm" : "am"),
      "Driver First Name": item?.driverId?.firstName,
      "Driver Last Name": item?.driverId?.lastName,
      "Driver Amount": item?.fareAndDistance?.driverAmount
        ? parseFloat(item?.fareAndDistance?.driverAmount)
        : 0,
      Status: item?.status,
    };
  });

  // const data2 = reservation?.getReservationsByDateV2?.map(item=>({
  const data2 = reservation?.getReservationsByDateV2?.map((item) => {
    const dateString = item?.dateForReservation;
    const [datePart, timePart] = dateString.split("T");
    const [year, month, day] = datePart.split("-");
    const formattedDate = `${month}/${day}/${year}`;
    return {
      "Order Id": item?.orderId,
      "Load Number": item?.loadNumber,
      "Reservation Date": formattedDate,
      "Passenger First Name": item?.customerId?.firstName,
      "Passenger Last Name": item?.customerId?.lastName,
      "Pick Up Address": item?.pickUpAddress?.address,
      "Drop Off Address": item?.dropOffAddress?.address,
      // "Pick Up Time": item?.pickUpTime,
      "Pick Off Time":
        (parseInt(item?.pickUpTime?.substring(0, 2)) === 0
          ? 12
          : parseInt(item?.pickUpTime?.substring(0, 2)) > 12
          ? parseInt(item?.pickUpTime?.substring(0, 2)) - 12
          : parseInt(item?.pickUpTime?.substring(0, 2))) +
        ":" +
        item?.pickUpTime?.substring(3, 5) +
        (parseInt(item?.pickUpTime?.substring(0, 2)) > 11 ? "pm" : "am"),
      // "Drop Off Time": item?.dropOffTime,
      "Drop Off Time":
        (parseInt(item?.dropOffTime?.substring(0, 2)) === 0
          ? 12
          : parseInt(item?.dropOffTime?.substring(0, 2)) > 12
          ? parseInt(item?.dropOffTime?.substring(0, 2)) - 12
          : parseInt(item?.dropOffTime?.substring(0, 2))) +
        ":" +
        item?.dropOffTime?.substring(3, 5) +
        (parseInt(item?.dropOffTime?.substring(0, 2)) > 11 ? "pm" : "am"),
      "Driver First Name": item?.driverId?.firstName,
      "Driver Last Name": item?.driverId?.lastName,
      "Driver Amount": item?.fareAndDistance?.driverAmount
        ? parseFloat(item?.fareAndDistance?.driverAmount)
        : 0,
      Status: item?.status,
    };
  });

  const [exportData, setExportData] = useState([]);

  // useEffect(() => {
  //   dispatch(getAllStudents({ companyId: auth.user.companyId.id }));
  // }, []);
  const locationUrl = useLocation();
  const queryParams = new URLSearchParams(locationUrl.search);
  const isThisWeek = queryParams.get("this-week");
  const isLastWeek = queryParams.get("last-week");
  const isThisMonth = queryParams.get("this-month");
  const isLastMonth = queryParams.get("last-month");
  const [showPastData, setShowPastData] = useState(true);
  useEffect(() => {
    if (
      isThisWeek == "true" ||
      isLastWeek == "true" ||
      isThisMonth == "true" ||
      isLastMonth == "true"
    ) {
      setShowFilter(true);
      setShowPastData(false);
    }
  }, []);
  return (
    <>
      <AdminHeader />
      <AdminSidebar />
      <div className="body-wrapper clearfix">
        <section className="main-container " id="fullscreen">
          <div className="px-4 py-2 border-top-0 emp-dashboard">
            <div className="d-block d-lg-flex d-md-flex justify-content-between action-bar mb-20 mt-3">
              <div
                id="table-actions"
                className="flex-grow-1 align-items-center"
              >
                <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">
                  {" "}
                  Past Bookings{" "}
                </h4>
              </div>
              {/* <div
                className="btn-group mt-2 mt-lg-0 mt-md-0 ml-0 ml-lg-3 ml-md-3"
                role="group"
              >
                <NavLink
                  to="/booking-order"
                  type="button"
                  className="btn-secondary rounded f-14 p-2 mr-3 float-left mb-2 mb-lg-0 mb-md-0"
                  id="add-lead"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-eye"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                  </svg>{" "}
                  Create Booking
                </NavLink>
                <NavLink
                  to="/today-bookings"
                  type="button"
                  className="btn-secondary rounded f-14 p-2 mr-3 float-left mb-2 mb-lg-0 mb-md-0"
                  id="add-lead"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-eye"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                  </svg>{" "}
                  Today's Bookings
                </NavLink>
                <NavLink
                  to="/past-bookings"
                  type="button"
                  className="btn-secondary rounded f-14 p-2 mr-3 float-left mb-2 mb-lg-0 mb-md-0 activee"
                  id="add-lead"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-eye"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                  </svg>{" "}
                  Past Bookings
                </NavLink>
              </div> */}
              <div
                className="btn-group mt-2 mt-lg-0 mt-md-0 ml-0 ml-lg-3 ml-md-3"
                role="group"
              >
                <div
                  className="btn-group me-2"
                  role="group"
                  aria-label="First group"
                >
                  {reservation?.getReservationsByDateV2 ? (
                    <ExcelExport
                      excelData={data2}
                      // fileName={`Route Distance Report ${moment().format(
                      //   "MM/DD/YYYY"
                      // )}`}
                      fileName={`Booking Searched Report`}
                    />
                  ) : (
                    <ExcelExport
                      excelData={data}
                      fileName={`Past Booking Report`}
                    />
                  )}{" "}
                  {/* &nbsp; &nbsp; */}
                  <button
                    type="button"
                    className="btn btn-success"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Refresh"
                    onClick={refresh}
                  >
                    <i className="fas fa-sync"></i>
                  </button>
                  {/* &nbsp; &nbsp; */}
                  <button
                    type="button"
                    className="btn btn-dark"
                    data-toggle="tooltip"
                    data-original-title="Filters"
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    <i className="fa fa-filter"></i>{" "}
                  </button>{" "}
                  {/* &nbsp; &nbsp; */}
                  <Link
                    type="button"
                    className="btn btn-info d-flex align-items-center"
                    to="/today-bookings"
                  >
                    Today's Booking{" "}
                    <i
                      className="fa fa-eye"
                      style={{ fontSize: "22px", marginLeft: "5px" }}
                    ></i>
                  </Link>
                  {/* &nbsp; &nbsp; */}
                  <Link
                    type="button"
                    className="btn btn-warning d-flex align-items-center"
                    to="/scheduled-bookings"
                  >
                    Scheduled Booking{" "}
                    <i
                      className="fa fa-eye"
                      style={{ fontSize: "22px", marginLeft: "5px" }}
                    ></i>
                  </Link>
                  {/* &nbsp; &nbsp; */}
                  <Link
                    type="button"
                    className="btn btn-primary d-flex align-items-center"
                    to="/booking-order"
                  >
                    Create{" "}
                    <i
                      className="fa fa-plus-circle"
                      style={{ fontSize: "22px", marginLeft: "5px" }}
                    ></i>
                  </Link>
                </div>
              </div>
            </div>
            {showFilter && (
              <AdminSearchByFilterComponentV2
                showFilter={showFilter}
                setShowFilter={setShowFilter}
              />
            )}

            {myState.viewDispatching === "dateSearch" ? (
              <>
                {/* <AdminSearchByFilterComponent /> */}
                <AdminOrderSearchTable setExportData={setExportData} />
              </>
            ) : (
              showPastData && (
                <AdminBookingPastBooking setExportData={setExportData} />
              )
            )}
          </div>
        </section>
      </div>
    </>
  );
};
export default AdminBookingPastPage;

{
  /* <div
                className="btn-group mt-2 mt-lg-0 mt-md-0 ml-0 ml-lg-3 ml-md-3"
                role="group"
              >
                <div
                  className="btn-group me-2"
                  role="group"
                  aria-label="First group"
                >
                  {reservation?.getReservationsByDateV2 ? (
                    <ExcelExport
                      excelData={data2}
                      // fileName={`Route Distance Report ${moment().format(
                      //   "MM/DD/YYYY"
                      // )}`}
                      fileName={`Booking Searched Report`}
                    />
                  ) : (
                    <ExcelExport
                      excelData={data}
                      fileName={`Past Booking Report`}
                    />
                  )}
                  <button
                    type="button"
                    className="btn btn-success"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Refresh"
                    onClick={refresh}
                  >
                    <i className="fas fa-sync"></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-dark"
                    data-toggle="tooltip"
                    data-original-title="Filters"
                    onClick={() => setShowFilter(!showFilter)}
                  >
                    <i className="fa fa-filter"></i>{" "}
                  </button>{" "}
                  <Link
                    type="button"
                    className="btn btn-info d-flex align-items-center"
                    to="/today-bookings"
                  >
                    Today's Booking{" "}
                    <i
                      className="fa fa-eye"
                      style={{ fontSize: "22px", marginLeft: "5px" }}
                    ></i>
                  </Link>
                  <Link
                    type="button"
                    className="btn btn-warning d-flex align-items-center"
                    to="/scheduled-bookings"
                  >
                    Scheduled Booking{" "}
                    <i
                      className="fa fa-eye"
                      style={{ fontSize: "22px", marginLeft: "5px" }}
                    ></i>
                  </Link>
                  <Link
                    type="button"
                    className="btn btn-primary d-flex align-items-center"
                    to="/booking-order"
                  >
                    Create{" "}
                    <i
                      className="fa fa-plus-circle"
                      style={{ fontSize: "22px", marginLeft: "5px" }}
                    ></i>
                  </Link>
                </div>
              </div>
            </div> */
}
