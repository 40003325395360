/** @format */

import React, { useEffect, useState } from "react";
import {
  DirectionsRenderer,
  GoogleMap,
  Polyline,
  useJsApiLoader,
} from "@react-google-maps/api";
import { Marker } from "@react-google-maps/api";
import io from "socket.io-client";
import {
  SERVERADDRESS,
  SOCKET_CONNECTED,
  SOCKET_DISCONNECT,
  SOCKET_SERVER_ADDRESS,
} from "../../../constants";
// import onGoingRide from "../../../assets/images/Untitled-1-02.png"
// import notWorking from "../../../assets/images/Untitled-1-01.png"
// import onGoingRide from "../../../assets/images/Frame 6.png"
// import notWorking from "../../../assets/images/Frame 7.png"
import onGoingRide from "../../../assets/images/driver-onride.svg";
import notWorking from "../../../assets/images/driver-offline.svg";
// import notWorking from "../../../assets/images/markerNotMoving.png"
import newhomeicon from "../../../assets/images/home.png";

// import { useParams } from 'react-router-dom';
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import CustomMarker from "./CustomMarker";
import MarkerWithTransition from "./MarkerWithTransition";
import Demo5 from "../../../pages/Demo5";
import { useLocation, useParams } from "react-router-dom";
import MapSidebar from "../adminHeaderSidebarTitleComponents/MapSidebar";
// const containerStyle = {
//   width: "100%",
//   height: "80vh",
// };

// const socket = io('http://localhost:5000')
// const socket = io(SOCKET_SERVER_ADDRESS)
const options = {
  // zoomControlOptions: {
  //   position: window.google.maps.ControlPosition.BOTTOM_RIGHT,
  // },
  // mapTypeControlOptions: {
  //   position: new window.google.maps.ControlPosition.TOP_RIGHT(),
  // },
  mapTypeControlOptions: {
    style: window?.google?.maps?.MapTypeControlStyle?.DROPDOWN_MENU,
    position: window?.google?.maps?.ControlPosition?.TOP_RIGHT,
  },
  scrollwheel: true,
  // fullscreenControlOptions: {
  //   position: window.google.maps.ControlPosition.TOP_RIGHT,
  // },
};

const DriverTrackingMap = ({ allData, onGoing, notOnTrip }) => {
  const auth = useSelector((state) => state.auth);
  const users = useSelector((state) => state.users);
  const location = useSelector((state) => state.location);
  const reservation = useSelector((state) => state.reservation);
  const trip = useSelector((state) => state.trip);
  const sockets = useSelector((state) => state.sockets);
  const [isSidebarVisible, setSidebarVisible] = useState(true);
  const [selectedDriverMarker, setSelectedDriverMarker] = useState(null);
  const [coordinatesList, setCoordinatesList] = useState([]);
  const [coordinatesListDropoff, setCoordinatesListDropoff] = useState([]);
  // console.log(coordinatesList, "coordinateslist");
  // console.log(trip, "trip");

  let { socketConnected, socketInstance } = sockets;
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    // googleMapsApiKey: "AIzaSyDv5TZ4_il1sukhMFSFChda0UHcL0js0Zg"
    googleMapsApiKey: "AIzaSyC653TecaJSucmBmqXPr9twuP_jG55NACA",
  });
  // const params = useParams()
  const [center, setCenter] = useState({
    lat: 0,
    lng: 0,
  });
  const [places, setPlaces] = useState([]);
  const [map, setMap] = React.useState(null);
  const [coordinates, setCoordinates] = useState({
    lat: 0,
    lng: 0,
  });
  // const [cc, setCC] = useState(0);
  const [trackingData, setTrackingData] = useState([]);
  // useEffect(() => {
  //     // console.log(socket.id, "useeddecr")
  //     if (!socketConnected) return;
  //     socketInstance.emit("user-location", { companyId: auth.user.companyId.id }, (response) => {
  //         setTrackingData(response.status)
  //         // setCoordinates({
  //         //     lat: response.status.latitude,
  //         //     lng: response.status.longitude,
  //         // });
  //         setHqPoint(response.baseLoc)
  //         return () => {
  //             socketInstance.off("user-location")
  //         }
  //     })

  // }, [cc, coordinates.lat, coordinates.lng,])
  // useEffect(() => {
  //     setCurrentLocation()
  // }, [location])
  // let bb = 0
  // useEffect(()=>{
  //   setInterval(() =>
  //       setCC(bb++)
  //   , 100);
  // },[])
  useEffect(() => {
    if (!socketConnected) return;
    if (socketInstance) {
      // console.log("useeddecr");
      // socketInstance.emit("user-tracking-send", {
      //   companyId: auth.user.companyId.id,
      // });
      socketInstance.on("user-tracking-received", (response) => {
        // console.log(response, "responseresponse")
        setTrackingData(response.status);
        // setCoordinates({
        //     lat: response.status.latitude,
        //     lng: response.status.longitude,
        // });
        setHqPoint(response.baseLoc);
        // return () => {
        //     socketInstance.off("user-location")
        // }
      });
    }
    return () => {
      socketInstance.off("user-tracking-received");
    };
    // }, [cc]);
  }, [socketInstance]);
  useEffect(() => {
    setCurrentLocation();
  }, [location]);
  // let bb = 0;
  // useEffect(() => {
  //   setInterval(() => setCC(bb++), 100);
  // }, []);
  const [hqPoint, setHqPoint] = useState([]);
  const setCurrentLocation = async () => {
    // const res = await axios.post(`${SERVERADDRESS}/v1/track/trackData`,
    //     {
    //         userId: params.id
    //     }
    // )
    // if (res.data.latitude !== null || res.data.longitude !== null){
    //     setCenter({
    //         lat: res.data.latitude,
    //         lng: res.data.longitude,
    //     })
    //     setCoordinates({
    //         lat: res.data.latitude,
    //         lng: res.data.longitude,
    //     });
    // }
    // else {
    //     if ("geolocation" in navigator) {
    //         navigator.geolocation.getCurrentPosition((position) => {
    //             // setCenter([position.coords.latitude, position.coords.longitude])
    //             setCenter({
    //                 lat: position.coords.latitude,
    //                 lng: position.coords.longitude,
    //             })
    //             setCoordinates({
    //                 lat: position.coords.latitude,
    //                 lng: position.coords.longitude,
    //             });
    //         });
    //     }
    // }
    setCenter({
      lat: location?.baseLocation?.latitude,
      lng: location?.baseLocation?.longitude,
      // lng: 67.14,
      // lat: 24.93,
    });
    // setHqPoint({
    //     lat: location.baseLocation.latitude,
    //     lng: location.baseLocation.longitude,
    // })
  };
  // console.log(hqPoint,"ssssssssssssssss")
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  var iconPin = {
    // path: 'M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z',
    // url: 'https://cdn-icons-png.flaticon.com/128/75/75782.png',
    url: notWorking,
    scaledSize: new window.google.maps.Size(40, 40),
    // fillColor: '#64be67',
    // fillOpacity: 1,
    // scale: 0.05, //to reduce the size of icons
  };
  var movingPin = {
    // path: 'M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z',
    // url: 'https://cdn-icons-png.flaticon.com/512/2113/2113098.png',
    url: onGoingRide,
    scaledSize: new window.google.maps.Size(40, 40),
    // fillColor: '#64be67',
    // fillOpacity: 1,
    // scale: 0.05, //to reduce the size of icons
  };
  var hqIcon = {
    // url: 'https://cdn-icons-png.flaticon.com/512/25/25694.png',
    url: newhomeicon,
    scaledSize: new window.google.maps.Size(40, 40),
  };
  const onClickDetails = (i) => {
    // console.log(i, "details hain bhai")
  };
  const locationUrl = useLocation();
  const [zoom, setZoom] = useState(
    locationUrl && locationUrl.pathname === "/track" ? 11 : 14
  );
  const [dateTime, setDateTime] = useState(new Date().toLocaleString());
  useEffect(() => {
    const updateDateTime = () => {
      setDateTime(new Date().toLocaleString());
    };
    const intervalId = setInterval(updateDateTime, 1000);
    return () => clearInterval(intervalId);
  }, []);
  const { getTracks } = users;
  const { baseLocation } = location;
  useEffect(() => {
    if (getTracks) {
      setTrackingData(getTracks);
    }
    if (baseLocation) {
      setHqPoint([baseLocation]);
    }
  }, []);
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   if (locationUrl.pathname === "/track" || !socketConnected) {
  //     socketInstance = io(SOCKET_SERVER_ADDRESS);
  //     socketInstance?.emit("setup", auth.user.id);
  //     socketInstance?.on("connected", () => {
  //       dispatch({
  //         type: SOCKET_CONNECTED,
  //         payload: { socketInstance, socketConnected: true },
  //       });
  //     });
  //     socketInstance?.emit("newUser", auth.user.id);
  //     return () => {
  //       socketInstance?.disconnect();
  //       dispatch({
  //         type: SOCKET_DISCONNECT,
  //         payload: { socketInstance: null, socketConnected: false },
  //       });
  //     };
  //   }
  // }, [locationUrl, socketConnected, socketInstance]);
  useEffect(() => {
    if (map) {
      map.setOptions({
        // zoomControl: false,
        mapTypeControl: false,
        scrollwheel: true,
        // streetViewControl: false,
        // fullscreenControl: false,
      });
      // Create a custom control with a FontAwesome icon and dropdown
      const controlDiv = document.createElement("div");
      controlDiv.style.margin = "10px";
      controlDiv.style.cursor = "pointer";
      controlDiv.style.position = "relative";

      // Create the button with the icon
      const controlButton = document.createElement("button");
      controlButton.innerHTML =
        '<i class="fas fa-layer-group" style="font-size: 30px;"></i>';
      controlButton.style.backgroundColor = "white";
      controlButton.style.border = "none";
      controlButton.style.padding = "5px";
      controlButton.style.borderRadius = "3px";
      controlButton.title = "Layers";

      // Create the dropdown menu
      const dropdownMenu = document.createElement("div");
      dropdownMenu.style.display = "none";
      dropdownMenu.style.position = "absolute";
      dropdownMenu.style.top = "40px";
      dropdownMenu.style.left = "-20px";
      dropdownMenu.style.backgroundColor = "white";
      dropdownMenu.style.boxShadow = "0px 4px 8px rgba(0,0,0,0.2)";
      dropdownMenu.style.borderRadius = "3px";
      dropdownMenu.style.zIndex = "1000";

      // Add options to the dropdown
      const mapOption = document.createElement("div");
      mapOption.innerHTML = "Map";
      mapOption.style.padding = "10px";
      mapOption.style.cursor = "pointer";
      dropdownMenu.appendChild(mapOption);

      const satelliteOption = document.createElement("div");
      satelliteOption.innerHTML = "Satellite";
      satelliteOption.style.padding = "10px";
      satelliteOption.style.cursor = "pointer";
      dropdownMenu.appendChild(satelliteOption);

      // Add event listeners to toggle the map type
      mapOption.addEventListener("click", () => {
        map.setMapTypeId(window.google.maps.MapTypeId.ROADMAP);
        dropdownMenu.style.display = "none";
      });

      satelliteOption.addEventListener("click", () => {
        map.setMapTypeId(window.google.maps.MapTypeId.SATELLITE);
        dropdownMenu.style.display = "none";
      });

      // Toggle dropdown visibility on button click
      controlButton.addEventListener("click", () => {
        dropdownMenu.style.display =
          dropdownMenu.style.display === "none" ? "block" : "none";
      });

      // Close dropdown when mouse leaves the dropdown area
      dropdownMenu.addEventListener("mouseleave", () => {
        dropdownMenu.style.display = "none";
      });

      controlDiv.appendChild(controlButton);
      controlDiv.appendChild(dropdownMenu);

      // Add the custom control to the map
      map.controls[window.google.maps.ControlPosition.TOP_RIGHT].push(
        controlDiv
      );
    }
  }, [map]);
  const containerStyle = {
    width: isSidebarVisible ? "100%" : "100%",
    height: "100vh",
    transition: "width 0.3s ease",
  };
  const sidebarStyle = {
    width: "30%",
    height: "100vh",
    transition: "transform 0.3s ease",
    transform: isSidebarVisible ? "translateX(0)" : "translateX(-100%)",
  };
  const handleSidebarToggle = () => {
    setSidebarVisible(!isSidebarVisible);
  };
  // const handleSelectDriver = (driverId) => {
  //   setSelectedDriverMarker(driverId);
  // };
  const [selectedDriverId, setSelectedDriverId] = useState(null);

  const handleDriverSelection = (driverId) => {
    setSelectedDriverId(driverId);
  };
  const [directions, setDirections] = useState(null);

  const [statusTime, setStatusTime] = useState([]);
  useEffect(() => {
    if (trip?.wayPoints?.wayPoints) {
      const a = trip?.wayPoints?.wayPoints
        ?.filter((x) => x.type === "pickup")
        ?.map((item) => ({
          lat: item.coordinates[0],
          lng: item.coordinates[1],
        }));
      const b = trip?.wayPoints?.wayPoints
        ?.filter((x) => x.type === "dropoff")
        ?.map((item) => ({
          lat: item.coordinates[0],
          lng: item.coordinates[1],
        }));
      const pickPassenger = trip?.wayPoints?.wayPoints?.find(
        (x) => x.type === "dropoff"
      );
      setStatusTime([
        { ...trip?.wayPoints?.wayPoints[0] },
        pickPassenger ? { ...pickPassenger } : null,
        {
          ...trip?.wayPoints?.wayPoints[trip?.wayPoints?.wayPoints?.length - 1],
        },
      ]);
      setCoordinatesList(a);
      setCoordinatesListDropoff(b);
      // if (a && a.length > 1) {
      //   const directionsService = new window.google.maps.DirectionsService();
      //   directionsService.route(
      //     {
      //       origin: a[0],
      //       destination: a[a.length - 1],
      //       waypoints: a.slice(1, -1).map((loc) => ({ location: loc })),
      //       travelMode: window.google.maps.TravelMode.DRIVING,
      //     },
      //     (result, status) => {
      //       if (status === window.google.maps.DirectionsStatus.OK) {
      //         console.log(result);
      //         setDirections(result);
      //       } else {
      //         console.error(`error fetching directions ${result}`);
      //       }
      //     }
      //   );
      // }
      // console.log(a, "a");
    } else {
      setCoordinatesList([]);
      setCoordinatesListDropoff([]);
      setStatusTime([]);
    }
  }, [trip]);
  const convertDate = (date) => {
    const utcDate = new Date(date);
    const chicagoDate = new Date(
      utcDate.toLocaleString("en-US", {
        timeZone: "America/Chicago",
      })
    );
    const chicagoDateTimeString = chicagoDate.toLocaleString("en-US");
    return chicagoDateTimeString.split(", ")[1];
  };
  return isLoaded ? (
    <>
      <div style={{ display: "flex" }}>
        {locationUrl && locationUrl.pathname === "/track" && (
          <>
            <div
              style={sidebarStyle}
              className={`${isSidebarVisible ? "show" : "hide"}`}
            >
              <MapSidebar
                setCenter={setCenter}
                trackingDrivers={trackingData}
                // onSelectDriver={handleSelectDriver}
                onDriverSelect={handleDriverSelection}
              />
            </div>
            <button
              style={{ padding: "10px", background: "#2570b8", color: "#fff" }}
              onClick={handleSidebarToggle}
              // onMouseEnter={() => setSidebarVisible(true)}
              // onMouseLeave={() => setSidebarVisible(false)}
            >
              {isSidebarVisible ? "<" : ">"}
            </button>
          </>
        )}
        <div style={containerStyle}>
          <GoogleMap
            // mapContainerStyle={containerStyle}
            mapContainerStyle={{
              width: "100%",
              height:
                locationUrl && locationUrl.pathname === "/track"
                  ? "100vh"
                  : "100vh",
            }}
            center={center}
            zoom={zoom}
            onUnmount={onUnmount}
            onLoad={(mapInstance) => setMap(mapInstance)}
            // options={options}
            // onClick={_onClick}
          >
            {
              // (allData === true || headquarter === true) &&
              hqPoint.length > 0 &&
                hqPoint.map((stop, i) => (
                  // return baand kiya hai
                  <Marker
                    key={`hq-${i}`} // Unique key for each headquarter marker
                    label={{
                      text: "Headquarter",
                      color: "#fff",
                      className: "labels2",
                    }}
                    icon={hqIcon}
                    position={{
                      lat: stop.latitude,
                      lng: stop.longitude,
                    }}
                  />
                ))
            }
            {(allData === true || onGoing === true) &&
              trackingData.length > 0 &&
              trackingData.map((stop, i) => {
                if (stop.onGoingRide === true) {
                  if (
                    selectedDriverId === null ||
                    selectedDriverId === stop.driverId?.id
                  ) {
                    return (
                      <>
                        <Demo5
                          key={`ongoing-${i}`} // Ensure unique key for each element
                          coordinates={{
                            lat: stop.latitude,
                            lng: stop.longitude,
                          }}
                          icon={movingPin}
                          stop={stop}
                        />
                        {coordinatesList.length > 0 && (
                          <>
                            <Polyline
                              path={coordinatesList}
                              options={{
                                strokeColor: "#0000FF",
                                strokeOpacity: 1.0,
                                strokeWeight: 6,
                              }}
                            />
                            {/* <Marker
                              position={{lat:statusTime[0].coordinates[0], lng:statusTime[0].coordinates[1]}}
                              label={{
                                text: "Start at "+statusTime[0].timestamp,
                                color: "#fff",
                                className: "labels2",
                              }}
                              icon={{
                                url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                              }}
                            /> */}
                            {/* <Marker
                              position={
                                coordinatesList[coordinatesList.length - 1]
                              }
                              label={{
                                text: "End",
                                color: "#fff",
                                className: "labels2",
                              }}
                              icon={{
                                url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                              }}
                            /> */}
                          </>
                        )}
                        {statusTime.length > 0 && (
                          <>
                            <Marker
                              position={{
                                lat: statusTime[0].coordinates[0],
                                lng: statusTime[0].coordinates[1],
                              }}
                              label={{
                                text:
                                  "Start at " +
                                  convertDate(statusTime[0].timestamp),
                                color: "#fff",
                                className: "labels2",
                              }}
                              icon={{
                                url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                              }}
                            />
                            {statusTime[1] && (
                              <Marker
                                position={{
                                  lat: statusTime[1].coordinates[0],
                                  lng: statusTime[1].coordinates[1],
                                }}
                                label={{
                                  text:
                                    "Pick up at " +
                                    convertDate(statusTime[1].timestamp),
                                  color: "#fff",
                                  className: "labels2",
                                }}
                                icon={{
                                  url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
                                }}
                              />
                            )}
                            {reservation?.reservationDetails?.status !==
                              "In Progress" && (
                              <Marker
                                position={{
                                  lat: statusTime[2].coordinates[0],
                                  lng: statusTime[2].coordinates[1],
                                }}
                                label={{
                                  text:
                                    (statusTime[1] === null
                                      ? "No Show"
                                      : "End") +
                                    " at " +
                                    convertDate(statusTime[2].timestamp),
                                  color: "#fff",
                                  className: "labels2",
                                }}
                                icon={{
                                  url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                                }}
                              />
                            )}
                          </>
                        )}
                        {coordinatesListDropoff.length > 0 && (
                          <>
                            <Polyline
                              path={coordinatesListDropoff}
                              options={{
                                strokeColor: "#00FF00",
                                strokeOpacity: 1.0,
                                strokeWeight: 6,
                              }}
                            />
                          </>
                        )}
                      </>

                      // <Marker
                      //   key={i}
                      //   label={{text:" ("+ stop.driverId.vehicleId.assetNo +") "+stop.driverId.firstName + " " + stop.driverId.lastName, color: "#fff" , className: 'labels2'}}
                      //   icon={movingPin}
                      //   position={{lat: stop.latitude,
                      //       lng: stop.longitude}}
                      // />
                      // <MarkerWithTransition key={i} stop={stop} icon={movingPin}/>
                    );
                  }
                }
                return null;
              })}
            {(allData === true || notOnTrip === true) &&
              trackingData.length > 0 &&
              trackingData.map((stop, i) => {
                if (stop.onGoingRide === false) {
                  if (
                    selectedDriverId === null ||
                    selectedDriverId === stop.driverId?.id
                  ) {
                    return (
                      <>
                        <Demo5
                          key={`notontrip-${i}`} // Ensure unique key for each element
                          coordinates={{
                            lat: stop.latitude,
                            lng: stop.longitude,
                          }}
                          icon={iconPin}
                          stop={stop}
                        />
                        {coordinatesList.length > 0 && (
                          <>
                            <Polyline
                              path={coordinatesList}
                              options={{
                                strokeColor: "#0000FF",
                                strokeOpacity: 1.0,
                                strokeWeight: 6,
                              }}
                            />
                            {/* <Marker
                              position={coordinatesList[0]}
                              label={{
                                text: "Start",
                                color: "#fff",
                                className: "labels2",
                              }}
                              icon={{
                                url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                              }}
                            /> */}
                            {/* <Marker
                              position={
                                coordinatesList[coordinatesList.length - 1]
                              }
                              label={{
                                text: "End",
                                color: "#fff",
                                className: "labels2",
                              }}
                              icon={{
                                url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                              }}
                            /> */}
                          </>
                        )}
                        {statusTime.length > 0 && (
                          <>
                            <Marker
                              position={{
                                lat: statusTime[0].coordinates[0],
                                lng: statusTime[0].coordinates[1],
                              }}
                              label={{
                                text:
                                  "Start at " +
                                  convertDate(statusTime[0].timestamp),
                                color: "#fff",
                                className: "labels2",
                              }}
                              icon={{
                                url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                              }}
                            />
                            {statusTime[1] && (
                              <Marker
                                position={{
                                  lat: statusTime[1].coordinates[0],
                                  lng: statusTime[1].coordinates[1],
                                }}
                                label={{
                                  text:
                                    "Pick up at " +
                                    convertDate(statusTime[1].timestamp),
                                  color: "#fff",
                                  className: "labels2",
                                }}
                                icon={{
                                  url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
                                }}
                              />
                            )}
                            {}
                            <Marker
                              position={{
                                lat: statusTime[2].coordinates[0],
                                lng: statusTime[2].coordinates[1],
                              }}
                              label={{
                                text:
                                  (statusTime[1] === null ? "No Show" : "End") +
                                  " at " +
                                  convertDate(statusTime[2].timestamp),
                                color: "#fff",
                                className: "labels2",
                              }}
                              icon={{
                                url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                              }}
                            />
                          </>
                        )}
                        {coordinatesListDropoff.length > 0 && (
                          <>
                            <Polyline
                              path={coordinatesListDropoff}
                              options={{
                                strokeColor: "#00FF00",
                                strokeOpacity: 1.0,
                                strokeWeight: 6,
                              }}
                            />
                            {/* <Marker
                              position={
                                coordinatesListDropoff[coordinatesListDropoff.length - 1]
                              }
                              label={{
                                text: "End",
                                color: "#fff",
                                className: "labels2",
                              }}
                              icon={{
                                url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                              }}
                            /> */}
                          </>
                        )}
                      </>
                      // <Marker
                      //   key={i}
                      //   // title='ssssssssssss'
                      //   label={{text:" ("+ stop.driverId.vehicleId.assetNo +") "+stop.driverId.firstName + " " + stop.driverId.lastName, color: "#fff" , className: 'labels2'}}
                      //   icon={iconPin}
                      //   position={{lat: stop.latitude, lng: stop.longitude}}
                      //   // onClick={()=>onClickDetails(stop)}
                      //   // onMouseOver={()=>onClickDetails(stop)}
                      // />
                      // <MarkerWithTransition key={i} stop={stop} icon={iconPin}/>
                      // <CustomMarker
                      //   lat={stop.latitude}
                      //   lng= {stop.longitude}
                      //   name="My Marker"
                      //   color="blue"/>
                    );
                  }
                }
                return null;
              })}
            {/* {directions && (
              <DirectionsRenderer
                directions={directions}
                options={{
                  polylineOptions: {
                    strokeColor: "#000000",
                    strokeOpacity: 1.0,
                    strokeWeight: 6,
                  },
                }}
              />
            )} */}
          </GoogleMap>
          {locationUrl && locationUrl.pathname === "/track" && (
            <div
              style={{
                position: "absolute",
                top: "0%", // Position the div at the top
                left: "50%", // Center horizontally
                transform: "translateX(-50%)", // Adjust to center horizontally
                width: "50%", // Full width of the map container
                height: "50px", // Height of the div
                backgroundColor: "white", // Background color
                borderBottomLeftRadius: "50px", // Rounded corners at the bottom-left
                borderBottomRightRadius: "50px", // Rounded corners at the bottom-right
                display: "flex", // Flexbox layout
                alignItems: "center", // Center vertically in the div
                justifyContent: "center", // Center horizontally in the div
                boxShadow: "0 2px 5px rgba(0,0,0,0.2)", // Shadow for visual effect
                padding: "0 10px", // Padding to avoid text getting cut off
                boxSizing: "border-box", // Include padding in the width calculation
                textAlign: "center", // Center text horizontally
                // color: "lightskyblue",
              }}
            >
              <span>{dateTime}</span>
            </div>
          )}
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};
export default DriverTrackingMap;
