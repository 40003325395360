/** @format */

import React, { useEffect } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useSelector } from "react-redux";
import Select from "react-select";
import AddressSearchInput from "../../../components/AddressSearchInput";
import { notifyFailure } from "../../../components/toast";

function PassengerRouteAccordionItem({
  values,
  setValues,
  customerRoute,
  index,
  removePassengerRoute,
  removeReturnRoutePM,
  removeReturnRouteAM,
  removeReturnRouteMID,
  setSelectedStudent,
  openDropDown,
  customerDetailsArr,
  setCustomerDetailsArr,
  addShiftAM,
  addShiftPM,
  addShiftMID,
}) {
  const directionsService = new window.google.maps.DirectionsService();

  const fare = useSelector((state) => state.fare);
  const service = useSelector((state) => state.service);

  const students = useSelector((state) => state.students);

  const location = useSelector((state) => state.location);

  const handleAddress = (addressObj, index, field) => {
    setCustomerDetailsArr(
      customerDetailsArr.map((x, i) =>
        i === index ? { ...x, [field]: addressObj } : x
      )
    );
  };

  // console.log(values, "values==>");
  const handleChange = (e, index) => {
    const { name, value } = e.target;
    if (name == "shiftTime") {
      setCustomerDetailsArr(
        customerDetailsArr.map((arrItem, itemIndex) =>
          index === itemIndex
            ? {
                ...arrItem,
                [name]: value,
                shiftAM: value == "AM" ? false : arrItem.shiftAM,
                shiftMID: value == "MID" ? false : arrItem.shiftMID,
                shiftPM: value == "PM" ? false : arrItem.shiftPM,
              }
            : arrItem
        )
      );
    } else {
      setCustomerDetailsArr(
        customerDetailsArr.map((arrItem, itemIndex) =>
          index === itemIndex
            ? { ...arrItem, [e.target.name]: e.target.value }
            : arrItem
        )
      );
    }
    // console.log(customerDetailsArr, "customerDetailsArr==>==>");
  };

  const calculateFareAndDistance = async ({
    pickUpAddress,
    dropOffAddress,
    field,
    pickUpTimeField,
    dropOffTimeField,
  }) => {
    let tempCustomerDetailsArr = [];
    // for (let i = 0; i < customerDetailsArr.length; i++) {
    let newArr = [];
    let newArrReturn = [];
    let distanceFromBaseLocation = 0;
    let distanceFromPickToDropOff = 0;
    let distanceFromBaseLocationReturn = 0;
    let distanceFromPickToDropOffReturn = 0;
    let totalMileage = 0;
    let totalMileageReturn = 0;

    const data = customerRoute;

    let obj = {};

    // const abc = JSON.parse(Cdata?.type);
    const abc = data?.type;

    let eta = { hour: 0, mins: 0 };

    await directionsService
      .route({
        origin: {
          query: location.baseLocation.address,
        },
        destination: {
          query: pickUpAddress.address,
        },
        travelMode: window.google.maps.TravelMode.DRIVING,
      })
      .then((response) => {
        distanceFromBaseLocation = parseFloat(
          response.routes[0].legs[0].distance.text
            .replace(/,/g, "")
            .split(" mi")[0]
        );
      })
      .catch((e) => {
        // console.log("Directions request failed due to ", e);
        // window.alert("Directions request failed due to ", e)
      });

    obj.distanceFromBaseLocation = distanceFromBaseLocation;

    // distanceFromPickToDropOff = await calculateDistance(
    //   data.pickUpAddress.address,
    //   data.dropOffAddress.address
    // );

    let oldDate = new Date(
      "2023-04-20T" + customerRoute[pickUpTimeField] + ":00.000Z"
    );

    let dropOffTime = "00:00";
    await directionsService
      .route({
        origin: {
          query: pickUpAddress.address,
        },
        destination: {
          query: dropOffAddress.address,
        },
        travelMode: window.google.maps.TravelMode.DRIVING,
      })
      .then((response) => {
        distanceFromPickToDropOff = parseFloat(
          response.routes[0].legs[0].distance.text
            .replace(/,/g, "")
            .split(" mi")[0]
        );

        // yahan se new logic
        if (response.routes[0].legs[0].duration.text.length > 7) {
          const timeString = response.routes[0].legs[0].duration.text;
          const hourRegex = /(\d+)\s*hour/;
          const minuteRegex = /(\d+)\s*min/;

          const hourMatch = hourRegex.exec(timeString);
          const minuteMatch = minuteRegex.exec(timeString);

          const hours = hourMatch ? parseInt(hourMatch[1]) : 0;
          const minutes = minuteMatch ? parseInt(minuteMatch[1]) : 0;

          eta.hour = hours;
          eta.mins = minutes;

          let aaa = (hours * 60 + minutes) * 60 * 1000;

          dropOffTime = new Date(aaa + new Date(oldDate).getTime())
            .toISOString()
            .slice(11, 16);

          // console.log("dropp==>", dropOffTimeField, dropOffTime);
          //   setCustomerDetailsArr(
          //     customerDetailsArr.map((x, i) =>
          //       i === index
          //         ? {
          //             ...x,
          //             [dropOffTimeField]: dropOffTime,
          //           }
          //         : x
          //     )
          //   );
        } else {
          eta.mins = parseInt(
            response.routes[0].legs[0].duration.text.split(" mins")[0]
          );

          let aaa =
            parseInt(
              response.routes[0].legs[0].duration.text.split(" mins")[0]
            ) *
            60 *
            1000;

          dropOffTime = new Date(aaa + new Date(oldDate).getTime())
            .toISOString()
            .slice(11, 16);
          // console.log("dropp==>", dropOffTimeField, dropOffTime);

          //   setCustomerDetailsArr(
          //     customerDetailsArr.map((x, i) =>
          //       i === index
          //         ? {
          //             ...x,
          //             // dropOffTime: new Date(aaa + new Date(oldDate).getTime())
          //             //   .toISOString()
          //             //   .slice(11, 16),
          //             [dropOffTimeField]: dropOffTime,
          //           }
          //         : x
          //     )
          //   );
        }
      })
      .catch((e) => {
        // console.log("Directions request failed due to ", e);
        notifyFailure(e);
        // window.alert("Directions request failed due to ", e)
      });

    obj.distanceFromPickToDropOff = distanceFromPickToDropOff;

    totalMileage = obj.totalMileage;
    obj.totalMileage =
      obj.distanceFromBaseLocation + obj.distanceFromPickToDropOff;

    let fares = fare.fare;

    let baseFare;
    let rideFare;
    let companyFare = [];
    let serviceFare = [];
    let offHourFee = 0;

    const student = students?.routePassengerList.find(
      (x) => x.id === data.customerId
    );

    // console.log("student==>", student);
    if (!student.externalCompanyId) {
      fares = fare.fare[0].details;
      fares.forEach((e) => {
        if (e.serviceId.id === abc) {
          serviceFare.push(e);
        }
      });
    } else {
      fares.forEach((e) => {
        if (e.business.id === student.externalCompanyId) {
          companyFare.push(...e.details);
        }
      });

      companyFare.forEach((e) => {
        if (e.serviceId.id === abc) {
          serviceFare.push(e);
        }
      });
    }
    serviceFare.sort((a, b) => a.totalMileageAllows - b.totalMileageAllows);
    let _fare;

    for (let i = 0; i < serviceFare.length; i++) {
      if (totalMileage <= serviceFare[i].totalMileageAllows) {
        _fare = serviceFare[i];

        break;
      }
    }

    if (!_fare) {
      _fare = serviceFare[serviceFare.length - 1];
    }
    obj.fare = _fare;

    if (distanceFromBaseLocation <= _fare.deadHeadThreshold) {
      baseFare = _fare.flatBaseFare;
      obj.baseFare = baseFare;
      obj.baseFareStructure = {
        values: { flatBaseFare: _fare.flatBaseFare },
        valuesFormula: "flatBaseFare",
      };
    } else {
      baseFare =
        _fare.flatBaseFare + _fare.baseFarePerMile * distanceFromBaseLocation;
      obj.baseFare = baseFare;
      obj.baseFareStructure = {
        values: {
          flatBaseFare: _fare.flatBaseFare,
          baseFarePerMile: _fare.baseFarePerMile,
          distanceFromBaseLocation: distanceFromBaseLocation,
        },
        valuesFormula:
          "flatBaseFare + (baseFarePerMile * distanceFromBaseLocation)",
      };
    }

    if (distanceFromPickToDropOff <= _fare.totalMileageAllows) {
      rideFare = distanceFromPickToDropOff * _fare.farePerMile;
      obj.rideFare = rideFare;
      obj.rideFareStructure = {
        values: {
          distanceFromPickToDropOff: distanceFromPickToDropOff,
          farePerMile: _fare.farePerMile,
        },
        valuesFormula: "distanceFromPickToDropOff * farePerMile",
      };
    } else {
      rideFare = distanceFromPickToDropOff * _fare.longDistancePerMile;
      obj.rideFare = rideFare;
      obj.rideFareStructure = {
        values: {
          distanceFromPickToDropOff: distanceFromPickToDropOff,
          longDistancePerMile: _fare.longDistancePerMile,
        },
        valuesFormula: "distanceFromPickToDropOff * longDistancePerMile",
      };
    }

    setCustomerDetailsArr(
      customerDetailsArr.map((x, i) =>
        i === index
          ? {
              ...x,
              // [dropOffTimeField]: dropOffTime,
              [field]: obj,
            }
          : x
      )
    );
  };

  // For Initial
  useEffect(() => {
    if (
      customerRoute.customerId &&
      customerRoute.type &&
      customerRoute.pickUpAddress &&
      customerRoute.dropOffAddress &&
      customerRoute.pickUpTime
    ) {
      calculateFareAndDistance({
        pickUpAddress: customerRoute.pickUpAddress,
        dropOffAddress: customerRoute.dropOffAddress,
        field: "fareAndDistance",
        pickUpTimeField: "pickUpTime",
        dropOffTimeField: "dropOffTime",
      });
    }
  }, [
    customerRoute.customerId,
    customerRoute.typ,
    customerRoute.pickUpAddress,
    customerRoute.dropOffAddress,
    customerRoute.pickUpTime,
  ]);

  // For AM
  useEffect(() => {
    if (
      customerRoute.shiftAM &&
      customerRoute.customerId &&
      customerRoute.type &&
      customerRoute.pickUpAddressAM &&
      customerRoute.dropOffAddressAM &&
      customerRoute.pickUpTimeAM
    ) {
      calculateFareAndDistance({
        pickUpAddress: customerRoute.pickUpAddressAM,
        dropOffAddress: customerRoute.dropOffAddressAM,
        field: "fareAndDistanceAM",
        pickUpTimeField: "pickUpTimeAM",
        dropOffTimeField: "dropOffTimeAM",
      });
    }
    //
  }, [
    customerRoute.shiftAM,

    customerRoute.customerId,
    customerRoute.type,
    customerRoute.pickUpAddressAM,
    customerRoute.dropOffAddressAM,
    customerRoute.pickUpTimeAM,
  ]);

  // PM
  useEffect(() => {
    if (
      customerRoute.shiftPM &&
      customerRoute.customerId &&
      customerRoute.type &&
      customerRoute.pickUpAddressPM &&
      customerRoute.dropOffAddressPM &&
      customerRoute.pickUpTimePM
    ) {
      calculateFareAndDistance({
        pickUpAddress: customerRoute.pickUpAddressPM,
        dropOffAddress: customerRoute.dropOffAddressPM,
        field: "fareAndDistancePM",
        pickUpTimeField: "pickUpTimePM",
        dropOffTimeField: "dropOffTimePM",
      });
    }
  }, [
    customerRoute.shiftPM,
    customerRoute.customerId,
    customerRoute.type,
    customerRoute.pickUpAddressPM,
    customerRoute.dropOffAddressPM,
    customerRoute.pickUpTimePM,
  ]);

  // MID
  useEffect(() => {
    if (
      customerRoute.shiftMID &&
      customerRoute.customerId &&
      customerRoute.type &&
      customerRoute.pickUpAddressMID &&
      customerRoute.dropOffAddressMID &&
      customerRoute.pickUpTimeMID
    ) {
      calculateFareAndDistance({
        pickUpAddress: customerRoute.pickUpAddressMID,
        dropOffAddress: customerRoute.dropOffAddressMID,
        field: "fareAndDistanceMID",
        pickUpTimeField: "pickUpTimeMID",
        dropOffTimeField: "dropOffTimeMID",
      });
    }
  }, [
    customerRoute.shiftMID,
    customerRoute.customerId,
    customerRoute.type,
    customerRoute.pickUpAddressMID,
    customerRoute.dropOffAddressMID,
    customerRoute.pickUpTimeMID,
  ]);

  // console.log(customerRoute, "customerRoute.returnRoute");

  return (
    <div className="border-top-grey">
      {/* <Accordion.Header className="w-100">
        <div className="d-flex align-items-center p-1 m-1 justify-content-between">
          <div>Passenger # {index + 1}</div>
          {index > 0 && (
            <div
              type="button"
              className="btn-lightest-grey rounded f-14 p-2 mr-3 height-35"
              id="save-form"
              onClick={() => removePassengerRoute(index)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-trash3-fill"
                viewBox="0 0 16 16"
              >
                <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
              </svg>
            </div>
          )}
        </div>
      </Accordion.Header> */}
      <div className=" bg-white border-0 text-capitalize d-flex justify-content-between  pb-3 pt-3 px-2">
        <h4 className="f-18 f-w-500 mb-0">Passenger # {index + 1}</h4>
      </div>
      {/* <Accordion.Body> */}
      <div className="row">
        <div className="col-sm-12 col-md-3 pl-4">
          <div className="form-group my-3">
            <div className="d-flex justify-content-between">
              <div>
                <div className="d-flex">
                  <div>
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="clnumber"
                    >
                      Passenger Name
                    </label>
                  </div>
                  <div className="p-1">
                    <sup className="text-danger f-12">*</sup>
                  </div>
                </div>
              </div>
              <div>
                <button
                  className="bg-button"
                  type="button"
                  data-toggle="modal"
                  data-target="#exampleModalCenter2"
                  onClick={() => {
                    setSelectedStudent({ index });
                    openDropDown();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-plus-circle-fill fa-w-20 f-20 text-dark"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                  </svg>
                </button>
              </div>
            </div>
            <Select
              className="basic-single"
              classNamePrefix="select"
              defaultValue={""}
              value={students?.routePassengerList
                ?.map((x) => ({
                  label: `${x.firstName} ${x.lastName ? x.lastName : ""}`,
                  value: x.id,
                }))
                .find(
                  (customer) => customer.value === customerRoute.customerId
                )}
              isDisabled={false}
              // isLoading={searchingClients}
              isClearable={false}
              isRtl={false}
              isSearchable={true}
              required={true}
              name="pickUp"
              // onInputChange={(e) =>
              //   setValues((prev) => ({ ...prev, name: e }))
              // }
              onChange={(e) =>
                setCustomerDetailsArr((prev) =>
                  prev.map((arrItem, itemIndex) =>
                    itemIndex === index
                      ? { ...arrItem, customerId: e.value }
                      : arrItem
                  )
                )
              }
              options={students?.routePassengerList
                .filter(
                  (x) =>
                    x.id !==
                    customerDetailsArr.find((y) => y.customerId == x.id)
                      ?.customerId
                )
                ?.map((x) => ({
                  label: `${x.firstName} ${x.lastName ? x.lastName : ""} `,
                  value: x.id,
                }))}
            />{" "}
          </div>
        </div>

        <div className="col-md-3 col-sm-12 pl-4">
          <div className="form-group my-3">
            <div className="d-flex">
              <div>
                <label
                  className="f-14 text-dark mb-12"
                  data-label=""
                  htmlFor="driver"
                >
                  Location Name
                </label>
              </div>
              <div className="p-1">
                {/* <sup className="text-danger f-12">*</sup> */}
              </div>
            </div>
            <input
              type="text"
              className="form-control height-35 f-14"
              placeholder="Enter location name"
              value={customerDetailsArr[index]?.schoolName}
              name="schoolName"
              // onChange={handleStopChange(i, "time")}
              onChange={(e) => handleChange(e, index)}
              // required
            />
          </div>
        </div>

        <div className="col-sm-12 col-md-3 pl-4">
          <div className="form-group my-3">
            <div className="d-flex">
              <div>
                <label
                  className="f-14 text-dark mb-12"
                  data-label=""
                  htmlFor="clnumber"
                >
                  Type
                </label>
              </div>
              <div className="p-1">
                <sup className="text-danger f-12">*</sup>
              </div>
            </div>
            <div className="select-picker">
              <select
                className="form-control select-picker"
                style={{ height: "39px", fontSize: "14px" }}
                value={customerDetailsArr[index].type}
                name="type"
                onChange={(e) => handleChange(e, index)}
                required
                disabled={customerRoute.customerId === ""}
              >
                <option value="" key={"st"}>
                  {" "}
                  Select Type
                </option>
                {service.service &&
                  service.service.length > 0 &&
                  service.service.map((current, i) => {
                    const selectedStudent = students?.routePassengerList?.find(
                      (student) => customerRoute?.customerId === student?.id
                    );
                    if (selectedStudent) {
                      if (
                        selectedStudent?.isCompany ||
                        selectedStudent?.externalCompanyId
                      ) {
                        const fareFound = fare?.fare?.find(
                          (z) =>
                            z?.business?.id ===
                            selectedStudent?.externalCompanyId
                        );
                        if (
                          fareFound?.details.find(
                            (k) => k?.serviceId?.id === current?.id
                          )
                        )
                          return (
                            <option
                              // value={JSON.stringify({
                              //   id: current.id,
                              //   name: current.name,
                              // })}
                              value={current.id}
                              key={i}
                            >
                              {current.name}
                            </option>
                          );
                      } else {
                        if (
                          !selectedStudent?.isCompany ||
                          !selectedStudent?.externalCompanyId
                        ) {
                          const fareFound = fare?.fare?.find(
                            (z) => z?.business?.name === "Company Fares"
                          );
                          if (
                            fareFound?.details.find(
                              (k) => k?.serviceId?.id === current?.id
                            )
                          )
                            return (
                              <option
                                // value={JSON.stringify({
                                //   id: current.id,
                                //   name: current.name,
                                // })}
                                value={current.id}
                                key={i}
                              >
                                {current.name}
                              </option>
                            );
                        }
                      }
                    }
                  })}
              </select>
            </div>
          </div>
        </div>

        <div className="col-sm-12 col-md-3 pl-4">
          <div className="form-group my-3">
            <div className="d-flex">
              <div>
                <label
                  className="f-14 text-dark mb-12"
                  data-label=""
                  htmlFor="clnumber"
                >
                  Shift Time
                </label>
              </div>
              <div className="p-2">
                <sup className="text-danger f-14">*</sup>
              </div>
            </div>
            <div className="select-picker">
              <select
                className="form-control select-picker"
                style={{ height: "39px", fontSize: "14px" }}
                name="shiftTime"
                value={customerDetailsArr[index]?.shiftTime}
                // onChange={handleStopChange(i, "time")}
                onChange={(e) => handleChange(e, index)}

                // value={values?.shiftTime}
                // onChange={(e) => {
                //   setValues({
                //     ...values,
                //     shiftTime: e.target.value,
                //   });
                // }}

                // value={customerDetailsArr[index]?.shift}
              >
                <option value="AM">Morning | AM</option>
                <option value="PM">Afternoon | PM</option>
                <option value="MID">Mid Day | MID</option>
              </select>
            </div>
          </div>
        </div>
        {/* {customerDetailsArr[index] &&
            customerDetailsArr[index].type &&
            customerDetailsArr[index].type !== "" && (
              <div className="col-sm-12 col-md-3 details ambulatory show">
                <div className="form-group my-3">
                  <div className="d-flex">
                    <div>
                      <label
                        className="f-14 text-dark mb-12"
                        data-label=""
                        htmlFor="clnumber"
                      >
                        Capacity
                      </label>
                    </div>
                    <div className="p-1">
                      <sup className="text-danger f-12">*</sup>
                    </div>
                  </div>
                  <div className="select-picker">
                    <input
                      className="form-control"
                      type="number"
                      style={{ height: "39px" }}
                      value={customerRoute?.capacity}
                      name="capacity"
                      onChange={(e) => handleChange(e, index)}
                    />
                  </div>
                </div>
              </div>
            )} */}

        {/* {passengerRouteArr[index] &&
                  passengerRouteArr[index].type &&
                  passengerRouteArr[index].type !== "" &&
                  JSON.parse(passengerRouteArr[index]?.type).name ===
                    "Ambulatory" && (
                    <>
                      <div className="col-sm-12 col-md-3 details ambulatory show">
                        <div className="form-group my-3">
                          <div className="d-flex">
                            <div>
                                                              <label
className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="clnumber"
                              >
                                Seats
                              </label>
                            </div>
                            <div className="p-1">
                              <sup className="text-danger f-12">*</sup>
                            </div>
                          </div>
                          <div className="select-picker">
                            <select
                              className="form-control select-picker"
                              style={{ height: "39px" }}
                              value={values.seats}
                              name="seats"
                              onChange={(e) => handleChange(e, index)}
                            >
                              <option value="1"> 1</option>
                              <option value="2"> 2</option>
                              <option value="3"> 3</option>
                              <option value="4"> 4</option>
                            </select>
                          </div>
                        </div>
                      </div>{" "}
                      <div className="col-12 col-md-3 e-d-tasks-projects-events details ambulatory show">
                        <div className="form-group my-3">
                          <div className="d-flex">
                            <div>
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="clnumber"
                              >
                                Need Attented
                              </label>
                            </div>
                            <div className="p-1">
                              <sup className="text-danger f-12">*</sup>
                            </div>
                          </div>
                          <div className="btn-choice">
                            <label className="switch">
                              <input
                                type="checkbox"
                                className="switch-input"
                                onChange={(e) =>
                                  setPassengerRouteArr(
                                    passengerRouteArr.map((x, i) =>
                                      i == index
                                        ? {
                                            ...x,
                                            needAttended: e.target.checked,
                                          }
                                        : x
                                    )
                                  )
                                }
                                required
                              />
                              <span
                                className="switch-label"
                                data-on="YES"
                                data-off="NO"
                              ></span>
                              <span className="switch-handle"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                {passengerRouteArr[index] &&
                  passengerRouteArr[index].type &&
                  passengerRouteArr[index].type !== "" &&
                  JSON.parse(passengerRouteArr[index]?.type).name ===
                    "Wheelchair" && (
                    <>
                      <div className="col-12 col-md-3 pl-4 details wheelchair show">
                        <div className="form-group my-3">
                          <div className="d-flex">
                            <div>
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="clnumber"
                              >
                                Wheelchairs
                              </label>
                            </div>
                            <div className="p-1">
                              <sup className="text-danger f-12">*</sup>
                            </div>
                          </div>
                          <div className="select-picker">
                            <select
                              id="default_task_status"
                              className="form-control select-picker"
                              style={{ height: "39px" }}
                              data-size="8"
                              tabIndex="null"
                              name="numberOfWheelchairs"
                              value={values.numberOfWheelchairs}
                              // onChange={handleChange("numberOfWheelchairs")}
                              onChange={(e) => handleChange(e, index)}
                            >
                              <option value="1"> 1</option>
                              <option value="2"> 2</option>
                              <option value="3"> 3</option>
                              <option value="4"> 4</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-3 e-d-tasks-projects-events details wheelchair show">
                        <div className="form-group my-3">
                          <div className="d-flex">
                            <div>
                              <label
                                className="f-14 text-dark mb-12"
                                data-label=""
                                htmlFor="clnumber"
                              >
                                AM Seats
                              </label>
                            </div>
                            <div className="p-1">
                              <sup className="text-danger f-12">*</sup>
                            </div>
                          </div>
                          <div className="select-picker">
                            <select
                              style={{ height: "39px" }}
                              id="default_task_status"
                              className="form-control select-picker"
                              data-size="8"
                              tabIndex="null"
                              name="amSeats"
                              value={values.amSeats}
                              // onChange={handleChange("amSeats")}
                              onChange={(e) => handleChange(e, index)}
                            >
                              <option value="0"> 0</option>
                              <option value="1"> 1</option>
                              <option value="2"> 2</option>
                              <option value="3"> 3</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </>
                  )} */}
      </div>
      {/* <div className="p-1">
          <div>
            <label
              className="f-14 text-dark mb-12"
              data-label=""
              htmlFor="clnumber"
            >
              Active days
            </label>
          </div>
          <div>
            <div className="d-flex flex-wrap">
              {customerRoute.daysOfWeek.map((day, dayIndex) => (
                <div
                  className={`d-flex align-items-center p-1 px-3 m-1 rounded text-white cursor-pointer ${
                    day.active ? "bg-primary" : "bg-secondary"
                  }`}
                  onClick={() =>
                    setCustomerDetailsArr((prev) =>
                      prev.map((arrItem, itemIndex) =>
                        itemIndex === index
                          ? {
                              ...arrItem,
                              daysOfWeek: arrItem.daysOfWeek.map(
                                (arrDayItem, arrDayIndex) =>
                                  arrDayIndex === dayIndex
                                    ? {
                                        ...arrDayItem,
                                        active: !arrDayItem.active,
                                      }
                                    : arrDayItem
                              ),
                            }
                          : arrItem
                      )
                    )
                  }
                >
                  {day.active ? (
                    <i
                      className="fa fa-check-circle mr-1"
                      aria-hidden="true"
                    ></i>
                  ) : (
                    <i
                      className="fa fa-plus-circle mr-1"
                      aria-hidden="true"
                    ></i>
                  )}

                  <p className="m-1">{day.dayName}</p>
                </div>
              ))}
            </div>
          </div>
        </div> */}

      <div className="col-sm-12">
        <div>
          <div className="mx-3">
            <label className="f-14 text-dark" data-label="" htmlFor="clnumber">
              Active days
            </label>
          </div>
          <div className="row">
            {customerRoute.daysOfWeek.map((day, dayIndex) => (
              <div className="col-md col-md-smlweekcol">
                <div className="form-group my-3 mx-3">
                  <div>
                    <label
                      className="f-14 text-dark"
                      data-label=""
                      htmlFor="clnumber"
                    >
                      {day.dayName}
                    </label>
                    <div className="btn-choice">
                      <label className="switch">
                        <input
                          type="checkbox"
                          className="switch-input"
                          id="toggle"
                          checked={day.active}
                          autocomplete="off"
                          onChange={() =>
                            setCustomerDetailsArr((prev) =>
                              prev.map((arrItem, itemIndex) =>
                                itemIndex === index
                                  ? {
                                      ...arrItem,
                                      daysOfWeek: arrItem.daysOfWeek.map(
                                        (arrDayItem, arrDayIndex) =>
                                          arrDayIndex === dayIndex
                                            ? {
                                                ...arrDayItem,
                                                active: !arrDayItem.active,
                                              }
                                            : arrDayItem
                                      ),
                                    }
                                  : arrItem
                              )
                            )
                          }
                        />
                        <span
                          className="switch-label"
                          data-on="Yes"
                          data-off="No"
                        ></span>
                        <span className="switch-handle"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 col-sm-12 pl-4">
          <div className="form-group my-3">
            <div className="d-flex justify-content-between">
              <div>
                <div className="d-flex">
                  <div>
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="clnumber"
                    >
                      Pickup Address
                    </label>
                  </div>
                  <div className="p-1">
                    <sup className="text-danger f-12">*</sup>
                  </div>
                </div>
              </div>
              <div>
                <button
                  className="bg-button"
                  type="button"
                  data-toggle="modal"
                  data-target="#exampleModalCenter2"
                  // onClick={openDropLoc}
                ></button>
              </div>
            </div>

            <AddressSearchInput
              chooseAddressHandler={handleAddress}
              index={index}
              field="pickUpAddress"
              isRequired={true}
            />
          </div>
        </div>
        {/* <div className="col-md-4 col-sm-12 pl-4">
                  <div className="form-group my-3">
                    <div className="d-flex">
                      <div>
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="clnumber"
                        >
                          Pickup Date
                        </label>
                      </div>
                      <div className="p-1">
                        <sup className="text-danger f-12">*</sup>
                      </div>
                    </div>

                    <input
                      type="date"
                      className="form-control height-35 f-14"
                      placeholder=""
                      id="driver"
                      min="0"
                      autoComplete="off"
                      value={passengerRouteArr[index]?.pickUpDate}
                      name="pickUpDate"
                      // value={}
                      // onChange={handleStopChange(i, "date")}
                      onChange={(e) => handleChange(e, index)}
                      required
                    />
                  </div>
                </div> */}
        <div className="col-md-3 col-sm-12 pl-4">
          <div className="form-group my-3">
            <div className="d-flex">
              <div>
                <label
                  className="f-14 text-dark mb-12"
                  data-label=""
                  htmlFor="driver"
                >
                  Pickup Time
                </label>
              </div>
              <div className="p-1">
                <sup className="text-danger f-12">*</sup>
              </div>
            </div>
            <input
              type="time"
              className="form-control height-35 f-14"
              placeholder=""
              id="driver"
              min="0"
              autoComplete="off"
              value={customerDetailsArr[index]?.pickUpTime}
              name="pickUpTime"
              // onChange={handleStopChange(i, "time")}
              onChange={(e) => handleChange(e, index)}
              required
            />
          </div>
        </div>
        <div className="col-md-3 col-sm-12  pl-4">
          <div className="form-group my-3">
            <div className="d-flex justify-content-between">
              <div>
                <div className="d-flex">
                  <div>
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="clnumber"
                    >
                      Dropoff Address
                    </label>
                  </div>
                  <div className="p-1">
                    <sup className="text-danger f-12">*</sup>
                  </div>
                </div>
              </div>
              <div>
                <button
                  className="bg-button"
                  type="button"
                  data-toggle="modal"
                  data-target="#exampleModalCenter2"
                  // onClick={openDropLoc}
                ></button>
              </div>
            </div>
            <AddressSearchInput
              chooseAddressHandler={handleAddress}
              index={index}
              field="dropOffAddress"
              isRequired={true}
            />
          </div>
        </div>

        <div className="col-md-3 col-sm-12 pl-4">
          <div className="form-group my-3">
            <div className="d-flex">
              <div>
                <label
                  className="f-14 text-dark mb-12"
                  data-label=""
                  htmlFor="driver"
                >
                  Dropoff Time
                </label>
              </div>
              <div className="p-1">
                <sup className="text-danger f-12">*</sup>
              </div>
            </div>
            <input
              type="time"
              className="form-control height-35 f-14"
              id="driver"
              value={customerDetailsArr[index]?.dropOffTime}
              name="dropOffTime"
              // onChange={handleStopChange(i, "time")}
              onChange={(e) => handleChange(e, index)}
              required
              // disabled
            />
          </div>
        </div>
      </div>

      {customerRoute.shiftAM && customerRoute.shiftTime !== "AM" && (
        <>
          <div className="row">
            <div className="col-12">
              <div className="ml-2 mt-3 p-2 bg-light">
                <div className="d-flex align-items-center p-1 m-1 justify-content-between">
                  <h6> Morning Route :</h6>{" "}
                  {customerRoute.shiftAM && (
                    <button
                      type="button"
                      className="btn-lightest-grey rounded f-14 p-2 mr-3 height-35"
                      id="save-form"
                      onClick={() => removeReturnRouteAM(index)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-trash3-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                      </svg>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-sm-12 pl-4">
              <div className="form-group my-3">
                <div className="d-flex justify-content-between">
                  <div>
                    <div className="d-flex">
                      <div>
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="clnumber"
                        >
                          Pickup Address
                        </label>
                      </div>
                      <div className="p-1">
                        <sup className="text-danger f-12">*</sup>
                      </div>
                    </div>
                  </div>
                  <div>
                    <button
                      className="bg-button"
                      type="button"
                      data-toggle="modal"
                      data-target="#exampleModalCenter2"
                      // onClick={openDropLoc}
                    ></button>
                  </div>
                </div>

                <AddressSearchInput
                  chooseAddressHandler={handleAddress}
                  index={index}
                  field="pickUpAddressAM"
                  searchAddress={customerRoute?.pickUpAddressAM?.address}
                  isRequired={true}
                />
              </div>
            </div>
            {/* <div className="col-md-4 col-sm-12 pl-4">
                  <div className="form-group my-3">
                    <div className="d-flex">
                      <div>
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="clnumber"
                        >
                          Pickup Date
                        </label>
                      </div>
                      <div className="p-1">
                        <sup className="text-danger f-12">*</sup>
                      </div>
                    </div>

                    <input
                      type="date"
                      className="form-control height-35 f-14"
                      placeholder=""
                      id="driver"
                      min="0"
                      autoComplete="off"
                      value={passengerRouteArr[index]?.pickUpDate}
                      name="pickUpDate"
                      // value={}
                      // onChange={handleStopChange(i, "date")}
                      onChange={(e) => handleChange(e, index)}
                      required
                    />
                  </div>
                </div> */}
            <div className="col-md-3 col-sm-12 pl-4">
              <div className="form-group my-3">
                <div className="d-flex">
                  <div>
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="driver"
                    >
                      Pickup Time
                    </label>
                  </div>
                  <div className="p-1">
                    <sup className="text-danger f-12">*</sup>
                  </div>
                </div>
                <input
                  type="time"
                  className="form-control height-35 f-14"
                  placeholder=""
                  id="driver"
                  min="0"
                  autoComplete="off"
                  value={customerDetailsArr[index]?.pickUpTimeAM}
                  name="pickUpTimeAM"
                  // onChange={handleStopChange(i, "time")}
                  onChange={(e) => handleChange(e, index)}
                  required
                />
              </div>
            </div>
            <div className="col-md-3 col-sm-12  pl-4">
              <div className="form-group my-3">
                <div className="d-flex justify-content-between">
                  <div>
                    <div className="d-flex">
                      <div>
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="clnumber"
                        >
                          Dropoff Address
                        </label>
                      </div>
                      <div className="p-1">
                        <sup className="text-danger f-12">*</sup>
                      </div>
                    </div>
                  </div>
                  <div>
                    <button
                      className="bg-button"
                      type="button"
                      data-toggle="modal"
                      data-target="#exampleModalCenter2"
                      // onClick={openDropLoc}
                    ></button>
                  </div>
                </div>
                <AddressSearchInput
                  chooseAddressHandler={handleAddress}
                  index={index}
                  field="dropOffAddressAM"
                  searchAddress={customerRoute?.dropOffAddressAM?.address}
                  isRequired={true}
                />
              </div>
            </div>

            <div className="col-md-3 col-sm-12 pl-4">
              <div className="form-group my-3">
                <div className="d-flex">
                  <div>
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="driver"
                    >
                      Dropoff Time
                    </label>
                  </div>
                  <div className="p-1">
                    <sup className="text-danger f-12">*</sup>
                  </div>
                </div>
                <input
                  type="time"
                  className="form-control height-35 f-14"
                  id="driver"
                  value={customerDetailsArr[index]?.dropOffTimeAM}
                  name="dropOffTimeAM"
                  // onChange={handleStopChange(i, "time")}
                  onChange={(e) => handleChange(e, index)}
                  required
                  // disabled
                />
              </div>
            </div>
          </div>
        </>
      )}

      {customerRoute.shiftPM && customerRoute.shiftTime !== "PM" && (
        <>
          <div className="row">
            <div className="col-12">
              <div className="ml-2 mt-3 p-2 bg-light">
                <div className="d-flex align-items-center p-1 m-1 justify-content-between">
                  <h6> Afternoon Route :</h6>{" "}
                  {customerRoute.shiftPM && (
                    <button
                      type="button"
                      className="btn-lightest-grey rounded f-14 p-2 mr-3 height-35 bg-black"
                      id="save-form"
                      onClick={() => removeReturnRoutePM(index)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-trash3-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                      </svg>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-sm-12 pl-4">
              <div className="form-group my-3">
                <div className="d-flex justify-content-between">
                  <div>
                    <div className="d-flex">
                      <div>
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="clnumber"
                        >
                          Pickup Address
                        </label>
                      </div>
                      <div className="p-1">
                        <sup className="text-danger f-12">*</sup>
                      </div>
                    </div>
                  </div>
                  <div>
                    <button
                      className="bg-button"
                      type="button"
                      data-toggle="modal"
                      data-target="#exampleModalCenter2"
                      // onClick={openDropLoc}
                    ></button>
                  </div>
                </div>

                <AddressSearchInput
                  chooseAddressHandler={handleAddress}
                  index={index}
                  field="pickUpAddressPM"
                  searchAddress={customerRoute?.pickUpAddressPM?.address}
                  isRequired={true}
                />
              </div>
            </div>
            {/* <div className="col-md-4 col-sm-12 pl-4">
                  <div className="form-group my-3">
                    <div className="d-flex">
                      <div>
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="clnumber"
                        >
                          Pickup Date
                        </label>
                      </div>
                      <div className="p-1">
                        <sup className="text-danger f-12">*</sup>
                      </div>
                    </div>

                    <input
                      type="date"
                      className="form-control height-35 f-14"
                      placeholder=""
                      id="driver"
                      min="0"
                      autoComplete="off"
                      value={passengerRouteArr[index]?.pickUpDate}
                      name="pickUpDate"
                      // value={}
                      // onChange={handleStopChange(i, "date")}
                      onChange={(e) => handleChange(e, index)}
                      required
                    />
                  </div>
                </div> */}
            <div className="col-md-3 col-sm-12 pl-4">
              <div className="form-group my-3">
                <div className="d-flex">
                  <div>
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="driver"
                    >
                      Pickup Time
                    </label>
                  </div>
                  <div className="p-1">
                    <sup className="text-danger f-12">*</sup>
                  </div>
                </div>
                <input
                  type="time"
                  className="form-control height-35 f-14"
                  placeholder=""
                  id="driver"
                  min="0"
                  autoComplete="off"
                  value={customerDetailsArr[index]?.pickUpTimePM}
                  name="pickUpTimePM"
                  // onChange={handleStopChange(i, "time")}
                  onChange={(e) => handleChange(e, index)}
                  required
                />
              </div>
            </div>
            <div className="col-md-3 col-sm-12  pl-4">
              <div className="form-group my-3">
                <div className="d-flex justify-content-between">
                  <div>
                    <div className="d-flex">
                      <div>
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="clnumber"
                        >
                          Dropoff Address
                        </label>
                      </div>
                      <div className="p-1">
                        <sup className="text-danger f-12">*</sup>
                      </div>
                    </div>
                  </div>
                  <div>
                    <button
                      className="bg-button"
                      type="button"
                      data-toggle="modal"
                      data-target="#exampleModalCenter2"
                      // onClick={openDropLoc}
                    ></button>
                  </div>
                </div>
                <AddressSearchInput
                  chooseAddressHandler={handleAddress}
                  index={index}
                  field="dropOffAddressPM"
                  searchAddress={customerRoute?.dropOffAddressPM?.address}
                  isRequired={true}
                />
              </div>
            </div>

            <div className="col-md-3 col-sm-12 pl-4">
              <div className="form-group my-3">
                <div className="d-flex">
                  <div>
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="driver"
                    >
                      Dropoff Time
                    </label>
                  </div>
                  <div className="p-1">
                    <sup className="text-danger f-12">*</sup>
                  </div>
                </div>
                <input
                  type="time"
                  className="form-control height-35 f-14"
                  id="driver"
                  value={customerDetailsArr[index]?.dropOffTimePM}
                  name="dropOffTimePM"
                  // onChange={handleStopChange(i, "time")}
                  onChange={(e) => handleChange(e, index)}
                  required
                  // disabled
                />
              </div>
            </div>
          </div>
        </>
      )}

      {customerRoute.shiftMID && customerRoute.shiftTime !== "MID" && (
        <>
          <div className="row">
            <div className="col-12">
              <div className="ml-2 mt-3 p-2 bg-light">
                <div className="d-flex align-items-center p-1 m-1 justify-content-between">
                  <h6>Mid Route :</h6>{" "}
                  {customerRoute.shiftMID && (
                    <button
                      type="button"
                      className="btn-lightest-grey rounded f-14 p-2 mr-3 height-35"
                      id="save-form"
                      onClick={() => removeReturnRouteMID(index)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-trash3-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                      </svg>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-sm-12 pl-4">
              <div className="form-group my-3">
                <div className="d-flex justify-content-between">
                  <div>
                    <div className="d-flex">
                      <div>
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="clnumber"
                        >
                          Pickup Address
                        </label>
                      </div>
                      <div className="p-1">
                        <sup className="text-danger f-12">*</sup>
                      </div>
                    </div>
                  </div>
                  <div>
                    <button
                      className="bg-button"
                      type="button"
                      data-toggle="modal"
                      data-target="#exampleModalCenter2"
                      // onClick={openDropLoc}
                    ></button>
                  </div>
                </div>

                <AddressSearchInput
                  chooseAddressHandler={handleAddress}
                  index={index}
                  field="pickUpAddressMID"
                  searchAddress={customerRoute?.pickUpAddressMID?.address}
                  isRequired={true}
                />
              </div>
            </div>
            {/* <div className="col-md-4 col-sm-12 pl-4">
                  <div className="form-group my-3">
                    <div className="d-flex">
                      <div>
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="clnumber"
                        >
                          Pickup Date
                        </label>
                      </div>
                      <div className="p-1">
                        <sup className="text-danger f-12">*</sup>
                      </div>
                    </div>

                    <input
                      type="date"
                      className="form-control height-35 f-14"
                      placeholder=""
                      id="driver"
                      min="0"
                      autoComplete="off"
                      value={passengerRouteArr[index]?.pickUpDate}
                      name="pickUpDate"
                      // value={}
                      // onChange={handleStopChange(i, "date")}
                      onChange={(e) => handleChange(e, index)}
                      required
                    />
                  </div>
                </div> */}
            <div className="col-md-3 col-sm-12 pl-4">
              <div className="form-group my-3">
                <div className="d-flex">
                  <div>
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="driver"
                    >
                      Pickup Time
                    </label>
                  </div>
                  <div className="p-1">
                    <sup className="text-danger f-12">*</sup>
                  </div>
                </div>
                <input
                  type="time"
                  className="form-control height-35 f-14"
                  placeholder=""
                  id="driver"
                  min="0"
                  autoComplete="off"
                  value={customerDetailsArr[index]?.pickUpTimeMID}
                  name="pickUpTimeMID"
                  // onChange={handleStopChange(i, "time")}
                  onChange={(e) => handleChange(e, index)}
                  required
                />
              </div>
            </div>
            <div className="col-md-3 col-sm-12  pl-4">
              <div className="form-group my-3">
                <div className="d-flex justify-content-between">
                  <div>
                    <div className="d-flex">
                      <div>
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="clnumber"
                        >
                          Dropoff Address
                        </label>
                      </div>
                      <div className="p-1">
                        <sup className="text-danger f-12">*</sup>
                      </div>
                    </div>
                  </div>
                  <div>
                    <button
                      className="bg-button"
                      type="button"
                      data-toggle="modal"
                      data-target="#exampleModalCenter2"
                      // onClick={openDropLoc}
                    ></button>
                  </div>
                </div>
                <AddressSearchInput
                  chooseAddressHandler={handleAddress}
                  index={index}
                  field="dropOffAddressMID"
                  searchAddress={customerRoute?.dropOffAddressMID?.address}
                  isRequired={true}
                />
              </div>
            </div>

            <div className="col-md-3 col-sm-12 pl-4">
              <div className="form-group my-3">
                <div className="d-flex">
                  <div>
                    <label
                      className="f-14 text-dark mb-12"
                      data-label=""
                      htmlFor="driver"
                    >
                      Dropoff Time
                    </label>
                  </div>
                  <div className="p-1">
                    <sup className="text-danger f-12">*</sup>
                  </div>
                </div>
                <input
                  type="time"
                  className="form-control height-35 f-14"
                  id="driver"
                  value={customerDetailsArr[index]?.dropOffTimeMID}
                  name="dropOffTimeMID"
                  // onChange={handleStopChange(i, "time")}
                  onChange={(e) => handleChange(e, index)}
                  required
                  // disabled
                />
              </div>
            </div>
          </div>
        </>
      )}

      <div className="row">
        <div className="col-sm-12 pl-4">
          <div className="form-group my-3">
            <div className="d-flex">
              <div>
                <label
                  className="f-14 text-dark mb-12"
                  data-label=""
                  htmlFor="driver"
                >
                  Notes
                </label>
              </div>
              <div className="p-1">
                {/* <sup className="text-danger f-12">*</sup> */}
              </div>
            </div>
            <textarea
              className="form-control f-14"
              placeholder=""
              id="driver"
              rows="3"
              name="dispatchNotes"
              // onChange={handleStopChange(i, "time")}
              onChange={(e) => handleChange(e, index)}
              // required
            />
          </div>
        </div>
      </div>
      <div className="row text-right">
        <div className="col-12 col-md-4 ml-auto pl-4">
          <div className="form-group my-2">
            <>
              <div className="d-flex gap-5 justify-content-end">
                {customerRoute.shiftTime !== "AM" && !customerRoute.shiftAM && (
                  <button
                    type="button"
                    className="btn-lightest-grey rounded f-12 p-2 mr-3 "
                    onClick={() => addShiftAM(index)}
                  >
                    Add Shift Morning
                  </button>
                )}
                {!customerRoute.shiftMID &&
                  customerRoute.shiftTime !== "MID" && (
                    <button
                      type="button"
                      className="btn-lightest-grey rounded f-12 p-2 mr-3 "
                      onClick={() => addShiftMID(index)}
                    >
                      Add Shift Mid
                    </button>
                  )}
                {!customerRoute.shiftPM && customerRoute.shiftTime !== "PM" && (
                  <button
                    type="button"
                    className="btn-lightest-grey rounded f-12 p-2 mr-3 "
                    onClick={() => addShiftPM(index)}
                  >
                    Add Shift Afternoon
                  </button>
                )}
              </div>
            </>
          </div>
        </div>
      </div>
      {/* </Accordion.Body> */}
      {/* <AdminDeleteRoute
        current={current}
        showDeleteDropDown={showDeleteDropDown}
        openDeleteDropDown={openDeleteDropDown}
        index={i}
        isClient={"true"} 
              />*/}
    </div>
  );
}
export default PassengerRouteAccordionItem;
