/** @format */

import React, { useEffect, useState } from "react";
import AdminHeader from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader";
import AdminSidebar from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar";
// import AdminDailyActivityReportTable from "../../components/adminComponents/adminReportComponents/AdminDailyActivityReportTable";
// import AdminDailyActivityReportSearchTable from "../../components/adminComponents/adminReportComponents/AdminDailyActivityReportSearchTable";
// import AdminReportSearchandFilterV2 from "../../components/adminComponents/adminReportComponents/AdminReportSearchandFilterV2";
import { useSelector } from "react-redux";
import axios from "axios";
import shift1 from "../../assets/images/load.png";

import { SERVERADDRESS } from "../../constants";
import ExcelExport from "../../ExcelExport";

function AdminAnnuallyDriverEarningsReportPage() {
  const auth = useSelector((state) => state.auth);
  const [earning, setEarning] = useState(null);
  const chicagoDateOptions = {
    timeZone: "America/Chicago",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  const chicagoDate = new Date().toLocaleDateString(
    "en-US",
    chicagoDateOptions
  );
  const parts = chicagoDate.split("/");
  const formattedChicagoDate = `${parts[2]}-${parts[0]}-${parts[1]}`;
  const [values, setValues] = useState({
    companyId: auth.user.companyId.id,
    // year: "2024",
    from: formattedChicagoDate,
    to: formattedChicagoDate,
  });
  const fetchEarnings = async () => {
    try {
      const { data } = await axios.post(
        `${SERVERADDRESS}/v1/kpi/driverAnnualEarning`,
        values
      );
      setEarning(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleClick = async () => {
    await fetchEarnings();
  };

  useEffect(() => {
    fetchEarnings(); // Call the same function inside useEffect
  }, []);

  // const handleClick = async () => {
  //   try {
  //     const { data } = await axios.post(
  //       `${SERVERADDRESS}/v1/kpi/driverAnnualEarning`,
  //       values
  //     );
  //     setEarning(data);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  // useEffect(() => {
  //   handleClick();
  // }, []);
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };

  const data = earning?.results?.map((current) => {
    return {
      "Driver Name":
        current?.driverDetails?.firstName +
        " " +
        current?.driverDetails?.lastName,
      // Year: earning?.year,
      Earning: current?.totalDriverAmount,
    };
  });

  return (
    <>
      <AdminHeader />
      <AdminSidebar />
      <div className="body-wrapper clearfix">
        <section className="main-container " id="fullscreen">
          <div className="px-4 py-2 border-top-0 emp-dashboard">
            <div
              className="d-lg-flex d-md-flex d-block py-4"
              style={{ justifyContent: "space-between" }}
            >
              <div>
                <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">
                  Annually Driver Earnings Report
                </h4>
              </div>
              <div
                className="btn-group mt-2 mt-lg-0 mt-md-0 ml-0 ml-lg-3 ml-md-3"
                role="group"
              >
                <div
                  className="btn-group me-2"
                  role="group"
                  aria-label="First group"
                >
                  <ExcelExport
                    excelData={data}
                    // fileName={`Route Distance Report ${moment().format(
                    //   "MM/DD/YYYY"
                    // )}`}
                    fileName={`Annually Driver Earnings Report`}
                  />
                </div>
              </div>
            </div>

            <div className="card mb-3">
              <div className="card-body p-0 ">
                <div className="row p-2 px-4">
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label
                        className="f-14 text-dark mb-12"
                        data-label=""
                        htmlFor="clnumber"
                      >
                        From
                      </label>
                      <input
                        type="date"
                        className="form-control height-35 f-14"
                        placeholder=""
                        id=""
                        autocomplete="off"
                        value={values.from}
                        onChange={handleChange("from")}
                      />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label
                        className="f-14 text-dark mb-12"
                        data-label=""
                        htmlFor="clnumber"
                      >
                        To
                      </label>
                      <input
                        type="date"
                        className="form-control height-35 f-14"
                        placeholder=""
                        id=""
                        autocomplete="off"
                        value={values.to}
                        onChange={handleChange("to")}
                      />
                    </div>
                  </div>
                  {/* <div className="col-lg-3">
                    <div className="form-group">
                      <label
                        className="f-14 text-dark mb-12"
                        data-label=""
                        htmlFor="clnumber"
                      >
                        Year
                      </label>
                      <select
                        name="leads-table_length"
                        aria-controls="leads-table"
                        className="custom-select custom-select-sm form-control form-control-sm"
                        autoComplete="false"
                        value={values.year}
                        onChange={handleChange("year")}
                      >
                        {["2024", "2023"].map((current, i) => {
                          return (
                            <option value={current} key={i}>
                              {current}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div> */}
                  <div className="col-lg-4 col-md-auto mt-0 mt-sm-3">
                    <div className="d-grid mt-3">
                      <button
                        className="btn btn-success"
                        data-toggle="modal"
                        data-target="#generatedData"
                        aria-hidden="true"
                        onClick={handleClick}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {earning && (
              <>
                <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
                  <div className="d-flex border">
                    <div className="flex-grow-1">
                      <div className="" id="leads-table_length">
                        <div className="col-md-12 text-right">
                          {/* <h3>Year: {values.year}</h3> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="leads-table_wrapper"
                    className="dataTables_wrapper dt-bootstrap4 no-footer"
                  >
                    <div className="row">
                      <div className="col-sm-12">
                        <table
                          className="table border w-100 dataTable no-footer"
                          id="leads-table"
                          role="grid"
                          aria-describedby="leads-table_info"
                          style={{ overflowX: "auto" }}
                        >
                          <thead className="card-header">
                            <tr role="row">
                              <th
                                title="Driver Name"
                                className="sorting border-right"
                                tabIndex="0"
                                aria-controls="leads-table"
                                rowSpan="1"
                                colSpan="1"
                                aria-label="Mobile: activate to sort column ascending"
                              >
                                Driver Name
                              </th>
                              {/* <th
                                title="Year"
                                className="sorting_disabled border-right"
                                rowSpan="1"
                                colSpan="1"
                                aria-label="Next Follow Up"
                              >
                                Year
                              </th> */}
                              <th
                                title="Earning"
                                className="sorting border-right"
                                tabIndex="0"
                                aria-controls="leads-table"
                                rowSpan="1"
                                colSpan="1"
                                aria-label="Lead Agent: activate to sort column ascending"
                              >
                                Earning
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {earning &&
                              earning?.results?.length > 0 &&
                              earning?.results?.map((current, i) => {
                                return (
                                  <tr
                                    role="row"
                                    className="odd border"
                                    key={i}
                                    // onMouseLeave={() => setshowMoreVertDropDown(false)}
                                  >
                                    <td className="border-right">
                                      {current?.driverDetails?.firstName +
                                        " " +
                                        current.driverDetails.lastName}
                                    </td>
                                    {/* <td className="border-right">
                                      {earning?.year}
                                    </td> */}
                                    <td className="border-right">
                                      ${current?.totalDriverAmount}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                        {earning &&
                          earning?.results &&
                          earning?.results?.length === 0 && (
                            <div
                              className="dash-stats-list"
                              style={{ textAlign: "center" }}
                            >
                              <br />
                              <br />
                              <img
                                alt=""
                                src={shift1}
                                style={{ width: "145px", opacity: "0.5" }}
                              />
                              <br />
                              <p style={{ opacity: "0.5" }}>No Data Found</p>
                            </div>
                          )}

                        {/* {kpi &&
                      kpi?.reservationReportSearch &&
                      kpi?.reservationReportSearch?.length === 0 && (
                        <div
                          className="dash-stats-list"
                          style={{ textAlign: "center" }}
                        >
                          <br />
                          <br />
                          <img
                            alt=""
                            src={shift1}
                            style={{ width: "145px", opacity: "0.5" }}
                          />
                          <br />
                          <p style={{ opacity: "0.5" }}>No Report Found</p>
                        </div>
                      )} */}

                        <div
                          id="leads-table_processing"
                          className="dataTables_processing card"
                          style={{ display: "none" }}
                        >
                          Processing...
                        </div>
                      </div>
                    </div>
                    <div className="d-flex border"></div>
                  </div>
                </div>
              </>
            )}
          </div>
        </section>
      </div>
    </>
  );
}

export default AdminAnnuallyDriverEarningsReportPage;
