import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPastReservationsByUserId,
  getReservationsByUserId,
} from "../../actions/reservationAction";
import AdminHeader from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader";
import AdminSidebar from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar";
import DriverDashboardTable from "../../components/driverComponents/driverDashboardComponents/DriverDashboardTable";
import { getServicesByCompanyId } from "../../actions/serviceActions";
import { useNavigate } from "react-router-dom";
import { navigateUserFunc } from "../../actions/generalActions";
import NotAllowed from "../../components/NotAllowed";
import moment from "moment";

const DriverDashboardPage = () => {
  const auth = useSelector((state) => state.auth);
  const service = useSelector((state) => state.service);
  const dispatch = useDispatch();
  const driverData = async (e) => {
    dispatch(
      getReservationsByUserId({
        driverId: auth.user.id,
        today: moment().format("YYYY-MM-DD"),
      })
    );
  };
  const driverHistoryData = async (e) => {
    dispatch(
      getPastReservationsByUserId({
        driverId: auth.user.id,
        today: moment().format("YYYY-MM-DD"),
      })
    );
  };
  const serviceData = async () => {
    dispatch(getServicesByCompanyId({ companyId: auth.user.companyId.id }));
  };
  let navigate = useNavigate();
  // useEffect(() => {
  //     if ((auth?.user?.permissionId?.dashboardDriver?.isAllow === false) || (auth?.user?.disable === true)){
  //         navigateUserFunc(auth?.user, navigate)
  //     }
  // },[])
  useEffect(() => {
    driverData();
    serviceData();
    driverHistoryData();
  }, []);

  useEffect(() => {
    if (
      auth?.user?.permissionId?.dashboardDriver?.isAllow === false ||
      auth?.user?.disable === true
    ) {
      navigateUserFunc(auth?.user, navigate);
    }
  }, []);
  return (
    <>
      <AdminHeader />
      <AdminSidebar />
      <div className="body-wrapper clearfix">
        <section className="main-container " id="fullscreen">
          <div className="px-4 py-2 border-top-0 emp-dashboard">
            <div className="d-lg-flex d-md-flex d-block py-4">
              <div>
                <h4 className=" mb-0 f-21 text-capitalize font-weight-bold">
                  Welcome to Connect Care Trans Dashboard
                </h4>
              </div>
            </div>
            {auth?.user?.permissionId?.dashboardDriver?.read === true ? (
              <DriverDashboardTable />
            ) : (
              <NotAllowed />
            )}
          </div>
        </section>
      </div>
    </>
  );
};
export default DriverDashboardPage;
