import { SOCKET_CONNECTED, SOCKET_DISCONNECT } from "../constants";

export const socketReducer = (
  state = {
    list: [],
    selectedChat: null,
    messages: [],
  },
  action
) => {
  switch (action.type) {
    case SOCKET_CONNECTED:
      return {
        ...state,
        ...action.payload,
      };
    case SOCKET_DISCONNECT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
