/** @format */

import React, { useEffect, useState } from "react";
import { InfoWindowF, Marker } from "@react-google-maps/api";
import ProgressBar from "react-bootstrap/ProgressBar";
import img1 from "../assets/images/Frame4.png";
import { ENVIRONMENT } from "../constants";

const Demo5 = ({ coordinates, icon, stop }) => {
  const [currentVal, setCurrentVal] = useState({
    lat: coordinates?.lat ? coordinates?.lat : 0,
    lng: coordinates?.lng ? coordinates?.lng : 0,
  });
  const [newVal, setNewVal] = useState({
    lat: "",
    lng: "",
  });
  useEffect(() => {
    if (currentVal.lat === "" && currentVal.lng === "") {
      setCurrentVal({
        lat: coordinates.lat,
        lng: coordinates.lng,
      });
    }
    if (currentVal.lat !== "" && currentVal.lng !== "") {
      if (
        currentVal.lat !== coordinates.lat &&
        currentVal.lng !== coordinates.lng
      ) {
        setNewVal({
          lat: coordinates.lat,
          lng: coordinates.lng,
        });
      }
    }
  }, [coordinates]);
  // var numDeltas = 100;
  // var delay = 10; //milliseconds
  // var i = 0;
  // var deltaLat;
  // var deltaLng;
  // const transition = () => {
  //     if ((newVal.lat !== '') && (newVal.lng !== '')){
  //         i = 0;
  //         deltaLat = parseFloat((newVal.lat-currentVal.lat)/numDeltas);
  //         deltaLng = parseFloat((newVal.lng-currentVal.lng)/numDeltas);
  //         moveMarker();
  //     }
  // }
  // const moveMarker = () => {
  //     // position[0] += deltaLat;
  //     // position[1] += deltaLng;
  //     setCurrentVal(
  //         {
  //             lat: parseFloat(currentVal.lat + deltaLat),
  //             lng: parseFloat(currentVal.lng + deltaLng)
  //         }
  //     )
  //     if(i!=numDeltas){
  //         i++;
  //         setTimeout(moveMarker, delay);
  //     }
  // }
  // useEffect(()=> {
  //     transition()
  // }, [coordinates])
  const [deltaCoordinates, setDeltaCoordinates] = useState({
    lat: 0,
    lng: 0,
  });

  useEffect(() => {
    // setTimeout(() => {
    //     if (currentVal.lat !== newVal.lat){
    //     setInterval(() => {
    //     let cLat = (newVal.lat-currentVal.lat)/100
    //     let cLng = (newVal.lng-currentVal.lng)/100
    //     console.log(newVal.lat,currentVal.lat, "newVal.lat,currentVal.lat")
    //     setCurrentVal(
    //         {
    //         lat: currentVal.lat += cLat,
    //         lng: currentVal.lng += cLng
    //         }
    //     )
    //     }
    //     , 10)
    //     }
    // }
    // , 5000)
    if (currentVal.lat !== coordinates.lat) {
      if (newVal.lat !== "" && newVal.lng !== "") {
        setTimeout(() => {
          setDeltaCoordinates({
            lat: parseFloat((newVal.lat - currentVal.lat) / 10), // phele 100 likha tha for smoothing
            lng: parseFloat((newVal.lng - currentVal.lng) / 10), // phele 100 likha tha for smoothing
          });
          // let cLat = parseFloat((newVal.lat-currentVal.lat)/100)
          // let cLng = parseFloat((newVal.lng-currentVal.lng)/100)
          // console.log(cLat, cLng, "=======")
          // setCurrentVal((x)=> ({
          //     lat: x.lat += cLat,
          //     lng: x.lng += cLng
          // })
          // )
        }, 10);
      }
    }
  }, [coordinates]);
  useEffect(() => {
    // if (stop?.driverId?.id == "64f26dded291b894f64c2749") {
    //   console.log(
    //     Math.abs(deltaCoordinates.lat) >= 0.1,
    //     Math.abs(newVal.lat - currentVal.lat) >= 0.1,
    //     Math.abs(deltaCoordinates.lat),
    //     Math.abs(newVal.lat - currentVal.lat),
    //     newVal.lat,
    //     currentVal.lat,
    //     Math.abs(deltaCoordinates.lng) >= 0.1,
    //     Math.abs(newVal.lng - currentVal.lng) >= 0.1,
    //     Math.abs(deltaCoordinates.lng),
    //     Math.abs(newVal.lng - currentVal.lng),
    //     newVal.lng,
    //     currentVal.lng,
    //     "hhhhhhhhhhhhhhhhhhhhhhh"
    //   );
    // }
    setCurrentVal((x) => ({
      lat:
        Math.abs(newVal.lat - currentVal.lat) >= 0.1
          ? newVal.lat
          : (x.lat += deltaCoordinates.lat),
      lng:
        Math.abs(newVal.lng - currentVal.lng) >= 0.1
          ? newVal.lng
          : (x.lng += deltaCoordinates.lng),
    }));
  }, [deltaCoordinates]);
  // console.log(deltaCoordinates,currentVal, newVal, "=======")
  const [openPopup, setOpenPopup] = useState(false);
  // console.log(stop, "stop")
  const calculateEta = (stop) => {
    let travelTime;
    let travelTime2;
    let location;
    let lowSpeed = 15;
    let highSpeed = 28;
    // if (stop.speed) {
    //   if (stop.speed > 28) {
    //     highSpeed = stop.speed;
    //   } else if (stop.speed > 15) {
    //     lowSpeed = stop.speed;
    //   }
    // }
    if (
      !stop?.tripId?.dropOffTime &&
      stop?.tripId?.pickUpTime &&
      stop?.tripId?.arrivedTime
    ) {
      const distance = calculateDistance(
        {
          latitude: stop?.tripId?.reservationId?.dropOffAddress?.latitude,
          longitude: stop?.tripId?.reservationId?.dropOffAddress?.longitude,
        },
        {
          // latitude: currentVal.lat, longitude: currentVal.lng
          latitude: stop.latitude,
          longitude: stop.longitude,
        }
      );
      // let speed = 18;
      const changeInDistance = calculateDistance(
        {
          latitude: stop.latitude,
          longitude: stop.longitude,
        },
        {
          latitude: currentVal.lat,
          longitude: currentVal.lng,
        }
      );
      // if (changeInDistance > 0.06) {
      //   speed = 35;
      // } else if (changeInDistance > 0.055) {
      //   speed = 32.5;
      // } else if (changeInDistance > 0.05) {
      //   speed = 30;
      // } else if (changeInDistance > 0.045) {
      //   speed = 27.5;
      // } else if (changeInDistance > 0.04) {
      //   speed = 25;
      // }
      // if (changeInDistance > 0.06) {
      //   speed = 32;
      // } else if (changeInDistance > 0.05) {
      //   speed = 28;
      // } else if (changeInDistance > 0.04) {
      //   speed = 23;
      // }
      // if (changeInDistance > 0.05) {
      //   speed = 28;
      // }
      travelTime = distance / highSpeed;
      travelTime2 = distance / lowSpeed;
      location = " (dropoff)";
      // console.log(
      //   calculateDistance(
      //     {
      //       latitude: stop.latitude,
      //       longitude: stop.longitude,
      //     },
      //     {
      //       latitude: currentVal.lat,
      //       longitude: currentVal.lng,
      //     }
      //   ),
      //   speed,
      //   "travelTime"
      // );
    } else if (
      !stop?.tripId?.dropOffTime &&
      !stop?.tripId?.pickUpTime &&
      stop?.tripId?.arrivedTime
    ) {
      return "arrived";
    } else {
      const distance = calculateDistance(
        {
          latitude: stop?.tripId?.reservationId?.pickUpAddress?.latitude,
          longitude: stop?.tripId?.reservationId?.pickUpAddress?.longitude,
        },
        {
          // latitude: currentVal.lat, longitude: currentVal.lng
          latitude: stop.latitude,
          longitude: stop.longitude,
        }
      );
      let speed = 18;
      const changeInDistance = calculateDistance(
        {
          latitude: stop.latitude,
          longitude: stop.longitude,
        },
        {
          latitude: currentVal.lat,
          longitude: currentVal.lng,
        }
      );
      // if (changeInDistance > 0.06) {
      //   speed = 35;
      // } else if (changeInDistance > 0.055) {
      //   speed = 32.5;
      // } else if (changeInDistance > 0.05) {
      //   speed = 30;
      // } else if (changeInDistance > 0.045) {
      //   speed = 27.5;
      // } else if (changeInDistance > 0.04) {
      //   speed = 25;
      // }
      // if (changeInDistance > 0.06) {
      //   speed = 32;
      // } else if (changeInDistance > 0.05) {
      //   speed = 28;
      // } else if (changeInDistance > 0.04) {
      //   speed = 23;
      // }
      // if (changeInDistance > 0.05) {
      //   speed = 28;
      // }
      travelTime = distance / highSpeed;
      travelTime2 = distance / lowSpeed;
      location = " (pickup)";
      // console.log(
      //   calculateDistance(
      //     {
      //       latitude: stop.latitude,
      //       longitude: stop.longitude,
      //     },
      //     {
      //       latitude: currentVal.lat,
      //       longitude: currentVal.lng,
      //     }
      //   ),
      //   speed,
      //   changeInDistance,
      //   changeInDistance > 0.4,
      //   "travelTime"
      // );
    }
    const result = formatHours(travelTime);
    const result2 = formatHours(travelTime2);
    const showTime =
      result === result2 ? result : "(" + result + " - " + result2 + ")";
    return showTime === "0 min" ? "1 min" : showTime + location;
  };
  const calculateDistance = (coord1, coord2, check) => {
    // const R = 6371; // Radius of Earth in kilometers
    const R = 3958.8; // Radius of Earth in miles
    const dLat = (coord2.latitude - coord1.latitude) * (Math.PI / 180);
    const dLon = (coord2.longitude - coord1.longitude) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(coord1.latitude * (Math.PI / 180)) *
        Math.cos(coord2.latitude * (Math.PI / 180)) *
        Math.sin(dLon / 2) *
        Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const tuningFactor = 1.44; // use because this is for two points in straight line
    const distance = R * c * tuningFactor;
    if (check) {
      // console.log(coord1, coord2, distance, "distance"); // commenting very good for checking
    }
    return distance;
  };
  const formatHours = (hours) => {
    const totalMinutes = Math.floor(hours * 60);
    const hoursPart = Math.floor(totalMinutes / 60);
    const minutesPart = totalMinutes % 60;
    let output = "";
    if (hoursPart > 0) {
      output += `${hoursPart} h, `;
    }
    output += `${minutesPart} min`;

    return output;
  };

  return (
    <Marker
      label={{
        text:
          // " (" +
          // stop?.driverId?.vehicleId?.assetNo +
          // ") " +
          stop?.driverId?.firstName + " " + stop?.driverId?.lastName,
        color: "#fff",
        className: "labels2",
      }}
      icon={icon}
      position={currentVal}
      onMouseOver={() => setOpenPopup(true)}
      onMouseOut={() => setOpenPopup(false)}
      onClick={() => setOpenPopup(true)}
    >
      {openPopup && stop?.tripId && (
        <InfoWindowF
        // position={{
        //   lat: location.coordinates[0],
        //   lng: location.coordinates[1],
        // }}
        // onCloseClick={() => setOpenPopup(false)}
        >
          <div>
            <p style={{ top: "15px", position: "absolute" }}>
              <span style={{ color: "lightskyblue", fontWeight: "bold" }}>
                {stop?.driverId?.vehicleId?.assetNo +
                  " - " +
                  stop?.driverId?.firstName +
                  " " +
                  stop?.driverId?.lastName}
              </span>
            </p>
            <p style={{ top: "35px", position: "absolute" }}>
              <span style={{ fontWeight: "bold" }}>Order #</span>{" "}
              {stop?.tripId?.reservationId?.orderId}
            </p>
            <p style={{ top: "55px", position: "absolute" }}>
              <span style={{ fontWeight: "bold" }}>Customer: </span>
              {stop?.tripId?.reservationId?.customerId?.firstName +
                " " +
                stop?.tripId?.reservationId?.customerId?.lastName}
            </p>
            {ENVIRONMENT === "local" ? (
              <p style={{ top: "75px", position: "absolute" }}>
                <span style={{ fontWeight: "bold" }}>speed and eta: </span>
                {stop?.tripId?.eta ? stop?.tripId?.eta + " and" : ""}
                {stop.speed !== undefined && stop.speed !== null
                  ? stop?.speed + "mph"
                  : ""}
              </p>
            ) : (
              <p style={{ top: "75px", position: "absolute" }}>
                <span style={{ fontWeight: "bold" }}>ETA: </span>
                {calculateEta(stop)}
              </p>
            )}
            <p style={{ padding: "45px 0 0 0" }}>
              <span style={{ fontWeight: "bold" }}>Coordinates: </span>
              {currentVal.lat + "," + currentVal.lng}
            </p>
          </div>
        </InfoWindowF>
        // <InfoWindowF
        // // position={{
        // //   lat: location.coordinates[0],
        // //   lng: location.coordinates[1],
        // // }}
        // // onCloseClick={() => setOpenPopup(false)}
        // >
        //   <div>
        //     <h3>Order # {stop?.tripId?.reservationId?.orderId}</h3>
        //     <h5>
        //       Customer:{" "}
        //       {stop?.tripId?.reservationId?.customerId?.firstName +
        //         " " +
        //         stop?.tripId?.reservationId?.customerId?.lastName}
        //     </h5>
        //     <p>
        //       Status: In Progress <br />
        //     </p>
        //   </div>
        // </InfoWindowF>
      )}
      {openPopup && stop?.routeTripId && (
        <InfoWindowF>
          <div>
            <p style={{ top: "15px", position: "absolute" }}>
              <span style={{ color: "lightskyblue", fontWeight: "bold" }}>
                {stop?.driverId?.vehicleId?.assetNo +
                  " - " +
                  stop?.driverId?.firstName +
                  " " +
                  stop?.driverId?.lastName}
              </span>
            </p>
            <p style={{ top: "35px", position: "absolute" }}>
              <span style={{ fontWeight: "bold" }}>Route #</span>{" "}
              {stop?.routeTripId?.routeId?.routeNumber}
            </p>
            <p style={{ top: "55px", position: "absolute" }}>
              <span style={{ fontWeight: "bold" }}>Shift: </span>
              {stop?.routeTripId?.routeId?.routeNumberReturn ? "PM" : "AM"}
            </p>
            <p style={{ padding: "25px 0 0 0" }}>
              <span style={{ fontWeight: "bold" }}>Coordinates: </span>
              {currentVal.lat + "," + currentVal.lng}
            </p>
          </div>
        </InfoWindowF>
        // <InfoWindowF>
        //   <div>
        //     <h3>
        //       Route # {stop?.routeTripId?.routeId?.routeNumber}
        //       {/* {stop?.routeTripId?.routeId?.routeNumberReturn && "( Return )"} */}
        //     </h3>
        //     <h5>
        //       Shift:{" "}
        //       {stop?.routeTripId?.routeId?.routeNumberReturn ? "PM" : "AM"}
        //     </h5>
        //     {/* <h5>{stop?.routeTripId?.routeId?.routeNumberReturn ? "PM" : "AM"}</h5> */}
        //     <p>
        //       Status: In Progress <br />
        //       {/* Speed:{" "}
        //       {stop?.speed ? stop?.speed : 0 + "mph"} */}
        //     </p>
        //     {/* <img
        //       data-toggle="tooltip"
        //       src={img1}
        //       style={{ maxHeight: "27px" }}
        //     />
        //     <i class="fa fa-flag-checkered"></i>
        //     <ProgressBar
        //       now={50}
        //       min={0}
        //       max={100}
        //       variant="success"
        //       animated={true}
        //       // striped={true}
        //     /> */}
        //   </div>
        // </InfoWindowF>
      )}
      {openPopup && !stop?.tripId && !stop?.routeTripId && (
        <InfoWindowF>
          <div>
            <p style={{ top: "15px", position: "absolute", width: "auto" }}>
              <span style={{ color: "lightskyblue", fontWeight: "bold" }}>
                {stop?.driverId?.vehicleId?.assetNo +
                  " - " +
                  stop?.driverId?.firstName +
                  " " +
                  stop?.driverId?.lastName}
              </span>
            </p>
            <p>
              <span style={{ fontWeight: "bold" }}>Coordinates: </span>
              {currentVal.lat + "," + currentVal.lng}
            </p>
          </div>
        </InfoWindowF>
        // <InfoWindowF>
        //   <div>
        //     <h6>No ride in Progress</h6>
        //   </div>
        // </InfoWindowF>
      )}
    </Marker>
  );
};

export default Demo5;
