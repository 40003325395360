/** @format */

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// import AdminClientnUserDelete from "../adminUserComponents/AdminClientnUserDelete";
// import AdminClientEditNewModal from "./AdminPassengerEditNewModal";
import AdminPassengerEditNewModal from "./AdminPassengerEditNewModal";
import AdminPassengerDelete from "./AdminPassengerDelete";
import { addUser, createIndividualClient } from "../../../actions/userActions";
import AdminGrantPermissionModal from "./AdminGrantPermissionModal";

const AdminPassengerViewTableRow = ({
  current,
  i,
  dropDownRow,
  setDropDownRow,
  handleModal,
}) => {
  const vehicle = useSelector((state) => state.vehicle);
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };
  const navigate = useNavigate();
  const [showDropDown, setshowDropDown] = useState(false);
  const [showDeleteDropDown, setshowDeleteDropDown] = useState(false);
  const dispatch = useDispatch();

  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
  };
  const openDeleteDropDown = () => {
    setshowDeleteDropDown((showDeleteDropDown) => !showDeleteDropDown);
  };
  const auth = useSelector((state) => state.auth);

  const data = {
    companyId: current.companyId,
    firstName: current.firstName,
    lastName: current.lastName,
    email: current.email,
    isClient: true,
  };

  // console.log(current, "current");
  const handleGrantPermission = async (event) => {
    dispatch(createIndividualClient(data));
  };

  const [showGrantPermissionDropDown, setshowGrantPermissionDropDown] =
    useState(false);
  const openGrantPermissionDropDown = () => {
    setshowGrantPermissionDropDown(
      (showGrantPermissionDropDown) => !showGrantPermissionDropDown
    );
  };
  return (
    <>
      <tr
        id="row-20"
        role="row"
        className="odd border"
        key={i}
        onMouseLeave={() => setshowMoreVertDropDown(false)}
      >
        <td className="border-right">{current.uniqueId}</td>
        <td className="border-right">
          {current.firstName} {current.lastName}
        </td>
        <td className="border-right">{current.email}</td>
        <td className="border-right">{current.phone}</td>
        {/* <td className="border-right">{current.address}</td> */}
        <td className="border-right">{current.notes}</td>

        {/* <td className="border-right">
          {current.disable.toString() === "false" ? "Active" : "InActive"}
        </td> */}
        <td className=" text-right pr-20">
          <div className="task_view">
            <div className="dropdown">
              <Link
                className="task_view_more d-flex align-items-center justify-content-center dropdown-toggle"
                type="link"
                data-toggle="dropdown"
                onClick={openMoreVertDropDown}
                aria-haspopup="true"
                aria-expanded="false"
                to=""
              >
                <i className="icon-options-vertical icons"></i>
              </Link>
              <div
                className={`dropdown-menu dropdown-menu-right ${
                  showMoreVertDropDown
                    ? "dropdown-menu dropdown-menu-right show"
                    : "dropdown-menu dropdown-menu-right"
                }`}
                aria-labelledby="dropdownMenuLink-20"
              >
                <button
                  onClick={() =>
                    navigate(`/passenger-view/${current.id}`, {
                      state: current,
                    })
                  }
                  className="dropdown-item"
                >
                  <i className="fa fa-eye"> View</i>
                </button>
                {auth?.user?.permissionId?.clients?.update === true && (
                  <button className="dropdown-item" onClick={openDropDown}>
                    <i className="fa fa-edit"> Edit</i>
                  </button>
                )}
                {/* {current.isCompany === true ? (
                  <div></div>
                ) : (
                  <button
                    className="dropdown-item"
                    onClick={handleGrantPermission}
                  >
                    <i className="fa fa-shield"> Grant Permission</i>
                  </button>
                )} */}
                {current.isCompany === true ? (
                  // <div></div>
                  <button
                    className="dropdown-item"
                    onClick={openGrantPermissionDropDown}
                  >
                    <i className="fa fa-shield"> Grant Permission</i>
                  </button>
                ) : (
                  <button
                    className="dropdown-item"
                    onClick={openGrantPermissionDropDown}
                  >
                    <i className="fa fa-shield"> Grant Permission</i>
                  </button>
                )}
                {auth?.user?.permissionId?.clients?.delete === true && (
                  <Link
                    className="dropdown-item delete-table-row"
                    to=""
                    data-id="20"
                    onClick={openDeleteDropDown}
                  >
                    <i className="fa fa-trash"> Delete</i>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </td>
        {showDropDown && (
          <AdminPassengerEditNewModal
            current={current}
            showDropDown={showDropDown}
            openDropDown={openDropDown}
          />
        )}

        <AdminPassengerDelete
          current={current}
          showDeleteDropDown={showDeleteDropDown}
          openDeleteDropDown={openDeleteDropDown}
          index={i}
        />
        {showGrantPermissionDropDown && (
          <AdminGrantPermissionModal
            current={current}
            showGrantPermissionDropDown={showGrantPermissionDropDown}
            openGrantPermissionDropDown={openGrantPermissionDropDown}
            // index={i}
          />
        )}
      </tr>
    </>
  );
};
export default AdminPassengerViewTableRow;
