/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import MyComponent from "../Map/Map";
import { getExternalCompanies } from "../../../actions/companyAction";
import {
  // addClient,
  addPassengerFormAction,
  // addStudentAction,
} from "../../../actions/userActions";
// import AdminSettingPaymentAddCompanyModal from "../../../components/adminComponents/adminSettingComponents/AdminSettingPaymentAddCompanyModal";
import { useNavigate } from "react-router-dom";
import Select, { NonceProvider } from "react-select";
import AdminSettingAddFacilityModal from "../adminSettingComponents/AdminSettingAddFacilityModal";
import { createFare } from "../../../actions/fareActions";

const AdminSettingClientFareAddComponent = ({
  showDropDown,
  openDropDown,
  setSelectedStudent,
}) => {
  let [showModal, setShowModal] = useState();
  // let [selectedCompany, setSelectedCompany] = useState();
  const externalCompanies = useSelector((state) => state.externalCompanies);
  const auth = useSelector((state) => state.auth);
  const students = useSelector((state) => state.students);
  const { loading } = students;
  const kpi = useSelector((state) => state.kpi);
  const service = useSelector((state) => state.service);
  const { facility } = kpi;
  useEffect(() => {
    dispatch(getExternalCompanies({ companyId: auth.user.companyId.id }));
  }, [auth]);

  let [selectedCompanyId, setSelectedCompanyId] = useState("");
  const [values, setValues] = useState({
    companyId: auth.user.companyId.id,
    serviceId: "",
    driverPercentage: 0,
    name: "",
    flatBaseFare: "",
    farePerMile: "",
    deadHeadThreshold: "",
    baseFarePerMile: "",
    totalMileageAllows: "",
    longDistancePerMile: "",
    offHourFee: "",
    noShowFee: "",
    cancelationWindow: "",
    cancelationFee: "",
    isCompany: false,
    isClientRate: true,
    infectiousDiseaseRate: "",
    responsiblePartyRate: "",
    curbToCurbRate: "",
    doorToDoorRate: "",
    doorThroughDoorRate: "",
  });

  const dispatch = useDispatch();

  const successHandler = (studentId) => {
    setSelectedStudent((prev) => ({ ...prev, studentId }));
    openDropDown();
  };

  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };

  const handlePhone = (fieldName) => (event) => {
    const numericValue = event.target.value
      .replace(/[^0-9.]/g, "")
      .replace(/(\..*)\./g, "$1");
    setValues({
      ...values,
      [fieldName]: numericValue,
    });
  };

  const navigate = useNavigate();
  // const navigatePassenger = () => {
  //   navigate("/passengers");
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
    let fareData = values;
    const found = service?.service?.find((x) => x.id == values.serviceId);
    const name = found?.name + " Client Fare";
    fareData.name = name;
    for (let key of Object.keys(fareData)) {
      if (fareData[key] === "") {
        delete fareData[key];
      }
    }
    dispatch(createFare(fareData, navigate));
    // navigate("/passengers")
  };
  const [showDropDownFacility, setshowDropDownFacility] = useState(false);

  const openDropDownFacility = () => {
    setshowDropDownFacility((showDropDownFacility) => !showDropDownFacility);
  };

  return (
    <>
      <div className="d-flex flex-column w-tables rounded mt-3 bg-white px-4 pb-3">
        <div
          id="leads-table_wrapper"
          className="dataTables_wrapper dt-bootstrap4 no-footer"
        >
          <form onSubmit={handleSubmit}>
            <div className="row p-3">
              <div className="col-md-3">
                <div className="form-group my-3">
                  <label
                    className="f-14 text-dark-grey mb-12"
                    data-label=""
                    htmlFor="after_days"
                  >
                    Type
                    <sup className="text-danger f-12">*</sup>
                  </label>
                  <div className="select-picker">
                    <select
                      id="type2"
                      name="type2"
                      className="form-control select-picker"
                      style={{ height: "39px", fontSize: "14px" }}
                      value={values.serviceId}
                      onChange={handleChange("serviceId")}
                      required
                    >
                      <option value="" id="opt" key={"st"}>
                        {" "}
                        Select Type
                      </option>
                      {service.service &&
                        service.service.length > 0 &&
                        service.service.map((current, i) => {
                          return (
                            <option value={current.id} key={i}>
                              {current.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group my-3">
                  <label
                    className="f-14 text-dark-grey mb-12"
                    data-label=""
                    htmlFor="after_days"
                  >
                    Curb-to-Curb
                  </label>
                  <input
                    type="tel"
                    className="form-control height-35 f-14"
                    name="curbToCurbRate"
                    value={values.curbToCurbRate}
                    onChange={handlePhone("curbToCurbRate")}
                    placeholder="0.0"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group my-3">
                  <label
                    className="f-14 text-dark-grey mb-12"
                    data-label=""
                    htmlFor="after_days"
                  >
                    Door-to-Door
                  </label>
                  <input
                    type="tel"
                    className="form-control height-35 f-14"
                    name="doorToDoorRate"
                    value={values.doorToDoorRate}
                    onChange={handlePhone("doorToDoorRate")}
                    placeholder="0.0"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group my-3">
                  <label
                    className="f-14 text-dark-grey mb-12"
                    data-label=""
                    htmlFor="after_days"
                  >
                    Door-through-Door
                  </label>
                  <input
                    type="tel"
                    className="form-control height-35 f-14"
                    name="doorThroughDoorRate"
                    value={values.doorThroughDoorRate}
                    onChange={handlePhone("doorThroughDoorRate")}
                    placeholder="0.0"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group my-3">
                  <label
                    className="f-14 text-dark-grey mb-12"
                    data-label=""
                    htmlFor="after_days"
                  >
                    Responsible Party
                  </label>
                  <input
                    type="tel"
                    className="form-control height-35 f-14"
                    name="responsiblePartyRate"
                    value={values.responsiblePartyRate}
                    onChange={handlePhone("responsiblePartyRate")}
                    placeholder="0.0"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group my-3">
                  <label
                    className="f-14 text-dark-grey mb-12"
                    data-label=""
                    htmlFor="after_days"
                  >
                    Infectious Disease
                  </label>
                  <input
                    type="tel"
                    className="form-control height-35 f-14"
                    name="infectiousDiseaseRate"
                    value={values.infectiousDiseaseRate}
                    onChange={handlePhone("infectiousDiseaseRate")}
                    placeholder="0.0"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group my-3">
                  <label
                    className="f-14 text-dark-grey mb-12"
                    data-label=""
                    htmlFor="after_days"
                  >
                    Mileage Rate (Loaded)
                  </label>
                  <input
                    type="tel"
                    className="form-control height-35 f-14"
                    name="flatBaseFare"
                    value={values.flatBaseFare}
                    onChange={handlePhone("flatBaseFare")}
                    placeholder="0.0"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group my-3">
                  <label
                    className="f-14 text-dark-grey mb-12"
                    data-label=""
                    htmlFor="after_days"
                  >
                    Long Distance Mileage Rate
                  </label>
                  <input
                    type="tel"
                    className="form-control height-35 f-14"
                    name="farePerMile"
                    value={values.farePerMile}
                    onChange={handlePhone("farePerMile")}
                    placeholder="0.0"
                  />
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group my-3">
                  <label
                    className="f-14 text-dark-grey mb-12"
                    data-label=""
                    htmlFor="after_days"
                  >
                    Deadhead Mileage Rate
                  </label>
                  <input
                    type="tel"
                    className="form-control height-35 f-14"
                    name="baseFarePerMile"
                    value={values.baseFarePerMile}
                    onChange={handlePhone("baseFarePerMile")}
                    placeholder="0.0"
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div
                  className="form-group "
                  style={{ marginTop: "1.5em !important" }}
                >
                  <br />
                  <button
                    type="submit"
                    className="btn-primary rounded f-14 p-2 mr-3"
                    disabled={loading}
                    // onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default AdminSettingClientFareAddComponent;
