import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteClientorUser,
  getAllUser,
  getAllUsers,
} from "../../../actions/userActions";

const AdminClientnUserDelete = ({
  showDeleteDropDown,
  openDeleteDropDown,
  current,
  index,
  isClient,
}) => {
  const [values, setValues] = useState({
    userId: "",
    delete: "",
    // cancelReason: '',
    // status: '',
  });
  const auth = useSelector((state) => state.auth);
  const users = useSelector((state) => state.users);
  useEffect(() => {
    setValues({ ...values, userId: current?.id });
  }, [current]);
  const handleChange = (fieldName) => (event) => {
    setValues({
      ...values,
      [fieldName]: event.target.value,
    });
  };
  const data = {
    // _id: values._id,
    // userId: current.id,
    delete: true,
    userId: values.userId,

    // cancelReason: values.cancelReason,
    // status: 'Canceled',
  };
  const dispatch = useDispatch();
  const handleSumbit = async (event) => {
    event.preventDefault();
    dispatch(deleteClientorUser(data));
    setTimeout(() => {
      if (isClient === "false") {
        dispatch(
          getAllUser(
            {
              companyId: auth?.user?.companyId?.id,
              isClient: "false",
              // limit: users.users.limit,
              // page: users.users.page === 1 ?
              // users.users.page :
              // users.users.results.length === 1 ?
              // users.users.page - 1:
              // users.users.page
            },
            users.users.limit,
            users.users.page === 1
              ? users.users.page
              : users.users.results.length === 1
              ? users.users.page - 1
              : users.users.page
          )
        );
      } else if (isClient === "true") {
        dispatch(
          getAllUsers(
            {
              companyId: auth?.user?.companyId?.id,
              isClient: "true",
              // limit: users.client.limit,
              // page: users.client.page === 1 ?
              // users.client.page :
              // users.client.results.length === 1 ?
              // users.client.page - 1:
              // users.client.page
            },
            users.client.limit,
            users.client.page === 1
              ? users.client.page
              : users.client.results.length === 1
              ? users.client.page - 1
              : users.client.page
          )
        );
      }
    }, 2000);
    openDeleteDropDown();
  };
  return (
    <>
      <div
        className={`modal fade ${
          showDeleteDropDown === true ? "show modal-y-axis" : "hide"
        }`}
        role="dialog"
        data-backdrop="static"
        data-keyboard="false" // To prevent closing with the keyboard
        onClick={(e) => {
          // Check if the click occurred outside the modal content
          if (e.target === e.currentTarget) {
            openDeleteDropDown(); // Call your close modal function
          }
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <form onSubmit={handleSumbit}>
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLongTitle">
                  {" "}
                  Delete User
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => openDeleteDropDown()}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body" style={{ width: "50% !important" }}>
                <div className="row">
                  <div className="col-md-12">
                    <p>Are you sure you want to delele? </p>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn-secondary rounded f-14 p-2 mr-3"
                  data-dismiss="modal"
                  onClick={() => openDeleteDropDown()}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn-primary rounded f-14 p-2 mr-3"
                >
                  Yes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdminClientnUserDelete;
