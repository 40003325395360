import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import SearchedByMap from "../adminComponents/Map/SearchedByMap";

const ExternalCompanyBookingReturnTrip = ({
  values,
  values2,
  setValues2,
  isSpecialNumber2,
  handleCheckboxChange2,
  returnStopArr,
  setReturnStopArr,
  details,
  willCall,
  deadHeadForR,
  baseToPickUpR,
  setPickUpToDropOffAvg,
  pickUpToDropOffOrig,
}) => {
  const directionsService = new window.google.maps.DirectionsService();
  const location = useSelector((state) => state.location);
  const fare = useSelector((state) => state.fare);
  const auth = useSelector((state) => state.auth);
  const [fareDetails, setFareDetails] = useState();
  const [showDetailDropDown, setshowDetailDropDown] = useState(false);
  const openDetailDropDown = () => {
    setshowDetailDropDown((showDetailDropDown) => !showDetailDropDown);
  };

  const handleVeryDirectFare = () => {
    const dh = deadHeadForR;
    const temp = parseFloat(
      (
        parseFloat(
          parseFloat(
            parseFloat(hardCodedFares.distanceFromPickToDropOff).toFixed(2)
          ) * parseFloat(hardCodedFares.farePerMile)
        ) +
        parseFloat(hardCodedFares.offHourFee ? hardCodedFares.offHourFee : 0) +
        parseFloat(hardCodedFares.flatBaseFare) +
        deadHeadForR
      ).toFixed(2)
    );

    const tempD = parseFloat(
      temp * (hardCodedFares.driverPercentage / 100).toFixed(2)
    );

    setFareDetails({
      ...fareDetails,
      distanceFromBaseLocation: baseToPickUpR,
      distanceFromPickToDropOff: parseFloat(
        parseFloat(hardCodedFares?.distanceFromPickToDropOff)?.toFixed(2)
      ),
      precalculatedMiles: parseFloat(
        parseFloat(hardCodedFares?.precalculatedMiles)?.toFixed(2)
      ),
      apiDistanceFromPickToDropOff: hardCodedFares.apiDistanceFromPickToDropOff,
      apiDistanceFromBaseLocation: hardCodedFares.apiDistanceFromBaseLocation,
      usePrecalculatedMiles: hardCodedFares.usePrecalculatedMiles,
      avgDistanceFromPickToDropOff: parseFloat(
        parseFloat(hardCodedFares?.avgDistanceFromPickToDropOff)?.toFixed(2)
      ),
      useAvgDistance: hardCodedFares.useAvgDistance,
      totalMileage: parseFloat(
        (
          baseToPickUpR + parseFloat(hardCodedFares.distanceFromPickToDropOff)
        ).toFixed(2)
      ),
      baseFare: parseFloat(parseFloat(hardCodedFares.flatBaseFare).toFixed(2)),
      baseFareStructure: {
        values: {
          flatBaseFare: hardCodedFares.flatBaseFare,
        },
        valuesFormula: "flatBaseFare",
      },
      deadheadFare: dh,
      deadheadFareStructure: {
        values: {
          baseFarePerMile: hardCodedFares.baseFarePerMile,
          distanceFromBaseLocation: baseToPickUpR,
        },
        valuesFormula: "(baseFarePerMile * distanceFromBaseLocation)/2",
      },
      rideFare: parseFloat(
        (
          parseFloat(hardCodedFares.distanceFromPickToDropOff).toFixed(2) *
          parseFloat(hardCodedFares.farePerMile)
        ).toFixed(2)
      ),
      rideFareStructure: {
        values: {
          distanceFromPickToDropOff: hardCodedFares.distanceFromPickToDropOff,
          farePerMile: hardCodedFares.farePerMile,
        },
        valuesFormula: "distanceFromPickToDropOff * farePerMile",
      },
      offHourFee: parseFloat(hardCodedFares.offHourFee),
      amount: temp,
      amountStructure: {
        values: {
          rideFare: parseFloat(
            parseFloat(hardCodedFares.distanceFromPickToDropOff).toFixed(2) *
              parseFloat(hardCodedFares.farePerMile)
          ).toFixed(2),
          offHourFee: parseFloat(hardCodedFares.offHourFee),
          baseFare: parseFloat(hardCodedFares.flatBaseFare).toFixed(2),
          deadheadFare: dh,
        },
        valuesFormula: "rideFare + offHourFee + baseFare + deadheadFare",
      },
      driverPercentage: hardCodedFares.driverPercentage,
      driverAmount: parseFloat(tempD.toFixed(2)),
      companyAmount: parseFloat((temp - tempD).toFixed(2)),
    });
    setAmount(temp);
    setMil(hardCodedFares.distanceFromPickToDropOff);
  };
  const [eta, setEta] = useState();

  let temp = [];

  const [showDropLoc, setDropLoc] = useState(false);
  const openDropLoc = () => {
    setDropLoc((showDropLoc) => !showDropLoc);
  };
  const handleChange = (fieldName) => (event) => {
    setValues2({
      ...values2,
      [fieldName]: event.target.value,
    });
  };

  useEffect(() => {
    setValues2({
      ...values,
      // loadNumber: values2.loadNumber,
      customerId: values.customerId,
      seats: values.seats,
      email: values.email,
      willCall: values.willCall,
      needAttended: values.needAttended,
      numberOfWheelchairs: values.numberOfWheelchairs,
      amSeats: values.amSeats,
      needWheel: values.needWheel,
      needAssigned: values.needAssigned,
      pickUpAddress: values.pickUpAddress,
      pickUpDate: values.pickUpDate,
      pickUpTime: values.pickUpTime,
      dropOffAddress: values.dropOffAddress,
      dropOffDate: values.dropOffDate,
      dropOffTime: values.dropOffTime,
      dateForReservation: values.dateForReservation,
      // driverNotes: values.driverNotes,
      // dispatchNotes: values.dispatchNotes,
      distanceFromBaseLocation: 0,
      distanceFromPickToDropOff: 0,
      totalMileage: 0,
      rideHistory: values.rideHistory,
      externalCompanyId: values.externalCompanyId
        ? details.externalCompanyId
        : "",
    });
    recalculate();
  }, [values]);
  const handleStopChange = (index, field) => (event) => {
    // yahan se new logic
    setStopArrUseApi(true);
    // yahan takh new logic
    setReturnStopArr(
      returnStopArr.map((stop, i) =>
        i === index ? { ...stop, [field]: event.target.value } : { ...stop }
      )
    );
  };
  const increaseStop = () => {
    // yahan se new logic
    setStopArrUseApi(true);
    // yahan takh new logic
    setReturnStopArr([
      ...returnStopArr,
      {
        address: "",
        date: "",
        time: "",
        // id: returnStopArr.length
      },
    ]);
  };
  const decreaseStop = (index) => {
    // yahan se new logic
    setStopArrUseApi(true);
    // yahan takh new logic
    setReturnStopArr(returnStopArr.filter((stop, i) => i !== index));
  };
  let newArr = [];
  let [amount, setAmount] = useState(0);
  let distanceFromBaseLocation = 0;
  let distanceFromPickToDropOff = 0;
  let totalMileage = 0;

  // console.log(returnStopArr, "returnStopArr");

  // yahan se new logic
  const [useHardCodedFares, setUseHardCodedFares] = useState(true);
  const [hardCodedFares, setHardCodedFares] = useState({
    flatBaseFare: "",
    farePerMile: "",
    deadHeadThreshold: "",
    baseFarePerMile: "",
    totalMileageAllows: "",
    longDistancePerMile: "",
    offHourFee: "",
    noShowFee: "",
    cancelationFee: "",
    additionalCharges: 0,
    deduction: 0,
    precalculatedMiles: 0,
    usePrecalculatedMiles: false,
    avgDistanceFromPickToDropOff: 0,
    useAvgDistance: false,
  });
  const handleHardCodedFares = (fieldName) => (event) => {
    setHardCodedFares({
      ...hardCodedFares,
      // [fieldName]: event.target.value !== "" ? parseFloat(event.target.value) : 0,
      [fieldName]: event.target.value,
    });
  };
  const [useMiles, setUseMiles] = useState(false);
  const handleUsePrecalculatedMilesTrue = () => {
    setHardCodedFares((prevFares) => ({
      ...prevFares,
      usePrecalculatedMiles: true,
    }));
    setUseMiles(true);
  };
  const handleUsePrecalculatedMilesFalse = () => {
    // Update usePrecalculatedMiles to true
    setHardCodedFares((prevFares) => ({
      ...prevFares,
      usePrecalculatedMiles: false,
    }));
    setUseMiles(true);
  };

  useEffect(() => {
    if (useMiles) {
      recalculate();
      setUseMiles(false);
    }
  }, [useMiles]);

  const recalculate = () => {
    calculateFareFrontEnd();
  };
  // console.log(useHardCodedFares,hardCodedFares, "hardCodedFares")
  const toogleHardCoded = () => {
    if (!useHardCodedFares === true) {
      setUseDirectFare(false);
    }
    setUseHardCodedFares((useHardCodedFares) => !useHardCodedFares);
  };
  const [useDirectFare, setUseDirectFare] = useState(false);
  const [directFare, setDirectFare] = useState(0);
  const toogleDirectFare = () => {
    if (!useDirectFare === true) {
      setUseHardCodedFares(false);
    }
    setUseDirectFare((useDirectFare) => !useDirectFare);
  };
  const handleDirectFare = (event) => {
    setDirectFare(event.target.value);
  };
  const fareDirectlyCalculate = () => {
    // IDHAR KAAM HOGA DRIVER AMOUNT ORIGINAL USE HO RHI HAI FARE SE NAHI
    let driverAmount = parseFloat(
      (directFare * (auth?.user?.companyId?.driverPercentage / 100)).toFixed(2)
    );
    let companyAmount = parseFloat(directFare - driverAmount);
    setFareDetails({
      distanceFromBaseLocation: fareDetails.distanceFromBaseLocation,
      distanceFromPickToDropOff: fareDetails.distanceFromPickToDropOff,
      totalMileage: fareDetails.totalMileage,
      fare: parseFloat(directFare),
      amount: parseFloat(directFare),
      estimatedTime: fareDetails.estimatedTime,
      etaTime: fareDetails.etaTime,
      driverAmount: driverAmount,
      companyAmount: companyAmount,
      baseFare: 0,
      rideFare: 0,
      offHourFee: 0,
    });
    setAmount(directFare);
  };
  // console.log(fareDetails, "fareDetails")
  const [useFlatDriverRate, setUseFlatDriverRate] = useState(false);
  const [flatDriverRate, setFlatDriverRate] = useState(0);
  const toogleFlatDriverRate = () => {
    if (!useFlatDriverRate === true) {
      setUseHardCodedFares(false);
      setUseDirectFare(false);
    }
    setUseFlatDriverRate((useFlatDriverRate) => !useFlatDriverRate);
  };
  const handleFlatDriverRate = (event) => {
    setFlatDriverRate(event.target.value);
  };
  const flatDriverRateRecalculate = () => {
    let driverAmount = parseFloat(flatDriverRate);
    let companyAmount = parseFloat(fareDetails.amount - driverAmount);
    setFareDetails({
      distanceFromBaseLocation: fareDetails.distanceFromBaseLocation,
      distanceFromPickToDropOff: fareDetails.distanceFromPickToDropOff,
      totalMileage: fareDetails.totalMileage,
      fare: fareDetails.fare,
      amount: fareDetails.amount,
      estimatedTime: fareDetails.estimatedTime,
      etaTime: fareDetails.etaTime,
      driverAmount: driverAmount,
      companyAmount: companyAmount,
      baseFare: 0,
      rideFare: 0,
      offHourFee: 0,
      baseFare: fareDetails.baseFare,
      rideFare: fareDetails.rideFare,
      offHourFee: fareDetails?.offHourFee ? fareDetails.offHourFee : 0,
    });
    // setAmount(directFare);
  };
  // yahan takh new logic

  const calculateFareFrontEnd = async () => {
    const abc = JSON.parse(values.type);
    let newArr = [];
    returnStopArr.forEach((e) => {
      newArr.push(e?.address?.address);
    });
    let obj = {};
    // yahan se new logic
    let eta = { hour: [], mins: [] };
    // yahan takh new logic
    await directionsService
      .route({
        origin: {
          query: location.baseLocation.address,
        },
        destination: {
          query: newArr[0],
        },
        travelMode: window.google.maps.TravelMode.DRIVING,
      })
      .then((response) => {
        // console.log("response", response);
        distanceFromBaseLocation = parseFloat(
          response.routes[0].legs[0].distance.text
            .replace(/,/g, "")
            .split(" mi")[0]
        );
        obj.distanceFromBaseLocation = baseToPickUpR;
      })
      .catch((e) => {});
    // yahan se new logic
    let oldDate = new Date(
      returnStopArr[0].date.substring(0, 10) +
        "T" +
        returnStopArr[0].time +
        ":00.000Z"
    );
    // yahan takh new logic
    for (let i = 0; i < newArr.length - 1; i++) {
      // console.log("newArr", newArr, newArr.length, newArr[i]);
      await directionsService
        .route({
          origin: {
            query: newArr[i],
          },
          destination: {
            query: newArr[i + 1],
          },
          travelMode: window.google.maps.TravelMode.DRIVING,
        })
        .then((response) => {
          // console.log("response", response);
          distanceFromPickToDropOff += parseFloat(
            response.routes[0].legs[0].distance.text
              .replace(/,/g, "")
              .split(" mi")[0]
          );
          // yahan se new logic
          if (response.routes[0].legs[0].duration.text.length > 7) {
            const timeString = response.routes[0].legs[0].duration.text;
            const hourRegex = /(\d+)\s*hour/;
            const minuteRegex = /(\d+)\s*min/;

            const hourMatch = hourRegex.exec(timeString);
            const minuteMatch = minuteRegex.exec(timeString);

            const hours = hourMatch ? parseInt(hourMatch[1]) : 0;
            const minutes = minuteMatch ? parseInt(minuteMatch[1]) : 0;
            eta.hour.push(hours);
            eta.mins.push(minutes);
            let aaa = (hours * 60 + minutes) * 60 * 1000;
            oldDate = new Date(aaa + new Date(oldDate).getTime()).toUTCString();
            let oldDateTime = oldDate.toString().split(" ")[4];
            let ind = i + 1;
            setReturnStopArr(
              returnStopArr.map((stop, index) =>
                index === ind
                  ? {
                      ...stop,
                      date: new Date(oldDate).toISOString().substring(0, 10),
                      time: oldDateTime.substring(0, 5),
                    }
                  : { ...stop }
              )
            );
          } else {
            eta.mins.push(
              parseInt(
                response.routes[0].legs[0].duration.text.split(" mins")[0]
              )
            );
            let aaa =
              parseInt(
                response.routes[0].legs[0].duration.text.split(" mins")[0]
              ) *
              60 *
              1000;
            oldDate = new Date(aaa + new Date(oldDate).getTime()).toUTCString();
            let oldDateTime = oldDate.toString().split(" ")[4];
            let ind = i + 1;
            setReturnStopArr(
              returnStopArr.map((stop, index) =>
                index === ind
                  ? {
                      ...stop,
                      date: new Date(oldDate).toISOString().substring(0, 10),
                      time: oldDateTime.substring(0, 5),
                    }
                  : { ...stop }
              )
            );
          }
          // yahan takh new logic
        })
        .catch((e) => {});
    }
    distanceFromBaseLocation = parseFloat(baseToPickUpR);
    obj.apiDistanceFromBaseLocation = distanceFromBaseLocation;
    distanceFromPickToDropOff = parseFloat(
      distanceFromPickToDropOff.toFixed(2)
    );
    obj.apiDistanceFromPickToDropOff = distanceFromPickToDropOff;
    if (hardCodedFares.usePrecalculatedMiles === true) {
      obj.usePrecalculatedMiles = true;
      distanceFromPickToDropOff = parseFloat(
        parseFloat(hardCodedFares.precalculatedMiles).toFixed(2)
      );
      obj.precalculatedMiles = distanceFromPickToDropOff;
    } else {
      obj.usePrecalculatedMiles = false;
    }
    distanceFromPickToDropOff = parseFloat(
      ((distanceFromPickToDropOff + pickUpToDropOffOrig) / 2).toFixed(2)
    );
    obj.avgDistanceFromPickToDropOff = distanceFromPickToDropOff;
    obj.useAvgDistance = true;
    setPickUpToDropOffAvg(distanceFromPickToDropOff);
    totalMileage = parseFloat(
      (baseToPickUpR + distanceFromPickToDropOff).toFixed(2)
    );
    obj.distanceFromPickToDropOff = distanceFromPickToDropOff;
    obj.totalMileage = totalMileage;
    let fares = fare.fare;
    let baseFare;
    let deadheadFare = deadHeadForR;
    let rideFare;
    let companyFare = [];
    let serviceFare = [];
    let offHourFee = 0;
    // yahan se new logic
    if (
      details.isCompany === false ||
      details.isCompany === "" ||
      !details.isCompany
    ) {
      // yahan takh new logic
      fares = fare.fare[0].details;
      fares.forEach((e) => {
        if (e.serviceId.id === abc.id) {
          serviceFare.push(e);
        }
      });
    } else {
      fares.forEach((e) => {
        // console.log(e,e.business.id === details.externalCompanyId, e.business.id,details.externalCompanyId)
        if (e.business.id === details.externalCompanyId) {
          companyFare.push(...e.details);
        }
      });
      companyFare.forEach((e) => {
        if (e.serviceId.id === abc.id) {
          serviceFare.push(e);
        }
      });
    }
    serviceFare.sort((a, b) => a.totalMileageAllows - b.totalMileageAllows);
    let _fare;
    for (let i = 0; i < serviceFare.length; i++) {
      if (totalMileage <= serviceFare[i].totalMileageAllows) {
        _fare = serviceFare[i];
        // console.log(_fare)
        break;
      }
    }
    if (!_fare) {
      _fare = serviceFare[serviceFare.length - 1];
    }

    obj.fare = _fare;
    if (baseToPickUpR <= _fare.deadHeadThreshold) {
      baseFare = _fare.flatBaseFare;
      obj.baseFare = baseFare;
      obj.baseFareStructure = {
        values: { flatBaseFare: _fare.flatBaseFare },
        valuesFormula: "flatBaseFare",
      };
      obj.deadheadFare = 0;
      obj.deadheadFareStructure = {
        values: {
          baseFarePerMile: 0,
          distanceFromBaseLocation: baseToPickUpR,
        },
        valuesFormula: "baseFarePerMile * distanceFromBaseLocation",
      };
    } else {
      // yahan se new logic
      if (_fare.deadHeadThreshold) {
        baseFare = _fare.flatBaseFare;
        obj.baseFare = baseFare;
        obj.baseFareStructure = {
          values: {
            flatBaseFare: _fare.flatBaseFare,
          },
          valuesFormula: "flatBaseFare",
        };
        deadheadFare = deadHeadForR;
        obj.deadheadFare = deadHeadForR;
        obj.deadheadFareStructure = {
          values: {
            baseFarePerMile: _fare.baseFarePerMile,
            distanceFromBaseLocation: baseToPickUpR,
          },
          valuesFormula: "(baseFarePerMile * distanceFromBaseLocation)/2",
        };
      } else {
        baseFare = _fare.flatBaseFare;
        obj.baseFare = baseFare;
        obj.baseFareStructure = {
          values: { flatBaseFare: _fare.flatBaseFare },
          valuesFormula: "flatBaseFare",
        };
        obj.deadheadFare = 0;
        obj.deadheadFareStructure = {
          values: {
            baseFarePerMile: 0,
            distanceFromBaseLocation: baseToPickUpR,
          },
          valuesFormula: "baseFarePerMile * distanceFromBaseLocation",
        };
      }
      // yahan takh new logic
    }
    if (distanceFromPickToDropOff <= _fare.totalMileageAllows) {
      // console.log(distanceFromPickToDropOff, "distanceFromPickToDropOff")
      rideFare = distanceFromPickToDropOff * _fare.farePerMile;
      obj.rideFare = rideFare;
      obj.rideFareStructure = {
        values: {
          distanceFromPickToDropOff: distanceFromPickToDropOff,
          farePerMile: _fare.farePerMile,
        },
        valuesFormula: "distanceFromPickToDropOff * farePerMile",
      };
    } else {
      // yahan se new logic
      if (_fare.totalMileageAllows) {
        rideFare = distanceFromPickToDropOff * _fare.longDistancePerMile;
        obj.rideFare = rideFare;
        obj.rideFareStructure = {
          values: {
            distanceFromPickToDropOff: distanceFromPickToDropOff,
            longDistancePerMile: _fare.longDistancePerMile,
          },
          valuesFormula: "distanceFromPickToDropOff * longDistancePerMile",
        };
      } else {
        rideFare = distanceFromPickToDropOff * _fare.farePerMile;
        obj.rideFare = rideFare;
        obj.rideFareStructure = {
          values: {
            distanceFromPickToDropOff: distanceFromPickToDropOff,
            farePerMile: _fare.farePerMile,
          },
          valuesFormula: "distanceFromPickToDropOff * farePerMile",
        };
      }
      // yahan takh
    }

    const company = auth?.user?.companyId;
    // console.log(company)
    for (let key of Object.keys(company.availability)) {
      if (key === moment(returnStopArr[0].date).format("dddd")) {
        if (company.availability[key] === false) {
          offHourFee += _fare.offHourFee ? _fare.offHourFee : 0;
          obj.offHourFee = offHourFee;
        }
      }
    }
    if (willCall !== true) {
      if (offHourFee === 0) {
        if (
          parseInt(company?.offHours?.from.substring(0, 2)) <=
            parseInt(returnStopArr[0].time.substring(0, 2)) &&
          parseInt(returnStopArr[0].time.substring(0, 2)) <=
            parseInt(company?.offHours?.to.substring(0, 2))
        ) {
          if (
            parseInt(company?.offHours?.from.substring(3, 5)) <=
            parseInt(returnStopArr[0].time.substring(3, 5))
          ) {
            offHourFee += _fare.offHourFee ? _fare.offHourFee : 0;
            obj.offHourFee = offHourFee;
          }
        }
        // else {
        //   console.log("normal")
        // }
      }
    }
    if (offHourFee === 0) {
      amount = (rideFare + baseFare + deadheadFare).toFixed(2);
      // yahan se new logic
      if (useHardCodedFares === true) {
        amount =
          parseFloat(amount) +
          parseFloat(hardCodedFares.additionalCharges) -
          parseFloat(hardCodedFares.deduction);
      }
      obj.amount = parseFloat(amount);
      obj.amountStructure = {
        values: {
          rideFare: rideFare,
          baseFare: baseFare,
          deadheadFare: deadheadFare,
          ...(useHardCodedFares === true && {
            additionalCharges: parseFloat(hardCodedFares.additionalCharges),
            deduction: parseFloat(hardCodedFares.deduction),
          }),
        },
        valuesFormula: "rideFare + baseFare + deadheadFare",
      };
      // yahan takh
    } else {
      amount = (rideFare + baseFare + offHourFee + deadheadFare).toFixed(2);
      // yahan se new logic
      if (useHardCodedFares === true) {
        amount =
          parseFloat(amount) +
          parseFloat(hardCodedFares.additionalCharges) -
          parseFloat(hardCodedFares.deduction);
      }
      obj.amount = parseFloat(amount);
      obj.amountStructure = {
        values: {
          rideFare: rideFare,
          baseFare: baseFare,
          deadheadFare: deadheadFare,
          offHourFee: offHourFee,
          ...(useHardCodedFares === true && {
            additionalCharges: parseFloat(hardCodedFares.additionalCharges),
            deduction: parseFloat(hardCodedFares.deduction),
          }),
        },
        valuesFormula: "rideFare + baseFare + offHourFee + deadheadFare",
      };
      // yahan takh
    }
    // yahan se new logic
    let m = 0;
    let hr = 0;
    let tempHr = 0;
    eta.mins.forEach((e) => {
      m += e;
    });
    tempHr = Math.floor(m / 60);
    m = m % 60;
    eta.hour.forEach((e) => {
      hr += e;
    });
    hr += tempHr;
    let estimatedTime = hr !== 0 ? hr + " h " + m + " m" : m + " m";
    obj.estimatedTime = estimatedTime;
    let newDropOffEta = new Date(
      returnStopArr[0].date.substring(0, 10) +
        "T" +
        returnStopArr[0].time +
        ":00.000Z"
    ).getTime();
    let addNewDropOffEta = (hr * 60 + m) * 60 * 1000;
    newDropOffEta = new Date(addNewDropOffEta + newDropOffEta).toUTCString();
    newDropOffEta = newDropOffEta.toString().split(" ")[4];
    let etaTime = newDropOffEta.substring(0, 5);
    obj.etaTime = etaTime;
    let driverAmount = parseFloat(
      (amount * (_fare?.driverPercentage / 100)).toFixed(2)
    );
    let companyAmount = parseFloat((amount - driverAmount).toFixed(2));
    obj.driverPercentage = _fare?.driverPercentage;
    obj.driverAmount = driverAmount;
    obj.companyAmount = companyAmount;
    setEta(estimatedTime);
    setHardCodedFares({
      ...hardCodedFares,
      flatBaseFare: _fare.flatBaseFare,
      farePerMile: _fare.farePerMile,
      deadHeadThreshold: _fare.deadHeadThreshold,
      baseFarePerMile: _fare.baseFarePerMile,
      totalMileageAllows: _fare.totalMileageAllows,
      longDistancePerMile: _fare.longDistancePerMile,
      offHourFee: _fare.offHourFee,
      noShowFee: _fare.noShowFee,
      cancelationFee: _fare.cancelationFee,
      distanceFromBaseLocation: baseToPickUpR,
      apiDistanceFromPickToDropOff: obj.apiDistanceFromPickToDropOff,
      apiDistanceFromBaseLocation: obj.apiDistanceFromBaseLocation,
      distanceFromPickToDropOff: obj.distanceFromPickToDropOff,
      precalculatedMiles: obj.precalculatedMiles,
      usePrecalculatedMiles: obj.usePrecalculatedMiles,
      avgDistanceFromPickToDropOff: obj.avgDistanceFromPickToDropOff,
      useAvgDistance: obj.useAvgDistance,
      driverPercentage: _fare?.driverPercentage,
      deadheadFare: deadheadFare,
      baseFarePerMile: deadheadFare == 0 ? 0 : _fare.baseFarePerMile,
      deadHeadThreshold:
        !_fare?.deadHeadThreshold || _fare?.deadHeadThreshold == NaN
          ? 0
          : _fare.deadHeadThreshold,
    });
    // yahan takh new logic
    setAmount(obj.amount);
    setMil(obj.distanceFromPickToDropOff);
    setFareDetails(obj);
    // yahan se new logic
    setStopArrUseApi(false);
    // yahan takh new logic
    setValues2({
      ...values2,
      fareAndDistance: obj,
      totalAmount: obj.amount,
      totalMileage: obj.totalMileage,
      rideHistory: returnStopArr,
      distanceFromBaseLocation: baseToPickUpR,
      distanceFromPickToDropOff: obj.distanceFromPickToDropOff,
      isCompany: details?.isCompany === true ? true : false,
      externalCompanyId: details.externalCompanyId
        ? details.externalCompanyId
        : "",
    });
  };
  // console.log(values2, "objobjobjobj2" )
  const [mil, setMil] = useState(0);
  // yahan se new logic
  const [stopArrUseApi, setStopArrUseApi] = useState(false);
  // yahan takh new logic
  useEffect(() => {
    // yahan se new logic
    if (stopArrUseApi === true) {
      if (returnStopArr.length >= 2) {
        let cond = true;

        returnStopArr.forEach((e) => {
          if (e.address === "") {
            cond = false;
          }
        });
        if (willCall === false) {
          if (returnStopArr[0].time === "" || returnStopArr[0].date === "") {
            cond = false;
          }
        } else {
          if (returnStopArr[0].date === "") {
            cond = false;
          }
        }
        if (cond === true) {
          calculateFareFrontEnd();
        }
      }
    }
    // yahan takh new logic
  }, [returnStopArr]);
  return (
    <>
      {returnStopArr.length > 0 &&
        returnStopArr.map((current, i) => {
          return (
            <div className="row col-lg-12" key={i}>
              <div className="col-sm-6 pl-4">
                <div className="form-group my-3">
                  <div className="d-flex justify-content-between">
                    <div>
                      <div className="d-flex">
                        <div>
                          <label
                            className="f-14 text-dark mb-12"
                            data-label=""
                            htmlFor="address3"
                          >
                            {/* Pickup Address */}
                            {i === 0 ? "Pickup Address" : "Dropoff Address"}
                          </label>
                        </div>
                        <div className="p-1">
                          <sup className="text-danger f-12">*</sup>
                        </div>
                      </div>
                    </div>
                  </div>
                  <SearchedByMap
                    id="address3"
                    stopArr={returnStopArr}
                    setStopArr={setReturnStopArr}
                    field="address"
                    index={i}
                    setStopArrUseApi={setStopArrUseApi}
                    searchAddress={current.address.address}
                  />
                </div>
              </div>

              <div className="col-sm-3 pl-4">
                <div className="form-group my-3">
                  <div className="d-flex">
                    {i === 0 && (
                      <>
                        <div>
                          <label
                            className="f-14 text-dark mb-12"
                            data-label=""
                            htmlFor="driver1"
                          >
                            Pickup Date
                            {/* {i === 0 ? "Pickup Date" : "Dropoff Date"} */}
                          </label>
                        </div>
                        <div className="p-1">
                          <sup className="text-danger f-12">*</sup>
                        </div>
                      </>
                    )}
                  </div>
                  {i === 0 && (
                    <input
                      type="date"
                      className="form-control height-35 f-14"
                      placeholder=""
                      id="driver1"
                      min="0"
                      value={returnStopArr[i].date}
                      onChange={handleStopChange(i, "date")}
                    />
                  )}
                </div>
              </div>
              {willCall !== true && (
                <div className="col-sm-3 pl-4">
                  <div className="form-group my-3">
                    <div className="d-flex">
                      {i === 0 && (
                        <>
                          <div>
                            <label
                              className="f-14 text-dark mb-12"
                              htmlFor="driver3"
                            >
                              Pickup Time
                            </label>
                          </div>
                          <div className="p-1">
                            <sup className="text-danger f-12">*</sup>
                          </div>
                        </>
                      )}
                    </div>

                    {/* Render time input field only for the first item (i === 0) */}
                    {i === 0 && (
                      <input
                        type="time"
                        className="form-control height-35 f-14"
                        placeholder=""
                        id="driver3"
                        value={returnStopArr[i].time} // Assuming `returnStopArr` is an array with `time` property
                        onChange={handleStopChange(i, "time")} // Call function to handle changes
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          );
        })}
    </>
  );
};
export default ExternalCompanyBookingReturnTrip;
