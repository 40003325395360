/** @format */

import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import {
  getAllReservationV2,
  getTodayReservationsByCompanyId,
} from "../../../actions/reservationAction";
import moment from "moment";
import AdminBookingTodayBookingRow from "./AdminBookingTodayBookingRow";
import { Link, NavLink, useLocation } from "react-router-dom";
import shift1 from "../../../assets/images/load.png";
import NotAllowed from "../../NotAllowed";
import { paginationDataToShow } from "../../../actions/generalActions";
import LoadingComponentRideModal from "../../LoadingComponentRideModal";
import { allFacilities } from "../../../actions/kpiActions";

const AdminBookingTodayBooking = ({ setExportData, isRefreshing }) => {
  const auth = useSelector((state) => state.auth);
  const reservation = useSelector((state) => state.reservation);
  const { loading } = reservation;
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };
  const dispatch = useDispatch();

  // const reservationData = async (e) => {
  //   dispatch(
  //     getAllReservationV2({
  //       companyId: auth.user.companyId.id,
  //       today: moment().format("YYYY-MM-DD"),
  //       isToday: true,
  //     })
  //   );
  // };

  const reservationData = async (e) => {
    const chicagoDateOptions = {
      timeZone: "America/Chicago",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    const chicagoDate = new Date().toLocaleDateString(
      "en-US",
      chicagoDateOptions
    );
    const parts = chicagoDate.split("/");
    const formattedChicagoDate = `${parts[2]}-${parts[0]}-${parts[1]}`;

    // dispatch(
    //   getAllReservationV2(
    //     {
    //       companyId: auth.user.companyId.id,
    //       today: formattedChicagoDate,
    //       isToday: true,
    //     },
    //     tempData
    //   )
    // );
    dispatch(
      getTodayReservationsByCompanyId(
        {
          companyId: auth.user.companyId.id,
          today: formattedChicagoDate,
          isToday: true,
        },
        tempData
      )
    );
    dispatch(allFacilities({ companyId: auth.user.companyId.id }));
  };

  const tempData = (data) => {
    // setFullData(data?.today);
    setFullData(data);
  };
  // console.log(reservationData);

  useEffect(() => {
    reservationData();
  }, []);

  // State to manage pagination, sorting, and limit
  const [currentPage, setCurrentPage] = useState(1);
  const [sortField, setSortField] = useState("pickUpTime");
  const [sortOrder, setSortOrder] = useState("asc"); //asc, desc
  const [searchTerm, setSearchTerm] = useState("");
  const [limit, setLimit] = useState(50); // Set your default limit here

  // const [paginatedData, setPaginatedData] = useState({});

  // Function to handle pagination, sorting, and searching
  const handlePagination = (page, field, order, search, newLimit) => {
    let newPage;
    if (page < 1) {
      newPage = 1;
    } else if (page > paginatedData.totalPages) {
      newPage = paginatedData.totalPages;
    } else {
      newPage = page;
    }
    setCurrentPage(newPage);
    setSortField(field);
    setSortOrder(order);
    setSearchTerm(search);
    setLimit(newLimit);
  };

  // useEffect(() => {
  //   const fullData = reservation?.reservation?.today;
  //   // Use the pagination function to get the data for the current page
  //   setPaginatedData(
  //     paginationDataToShow(
  //       limit,
  //       currentPage,
  //       fullData,
  //       sortField,
  //       sortOrder,
  //       searchTerm
  //     )
  //   );
  // }, [
  //   limit,
  //   currentPage,
  //   sortField,
  //   sortOrder,
  //   searchTerm,
  //   reservation?.reservation,
  // ]);
  const [fullData, setFullData] = useState(reservation?.reservation?.today);
  const { today } = reservation?.reservation;
  useEffect(() => {
    if (hideAssign === true) {
      setCompleteData(today);
      setFullData(today.filter((item) => !item?.driverId));
    } else if (showCompleted === true) {
      setCompleteData(today);
      setFullData(
        today.filter(
          (item) =>
            item.status === "Completed" ||
            item.status === "Canceled" ||
            item.status === "No Show"
        )
      );
    } else if (showNotCompleted === true) {
      setCompleteData(today);
      setFullData(
        today.filter(
          (item) => item.status === "Requested" || item.status === "Scheduled"
        )
      );
    } else if (showActive === true) {
      setCompleteData(today);
      setFullData(today.filter((item) => item.status === "In Progress"));
    } else {
      setFullData(today);
    }
  }, [today]);
  // Use the pagination function to get the data for the current page
  const [paginatedData, setPaginatedData] = useState(
    paginationDataToShow(
      limit,
      currentPage,
      fullData,
      sortField,
      sortOrder,
      searchTerm
    )
  );

  useEffect(() => {
    setPaginatedData(
      paginationDataToShow(
        limit,
        currentPage,
        fullData,
        sortField,
        sortOrder,
        searchTerm
      )
    );
  }, [limit, currentPage, fullData, sortField, sortOrder, searchTerm]);
  useEffect(() => {
    if (paginatedData.results) {
      setExportData(paginatedData.results);
    }
    // Use the pagination function to get the data for the current page
  }, [paginatedData]);
  // Function to handle sorting icon click
  const handleSortClick = (field, order) => {
    // Call handlePagination with sorting parameters
    handlePagination(currentPage, field, order, searchTerm, limit);
  };
  // Function to determine the color based on the current sorting order
  const getArrowColor = (field, direction) => {
    return sortField === field && sortOrder === direction
      ? "#000000"
      : "#A0A0A0";
  };
  // console.log(paginatedData, "paginatedData1");

  const locationUrl = useLocation();
  const queryParams = new URLSearchParams(locationUrl.search);
  const isCancelParam = queryParams.get("cancel");
  const isNoshowParam = queryParams.get("noshow");
  const isActiveParam = queryParams.get("active");
  const isCompletedParam = queryParams.get("completed");
  const isNotStartedParam = queryParams.get("notstarted");
  // console.log( "iscancelparam --> ", isCancelParam);

  useEffect(() => {
    if (isCancelParam == "true") {
      setSearchTerm("Canceled");
    }
    if (isNoshowParam == "true") {
      setSearchTerm("No Show");
    }
    if (isActiveParam == "true") {
      setSearchTerm("In Progress");
    }
    if (isCompletedParam == "true") {
      setSearchTerm("Completed");
    }
    if (isNotStartedParam == "true") {
      setSearchTerm("not started");
    }
  }, []);

  const [hideAssign, setHideAssign] = useState(false);
  const [showCompleted, setShowCompleted] = useState(false);
  const [showNotCompleted, setShowNotCompleted] = useState(false);
  const [showActive, setShowActive] = useState(false);
  const [completeData, setCompleteData] = useState([]);

  const openHideAssign = () => {
    setShowCompleted(false);
    setShowNotCompleted(false);
    setShowActive(false);
    if (hideAssign === false) {
      setCompleteData(reservation?.reservation?.today);
      setFullData(
        reservation?.reservation?.today?.filter((item) => !item?.driverId)
      );
    } else {
      setFullData(completeData);
    }
    setHideAssign((hideAssign) => !hideAssign);
  };
  const openShowCompleted = () => {
    setHideAssign(false);
    setShowNotCompleted(false);
    setShowActive(false);
    if (showCompleted === false) {
      setCompleteData(reservation?.reservation?.today);
      setFullData(
        reservation?.reservation?.today?.filter(
          (item) =>
            item.status === "Completed" ||
            item.status === "Canceled" ||
            item.status === "No Show"
        )
      );
    } else {
      setFullData(completeData);
    }
    setShowCompleted((showCompleted) => !showCompleted);
  };
  const openShowNotCompleted = () => {
    setHideAssign(false);
    setShowCompleted(false);
    setShowActive(false);
    if (showNotCompleted === false) {
      setCompleteData(reservation?.reservation?.today);
      setFullData(
        reservation?.reservation?.today?.filter(
          (item) => item.status === "Requested" || item.status === "Scheduled"
        )
      );
    } else {
      setFullData(completeData);
    }
    setShowNotCompleted((showNotCompleted) => !showNotCompleted);
  };
  const openShowActive = () => {
    setHideAssign(false);
    setShowCompleted(false);
    setShowNotCompleted(false);
    if (showActive === false) {
      setCompleteData(reservation?.reservation?.today);
      setFullData(
        reservation?.reservation?.today?.filter(
          (item) => item.status === "In Progress"
        )
      );
    } else {
      setFullData(completeData);
    }
    setShowActive((showActive) => !showActive);
  };

  return auth?.user?.permissionId?.booking?.read === true ? (
    <>
      {loading && !reservation?.reservation && (
        // <LoadingComponentRideModal loadingTable />
        <LoadingComponentRideModal />
      )}
      {isRefreshing && <LoadingComponentRideModal />}

      {!isRefreshing && reservation?.reservation && (
        <>
          <div className="d-flex flex-column w-tables rounded mt-3 bg-white">
            <div className="d-flex ">
              <div className="flex-grow-1">
                <div
                  className="dataTables_length d-flex"
                  id="leads-table_length"
                >
                  <div className="p-2">
                    <label>Show entries</label>
                  </div>
                  <label>
                    <select
                      name="leads-table_length"
                      aria-controls="leads-table"
                      className="custom-select custom-select-sm form-control form-control-sm"
                      autoComplete="false"
                      value={limit} // Make sure to set the value attribute to the current limit
                      onChange={(e) =>
                        handlePagination(
                          1,
                          sortField,
                          sortOrder,
                          searchTerm,
                          parseInt(e.target.value)
                        )
                      }
                    >
                      {[10, 25, 50, 100].map((current, i) => {
                        return (
                          <option value={current} key={i}>
                            {current}
                          </option>
                        );
                      })}
                    </select>
                  </label>
                </div>
              </div>
              <div className="col-md-6 d-flex align-items-center justify-content-center">
                <div className="form-check">
                  <input
                    type="checkbox"
                    checked={showCompleted}
                    onChange={openShowCompleted}
                  />
                  <label
                    className="form-check-label pl-2 f-14 text-dark mx-1"
                    htmlFor="flexCheckDefault"
                  >
                    Completed
                  </label>
                  <input
                    type="checkbox"
                    checked={showActive}
                    onChange={openShowActive}
                  />
                  <label
                    className="form-check-label pl-2 f-14 text-dark mx-1"
                    htmlFor="flexCheckDefault"
                  >
                    Active
                  </label>
                  <input
                    type="checkbox"
                    checked={showNotCompleted}
                    onChange={openShowNotCompleted}
                  />
                  <label
                    className="form-check-label pl-2 f-14 text-dark mx-1"
                    htmlFor="flexCheckDefault"
                  >
                    Not Started
                  </label>
                  <input
                    type="checkbox"
                    checked={hideAssign}
                    onChange={openHideAssign}
                  />
                  <label
                    className="form-check-label pl-2 f-14 text-dark mx-1"
                    htmlFor="flexCheckDefault"
                  >
                    Hide Assign
                  </label>
                </div>
              </div>
              <div className="task-search task-search-new search-col-table d-flex py-1 px-lg-3 px-0 align-items-center">
                <form
                  className="w-100 mr-1 mr-lg-0 mr-md-1 ml-md-1 ml-0 ml-lg-0"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="input-group bg-grey rounded">
                    <div className="input-group-prepend">
                      <span className="input-group-text border-0 bg-additional-grey">
                        <i className="fa fa-search f-13 text-dark-grey"></i>
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control f-14 p-1 border-additional-grey"
                      // id="search-text-field"
                      placeholder="Start typing to search"
                      value={searchTerm}
                      onChange={(e) =>
                        handlePagination(
                          1,
                          sortField,
                          sortOrder,
                          e.target.value,
                          limit
                        )
                      }
                    />
                  </div>
                </form>
              </div>
            </div>
            <div
              id="leads-table_wrapper"
              className="dataTables_wrapper dt-bootstrap4 no-footer"
            >
              <div className="row">
                <div className="col-sm-12">
                  <table
                    className="table border w-100 dataTable no-footer"
                    id="leads-table"
                    role="grid"
                    aria-describedby="leads-table_info"
                    style={{ overflowX: "auto" }}
                  >
                    <thead className="card-header">
                      <tr role="row">
                        <th
                          title="Order ID"
                          className="sorting border-right align-table-header min-width-orderId"
                          tabIndex="0"
                          aria-controls="leads-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Mobile: activate to sort column ascending"
                        >
                          Order ID <br />
                          <span className="right-sorting-new">
                            <i
                              className="fas fa-long-arrow-alt-up"
                              style={{ color: getArrowColor("orderId", "asc") }}
                              onClick={() => handleSortClick("orderId", "asc")}
                            ></i>
                            <i
                              className="fas fa-long-arrow-alt-down"
                              style={{
                                color: getArrowColor("orderId", "desc"),
                              }}
                              onClick={() => handleSortClick("orderId", "desc")}
                            ></i>
                          </span>
                        </th>
                        <th
                          title="Load#"
                          className="sorting border-right align-table-header"
                          tabIndex="0"
                          aria-controls="leads-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Mobile: activate to sort column ascending"
                        >
                          Load# <br />
                          <span className="right-sorting-new">
                            <i
                              className="fas fa-long-arrow-alt-up"
                              style={{
                                color: getArrowColor("loadNumber", "asc"),
                              }}
                              onClick={() =>
                                handleSortClick("loadNumber", "asc")
                              }
                            ></i>
                            <i
                              className="fas fa-long-arrow-alt-down"
                              style={{
                                color: getArrowColor("loadNumber", "desc"),
                              }}
                              onClick={() =>
                                handleSortClick("loadNumber", "desc")
                              }
                            ></i>
                          </span>
                        </th>
                        <th
                          title="Driver Name"
                          className="sorting border-right align-table-header min-width-orderId"
                          tabIndex="0"
                          aria-controls="leads-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Mobile: activate to sort column ascending"
                        >
                          Driver Name{" "}
                          <span className="right-sorting-new">
                            <i
                              className="fas fa-long-arrow-alt-up"
                              style={{
                                color: getArrowColor(
                                  "driverId.firstName",
                                  "asc"
                                ),
                              }}
                              onClick={() =>
                                handleSortClick("driverId.firstName", "asc")
                              }
                            ></i>
                            <i
                              className="fas fa-long-arrow-alt-down"
                              style={{
                                color: getArrowColor(
                                  "driverId.firstName",
                                  "desc"
                                ),
                              }}
                              onClick={() =>
                                handleSortClick("driverId.firstName", "desc")
                              }
                            ></i>
                          </span>
                        </th>

                        <th
                          title="Vehicle#"
                          className="sorting_disabled border-right align-table-header"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Next Follow Up"
                        >
                          Vehicle# <br />
                          <span className="right-sorting-new">
                            <i
                              className="fas fa-long-arrow-alt-up"
                              style={{
                                color: getArrowColor("assetId.assetNo", "asc"),
                              }}
                              onClick={() =>
                                handleSortClick("assetId.assetNo", "asc")
                              }
                            ></i>
                            <i
                              className="fas fa-long-arrow-alt-down"
                              style={{
                                color: getArrowColor("assetId.assetNo", "desc"),
                              }}
                              onClick={() =>
                                handleSortClick("assetId.assetNo", "desc")
                              }
                            ></i>
                          </span>
                        </th>
                        <th
                          title="Time"
                          className="sorting border-right align-table-header"
                          tabIndex="0"
                          aria-controls="leads-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Lead Agent: activate to sort column ascending"
                        >
                          Time <br />
                          <span className="right-sorting-new">
                            <i
                              className="fas fa-long-arrow-alt-up"
                              style={{
                                color: getArrowColor("pickUpTime", "asc"),
                              }}
                              onClick={() =>
                                handleSortClick("pickUpTime", "asc")
                              }
                            ></i>
                            <i
                              className="fas fa-long-arrow-alt-down"
                              style={{
                                color: getArrowColor("pickUpTime", "desc"),
                              }}
                              onClick={() =>
                                handleSortClick("pickUpTime", "desc")
                              }
                            ></i>
                          </span>
                        </th>
                        <th
                          title="Passenger"
                          className="sorting border-right align-table-header"
                          tabIndex="0"
                          aria-controls="leads-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Lead Agent: activate to sort column ascending"
                        >
                          Passenger{" "}
                          {/* <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{
                            color: getArrowColor("customerId.firstName", "asc"),
                          }}
                          onClick={() =>
                            handleSortClick("customerId.firstName", "asc")
                          }
                          ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{
                            color: getArrowColor("customerId.firstName", "desc"),
                          }}
                          onClick={() =>
                            handleSortClick("customerId.firstName", "desc")
                          }
                        ></i>
                      </span> */}
                        </th>
                        <th
                          title="Pickup - Drop Off"
                          className="sorting border-right w-30 align-table-header"
                          tabIndex="0"
                          aria-controls="leads-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Lead Agent: activate to sort column ascending"
                        >
                          Pickup - Drop Off{" "}
                          {/* <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{
                            color: getArrowColor("pickUpDate", "asc"),
                          }}
                          onClick={() => handleSortClick("pickUpDate", "asc")}
                          ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{
                            color: getArrowColor("pickUpDate", "desc"),
                          }}
                          onClick={() => handleSortClick("pickUpDate", "desc")}
                          ></i>
                        </span> */}
                        </th>
                        <th
                          title="Vehicle Type"
                          className="sorting border-right align-table-header"
                          tabIndex="0"
                          aria-controls="leads-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Lead Agent: activate to sort column ascending"
                        >
                          Vehicle Type{" "}
                          <span className="right-sorting-new">
                            <i
                              className="fas fa-long-arrow-alt-up"
                              style={{
                                color: getArrowColor("type.name", "asc"),
                              }}
                              onClick={() =>
                                handleSortClick("type.name", "asc")
                              }
                            ></i>
                            <i
                              className="fas fa-long-arrow-alt-down"
                              style={{
                                color: getArrowColor("type.name", "desc"),
                              }}
                              onClick={() =>
                                handleSortClick("type.name", "desc")
                              }
                            ></i>
                          </span>
                        </th>
                        <th
                          title="Driver Amount"
                          className="sorting border-right align-table-header"
                          tabIndex="0"
                          aria-controls="leads-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Lead Agent: activate to sort column ascending"
                        >
                          Driver Amount{" "}
                          {/* <span className="right-sorting-new">
                        <i
                          className="fas fa-long-arrow-alt-up"
                          style={{ color: getArrowColor("totalAmount", "asc") }}
                          onClick={() => handleSortClick("totalAmount", "asc")}
                          ></i>
                        <i
                          className="fas fa-long-arrow-alt-down"
                          style={{ color: getArrowColor("totalAmount", "desc") }}
                          onClick={() => handleSortClick("totalAmount", "desc")}
                        ></i>
                      </span> */}
                        </th>
                        <th
                          title="Status"
                          className="sorting border-right align-table-header"
                          tabIndex="0"
                          aria-controls="leads-table"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Lead Agent: activate to sort column ascending"
                        >
                          Status <br />
                          <span className="right-sorting-new">
                            <i
                              className="fas fa-long-arrow-alt-up"
                              style={{ color: getArrowColor("status", "asc") }}
                              onClick={() => handleSortClick("status", "asc")}
                            ></i>
                            <i
                              className="fas fa-long-arrow-alt-down"
                              style={{ color: getArrowColor("status", "desc") }}
                              onClick={() => handleSortClick("status", "desc")}
                            ></i>
                          </span>
                        </th>
                        <th
                          title="Actions"
                          className="text-right pr-20 sorting_disabled border-right align-table-header"
                          rowSpan="1"
                          colSpan="1"
                          aria-label="Action"
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {reservation &&
                        reservation?.reservation &&
                        reservation?.reservation?.today &&
                        reservation?.reservation?.today.length > 0 &&
                        // reservation?.reservation?.today.map((current, i) => {
                        paginatedData.results.map((current, i) => {
                          return (
                            <AdminBookingTodayBookingRow
                              current={current}
                              i={i}
                              key={i}
                              fullData={fullData}
                              setFullData={setFullData}
                            />
                          );
                        })}
                    </tbody>
                  </table>
                  {reservation &&
                    reservation?.reservation &&
                    reservation?.reservation?.today &&
                    paginatedData?.results?.length === 0 && (
                      <div
                        className="dash-stats-list"
                        style={{ textAlign: "center" }}
                      >
                        <br />
                        <br />
                        <img
                          alt=""
                          src={shift1}
                          style={{ width: "145px", opacity: "0.5" }}
                        />
                        <br />
                        <p style={{ opacity: "0.5" }}>No Data Found</p>
                      </div>
                    )}
                  <div
                    id="leads-table_processing"
                    className="dataTables_processing card"
                    style={{ display: "none" }}
                  >
                    Processing...
                  </div>
                </div>
              </div>
              {reservation &&
                reservation.reservation &&
                reservation.reservation.today &&
                reservation.reservation.today.length > 0 &&
                paginatedData?.results?.length > 0 && (
                  <div className="d-flex ">
                    {reservation.loading === false ? (
                      <div className="flex-grow-1">
                        <div
                          className="dataTables_info"
                          id="leads-table_info"
                          role="status"
                          aria-live="polite"
                        >
                          Showing{" "}
                          {1 +
                            paginatedData.limit *
                              (paginatedData?.currentPageNumber - 1)}{" "}
                          to{" "}
                          {paginatedData.displayingResults +
                            paginatedData.limit *
                              (paginatedData?.currentPageNumber - 1)}{" "}
                          of {paginatedData.totalResults} entries
                        </div>
                      </div>
                    ) : (
                      <div className="flex-grow-1">
                        <div
                          className="dataTables_info"
                          id="leads-table_info"
                          role="status"
                          aria-live="polite"
                        >
                          Loading entries
                        </div>
                      </div>
                    )}
                    <div className="search-col-table">
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="leads-table_paginate"
                      >
                        <ul className="pagination">
                          <li className="page-item">
                            <NavLink
                              className="page-link"
                              to="#"
                              aria-label="Previous"
                              // onClick={onClickPrev}
                              onClick={(e) =>
                                handlePagination(
                                  currentPage - 1,
                                  sortField,
                                  sortOrder,
                                  searchTerm,
                                  limit
                                )
                              }
                            >
                              <span aria-hidden="true">Previous</span>
                              <span className="sr-only">Previous</span>
                            </NavLink>
                          </li>
                          {reservation &&
                            reservation.reservation &&
                            reservation.reservation.today &&
                            paginatedData.paginationArr &&
                            paginatedData.paginationArr.length > 0 &&
                            // reservation.reservation.previous.paginationArr.map((current, i) => {
                            // reservation.reservation.previous.paginationArr.slice(startIndex, endIndex).map((current, i) => {
                            Array.from(
                              {
                                length:
                                  paginatedData.endPage -
                                  paginatedData.startPage +
                                  1,
                              },
                              (_, i) => {
                                const current = paginatedData.startPage + i;
                                return paginatedData?.currentPageNumber ===
                                  current ? (
                                  <li className="page-item active" key={i}>
                                    <Link
                                      to=""
                                      className="page-link"
                                      value={current}
                                    >
                                      {current}
                                    </Link>
                                  </li>
                                ) : (
                                  <li className="page-item" key={i}>
                                    <Link
                                      className="page-link"
                                      value={current}
                                      onClick={(e) =>
                                        handlePagination(
                                          parseInt(current),
                                          sortField,
                                          sortOrder,
                                          searchTerm,
                                          limit
                                        )
                                      }
                                      to=""
                                    >
                                      {current}
                                    </Link>
                                  </li>
                                );
                              }
                            )}
                          <li className="page-item">
                            <NavLink
                              className="page-link"
                              to="#"
                              aria-label="Next"
                              // onClick={onClickNext}
                              onClick={(e) =>
                                handlePagination(
                                  currentPage + 1,
                                  sortField,
                                  sortOrder,
                                  searchTerm,
                                  limit
                                )
                              }
                            >
                              <span aria-hidden="true">Next</span>
                              <span className="sr-only">Next</span>
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </>
      )}
    </>
  ) : (
    <>
      <NotAllowed />
    </>
  );
};
export default AdminBookingTodayBooking;
