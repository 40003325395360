import React, { useState } from "react";
import { useSelector } from "react-redux";
import AdminSidebar from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminSidebar";
import AdminHeader from "../../components/adminComponents/adminHeaderSidebarTitleComponents/AdminHeader";
import ExcelExport from "../../ExcelExport";
import AdminLoadNoAnalysisReportTable from "../../components/adminComponents/adminReportComponents/AdminLoadNoAnalysisReportTable";

const AdminLoadNoAnalysisReportPage = () => {
  const kpi = useSelector((state) => state.kpi);

  const formatDate = (value) => {
    const firstSplit = value?.split("-route:")[0];
    const secondSplit = firstSplit?.split(":")[1];
    const chicagoDateOptions = {
      timeZone: "America/Chicago",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    const chicagoDate = new Date(secondSplit).toLocaleDateString(
      "en-US",
      chicagoDateOptions
    );
    return chicagoDate;
  };

  const [excelData, setExcelData] = useState([]);
  // const exportData = excelData;
  return (
    <>
      <AdminSidebar />
      <AdminHeader />
      <div className="body-wrapper clearfix">
        <section className="main-container " id="fullscreen">
          <div className="px-4 py-2 border-top-0 emp-dashboard">
            <div className="settings-box rounded">
              <div className="s-b-inner s-b-notifications bg-white b-shadow-4 rounded">
                <div className="s-b-n-header d-lg-flex justify-content-between card-header border-bottom-grey">
                  <div className="s-b-n-header" id="tabs">
                    <h2 className="mb-0 p-20 f-21 font-weight-normal text-capitalize">
                      <span>LOAD # ANALYSIS REPORTS</span>
                    </h2>
                  </div>
                  <ExcelExport
                    excelData={excelData}
                    fileName="Load No Analysis Report"
                    styling
                  />
                </div>
                <AdminLoadNoAnalysisReportTable setExcelData={setExcelData} />
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* </div> */}
    </>
  );
};

export default AdminLoadNoAnalysisReportPage;
