/** @format */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getClientFaresByCompanyId,
  getFaresByCompanyId,
  getPrivatesFaresByCompanyId,
} from "../../../actions/fareActions";
import AdminSettingPaymentsFareTableRow from "./AdminSettingPaymentsFareTableRow";
import Accordion from "react-bootstrap/Accordion";
import AdminSettingPaymentEditFareModal from "./AdminSettingPaymentEditFareModal";
// import { Accordion } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";

const AdminSettingPaymentsFareTable = () => {
  const dispatch = useDispatch();
  const auth = useSelector((x) => x.auth);

  const fare = useSelector((state) => state.fare);
  const location = useSelector((state) => state.location);

  const [modal, setModal] = useState({ open: false, data: null });
  useEffect(() => {
    dispatch(getFaresByCompanyId({ companyId: auth.user.companyId.id }));
    dispatch(
      getPrivatesFaresByCompanyId({ companyId: auth.user.companyId.id })
    );
    if (auth?.user?.permissionId?.isAdmin) {
      dispatch(
        getClientFaresByCompanyId({ companyId: auth.user.companyId.id })
      );
    }
  }, []);

  return (
    <>
      {location.baseLocation ? (
        <>
          <div className="s-b-n-content">
            <div className="tab-content" id="nav-tabContent">
              <div
                className="tab-pane fade show active"
                id="nav-email"
                role="tabpanel"
                aria-labelledby="nav-email-tab"
              >
                <div className="d-flex flex-wrap justify-content-between">
                  <div className="col-lg-12 col-md-12 ntfcn-tab-content-left w-100 p-4 ">
                    <div className="row">
                      <div className="col-sm-12">
                        <Accordion defaultActiveKey="0">
                          {fare &&
                          auth?.user?.permissionId?.accounting?.isAllow ? (
                            <table
                              className="table border w-100 dataTable no-footer"
                              id="leads-table"
                              role="grid"
                              aria-describedby="leads-table_info"
                              style={{ overflowX: "auto" }}
                            >
                              <thead className="card-header">
                                <tr role="row">
                                  <th
                                    title="Name"
                                    className="sorting border-right "
                                    tabIndex="0"
                                    aria-controls="leads-table"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Mobile: activate to sort column ascending"
                                  >
                                    Name
                                  </th>
                                  <th
                                    title="Service Type"
                                    className="sorting_disabled border-right"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Next Follow Up"
                                  >
                                    Service Type
                                  </th>
                                  {/* <th
                                      title="isFlatBaseFare"
                                      className="sorting_disabled border-right"
                                      rowSpan="1"
                                      colSpan="1"
                                      aria-label="Next Follow Up"
                                    >
                                      Flat Base Fare
                                    </th> */}
                                  <th
                                    title="Load Fee"
                                    className="sorting border-right"
                                    tabIndex="0"
                                    aria-controls="leads-table"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Lead Agent: activate to sort column ascending"
                                  >
                                    Load Fee
                                  </th>
                                  <th
                                    title="Standard Mileage Rate"
                                    className="sorting border-right"
                                    tabIndex="0"
                                    aria-controls="leads-table"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Lead Agent: activate to sort column ascending"
                                  >
                                    Standard Mileage Rate
                                  </th>
                                  <th
                                    title="Dead Head Threshold"
                                    className="sorting border-right"
                                    tabIndex="0"
                                    aria-controls="leads-table"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Lead Agent: activate to sort column ascending"
                                  >
                                    Dead Head Thres- hold
                                  </th>
                                  <th
                                    title="Deadhead Mileage Rate"
                                    className="sorting border-right"
                                    tabIndex="0"
                                    aria-controls="leads-table"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Lead Agent: activate to sort column ascending"
                                  >
                                    Dead head Mileage Rate
                                  </th>
                                  <th
                                    title="Long Distance Threshold"
                                    className="sorting border-right"
                                    tabIndex="0"
                                    aria-controls="leads-table"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Lead Agent: activate to sort column ascending"
                                  >
                                    Long Distance Thres- hold
                                  </th>
                                  <th
                                    title="Long Distance Per Mile"
                                    className="sorting border-right"
                                    tabIndex="0"
                                    aria-controls="leads-table"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Lead Agent: activate to sort column ascending"
                                  >
                                    Long Distance Per Mile
                                  </th>
                                  <th
                                    title="Off-hour Fee"
                                    className="sorting border-right"
                                    tabIndex="0"
                                    aria-controls="leads-table"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Lead Agent: activate to sort column ascending"
                                  >
                                    Off-hour Fee
                                  </th>
                                  <th
                                    title="No-show Fee"
                                    className="sorting border-right"
                                    tabIndex="0"
                                    aria-controls="leads-table"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Lead Agent: activate to sort column ascending"
                                  >
                                    No-show Fee
                                  </th>
                                  <th
                                    title="Cancelation Window"
                                    className="sorting border-right"
                                    tabIndex="0"
                                    aria-controls="leads-table"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Lead Agent: activate to sort column ascending"
                                  >
                                    Can- celation Window
                                  </th>
                                  <th
                                    title="Cancelation Fee"
                                    className="sorting border-right"
                                    tabIndex="0"
                                    aria-controls="leads-table"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Lead Agent: activate to sort column ascending"
                                  >
                                    Can- celation Fee
                                  </th>
                                  <th
                                    title="Driver Percentage"
                                    className="sorting border-right"
                                    tabIndex="0"
                                    aria-controls="leads-table"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Lead Agent: activate to sort column ascending"
                                  >
                                    Driver %
                                  </th>
                                  <th
                                    title="Action"
                                    className="text-right pr-20 sorting_disabled border-right"
                                    rowSpan="1"
                                    colSpan="1"
                                    aria-label="Action"
                                  >
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {fare.privateFare &&
                                  fare.privateFare.length > 0 &&
                                  fare.privateFare?.map((fares, i) => {
                                    return (
                                      <AdminSettingPaymentsFareTableRow
                                        current={fares}
                                        i={i}
                                        setModal={setModal}
                                        key={i}
                                      />
                                    );
                                  })}
                              </tbody>
                            </table>
                          ) : (
                            fare.fare &&
                            fare.fare.length > 0 &&
                            fare.fare.map((fareType, index) => (
                              <Accordion.Item eventKey={index} key={index}>
                                <Accordion.Header className="w-100">
                                  {fareType?.business?.name}
                                </Accordion.Header>
                                <Accordion.Body>
                                  <table
                                    className="table border w-100 dataTable no-footer"
                                    id="leads-table"
                                    role="grid"
                                    aria-describedby="leads-table_info"
                                    style={{ overflowX: "auto" }}
                                  >
                                    <thead className="card-header">
                                      <tr role="row">
                                        <th
                                          title="Name"
                                          className="sorting border-right align-table-header"
                                          tabIndex="0"
                                          aria-controls="leads-table"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Mobile: activate to sort column ascending"
                                        >
                                          Name
                                        </th>
                                        <th
                                          title="Service Type"
                                          className="sorting_disabled border-right align-table-header"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Next Follow Up"
                                        >
                                          Service Type
                                        </th>
                                        {/* <th
                                          title="isFlatBaseFare"
                                          className="sorting_disabled border-right"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Next Follow Up"
                                        >
                                          Flat Base Fare
                                        </th> */}
                                        <th
                                          title="Load Fee"
                                          className="sorting border-right align-table-header"
                                          tabIndex="0"
                                          aria-controls="leads-table"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Lead Agent: activate to sort column ascending"
                                        >
                                          Load Fee
                                        </th>
                                        <th
                                          title="Standard Mileage Rate"
                                          className="sorting border-right align-table-header"
                                          tabIndex="0"
                                          aria-controls="leads-table"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Lead Agent: activate to sort column ascending"
                                        >
                                          Standard Mileage Rate
                                        </th>
                                        <th
                                          title="Dead Head Threshold"
                                          className="sorting border-right align-table-header"
                                          tabIndex="0"
                                          aria-controls="leads-table"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Lead Agent: activate to sort column ascending"
                                        >
                                          Dead Head Threshold
                                        </th>
                                        <th
                                          title="Deadhead Mileage Rate"
                                          className="sorting border-right align-table-header"
                                          tabIndex="0"
                                          aria-controls="leads-table"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Lead Agent: activate to sort column ascending"
                                        >
                                          Deadhead Mileage Rate
                                        </th>
                                        <th
                                          title="Long Distance Threshold"
                                          className="sorting border-right align-table-header"
                                          tabIndex="0"
                                          aria-controls="leads-table"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Lead Agent: activate to sort column ascending"
                                        >
                                          Long Distance Threshold
                                        </th>
                                        <th
                                          title="Long Distance Per Mile"
                                          className="sorting border-right align-table-header"
                                          tabIndex="0"
                                          aria-controls="leads-table"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Lead Agent: activate to sort column ascending"
                                        >
                                          Long Distance Per Mile
                                        </th>
                                        <th
                                          title="Off-hour Fee"
                                          className="sorting border-right align-table-header"
                                          tabIndex="0"
                                          aria-controls="leads-table"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Lead Agent: activate to sort column ascending"
                                        >
                                          Off-hour Fee
                                        </th>
                                        <th
                                          title="No-show Fee"
                                          className="sorting border-right align-table-header"
                                          tabIndex="0"
                                          aria-controls="leads-table"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Lead Agent: activate to sort column ascending"
                                        >
                                          No-show Fee
                                        </th>
                                        <th
                                          title="Cancelation Window"
                                          className="sorting border-right align-table-header"
                                          tabIndex="0"
                                          aria-controls="leads-table"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Lead Agent: activate to sort column ascending"
                                        >
                                          Cancelation Window
                                        </th>
                                        <th
                                          title="Cancelation Fee"
                                          className="sorting border-right align-table-header"
                                          tabIndex="0"
                                          aria-controls="leads-table"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Lead Agent: activate to sort column ascending"
                                        >
                                          Cancelation Fee
                                        </th>
                                        <th
                                          title="Driver Percentage"
                                          className="sorting border-right align-table-header"
                                          tabIndex="0"
                                          aria-controls="leads-table"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Lead Agent: activate to sort column ascending"
                                        >
                                          Driver Percentage
                                        </th>
                                        <th
                                          title="Actions"
                                          className="sorting_disabled border-right align-table-header"
                                          // className="text-right pr-20 sorting_disabled border-right align-table-header"
                                          rowSpan="1"
                                          colSpan="1"
                                          aria-label="Action"
                                        >
                                          Actions
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {fareType?.details?.map((fares, i) => {
                                        return (
                                          <AdminSettingPaymentsFareTableRow
                                            current={fares}
                                            i={i}
                                            setModal={setModal}
                                            key={i}
                                          />
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </Accordion.Body>
                              </Accordion.Item>
                            ))
                          )}
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="s-b-n-content">
          <div className="tab-content" id="nav-tabContent">
            <div
              className="tab-pane fade show active"
              id="nav-email"
              role="tabpanel"
              aria-labelledby="nav-email-tab"
            >
              <div className="d-flex flex-wrap justify-content-between">
                <div className="col-lg-12 col-md-12 ntfcn-tab-content-left w-100 p-4 ">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group my-3">
                        <label
                          className="f-14 text-dark mb-12"
                          data-label=""
                          htmlFor="Name"
                        >
                          {" "}
                          First Fill the Base Location then Come Back
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {modal.open && (
        <AdminSettingPaymentEditFareModal {...{ modal, setModal }} />
      )}
    </>
  );
};

export default AdminSettingPaymentsFareTable;
