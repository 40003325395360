/** @format */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import defaultimg from "../../../assets/images/profile.jpg";
import moment from "moment";
import {
  deleteSettlement,
  updateSettlement,
} from "../../../actions/invoiceActions";
import ExcelExport from "../../../ExcelExport";

const AdminDiverSettlementSearchFilterRow = ({
  current,
  i,
  excel,
  // handleModal,
  // dropDownRow,
  // setDropDownRow,
}) => {
  const [showMoreVertDropDown, setshowMoreVertDropDown] = useState(false);
  const openMoreVertDropDown = () => {
    setshowMoreVertDropDown((showMoreVertDropDown) => !showMoreVertDropDown);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState(defaultimg);
  const [showDropDown, setshowDropDown] = useState(false);
  const [showDeleteDropDown, setshowDeleteDropDown] = useState(false);
  const openDropDown = () => {
    setshowDropDown((showDropDown) => !showDropDown);
  };
  const openDeleteDropDown = () => {
    setshowDeleteDropDown((showDeleteDropDown) => !showDeleteDropDown);
  };
  const invoices = useSelector((state) => state.invoices);
  const auth = useSelector((x) => x.auth);
  const vehicle = useSelector((x) => x.vehicle);
  const vehicleFound = vehicle?.vehicle?.find(
    (x) => x.id === current?.driverId?.vehicleId
  );
  const update = () => {
    const chicagoDateOptions = {
      timeZone: "America/Chicago",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    const chicagoDate = new Date().toLocaleDateString(
      "en-US",
      chicagoDateOptions
    );
    const parts = chicagoDate.split("/");
    const formattedChicagoDate = `${parts[2]}-${parts[0]}-${parts[1]}`;
    dispatch(
      updateSettlement({
        _id: current._id ? current._id : current.id,
        status: "Paid",
        payerId: auth.user.id,
        datePaid: formattedChicagoDate,
      })
    );
  };
  const deleteSet = () => {
    dispatch(
      deleteSettlement({
        _id: current.id,
        delete: true,
      })
    );
  };
  let subStr;
  let parts;
  let displayDate;
  if (current?.datePaid) {
    subStr = current?.datePaid?.substring(0, 10);
    parts = subStr?.split("-");
    displayDate = `${parts[1]}/${parts[2]}/${parts[0]}`;
  }
  const indexValue = invoices?.settlement?.findIndex(
    (obj) => obj.id == current.id
  );

  const allOrderIds = current?.reservations
    ?.map((order) => order.orderId)
    .join(", ");
  const [showAllOrders, setShowAllOrders] = useState(false);
  const handleMoreHover = () => {
    setShowAllOrders(true);
  };
  const handleMoreLeave = () => {
    setShowAllOrders(true);
    setTimeout(() => {
      setShowAllOrders(false);
    }, 10000);
  };

  const [showAllOrdersComp, setShowAllOrdersComp] = useState(false);
  const handleMoreHoverComp = () => {
    setShowAllOrdersComp(true);
  };
  const handleMoreLeaveComp = () => {
    setShowAllOrdersComp(true);
    setTimeout(() => {
      setShowAllOrdersComp(false);
    }, 10000);
  };

  const [isOpen, setIsOpen] = useState(false);
  const openDetail = () => {
    setIsOpen(!isOpen);
  };

  
  const data = current?.reservations?.map((e) => ({
    "Batch Number": current?.batchNumber,
    "Order Id": e?.orderId,
    "Driver First Name": current?.driverId?.firstName,
    "Driver Last Name": current?.driverId?.lastName,
    "Passenger First Name": e?.customerId?.firstName,
    "Passenger Last Name": e?.customerId?.lastName,
    Pickup: e?.pickUpAddress?.address,
    "Drop Off": e?.dropOffAddress?.address,
    "Driver Amount":
      "$" + parseFloat(e?.fareAndDistance?.driverAmount)?.toFixed(2),
  }));
  return (
    <>
      <tr
        id="row-20"
        role="row"
        className="odd border"
        key={i}
        // onMouseLeave={() => setshowMoreVertDropDown(false)}
        onClick={openDetail}
      >
        <td className="border-right text-14-new">
          {/* {indexValue + 1} */}
          <strong>
            {current?.batchNumber} &nbsp;
            {excel && (
              <div
                onClick={(event) => event.stopPropagation()}
                className="d-inline"
              >
                <ExcelExport
                  excelData={data}
                  fileName={`Detail Driver Settlement Report`}
                />
              </div>
            )}
          </strong>
        </td>
        <td className="border-right text-14-new">
          {`${current?.driverId?.firstName} ${current?.driverId?.lastName}`}
        </td>
        <td className="border-right text-14-new">
          {
            <div
              className="text-primary"
              onMouseEnter={handleMoreHover}
              onMouseLeave={handleMoreLeave}
              title={allOrderIds}
            >
              {current?.reservations?.length}
            </div>
          }
        </td>
        <td className="border-right text-14-new">
          {vehicleFound ? vehicleFound?.assetNo : "-"}
        </td>
        <td className="border-right text-14-new">
          {" "}
          {vehicleFound ? vehicleFound?.serviceType?.name : "-"}
        </td>
        {/* <td className="border-right text-14-new">
          {[
            ...new Set(
              current?.reservations?.map((item) => item?.assetId?.assetNo)
            ),
          ]?.map((x, i) => {
            if (
              i ===
              [
                ...new Set(
                  current?.reservations?.map((item) => item?.assetId?.assetNo)
                ),
              ].length -
                1
            ) {
              return x;
            } else {
              return x + ", ";
            }
          })}
        </td> */}
        <td className="border-right text-14-new">
          {
            // current?.dateRange?.from
            //   ?
            moment(current?.dateRange?.from?.substring(0, 10)).format("MMM D") +
              " - " +
              moment(current?.dateRange?.to?.substring(0, 10)).format(
                "MMM D, YYYY"
              )
            // : "N/A"
          }
        </td>
        <td className="border-right text-14-new">
          <strong>${parseFloat(current?.netAmount).toFixed(2)}</strong>
        </td>
      </tr>
      {current.reservations?.length > 0 &&
        current.reservations?.map((e, ind) => {
          return (
            <>
              <tr
                key={ind}
                className={`collapsing hiddenRow ${isOpen ? "" : "hide"}`}
              >
                <td colspan="7" className="p-1">
                  <div className="table table-responsive">
                    <table className="invoice-table table border table-center mb-0 ">
                      <thead className="card-header">
                        <tr>
                          <th className=" border-right">Batch#</th>
                          <th className=" border-right">Order ID</th>
                          <th className=" border-right">Driver Name</th>
                          <th className=" border-right">Passenger</th>
                          <th className=" border-right">Pickup - Drop Off</th>
                          <th className=" border-right">Driver Amount </th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className=" border-right">
                            {current.batchNumber}
                          </td>
                          <td className=" border-right">
                            <a href="#" target="_blank">
                              {e.orderId}{" "}
                            </a>
                          </td>
                          <td className=" border-right">
                            {current.driverId.firstName +
                              " " +
                              current.driverId.lastName}
                          </td>
                          <td className=" border-right">
                            {e?.customerId?.firstName +
                              " " +
                              e?.customerId?.lastName}
                          </td>
                          <td className="border-right w-30">
                            <div className="d-flex justify-content-between">
                              <div>
                                <div>
                                  <p className="f-12 font-medium mb-0">
                                    <strong>{e?.pickUpAddress?.address}</strong>{" "}
                                  </p>
                                </div>
                                <div>
                                  <time>
                                    {" "}
                                    {moment(
                                      e?.pickUpDate?.substring(0, 10)
                                    ).format("MM/DD/YYYY")}{" "}
                                    -{" "}
                                    {parseInt(
                                      e?.pickUpTime?.substring(0, 2)
                                    ) === 0
                                      ? 12
                                      : parseInt(
                                          e?.pickUpTime?.substring(0, 2)
                                        ) > 12
                                      ? parseInt(
                                          e?.pickUpTime?.substring(0, 2)
                                        ) - 12
                                      : parseInt(
                                          e?.pickUpTime?.substring(0, 2)
                                        )}
                                    {":" + e?.pickUpTime?.substring(3, 5)}
                                    {parseInt(e?.pickUpTime?.substring(0, 2)) >
                                    11
                                      ? "pm"
                                      : "am"}
                                  </time>
                                </div>
                              </div>
                              <div className="mt-4 px-4">
                                <div className="hidden sm:block">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="50"
                                    height="8"
                                    viewBox="0 0 40 6"
                                    fill="none"
                                  >
                                    <path
                                      d="M40 3L35 0.113249V5.88675L40 3ZM0 3.5H35.5V2.5H0V3.5Z"
                                      fill="black"
                                    ></path>
                                  </svg>
                                </div>
                                <p className="text-center font-medium">
                                  {/* <span>{e.distanceFromBaseLocation} {e.distanceFromPickToDropOff} {e.totalMileage} mi</span> */}
                                  <span>
                                    {e?.fareAndDistance?.distanceFromPickToDropOff?.toFixed(
                                      2
                                    )}{" "}
                                    mi
                                  </span>
                                </p>
                              </div>
                              <div>
                                <div>
                                  <p className="f-12 font-medium mb-0">
                                    <strong>
                                      {e?.dropOffAddress?.address}
                                    </strong>
                                  </p>
                                </div>
                                <div>
                                  <time>
                                    {" "}
                                    {moment(
                                      e?.dropOffDate?.substring(0, 10)
                                    ).format("MM/DD/YYYY")}{" "}
                                    -{" "}
                                    {parseInt(
                                      e?.dropOffTime?.substring(0, 2)
                                    ) === 0
                                      ? 12
                                      : parseInt(
                                          e?.dropOffTime?.substring(0, 2)
                                        ) > 12
                                      ? parseInt(
                                          e?.dropOffTime?.substring(0, 2)
                                        ) - 12
                                      : parseInt(
                                          e?.dropOffTime?.substring(0, 2)
                                        )}
                                    {":" + e?.dropOffTime?.substring(3, 5)}
                                    {parseInt(e?.dropOffTime?.substring(0, 2)) >
                                    11
                                      ? "pm"
                                      : "am"}
                                  </time>
                                </div>
                              </div>
                            </div>
                          </td>

                          <td className=" border-right">
                            <strong>
                              $
                              {parseFloat(
                                e?.fareAndDistance?.driverAmount
                              )?.toFixed(2)}
                            </strong>
                          </td>
                          <td>{e.status}</td>
                        </tr>
                      </tbody>
                      {/* <tfoot className="card-header">
                        <tr>
                          <td colspan="4"></td>
                          <td className="text-right border-right">Total </td>
                          <td colspan="2">$100</td>
                        </tr>
                      </tfoot> */}
                    </table>
                  </div>
                </td>
              </tr>
            </>
          );
        })}
    </>
  );
};
export default AdminDiverSettlementSearchFilterRow;

// paid and unpaid ka code
{
  /* <td className="border-right text-14-new">
          {current?.status === "Paid" ? (
            <span className="badge bg-success  p-1 px-2 text-white">Paid</span>
          ) : (
            <span className="badge bg-warning   p-1 px-2 text-white">Due</span>
          )}
        </td> */
}
{
  /* <td className=" text-right pr-20">
          <div className="task_view">
            <div className="dropdown">
              <Link
                className="task_view_more d-flex align-items-center justify-content-center dropdown-toggle"
                type="link"
                data-toggle="dropdown"
                onClick={openMoreVertDropDown}
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="icon-options-vertical icons"></i>
              </Link>
              <div
                className={`dropdown-menu dropdown-menu-right ${
                  showMoreVertDropDown
                    ? "dropdown-menu dropdown-menu-right show"
                    : "dropdown-menu dropdown-menu-right"
                }`}
                aria-labelledby="dropdownMenuLink-20"
              >
                {current.status !== "Paid" && (
                  <button onClick={update} className="dropdown-item">
                    <i className="fa fa-check-circle mr-2"></i> Mark as Paid
                  </button>
                )}
                <button
                  onClick={() =>
                    navigate(
                      `/driver-settlement/details/${current.id}?view=true`,
                      {
                        state: current,
                      }
                    )
                  }
                  className="dropdown-item"
                >
                  <i className="fa fa-eye mr-2"></i>
                  View
                </button>
                {current.status !== "Paid" && (
                  <button
                    onClick={() =>
                      navigate(
                        `/driver-settlement/details/${current.id}?edit=true`,
                        {
                          state: current,
                        }
                      )
                    }
                    className="dropdown-item"
                  >
                    <i className="fa fa-edit mr-2"></i>
                    Edit
                  </button>
                )}
                {current.status !== "Paid" && (
                  <button
                    className="dropdown-item delete-table-row"
                    to=""
                    data-id="20"
                    onClick={deleteSet}
                  >
                    <i className="fa fa-trash mr-2"></i> Delete
                  </button>
                )}
              </div>
            </div>
          </div>
        </td> */
}
