import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getReservationById,
  getTodayReservationsByCompanyId,
} from "../../../actions/reservationAction";
import {  SERVERADDRESS, TRIP_RESET_WAY_POINTS } from "../../../constants";
import axios from "axios";
import {
  getAllTripAndRouteTripOfDrivers,
  getTripWayPoints,
} from "../../../actions/tripActions";
import moment from "moment";
// import './Sidebar.css';

const MapSidebar = ({ trackingDrivers, onDriverSelect, setCenter }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [openInfoBox, setOpenInfoBox] = useState({}); // State to track which info-box is open
  // console.log(trackingDrivers, "trackingDrivers trackingDrivers");
  const auth = useSelector((state) => state.auth);
  const reservation = useSelector((state) => state.reservation);
  const trip = useSelector((state) => state.trip);
  const { tripMapData } = trip;
  const { reservationDetails } = reservation;
  // const availableDrivers = useSelector(
  //   (state) => state.users.allDriversWithoutPagination
  // );
  // console.log(reservation, "reservation");
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(moment());

  // const { loading } = reservation;

  const reservationData = async (e) => {
    const chicagoDateOptions = {
      timeZone: "America/Chicago",
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };

    const chicagoDate = new Date().toLocaleDateString(
      "en-US",
      chicagoDateOptions
    );
    const parts = chicagoDate.split("/");
    const formattedChicagoDate = `${parts[2]}-${parts[0]}-${parts[1]}`;

    setSelectedDate(moment(formattedChicagoDate));
    dispatch(
      getAllTripAndRouteTripOfDrivers({
        companyId: auth.user.companyId.id,
        date: formattedChicagoDate,
      })
    );
    // dispatch(
    //   getTodayReservationsByCompanyId({
    //     companyId: auth.user.companyId.id,
    //     today: formattedChicagoDate,
    //     isToday: true,
    //   })
    // );
  };

  useEffect(() => {
    reservationData();
  }, []);

  const toggleInfoBox = (driverId) => {
    setOpenInfoBox((prevState) => {
      // Close all info boxes and only open the clicked one
      const newState = {};
      newState[driverId] = !prevState[driverId];
      return newState;
    });
    onDriverSelect(driverId);
    dispatch({
      type: TRIP_RESET_WAY_POINTS,
      payload: null,
    });
    setRouteTripDetails({
      routeTripId:'',
      isCompleted: ''
    })
  };

  const handleSubmit = async (id, reservationId) => {
    // if (id && reservationId) {
      dispatch(getTripWayPoints({ tripId: id }));
      dispatch(getReservationById({ _id: reservationId }));
    // }
    // if (id && !reservationId) {
    //   dispatch(getTripWayPoints({ routeTripId: id }));
    // }
  };

  const [routeTripDetails, setRouteTripDetails] = useState({
    routeTripId:'',
    isCompleted: ''
  })
  const handleSubmitRoute = async (id, isCompleted) => {
      dispatch(getTripWayPoints({ routeTripId: id }));
      setRouteTripDetails({
        routeTripId:id,
        isCompleted: isCompleted
      })
  };

  // const handleDriverOrderClick = async (reservationId) => {
  //   // event.stopPropagation();
  //   try {
  //     const { data } = await axios.post(
  //       `${SERVERADDRESS}/v1/trip/getTripWayPoints`,
  //       {
  //         // companyId: auth?.user?.companyId?.id,
  //         reservationId: reservationId,
  //       }
  //     );
  //     // setRevenue(data);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const filteredDrivers = tripMapData
    ?.filter((driver) =>
      `${driver?.driverId?.firstName} ${driver?.driverId?.lastName}`
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      const nameA =
        `${a?.driverId?.firstName} ${a?.driverId?.lastName}`.toLowerCase();
      const nameB =
        `${b?.driverId?.firstName} ${b?.driverId?.lastName}`.toLowerCase();

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
  useEffect(() => {
    let intervalId;
    if (routeTripDetails.isCompleted === '' || routeTripDetails.isCompleted === true){
      if (reservationDetails?.status === "In Progress") {
        intervalId = setInterval(() => {
          dispatch(
            getTripWayPoints({
              tripId: reservationDetails?.trip?._id
                ? reservationDetails?.trip?._id
                : reservationDetails?.trip?.id,
            })
          );
        }, 10000);
      }
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [reservationDetails]);
  useEffect(() => {
    let intervalId;
    if (!routeTripDetails.isCompleted){
        intervalId = setInterval(() => {
          dispatch(
            getTripWayPoints({
              routeTripId: routeTripDetails.routeTripId
            })
          );
        }, 10000);
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [routeTripDetails]);
  const handleDate = (event) => {
    setSelectedDate(moment(event.target.value));
    dispatch(
      getAllTripAndRouteTripOfDrivers({
        companyId: auth.user.companyId.id,
        date: moment(event.target.value).format("YYYY-MM-DD"),
      })
    );
  };
  return (
    <div className="map-sidebar">
      {/* <div className="d-flex align-items-center"> */}
      <input
        type="text"
        placeholder="Search Driver"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="map-search-bar"
      />
      {/* <button
        type="button"
        className="btn-lightest-grey rounded f-12 p-2 mr-1 "
      >
        All
      </button>
    </div> */}
      <div className="d-flex justify-content-center w-100">
        <ul className="date-display d-flex align-items-center">
          <button
            className="btn btn-secondary py-0"
            onClick={() => {
              setSelectedDate(moment(selectedDate).subtract(1, "days"));
              dispatch(
                getAllTripAndRouteTripOfDrivers({
                  companyId: auth.user.companyId.id,
                  date: moment(selectedDate)
                    .subtract(1, "days")
                    .format("YYYY-MM-DD"),
                })
              );
            }}
          >
            <i className="fa fa-angle-left"></i>
          </button>
          <h6 className="pl-3 pr-3 m-0" id="date-info">
            <input
              type="date"
              className="form-control height-35 f-14"
              value={selectedDate.format("YYYY-MM-DD")}
              onChange={handleDate}
            />
          </h6>
          <button
            className="btn btn-secondary py-0"
            onClick={() => {
              setSelectedDate(moment(selectedDate).add(1, "days"));
              dispatch(
                getAllTripAndRouteTripOfDrivers({
                  companyId: auth.user.companyId.id,
                  date: moment(selectedDate)
                    .add(1, "days")
                    .format("YYYY-MM-DD"),
                })
              );
            }}
          >
            <i className="fa fa-angle-right"></i>
          </button>
        </ul>
      </div>
      <br />
      <div className="map-asset-list">
        {filteredDrivers?.map((x, i) => {
          return (
            <div
              key={i}
              className="map-asset-item"
              style={{ cursor: "pointer" }}
              // onClick={() => toggleInfoBox(x.id)}
              onClick={() => {
                toggleInfoBox(x.driverId.id);
                setCenter({ lat: x.latitude, lng: x.longitude });
              }}
            >
              <p>
                <strong>
                  Asset#:{" "}
                  {x?.driverId?.vehicleId?.assetNo
                    ? x?.driverId?.vehicleId?.assetNo
                    : "Not Assigned"}
                </strong>{" "}
                {/* - location */}
              </p>
              <p>
                <i className="fa fa-user" aria-hidden="true"></i>
                {` ${x?.driverId?.firstName} ${x?.driverId?.lastName}`}
              </p>
              {tripMapData &&
                tripMapData?.length > 0 &&
                x?.trips?.length > 0 &&
                x?.trips?.map((current, i) => {
                  // console.log(current, "current");
                  // if (current?.driverId?._id === x?.driverId?.id)
                  return (
                    <div
                      className="info-box"
                      style={{
                        marginTop: "10px",
                        backgroundColor: "#ADD8E6",
                        padding: "10px",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        // display: openInfoBox[x.id] ? "block" : "none",
                        display: openInfoBox[x.driverId.id] ? "block" : "none",
                      }}
                      key={i}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        // onClick={handleDriverOrderClick}
                        onClick={() =>
                          handleSubmit(
                            current._id ? current._id : current.id,
                            current.reservationId._id
                              ? current.reservationId._id
                              : current.reservationId.id
                          )
                        }
                      >
                        <p>
                          <strong>Order ID:</strong>{" "}
                          {current?.reservationId?.orderId}
                        </p>
                        <p>
                          <strong>Status:</strong>{" "}
                          {current?.reservationId?.status}
                        </p>
                      </div>
                    </div>
                  );
                })}
              {tripMapData &&
                tripMapData?.length > 0 &&
                x?.routeTrips?.length > 0 &&
                x?.routeTrips?.map((current, i) => {
                  // console.log(current, "current");
                  // if (current?.driverId?._id === x?.driverId?.id)
                  return (
                    <div
                      className="info-box"
                      style={{
                        marginTop: "10px",
                        backgroundColor: "#ADD8E6",
                        padding: "10px",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                        // display: openInfoBox[x.id] ? "block" : "none",
                        display: openInfoBox[x.driverId.id] ? "block" : "none",
                      }}
                      key={i}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        // onClick={handleDriverOrderClick}
                        onClick={() =>
                          handleSubmitRoute(current._id ? current._id : current.id, current?.isCompleted)
                        }
                      >
                        <p>
                          <strong>Route #:</strong>{" "}
                          {current?.routeId?.routeNumber}
                        </p>
                        <p>
                          <strong>Shift:</strong>{" "}
                          {current?.routeDateIdentifier.split("shiftTime:")[1]}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default MapSidebar;
